import { orderBy } from "lodash";
import moment from "moment";

const noBookingOrInvoicePriorDate = new Date("2025-04-01T00:00:00.000Z");

export function getNoBookingPriorDate(): Date {
  return new Date(noBookingOrInvoicePriorDate);
}

export function getNoInvoicePriorDate(): Date {
  return new Date(noBookingOrInvoicePriorDate);
}

export enum PaymentPeriod {
  M1 = "M1",
  M3 = "M3",
  M6 = "M6",
  M12 = "M12",
  Quarterly = "Quarterly",
}

export enum PaymentTime {
  InArrears = "InArrears",
  InAdvance = "InAdvance",
}

export interface PaymentPeriodDetail {
  paymentDate: Date;
  percentage: number;
  periodStart: Date;
  periodEnd: Date;
  numberOfMonths: number;
}

interface DayOfYear {
  day: number;
  month: number;
}

interface ITimespan {
  months?: number;
  days?: number;
}

type PaymentTranche =
  | {
      type: "dayOfYear";
      percentage: number;
      dueDate: DayOfYear;
    }
  | {
      type: "relative";
      percentage: number;
      dueDate: ITimespan;
    }
  | {
      type: string;
      percentage: number;
      dueDate: any;
    };

interface PaymentSettings {
  period: PaymentPeriod;
  paymentTime: PaymentTime;
  startDate: Date;
  paymentTranches?: PaymentTranche[];
}

interface BillingYear {
  startDate: Date;
  endDate: Date;
  periods: BillingPeriod[];
}

interface BillingPeriod {
  startDate: Date;
  endDate: Date;
  isFirstPeriod: boolean;
  isLastPeriod: boolean;
}

/*
General remark, when working with dates:
- Always use UTC dates to avoid timezone issues.
- Always use midnight as time to avoid time issues.
- NEVER use JS Date object directly, always use moment.js to work with dates!

JS Date object is a mess! DO NOT USE IT!
E.g.
let d = new Date("2022-01-01T00:00:00Z");
d.setMonth(d.getMonth() + 4)
results in "2022-04-30T23:00:00Z"
instead of "2022-05-01T00:00:00Z"

Also keep in mind:
- month is zero-based in both - JS Date and moment.js!
- date is one-based in both!
- always clone moment.js objects before modifying them!
*/

// #region (public) "JS Date"

export function rollDate(date: Date, tenor: string, rollDay: number): Date {
  const moment = parseAndValidateDate(date);
  return rollMoment(moment, tenor, rollDay).toDate();
}

export function getBillingYears(from: Date, to: Date, paymentSettings: PaymentSettings[]): BillingYear[] {
  const billingYears: BillingYear[] = [];
  let dateInYear = from;
  const endDate = to ?? new Date();
  while (dateInYear.getTime() < endDate.getTime()) {
    const billingYear = getBillingYear(paymentSettings, dateInYear);
    billingYears.push(billingYear);
    dateInYear.setFullYear(dateInYear.getFullYear() + 1);
    if (dateInYear.getTime() > endDate.getTime()) {
      dateInYear = new Date(endDate.getTime());
    }
  }
  return billingYears;
}

export function getBillingYear(paymentSettings: PaymentSettings[], anyDateInPaymentYear: Date): BillingYear {
  if (!paymentSettings?.length || paymentSettings.some((x) => !x)) {
    throw new Error("Payment settings are required.");
  }

  if (!anyDateInPaymentYear) {
    throw new Error("Any date in payment year is required.");
  }

  const activePaymentSettings = getActivePaymentSetting(paymentSettings, anyDateInPaymentYear);

  if (!activePaymentSettings) {
    throw new Error("Active payment settings are required.");
  }

  const paymentStart = parseAndValidateDate(activePaymentSettings.startDate);
  const anyMomentInPaymentYear = moment.utc(anyDateInPaymentYear);
  const periods = getBillingPeriods(paymentStart, activePaymentSettings.period, anyMomentInPaymentYear);

  const billingYear: BillingYear = {
    startDate: periods[0].startDate,
    endDate: periods[periods.length - 1].endDate,
    periods: periods,
  };

  return billingYear;
}

function getActivePaymentSetting(paymentSettings: PaymentSettings[], anyDateInPaymentYear: Date): PaymentSettings {
  return orderBy(paymentSettings, (x) => x.startDate).find((x) => x.startDate <= anyDateInPaymentYear);
}

export function getBillingPeriodsForOneYear(
  paymentStart: Date,
  anyDateInPaymentYear: Date,
  paymentPeriod: PaymentPeriod
): BillingPeriod[] {
  const paymentStartMoment = parseAndValidateDate(paymentStart);
  const anyMomentInPaymentYear = moment.utc(anyDateInPaymentYear);
  return getBillingPeriodsForOneYearUsingMoment(paymentStartMoment, paymentPeriod, anyMomentInPaymentYear);
}

export function isInBillingPeriod(date: Date, billingPeriod: BillingPeriod): boolean {
  const moment = parseAndValidateDate(date, false, false);
  return moment.isBetween(billingPeriod.startDate, billingPeriod.endDate, "day", "[]");
}

export function isInBillingYear(date: Date, billingYear: BillingYear): boolean {
  const moment = parseAndValidateDate(date, false, false);
  return moment.isBetween(billingYear.startDate, billingYear.endDate, "day", "[]");
}

// #endregion

function parseAndValidateDate(date: Date, checkMidnight = true, checkUTC = true): moment.Moment {
  const parsed = moment.utc(date);
  if (
    checkMidnight &&
    (parsed.hour() !== 0 || parsed.minute() !== 0 || parsed.second() !== 0 || parsed.millisecond() !== 0)
  ) {
    throw new Error("Date must be at midnight.");
  }
  if (checkUTC && !parsed.isUTC()) {
    throw new Error("Date must be in UTC.");
  }
  return parsed;
}

// #region (private) "moment.js"

function getBillingPeriods(
  paymentStart: moment.Moment,
  paymentPeriod: PaymentPeriod,
  anyMomentInPaymentYear: moment.Moment
): BillingPeriod[] {
  if (paymentPeriod === "Quarterly") {
    const anyYear = anyMomentInPaymentYear.year();
    return getQuarterlyBillingPeriods(paymentStart, anyYear);
  } else {
    return getBillingPeriodsForOneYearUsingMoment(paymentStart, paymentPeriod, anyMomentInPaymentYear);
  }
}

function getQuarterlyBillingPeriods(paymentStart: moment.Moment, year: number): BillingPeriod[] {
  const periods: BillingPeriod[] = [];
  const startDate = paymentStart.clone();
  if (startDate.year() < year) {
    // even with moment.js, month is zero-based and date is one-based
    // In JS date smells really funny!
    startDate.set("year", year).set("month", 0).set("date", 1);
  }
  let periodStartDate = startDate.clone();
  while (periodStartDate.year() < year + 1) {
    const endOfQuarter = getEndOfQuarter(periodStartDate);
    const periodEndDate = endOfQuarter === periodStartDate ? rollMoment(endOfQuarter, "M3", 31) : endOfQuarter.clone();
    periods.push({
      startDate: periodStartDate.toDate(),
      endDate: periodEndDate.toDate(),
      isFirstPeriod: false,
      isLastPeriod: false,
    });
    periodStartDate = periodEndDate.clone().add(1, "day");
  }
  markFirstAndLastPeriod(periods);
  return periods;
}

function getEndOfQuarter(aMoment: moment.Moment): moment.Moment {
  // keep in mind that month is zero-based!
  const quarter = Math.ceil((aMoment.month() + 1) / 3);
  return aMoment
    .clone()
    .month(quarter * 3 - 1)
    .endOf("month")
    .startOf("day");
}

function getBillingPeriodsForOneYearUsingMoment(
  paymentStart: moment.Moment,
  paymentPeriod: PaymentPeriod,
  anyMomentInPaymentYear: moment.Moment
): BillingPeriod[] {
  const anyYear = anyMomentInPaymentYear.year();
  const startDate = paymentStart.clone().set("year", anyYear);
  if (startDate > anyMomentInPaymentYear) {
    startDate.set("year", anyYear - 1);
  }

  const tenorInMonths = getTenorInMonths(paymentPeriod);
  const numberOfPeriods = 12 / tenorInMonths;
  const rollDay = paymentStart.date();
  let periodStartDate = startDate.clone();

  const periods: BillingPeriod[] = [];
  for (let i = 0; i < numberOfPeriods; i++) {
    const rollDate = rollMoment(periodStartDate, paymentPeriod, rollDay);
    const periodEndDate = rollDate.clone().subtract(1, "day");
    periods.push({
      startDate: periodStartDate.toDate(),
      endDate: periodEndDate.toDate(),
      isFirstPeriod: false,
      isLastPeriod: false,
    });
    periodStartDate = rollDate.clone();
  }
  markFirstAndLastPeriod(periods);

  return periods;
}

function markFirstAndLastPeriod(periods: BillingPeriod[]): void {
  periods[0].isFirstPeriod = true;
  periods[periods.length - 1].isLastPeriod = true;
}

function rollMoment(moment: moment.Moment, tenor: string, rollDay: number): moment.Moment {
  const newMoment = moment.clone();
  const tenorInMonths = getTenorInMonths(tenor);
  newMoment.add(tenorInMonths, "months");
  const rollDayToSet = Math.min(rollDay, newMoment.daysInMonth());
  newMoment.set("date", rollDayToSet);
  return newMoment;
}

function getTenorInMonths(tenor: string): number {
  switch (tenor) {
    case PaymentPeriod.M1:
      return 1;
    case PaymentPeriod.M3:
      return 3;
    case PaymentPeriod.M6:
      return 6;
    case PaymentPeriod.M12:
      return 12;
    default:
      throw new Error(`Invalid tenor: ${tenor}`);
  }
}

//#endregion

// #region payment settings
export function getPaymentDates(paymentSettings: PaymentSettings[], anyDateInPaymentYear: Date): PaymentPeriodDetail[] {
  const activePaymentSettings = getActivePaymentSetting(paymentSettings, anyDateInPaymentYear);
  if (!activePaymentSettings) {
    throw new Error("Active payment settings are required.");
  }
  const billingYear = getBillingYear(paymentSettings, anyDateInPaymentYear);
  if (activePaymentSettings.paymentTime === PaymentTime.InAdvance) {
    return getPaymentDatesInAdvance(activePaymentSettings, billingYear);
  } else {
    return getPaymentDatesInArrears(activePaymentSettings, billingYear);
  }
}

function getPaymentDatesInAdvance(paymentSettings: PaymentSettings, billingYear: BillingYear): PaymentPeriodDetail[] {
  if (paymentSettings.paymentTranches?.length) {
    return paymentSettings.paymentTranches.map((tranche) => ({
      paymentDate: getPaymentDateForTranche(tranche, billingYear, paymentSettings.startDate),
      percentage: tranche.percentage,
      periodStart: billingYear.startDate,
      periodEnd: billingYear.endDate,
      numberOfMonths: 12,
    }));
  }

  return billingYear.periods.map((period) => ({
    paymentDate: period.startDate,
    percentage: 100,
    periodStart: period.startDate,
    periodEnd: period.endDate,
    numberOfMonths: getTenorInMonths(paymentSettings.period),
  }));
}

function getPaymentDatesInArrears(paymentSettings: PaymentSettings, billingYear: BillingYear): PaymentPeriodDetail[] {
  if (paymentSettings.paymentTranches?.length) {
    throw new Error("Payment tranches are not supported for payment time in arrears.");
  }
  const numberOfMonths = getTenorInMonths(paymentSettings.period);
  return billingYear.periods.map((period) => ({
    paymentDate: period.endDate,
    percentage: 100,
    periodStart: period.startDate,
    periodEnd: period.endDate,
    numberOfMonths: numberOfMonths,
  }));
}

function getPaymentDateForTranche(tranche: PaymentTranche, billingYear: BillingYear, referenceDate: Date): Date {
  if (tranche.type === "dayOfYear") {
    const date = new Date(billingYear.startDate.getTime());
    date.setMonth(tranche.dueDate.month);
    date.setDate(tranche.dueDate.day);

    if (date.getTime() < billingYear.startDate.getTime()) {
      date.setFullYear(date.getFullYear() + 1);
    }

    return date;
  }

  if (tranche.type === "relative") {
    const paymentDateMoment = moment(referenceDate);
    paymentDateMoment.set("year", billingYear.startDate.getFullYear() - 1);
    paymentDateMoment.add(tranche.dueDate.months ?? 0, "months");
    paymentDateMoment.add(tranche.dueDate.days ?? 0, "days");

    const date = paymentDateMoment.toDate();

    if (date.getTime() < billingYear.startDate.getTime()) {
      date.setFullYear(date.getFullYear() + 1);
    }

    return date;
  }

  throw new Error("Invalid tranche type");
}

// #endregion
