import { clone } from "lodash";

/**
 * Deeply merges two objects, merging arrays by their elements' IDs.
 *
 * This function takes two objects of the same type and merges them deeply.
 * Source will be merged into target
 * When encountering arrays, it merges them by their elements' IDs.
 * If the source value is not undefined, it will overwrite the target value.
 * If the source arrays consist only of primitives, they are directly assigned to the target.
 *
 * @template T - The type of the objects to be merged.
 * @param {T} target - The target object to merge into.
 * @param {T} source - The source object to merge from.
 * @returns {T} - The merged object.
 */
export function deepMergeMergingArraysById<T extends object>(target: T, source: T, validateIfIdsMatch: boolean): T {
  const output = clone(target);
  const keys = Object.keys(source);
  keys.forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(sourceValue)) {
      output[key] = mergeArraysById(Array.isArray(targetValue) ? targetValue : [], sourceValue, validateIfIdsMatch);
    } else if (typeof targetValue === "object" && sourceValue !== null && typeof sourceValue === "object") {
      output[key] = deepMergeMergingArraysById(targetValue, sourceValue, validateIfIdsMatch);
    } else {
      if (sourceValue !== undefined) {
        output[key] = sourceValue;
      }
    }
  });

  return output;
}

function mergeArraysById(targetValue: any[], sourceValue: any[], validateMatchingId: boolean): any {
  if (sourceValue.length == 0 || sourceValue.every((item) => typeof item !== "object")) {
    return sourceValue;
  }

  const output = [];
  const sourceMap = new Map();
  const errors: string[] = [];

  sourceValue.forEach((item) => {
    if (item && item.id !== undefined) {
      sourceMap.set(item.id, item);
    }
  });

  targetValue.forEach((item) => {
    if (item && item.id !== undefined && sourceMap.has(item.id)) {
      output.push(deepMergeMergingArraysById(item, sourceMap.get(item.id), validateMatchingId));
      sourceMap.delete(item.id);
    }
  });

  sourceMap.forEach((item) => {
    if (validateMatchingId && item.id !== undefined) {
      errors.push(`array item with id ${item.id} not found in target array`);
    }
    output.push(item);
  });

  if (errors.length > 0) {
    throw new Error(errors.join("\n"), { cause: errors });
  }

  return output;
}
