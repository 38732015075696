import { inject } from "@angular/core";
import { ApolloClientOptions, DefaultOptions, from, InMemoryCache, PossibleTypesMap, split } from "@apollo/client/core";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { HttpLink } from "apollo-angular/http";
import { createPersistedQueryLink } from "apollo-angular/persisted-queries";
import { sha256 } from "crypto-hash";
import { createClient } from "graphql-ws";
import { IEnvironment } from "../environment/environment.interface";
import { WsConnectionParamsProvider } from "./ws-connection-params.provider";

export abstract class ApolloErrorHandler {
  handle: (error: ErrorResponse) => void;
}

export function apolloFactory(
  environment: IEnvironment,
  possiblePolymorphismReturnTypes: PossibleTypesMap
): () => ApolloClientOptions<any> {
  return internalApolloFactory;

  function internalApolloFactory(): ApolloClientOptions<any> {
    // GraphQL Error Handling
    const errorHandler = inject(ApolloErrorHandler, { optional: true });
    const errorFunc = onError((errorResponse) => {
      errorHandler?.handle(errorResponse);
      const { graphQLErrors, networkError } = errorResponse;
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path, extensions }) =>
          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Message: ${message},Extension: ${extensions["code"]} ,Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) {
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${JSON.stringify(networkError)}`);
      }
    });

    const httpLink = inject(HttpLink);
    const connectionParamsProvider = inject(WsConnectionParamsProvider);

    const removeTypenameLink = removeTypenameFromVariables();
    const link = from([removeTypenameLink, httpLink.create({ uri: environment.graphqlURI })]);
    const persistedQueryLink = createPersistedQueryLink({ sha256 }).concat(link);
    const retry = new RetryLink({
      delay: {
        initial: 500,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: environment.production ? 8 : 2, // Do not retry on 500 Internal Server Error
        retryIf: (error, _operation): boolean =>
          error?.status == 0 /*Network*/ ||
          error?.status == 502 /*Bad Gateway*/ ||
          error?.status == 503 /*Service Unavailable*/ ||
          error?.status == 504 /*Gateway Timeout*/,
      },
    }).concat(persistedQueryLink);
    const error = errorFunc.concat(retry);

    //wsURI
    const wsLink = new GraphQLWsLink(
      createClient({
        url: environment.wsURI,
        connectionParams: async () => {
          return {
            Authorization: await connectionParamsProvider.getToken(),
            "Accept-Language": connectionParamsProvider.getAcceptLanguage(),
            "X-App-Version": connectionParamsProvider.getXAppVersion(),
          };
        },
        keepAlive: 10000,
      })
    );

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wsLink,
      error
    );

    return {
      connectToDevTools: true,
      assumeImmutableResults: true,
      link: splitLink,
      cache: new InMemoryCache({
        typePolicies: {
          PdfFormTemplate: {
            keyFields: ["pdfFormToken", "key"],
          },
          StaticDataModel: {
            keyFields: false,
          },
          StaticDataResult: {
            keyFields: false,
          },
          RecognitionAuthority: {
            keyFields: ["respId", "respState"],
          },
          DocumentRequirement: {
            keyFields: ["id", "type", "organizationId"],
          },
          SharingPreset: {
            fields: {
              sharing: {
                // no matter what, respect the new state
                merge: (_existing, incoming) => incoming,
              },
            },
          },
        },
        possibleTypes: possiblePolymorphismReturnTypes,
      }),
      defaultOptions: defaultOptions,
    };
  }
}

//https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "none",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "none",
  },
  mutate: {
    errorPolicy: "none",
  },
};
