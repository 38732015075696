<div class="flex flex-column">
  <p-toast></p-toast>
  <app-header class="z-2"></app-header>

  <app-login class="mt-2" *ngIf="!authenticated"></app-login>
  <p-tabView *ngIf="authenticated" class="p-0">
    <p-tabPanel *ngIf="isExcel" header="{{ myTranslation('officeAddin.dataImport') }}" class="p-0">
      <p-blockUI [target]="pnl" [blocked]="isBusy" class="z-1">
        <p-progressSpinner styleClass=""></p-progressSpinner>
      </p-blockUI>
      <p-card #pnl id="pnl" class="shadow-none m-0">
        <app-candidate-data (isBusy)="setBusyState($event)" class=""></app-candidate-data>
        <app-change-statistics (isBusy)="setBusyState($event)" class=""></app-change-statistics>
        <app-sales-data *ngIf="isMasterUser" (isBusy)="setBusyState($event)"></app-sales-data>
        <div class="h-15rem"></div>
      </p-card>
    </p-tabPanel>
    <p-tabPanel header="{{ myTranslation('officeAddin.knownVariables') }}">
      <p-card>
        <app-known-variables></app-known-variables>
      </p-card>
    </p-tabPanel>
  </p-tabView>
</div>
