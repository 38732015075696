const formats = {
  "sq-AL": { hour: "12", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "ar-AE": { hour: "12", date: "dd‏/mm‏/yy", month: "mm‏/yy", year: "yy" },
  "tr-TR": { hour: "24", date: "dd.mm.yy", month: "mm/yy", year: "yy" },
  "bg-BG": { hour: "24", date: "dd.mm.yy 'г'.", month: "mm.yy 'г'.", year: "yy 'г'." },
  "bs-BA": { hour: "24", date: "dd.mm.yy.", month: "mm/yy", year: "yy." },
  "da-DK": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "de-DE": { hour: "24", date: "dd.mm.yy", month: "mm/yy", year: "yy" },
  "en-GB": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "es-ES": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "fa-IR": { hour: "24", date: "yy/mm/dd", month: "yy/mm", year: "yy" },
  "fi-FI": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "fr-FR": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "ga-IE": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "he-IL": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "hi-IN": { hour: "12", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "hr-HR": { hour: "24", date: "dd. mm. yy.", month: "mm. yy.", year: "yy." },
  "it-IT": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "ja-JP": { hour: "24", date: "yy/mm/dd", month: "yy/mm", year: "yy年" },
  "me-ME": { hour: "24", date: "dd. mm. yy.", month: "mm. yy.", year: "yy." },
  "mk-MK": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "ml-IN": { hour: "12", date: "dd/mm/yy", month: "yy-mm", year: "yy" },
  "nl-NL": { hour: "24", date: "dd-mm-yy", month: "mm-yy", year: "yy" },
  "nb-NO": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "tl-PH": { hour: "12", date: "mm/dd/yy", month: "mm/yy", year: "yy" },
  "pt-PT": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "pt-BR": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "ro-RO": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "ru-RU": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "sr-SP": { hour: "24", date: "dd. mm. yy.", month: "mm. yy.", year: "yy." },
  "sv-SE": { hour: "24", date: "yy-mm-dd", month: "yy-mm", year: "yy" },
  "uk-UA": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "vi-VN": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "zh-CN": { hour: "24", date: "yy/mm/dd", month: "yy/mm", year: "yy年" },
  "et-EE": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "fp-PH": { hour: "12", date: "mm/dd/yy", month: "mm/yy", year: "yy" },
  "id-ID": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "ku-TR": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "ur-PK": { hour: "12", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "pl-PL": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "sy-SY": { hour: "12", date: "dd‏/mm‏/yy", month: "mm‏/yy", year: "yy" },
  "ta-IN": { hour: "12", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "bn-BD": { hour: "12", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "hu-HU": { hour: "24", date: "yy. mm. dd.", month: "yy. mm.", year: "yy." },
  "el-GR": { hour: "12", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "cm-CN": { hour: "24", date: "yy/mm/dd", month: "yy/mm", year: "yy年" },
  "pa-IN": { hour: "12", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "ko-KR": { hour: "12", date: "yy. mm. dd.", month: "yy. mm.", year: "yy년" },
  "th-TH": { hour: "24", date: "dd/mm/yy", month: "mm/yy", year: "yy" },
  "ka-GE": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
  "cs-CZ": { hour: "24", date: "dd. mm. yy", month: "mm/yy", year: "yy" },
  "hy-AM": { hour: "24", date: "dd.mm.yy", month: "mm.yy", year: "yy" },
};

export function getLocaleDateString(
  locale: string,
  view?: "hour" | "date" | "month" | "year" | "date_Placeholder" | "longYear"
): string {
  view ??= "date";
  const format = formats[locale] ?? formats["en-GB"];
  switch (view) {
    case "hour":
      return format.hour;
    case "date":
      return format.date;
    case "month":
      return format.month;
    case "year":
      return format.year;
    case "date_Placeholder":
      return format.date.replace("yy", "yyyy");
    case "longYear":
      return format.date.replace("yy", "yyyy");
  }
}
