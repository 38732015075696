import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { ensure4LetterIsoLanguage } from "@ankaadia/ankaadia-shared";
import { TranslocoService } from "@jsverse/transloco";
import { Observable } from "rxjs";

export function uiLanguageInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const transloco = inject(TranslocoService);
  req = req.clone({
    headers: req.headers.append("Accept-Language", ensure4LetterIsoLanguage(transloco.getActiveLang())),
  });
  return next(req);
}
