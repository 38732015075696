import { isEmpty, isEqual, uniq } from "lodash";
import { DocumentField, DocumentSetField, getFiles, getSetFiles, getSets, getTags } from "./document-helper";
import { declaredDocumentSetTypes, getMaxDocumentSetCount } from "./file-type-settings";
import { DocumentMode, DocumentModel } from "./model/document.model";
import { isSingleDocumentSet } from "./single-document-set.model";
import { requireKeys } from "./type-utils";

const documentFields = requireKeys<Exclude<DocumentField, "completionState">>({ comment: "comment" });
const documentSetFields = requireKeys<DocumentSetField>({
  validFrom: "validFrom",
  validUntil: "validUntil",
  issueDate: "issueDate",
  dateOfReceipt: "dateOfReceipt",
  resubmissionDate: "resubmissionDate",
  physicalTypes: "physicalTypes",
});

function validateOrganizationDocument(document: DocumentModel): void {
  // same structure now
  validateTemplateDocument(document);
}

function validateTemplateDocument(document: DocumentModel): void {
  if (!document.organizationId) {
    throw new Error("Organization is missing.");
  }
  if (document.type) {
    throw new Error("Organization and Template documents are not allowed to have a type.");
  }

  const sets = getSets(document);
  if (sets.length !== 1) {
    throw new Error("Organization and Template documents must have exactly one document set.");
  }
  if (!sets.some((s) => s.isDefaultSet)) {
    throw new Error("Documents must have exactly one default document set.");
  }
  if (sets.some((s) => s.type)) {
    throw new Error("Organization and Template documents are not allowed to have document set types.");
  }
  if (sets.some((s) => !s.name)) {
    throw new Error("Organization and Template documents must have document set names.");
  }

  for (const file of getFiles(document)) {
    if (!file.selectionValues?.length) {
      throw new Error("Criteria are missing.");
    }
    if (file.selectionValues.some((x) => !x.length)) {
      throw new Error("Criteria are empty.");
    }
    if (file.selectionValues.length !== (document.selectionCriteria ?? []).length) {
      throw new Error("Criteria are malformed.");
    }
    if (getFiles(document).some((f) => f !== file && isEqual(f.selectionValues, file.selectionValues))) {
      throw new Error("Criteria are duplicated.");
    }
  }
}

function validateNormalCandidateDocument(document: DocumentModel): void {
  validateCandidateDocument(document);

  const tags = getFiles(document).map((f) => getTags(f));
  if (tags.some((t) => t?.length < 1)) {
    throw new Error("Tags are malformed.");
  }

  for (const set of getSets(document)) {
    const values = getSetFiles(set).flatMap((g) => getTags(g));
    if (uniq(values).length !== values.length) {
      throw new Error("Tags are duplicated.");
    }
  }
}

function validateSingleSetCandidateDocument(document: DocumentModel): void {
  validateCandidateDocument(document);

  const configs = getFiles(document)
    .flatMap((f) => f.singleSetConfig)
    .filter((c) => c);

  if (configs.some((c) => !c.type || !c.formats?.length)) {
    throw new Error("Single set configs are malformed.");
  }
}

function validateCandidateDocument(document: DocumentModel): void {
  if (!document.organizationId) {
    throw new Error("Organization is missing.");
  }
  if (!document.candidateId) {
    throw new Error("Candidate is missing.");
  }
  if (!document.type) {
    throw new Error("Type is missing.");
  }

  const sets = getSets(document);
  if (sets.filter((s) => s.isDefaultSet).length !== 1) {
    throw new Error("Documents must have exactly one default document set.");
  }

  const maxDocumentSets = getMaxDocumentSetCount(document.type);
  if (sets.length > maxDocumentSets) {
    throw new Error(`Documents of type ${document.type} must have at most ${maxDocumentSets} document sets.`);
  }

  const declaredSetTypes = declaredDocumentSetTypes(document.type);
  if (declaredSetTypes) {
    const typeList = declaredSetTypes.join(", ");
    const containedSetTypes = sets.map((set) => set.type);
    if (containedSetTypes.length !== declaredSetTypes.length) {
      throw new Error(`Documents of type ${document.type} must have a document set per declared set type: ${typeList}`);
    }
    if (!declaredSetTypes.every((declaredType) => containedSetTypes.includes(declaredType))) {
      throw new Error(`Documents of type ${document.type} must have a document set per declared set type: ${typeList}`);
    }
    if (sets.some((set) => set.name)) {
      throw new Error(`Documents of type ${document.type} are not allowed to have document set names.`);
    }
    if (sets.some((set) => set.foreignKey)) {
      throw new Error(`Documents of type ${document.type} are not allowed to have foreign keys.`);
    }
  } else {
    if (sets.some((set) => !set.name)) {
      throw new Error(`Documents of type ${document.type} must have document set names.`);
    }
    if (sets.some((set) => set.type)) {
      throw new Error(`Documents of type ${document.type} are not allowed to have document set types.`);
    }

    const foreignKeySets = sets.filter((set) => set.foreignKey);
    if (uniq(foreignKeySets.map((set) => set.foreignKey)).length !== foreignKeySets.length) {
      throw new Error(`Documents of type ${document.type} must have unique foreign keys.`);
    }

    const nonForeignKeySets = sets.filter((set) => !set.foreignKey);
    if (uniq(nonForeignKeySets.map((set) => set.name)).length !== nonForeignKeySets.length) {
      throw new Error(`Documents must have unique document set names.`);
    }
  }
}

function validateDocumentNotEmpty(document: DocumentModel): void {
  const hasNoFiles = isEmpty(getFiles(document));
  const hasNoDocumentMetadata = documentFields.every((key) => !document[key]);
  const hasNoSetMetadata = getSets(document).every((set) => documentSetFields.every((key) => !set[key]));
  if (hasNoFiles && hasNoDocumentMetadata && hasNoSetMetadata) {
    throw new Error("Documents must have at least one file or some metadata.");
  }
}

export function validateDocument(document: DocumentModel): void {
  switch (document.mode) {
    case DocumentMode.Organization:
      validateOrganizationDocument(document);
      break;
    case DocumentMode.Template:
      validateTemplateDocument(document);
      break;
    case DocumentMode.Candidate:
      if (isSingleDocumentSet(document.type)) {
        validateSingleSetCandidateDocument(document);
      } else {
        validateNormalCandidateDocument(document);
      }
      break;
  }
}

export function tryValidateDocument(document: DocumentModel): boolean {
  return tryExecute(() => validateDocument(document));
}

export function tryValidateDocumentNotEmpty(document: DocumentModel): boolean {
  return tryExecute(() => validateDocumentNotEmpty(document));
}

function tryExecute(func: () => void): boolean {
  try {
    func();
    return true;
  } catch {
    return false;
  }
}
