type Contract = Omit<ILaborAgreementModel, "type" | "institutionA" | "institutionB" | "federalStates" | "payGrades">;
type Paygrade = Pick<ILaborAgreementPayGrade, "compensationType" | "compensationRate" | "workingHoursPerWeek">;
export type LaborContractDetails = Contract & Paygrade;

export type IContractTemplate = LaborContractDetails & {
  employmentRelationshipType?: EmploymentRelationshipType;
  workingTimeType?: WorkingTimeTypeEnum;
  workSchedule?: string;
  overtimeHours?: number;
  overtimeCompensation?: string;
  professionTitle?: string;
  professionDescription?: string;
  ezbAdditionalInformation?: string;
  contractType?: string;
  laborAgreementSelection?: ILaborAgreementSelection;
  customLaborAgreement?: ICustomLaborAgreement;
  otherPaymentRegulation?: string;
  numberOfSalaries?: number;
  overtimeWithinLimitsPermittedByLaw?: boolean;
};

export type IRecognitionPathContractTemplate = Omit<
  IContractTemplate,
  "id" | "organizationId" | "name" | "country" | "changedAt" | "changedBy" | "_typeName"
>;

export interface ILaborAgreementModel {
  id?: string;
  organizationId?: string;
  name?: string;
  type?: string;
  country?: string;
  institutionA?: string;
  institutionB?: string;
  federalStates?: string[];
  holidayEntitlement?: number;
  probationPeriod?: number;
  probationPeriodUnit?: ContractTermTimePeriod;
  noticePeriod?: number;
  noticePeriodUnit?: ContractTermTimePeriod;
  payGrades?: ILaborAgreementPayGrade[];
  allowances?: ILaborContractAllowance[];
  benefits?: ILaborContractBenefit[];
  validFrom?: Date;
  validUntil?: Date;
  changedAt?: Date;
  changedBy?: string;
}

export interface ILaborAgreementPayGrade {
  id?: string;
  salaryGroup?: string;
  salaryStep?: string;
  compensationType?: CompensationType;
  compensationRate?: number;
  workingHoursPerWeek?: number;
}

export interface ILaborAgreementId {
  laborAgreementId?: string;
  organizationId?: string;
}

export interface ILaborAgreementSelection {
  organizationId?: string;
  laborAgreementId?: string;
  payGradeId?: string;
}

export interface ICustomLaborAgreement {
  collectiveAgreement?: string;
  salaryGroup?: string;
}

export interface ILaborContractAllowance {
  id: string;
  type?: string;
  customType?: string;
  calculationBasis?: CalculationBasis;
  amount?: number;
  comment?: string;
}

export interface ILaborContractBenefit {
  id: string;
  type: string;
  timeInterval?: BenefitTimeInterval;
  calculationBasis?: CalculationBasis;
  amount?: number;
}

export enum ContractTermTimePeriod {
  Weeks = "Weeks",
  Months = "Months",
}

export enum CompensationType {
  PerHour = "PerHour",
  PerMonth = "PerMonth",
}

export enum RemunerationType {
  Wage = "Wage",
  Salary = "Salary",
}

export enum CalculationBasis {
  AbsoluteAmount = "AbsoluteAmount",
  Percentage = "Percentage",
}

export enum BenefitTimeInterval {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
  OneTimeOnly = "OneTimeOnly",
}

export enum EmploymentRelationshipType {
  Temporary = "Temporary",
  Permanent = "Permanent",
  Internship = "Internship",
  JobShadowing = "JobShadowing",
  SecondJob = "SecondJob",
}

export enum WorkingTimeTypeEnum {
  FullTime = "FullTime",
  PartTime = "PartTime",
  MarginalEmployment = "MarginalEmployment",
}
