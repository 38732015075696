import { clone, isEmpty, isNil, keys, omitBy } from "lodash";
import { isTechnicallyEmpty } from "./lodash-extra";
import {
  BenefitTimeInterval,
  CalculationBasis,
  ContractTermTimePeriod,
  IContractTemplate,
  ILaborAgreementModel,
  ILaborAgreementPayGrade,
  ILaborContractAllowance,
  ILaborContractBenefit,
  LaborContractDetails,
} from "./model/contract-template.model";
import { Intersect, IntersectionKey, KeyOf } from "./type-utils";

type MergeKey<TIn, TOut> = KeyOf<MergeInstruction<TIn, TOut>>;
type SourceInstruction = "Source" | "Target";

type ContractTemplateAllowance = ILaborContractAllowance & OverrideInfo;
type ContractTemplateBenefit = ILaborContractBenefit & OverrideInfo;
export type MergedContractTemplate = Omit<Partial<IContractTemplate>, "allowances" | "benefits"> & {
  allowances?: ContractTemplateAllowance[];
  benefits?: ContractTemplateBenefit[];
};

export type FinalContractTemplate = Omit<MergedContractTemplate, "laborAgreementSelection" | "customLaborAgreement"> & {
  collectiveAgreement: string;
  remunerationGroup: string;
};

export type MergeSource<TIn, TOut> = Pick<TOut, MergeKey<TIn, TOut>>;
export type MergeResult<TIn extends MergeSource<TIn, TOut>, TOut> = Pick<TOut, MergeKey<TIn, TOut>>;
export interface OverrideInfo {
  isOverride?: boolean;
  isIgnored?: boolean;
}

export type MergeInstruction<Source, Target> = {
  [K in keyof Intersect<Required<Source>, Required<Target>>]:
    | ((sourceValue?: Source[K], targetValue?: Target[K]) => Target[K] | null | undefined)
    | (K extends IntersectionKey<Source, Target> ? SourceInstruction : Extract<SourceInstruction, "Target">);
};

export function getAllowanceKey(item: ILaborContractAllowance): string {
  return getIdentifier(item, ["type", "customType"]);
}

export function getBenefitKey(item: ILaborContractBenefit): string {
  return getIdentifier(item, ["type"]);
}

export function buildLaborAgreementData(
  contractTemplate: MergedContractTemplate,
  laborAgreement: ILaborAgreementModel | ILaborAgreementModel[]
): LaborContractDetails {
  const laborAgreements = Array.isArray(laborAgreement) ? laborAgreement : [laborAgreement];
  const { laborAgreementSelection, customLaborAgreement } = contractTemplate ?? {};
  if (isNil(contractTemplate.contractType) || contractTemplate.contractType === "AgreementByContract") {
    if (!isTechnicallyEmpty(laborAgreementSelection)) {
      const error = "Contract template of type AgreementByContract must not have a labor agreement selection";
      throw new Error(error);
    }

    if (!isTechnicallyEmpty(customLaborAgreement)) {
      const error = "Contract template of type AgreementByContract must not have a custom labor agreement";
      throw new Error(error);
    }
  }

  if (isNil(laborAgreementSelection)) {
    return null;
  }

  if (isNil(laborAgreementSelection.laborAgreementId)) {
    return null;
  }

  const allAgreements = laborAgreements ?? [];
  const agreement = allAgreements.find(({ id }) => id === laborAgreementSelection.laborAgreementId);
  if (isNil(agreement)) {
    return null;
  }

  if (!isNil(contractTemplate.contractType)) {
    if (contractTemplate.contractType !== agreement.type) {
      const error = "Contract type and labor agreement type do not match";
      throw new Error(error);
    }

    if (!isNil(contractTemplate.country) && contractTemplate.country !== agreement.country) {
      const error = "Contract country and labor agreement country do not match";
      throw new Error(error);
    }
  }

  const payGrades = agreement.payGrades ?? [];
  const payGrade = payGrades.find(({ id }) => id === laborAgreementSelection.payGradeId);
  if (isNil(payGrade)) {
    return agreement;
  }

  return { ...agreement, ...payGrade };
}

export function createMergedContract(
  contractTemplate: MergedContractTemplate,
  laborAgreements: ILaborAgreementModel | ILaborAgreementModel[]
): MergedContractTemplate {
  const laborAgreementData = buildLaborAgreementData(contractTemplate, laborAgreements);
  return mergeWithInstructions(contractMergeInstructions, laborAgreementData, contractTemplate);
}

export function createRemunerationGroup(payGrade: ILaborAgreementPayGrade): string {
  const { salaryGroup, salaryStep } = payGrade ?? {};
  return !isNil(salaryGroup)
    ? !isNil(salaryStep)
      ? `${salaryGroup} / ${salaryStep}`
      : salaryGroup
    : !isNil(salaryStep)
      ? salaryStep
      : null;
}

export function mergeWithInstructions<TIn, TOut>(
  instructions: MergeInstruction<TIn, TOut>,
  source: TIn,
  target: TOut
): TOut {
  const cloned = clone(target);
  return keys(instructions).reduce((result, key) => {
    const resolveValue = (): unknown => {
      const instruction = instructions[key];
      if (instruction === "Source") {
        return source?.[key] ?? null;
      }

      if (instruction === "Target") {
        return target?.[key] ?? null;
      }

      if (instruction === "Never") {
        return undefined;
      }

      return instruction(source?.[key], target?.[key]);
    };

    const value = resolveValue();
    return value === undefined ? result : { ...result, [key]: resolveValue() };
  }, cloned);
}

function populateTargetIfIsEmpty<TValue>(source: TValue, target: TValue, fallback: TValue = null): TValue {
  return (isNil(target) ? source : target) ?? fallback;
}

const allowanceMergeInstructions: MergeInstruction<Required<ILaborContractAllowance>, ContractTemplateAllowance> = {
  id: "Target",
  type: populateTargetIfIsEmpty,
  customType: populateTargetIfIsEmpty,
  calculationBasis: populateTargetIfIsEmpty,
  amount: populateTargetIfIsEmpty,
  comment: "Target",
};

const benefitMergeInstructions: MergeInstruction<Required<ILaborContractBenefit>, ContractTemplateBenefit> = {
  id: "Target",
  type: populateTargetIfIsEmpty,
  calculationBasis: populateTargetIfIsEmpty,
  timeInterval: populateTargetIfIsEmpty,
  amount: populateTargetIfIsEmpty,
};

const contractMergeInstructions: MergeInstruction<Required<LaborContractDetails>, MergedContractTemplate> = {
  id: "Target",
  name: "Target",
  organizationId: "Target",
  country: "Target",
  holidayEntitlement: populateTargetIfIsEmpty,
  noticePeriod: populateTargetIfIsEmpty,
  noticePeriodUnit: (src, target) => populateTargetIfIsEmpty(src, target, ContractTermTimePeriod.Months),
  probationPeriod: populateTargetIfIsEmpty,
  probationPeriodUnit: (src, target) => populateTargetIfIsEmpty(src, target, ContractTermTimePeriod.Months),
  compensationType: populateTargetIfIsEmpty,
  compensationRate: populateTargetIfIsEmpty,
  workingHoursPerWeek: populateTargetIfIsEmpty,
  allowances: mergeAllowances,
  benefits: mergeBenefits,
  validFrom: "Target",
  validUntil: "Target",
  changedAt: "Target",
  changedBy: "Target",
};

function mergeAllowances(
  sources?: ILaborContractAllowance[],
  targets?: ContractTemplateAllowance[]
): ContractTemplateAllowance[] {
  const mappedSources = (sources ?? []).map(({ comment: _, ...rest }) => rest);
  const mappedTargets = targets ?? [];

  if (isEmpty(mappedSources)) {
    return mappedTargets.map((item) => fixAllowance({ ...item, isOverride: false, isIgnored: false }));
  }

  if (isEmpty(mappedTargets)) {
    return mappedSources.map((item) => fixAllowance({ ...item, isOverride: true, isIgnored: false }));
  }

  const existingIdentifiers = mappedTargets.map((target) => getAllowanceKey(target));
  const missingItems = mappedSources
    .filter((source) => !existingIdentifiers.includes(getAllowanceKey(source)))
    .map((item) => fixAllowance({ ...item, isOverride: true, isIgnored: false }));

  const mergedItems = targets.map((target) => {
    const sourceMatch = mappedSources.find((source) => getAllowanceKey(source) === getAllowanceKey(target));
    if (isNil(sourceMatch)) {
      const hasIrrelevantData = isNil(target.calculationBasis) && isNil(target.amount) && isNil(target.comment);
      return hasIrrelevantData ? null : fixAllowance({ ...target, isOverride: false, isIgnored: false });
    }

    const merged = mergeWithInstructions(allowanceMergeInstructions, sourceMatch, target);
    return fixAllowance({ ...merged, isOverride: true, isIgnored: false });
  });

  return [...mergedItems, ...missingItems].filter((x) => !isNil(x));
}

function mergeBenefits(
  sources?: ILaborContractBenefit[],
  targets?: ContractTemplateBenefit[]
): ContractTemplateBenefit[] {
  const mappedSources = sources ?? [];
  const mappedTargets = targets ?? [];

  if (isEmpty(mappedSources)) {
    return (mappedTargets ?? []).map((item) => fixBenefit({ ...item, isOverride: false, isIgnored: false }));
  }

  if (isEmpty(mappedTargets)) {
    return (mappedSources ?? []).map((item) => fixBenefit({ ...item, isOverride: true, isIgnored: false }));
  }

  const existingIdentifiers = targets.map((target) => getBenefitKey(target));
  const missingItems = sources
    .filter((source) => !existingIdentifiers.includes(getBenefitKey(source)))
    .map((item) => fixBenefit({ ...item, isOverride: true, isIgnored: false }));

  const mergedItems = targets.map((target) => {
    const sourceMatch = sources.find((source) => getBenefitKey(source) === getBenefitKey(target));
    if (isNil(sourceMatch)) {
      const hasIrrelevantData = isNil(target.timeInterval) && isNil(target.calculationBasis) && isNil(target.amount);
      return hasIrrelevantData ? null : fixBenefit({ ...target, isOverride: false, isIgnored: false });
    }

    const merged = mergeWithInstructions(benefitMergeInstructions, sourceMatch, target);
    return fixBenefit({ ...merged, isOverride: true, isIgnored: false });
  });

  return [...mergedItems, ...missingItems].filter((x) => !isNil(x));
}

function getIdentifier<T>(object: T, keys: KeyOf<T>[]): string {
  const uniqueValues = keys.reduce((acc, key) => ({ ...acc, [key]: object[key] }), {});
  return JSON.stringify(omitBy(uniqueValues, isNil));
}

function fixAllowance({
  calculationBasis,
  amount,
  ...allowance
}: ContractTemplateAllowance): ContractTemplateAllowance {
  return { ...allowance, calculationBasis: calculationBasis ?? CalculationBasis.AbsoluteAmount, amount: amount ?? 0 };
}

function fixBenefit({
  timeInterval,
  calculationBasis,
  amount,
  ...benefit
}: ContractTemplateBenefit): ContractTemplateBenefit {
  return {
    ...benefit,
    timeInterval: timeInterval ?? BenefitTimeInterval.Monthly,
    calculationBasis: calculationBasis ?? CalculationBasis.AbsoluteAmount,
    amount: amount ?? 0,
  };
}
