import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { authHttpInterceptorFn } from "@auth0/auth0-angular";
import { Observable } from "rxjs";
import { OfficeEnvService } from "../officeEnvService";

const noLoginRequiredOperations = ["getLoginLogoUrl", "resolveShortLink"];

export function authHttpInterceptorExcel(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const officeEnvService = inject(OfficeEnvService);

  if (!req.url.endsWith("api/graphql") || noLoginRequiredOperations.includes(req.body.operationName)) {
    return next(req);
  } else {
    if (officeEnvService.getOfficeEnv() != Office.PlatformType.OfficeOnline) {
      req = req.clone({
        setHeaders: {
          Authorization: `bearer ${officeEnvService.getBearerToken()}`,
        },
      });
      return next(req);
    } else {
      return authHttpInterceptorFn(req, next);
    }
  }
}
