import { keys } from "lodash";
import { DocumentSetDateField } from "./document-helper";
import { PickPropertyKeys, Union } from "./type-utils";

export const DefaultDocumentSetName = "Standard";
export const ContractDocumentSetTypeNames = ["SignedByAllParties", "SignedByOneParty", "Unsigned"] as const;
export const RecognitionPathDocumentSetTypeNames = ["CurrentRecognitionPath", "AlternativeRecognitionPath"] as const;
export const DocumentSetTypeNames = [...ContractDocumentSetTypeNames, ...RecognitionPathDocumentSetTypeNames] as const;

export type ContractDocumentSetType = Union<typeof ContractDocumentSetTypeNames>;
export type RecognitionPathDocumentSetType = Union<typeof RecognitionPathDocumentSetTypeNames>;

export type DocumentSetType = Union<typeof DocumentSetTypeNames>;
export type DocumentSetTypes = Readonly<[DocumentSetType, ...DocumentSetType[]]>;

const DocumentSetSettingsObject = {
  FIRSTCONTRACTEXTENSIONPRE: ContractDocumentSetTypeNames, // 1. Arbeitsvertragsverlängerung vor Berufsanerkennung
  SECONDCONTRACTEXTENSIONPRE: ContractDocumentSetTypeNames, // 2. Arbeitsvertragsverlängerung vor Berufsanerkennung
  FIRSTCONTRACTEXTENSIONAFTER: ContractDocumentSetTypeNames, // 1. Arbeitsvertragsverlängerung nach Berufsanerkennung
  SECONDCONTRACTEXTENSIONAFTER: ContractDocumentSetTypeNames, // 2. Arbeitsvertragsverlängerung nach Berufsanerkennung
  AGREEMENTSPONSORSHIP: ContractDocumentSetTypeNames, // Vereinbarung Anerkennungspartnerschaft
  CONTRACTWORK: ContractDocumentSetTypeNames, // Arbeitsvertrag
  AGGREEACC81A: ContractDocumentSetTypeNames, // Vereinbarung beschleunigtes Fachkräfteverfahren nach Pragraph 81a AufenthG
  TRAININGCONTRACT: ContractDocumentSetTypeNames, // Ausbildungsvertrag
  ACCEPTDOC: RecognitionPathDocumentSetTypeNames, // Berufsurkunde der zuständigen deutschen Landesbehörde, die zur vollständigen Ausübung des Berufes befähigt
  RECDOC: RecognitionPathDocumentSetTypeNames, // Bescheid über die volle Gleichwertigkeit (Vollanerkennungsbescheid)
  DEFICITNOTE: RecognitionPathDocumentSetTypeNames, // Bescheid über die teilweise Gleichwertigkeit (Defizitbescheid)
  REJECTREC: RecognitionPathDocumentSetTypeNames, // Ablehnungsbescheid Anerkennung
  CONFACCEPTAGENCY: RecognitionPathDocumentSetTypeNames,
  CONFIMMIGRAGENCY: RecognitionPathDocumentSetTypeNames,
  DECINENT: RecognitionPathDocumentSetTypeNames,
  APPPERMPRO: RecognitionPathDocumentSetTypeNames,
  COVERLETTERPROFRECOG: RecognitionPathDocumentSetTypeNames,
  COVERLETTERPROFACCEL: RecognitionPathDocumentSetTypeNames,
  DATAPRIVAGENCY: RecognitionPathDocumentSetTypeNames,
  POACHILDREN81A: RecognitionPathDocumentSetTypeNames,
  POAPARTNER81A: RecognitionPathDocumentSetTypeNames,
  FEESACCMIGRATION: RecognitionPathDocumentSetTypeNames,
  ACCEPTDOCGB: RecognitionPathDocumentSetTypeNames,
  POACANDIDATE: RecognitionPathDocumentSetTypeNames,
  POAMAIN81A: RecognitionPathDocumentSetTypeNames,
  DECLOFCOSTACCREC: RecognitionPathDocumentSetTypeNames,
  ADDREQACCPTAGENCY: RecognitionPathDocumentSetTypeNames,
  ADDREQMIGRAGENCY: RecognitionPathDocumentSetTypeNames,
  POASUB81A: RecognitionPathDocumentSetTypeNames,
  AUTHACCEPTAGENCY: RecognitionPathDocumentSetTypeNames,
  POWEROFATTORNEYRELOCATION: RecognitionPathDocumentSetTypeNames,
  CONFFULLACCEPTAGENCY: RecognitionPathDocumentSetTypeNames,
  CONFFULLMIGRAGENCY: RecognitionPathDocumentSetTypeNames,
  CANCELLATIONEMPLOYMENTCONTRACT: ContractDocumentSetTypeNames,
  CANCELLATIONTRAININGCONTRACT: ContractDocumentSetTypeNames,
} as const;

export type TypedSetDocumentType = keyof typeof DocumentSetSettingsObject;
export type NumberedSetDocumentType =
  | "CERTOFVACC"
  | "PASSPORT"
  | "VISA"
  | "CONFVISA"
  | "REJECTVISA"
  | "PREAPPROVAL"
  | "LANGCERT"
  | "LANGCERTGERA1"
  | "LANGCERTGERA2"
  | "LANGCERTGERB1"
  | "LANGCERTGERB2"
  | "LANGCERTGERC1"
  | "LANGCERTGERC2"
  | "RESIDENCEPERMIT"
  | "FICTIONALCERT"
  | "RENTALAGREEMENT"
  | "APPVALEDU"
  | "CERTZAB"
  | "CERTLAB"
  | "REJECTCERTAB"
  | "CERTIFICATEFURTHERTRAINING"
  | "EDUCATIONVOUCHER"
  | "PRECONTRACT"
  | "QUALPLAN"
  | "CONFIRMCFINSUPPCERTZAB"
  | "CONFIRMCERTZAB"
  | "FORMFINSUPPCERTZAB"
  | "FEESCERTZAB"
  | "ADDREQCERTAB"
  | "REJECTWORK"
  | "CONFEMPLOYAGENCY"
  | "REGCERT"
  | "CERTOFHOUSING"
  | "OVERVIEWINTERVIEW"
  | "POLICERECORDENHANCEDIMMIGRATION"
  | "CONFOQUAL";

export type DocumentType = TypedSetDocumentType | NumberedSetDocumentType;

export type RecognitionPathDocumentType = PickPropertyKeys<
  typeof DocumentSetSettingsObject,
  typeof RecognitionPathDocumentSetTypeNames
>;

export type ContractDocumentType = PickPropertyKeys<
  typeof DocumentSetSettingsObject,
  typeof ContractDocumentSetTypeNames
>;

export const DocumentTypeSynchronizedSetFields: Record<
  ContractDocumentType,
  readonly [DocumentSetDateField, ...DocumentSetDateField[]]
> = {
  AGREEMENTSPONSORSHIP: ["issueDate", "validFrom", "validUntil"],
  CONTRACTWORK: ["issueDate", "validFrom", "validUntil"],
  AGGREEACC81A: ["issueDate", "validFrom", "validUntil"],
  TRAININGCONTRACT: ["issueDate", "validFrom", "validUntil"],
  CANCELLATIONEMPLOYMENTCONTRACT: ["issueDate", "validFrom", "validUntil"],
  CANCELLATIONTRAININGCONTRACT: ["issueDate", "validFrom", "validUntil"],
  FIRSTCONTRACTEXTENSIONPRE: ["issueDate", "validFrom", "validUntil"],
  SECONDCONTRACTEXTENSIONPRE: ["issueDate", "validFrom", "validUntil"],
  FIRSTCONTRACTEXTENSIONAFTER: ["issueDate", "validFrom", "validUntil"],
  SECONDCONTRACTEXTENSIONAFTER: ["issueDate", "validFrom", "validUntil"],
} as const;

export const DocumentSetSettings: Record<TypedSetDocumentType, DocumentSetTypes> = DocumentSetSettingsObject;

export function getDocumentTypesUtilizingTypedSets(): DocumentType[] {
  return keys(DocumentSetSettings)
    .filter<DocumentType>((type: string): type is DocumentType => !!type)
    .filter((key) => hasDeclaredDocumentSetTypes(key));
}

export function getDocumentSetTypes(fileType?: string): DocumentSetTypes | number {
  return fileType ? (DocumentSetSettings[fileType] ?? Number.MAX_SAFE_INTEGER) : Number.MAX_SAFE_INTEGER;
}

export function getMaxDocumentSetCount(fileType?: string): number {
  const types = getDocumentSetTypes(fileType);
  return isNumber(types) ? types : types.length;
}

export function declaredDocumentSetTypes(fileType?: string): readonly DocumentSetType[] {
  const types = getDocumentSetTypes(fileType);
  return isNumber(types) ? null : types;
}

export function hasDeclaredDocumentSetTypes(fileType?: string): boolean {
  return declaredDocumentSetTypes(fileType) != null;
}

export function getSynchronizedSetFields(fileType?: string): DocumentSetDateField[] {
  return DocumentTypeSynchronizedSetFields[fileType]?.slice() ?? [];
}

function isNumber(value: DocumentSetTypes | number): value is number {
  return typeof value === "number";
}
