import { Component, OnInit } from "@angular/core";
import { TranslocoService, translate } from "@jsverse/transloco";
import { ConfirmationService, MenuItem } from "primeng/api";
import { filter } from "rxjs";
import { SettingsService } from "../../shared/settings.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
})
export class HeaderComponent implements OnInit {
  readonly language = this.transloco.getActiveLang();
  authenticated = false;
  organizationName: string;
  userDisplayName: string;
  flag = "de";
  items: MenuItem[] = [{ label: "Abmelden", icon: "pi pi-sign-out", command: () => this.settings.logout() }];
  langMenu: MenuItem[] = [
    {
      label: `<span class="flag flag-de"></span><span class="ml-2 mt-2">${translate("enum.language.de-DE")}</span>`,
      escape: false,
      command: () => this.changeLanguageDialog("de-DE"),
    },
    {
      label: `<span class="flag flag-uk"></span><span class="ml-2 mt-2">${translate("enum.language.en-GB")}</span>`,
      escape: false,
      command: () => this.changeLanguageDialog("en-GB"),
    },
  ];

  // orgMenu: MenuItem[] = [
  //   { label: "Ankaadia Master" },
  //   { label: "Ankaadia Test" },
  //   { label: "customer success organization" },
  // ];

  constructor(
    private readonly settings: SettingsService,
    private readonly confirmationService: ConfirmationService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.settings.isAuthenticated$.pipe(filter((x) => x)).subscribe((auth) => (this.authenticated = auth));
    this.settings.userDisplayName$.subscribe((displayName) => (this.userDisplayName = displayName));
    this.settings.organizationName$.subscribe(
      (orgName) => (this.organizationName = orgName?.length > 40 ? orgName.substring(0, 40) + "..." : orgName)
    );
    this.flag = this.language === "de-DE" ? "de" : "uk";
  }

  changeLanguage(language: string): void {
    this.flag = language === "de-DE" ? "de" : "uk";
    this.transloco.setActiveLang(language);
  }

  changeLanguageDialog(language: string): void {
    this.confirmationService.confirm({
      message: translate("officeAddin.changeLanguageWarning"),
      icon: "pi pi-exclamation-triangle",
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      rejectLabel: translate("common.cancel"),
      acceptLabel: translate("common.confirm"),
      accept: () => {
        this.changeLanguage(language);
        window.location.reload();
      },
    });
  }

  myTranslation(key: string): string {
    return translate(key);
  }
}
