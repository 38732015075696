import { StaticDataTypeFunctions, StaticDataTypeFunctionsValues } from "@ankaadia/ankaadia-shared";

export enum ProfessionCategory {
  HealthCare = "HealthCare",
  Other = "Other",
}

export enum CandidateSource {
  Direct = "Direct",
  Indirect = "Indirect",
}

export enum CandidateOrigin {
  NonEuropeanUnion = "NonEuropeanUnion",
  EuropeanUnion = "EuropeanUnion",
  AlreadyMigrated = "AlreadyMigrated",
}

export interface ICandidateSpecialization {
  professionCategory?: ProfessionCategory;

  candidateFunction?: StaticDataTypeFunctions;

  candidateSource?: CandidateSource;

  candidateOrigin?: CandidateOrigin;
}

export const WILDCARD = "*";

export function mapCandidateSpecializationToIdentifier(candidateSpecialization: ICandidateSpecialization): string {
  const parts = [
    candidateSpecialization.candidateSource ?? WILDCARD,
    candidateSpecialization.candidateFunction ?? WILDCARD,
    candidateSpecialization.professionCategory ?? WILDCARD,
    candidateSpecialization.candidateOrigin ?? WILDCARD,
  ];
  return parts.join("|");
}

export function extractCandidateSpecializationFromIdentifier(identifier: string): ICandidateSpecialization {
  if (!identifier) {
    return null;
  }
  const parts = identifier.split("|");
  return {
    candidateSource: parts[0] === WILDCARD ? undefined : (parts[0] as any),
    candidateFunction: parts[1] === WILDCARD ? undefined : (parts[1] as any),
    professionCategory: parts[2] === WILDCARD ? undefined : (parts[2] as any),
    candidateOrigin: parts[3] === WILDCARD ? undefined : (parts[3] as any),
  };
}

export function findBestMatch<T extends ICandidateSpecialization>(
  toFind: ICandidateSpecialization,
  items: T[]
): T | undefined {
  const scores = items.map((item) => calculateMatchingScore(toFind, item));
  const maxScore = Math.max(...scores);
  if (maxScore === 0) {
    return undefined;
  }
  const bestMatchIndex = scores.indexOf(maxScore);
  return items[bestMatchIndex];
}

function calculateMatchingScore(toMatch: ICandidateSpecialization, specialization: ICandidateSpecialization): number {
  const scoreSource = getScore(toMatch.candidateSource, specialization.candidateSource);
  const scoreFunction = getScore(toMatch.candidateFunction, specialization.candidateFunction);
  const scoreCategory = getScore(toMatch.professionCategory, specialization.professionCategory);
  const scoreOrigin = getScore(toMatch.candidateOrigin, specialization.candidateOrigin);

  if (scoreSource === 0 || scoreFunction === 0 || scoreCategory === 0) {
    return 0;
  }

  return 8 * scoreSource + 4 * scoreOrigin + 2 * scoreFunction + 1 * scoreCategory;

  function getScore(toMatch: string, specialization: string): number {
    if (toMatch === specialization) {
      return 1;
    } else if (isWildCard(specialization)) {
      return 0.5;
    } else {
      return 0;
    }
  }

  function isWildCard(value: any): boolean {
    return value === undefined || value === WILDCARD;
  }
}

export function generateAllSpecializations(): ICandidateSpecialization[] {
  const allSpecializations: ICandidateSpecialization[] = [];
  Object.values(CandidateSource).forEach((source) => {
    StaticDataTypeFunctionsValues.forEach((func) => {
      Object.values(ProfessionCategory).forEach((category) => {
        Object.values(CandidateOrigin).forEach((candidateOrigin) => {
          allSpecializations.push({
            candidateSource: source,
            candidateFunction: func,
            professionCategory: category,
            candidateOrigin,
          });
        });
      });
    });
  });

  return allSpecializations;
}
