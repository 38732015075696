import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { APP_VERSION } from "../services/tokens.model";

export function versionInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const version = inject(APP_VERSION);
  if (req.url.includes("graphql")) {
    req = req.clone({
      headers: req.headers.append("X-App-Version", version.full),
    });
  }
  return next(req);
}
