import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, PLATFORM_ID, ElementRef, booleanAttribute, Directive, Input, NgModule } from '@angular/core';
import * as i1 from 'primeng-v17/dom';

/**
 * AutoFocus manages focus on focusable element on load.
 * @group Components
 */
class AutoFocus {
  domHandler;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  autofocus = false;
  focused = false;
  platformId = inject(PLATFORM_ID);
  document = inject(DOCUMENT);
  host = inject(ElementRef);
  constructor(domHandler) {
    this.domHandler = domHandler;
  }
  ngAfterContentChecked() {
    // This sets the `attr.autofocus` which is different than the Input `autofocus` attribute.
    if (this.autofocus === false) {
      this.host.nativeElement.removeAttribute('autofocus');
    } else {
      this.host.nativeElement.setAttribute('autofocus', true);
    }
    if (!this.focused) {
      this.autoFocus();
    }
  }
  ngAfterViewChecked() {
    if (!this.focused) {
      this.autoFocus();
    }
  }
  autoFocus() {
    if (isPlatformBrowser(this.platformId) && this.autofocus) {
      setTimeout(() => {
        const focusableElements = this.domHandler.getFocusableElements(this.host?.nativeElement);
        if (focusableElements.length === 0) {
          this.host.nativeElement.focus();
        }
        if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }
        this.focused = true;
      });
    }
  }
  static ɵfac = function AutoFocus_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AutoFocus)(i0.ɵɵdirectiveInject(i1.DomHandler));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: AutoFocus,
    selectors: [["", "pAutoFocus", ""]],
    hostAttrs: [1, "p-element"],
    inputs: {
      autofocus: [2, "autofocus", "autofocus", booleanAttribute]
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocus, [{
    type: Directive,
    args: [{
      selector: '[pAutoFocus]',
      standalone: true,
      host: {
        class: 'p-element'
      }
    }]
  }], () => [{
    type: i1.DomHandler
  }], {
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }]
  });
})();
class AutoFocusModule {
  static ɵfac = function AutoFocusModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AutoFocusModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AutoFocusModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocusModule, [{
    type: NgModule,
    args: [{
      imports: [AutoFocus],
      exports: [AutoFocus]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AutoFocus, AutoFocusModule };
