import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { PLATFORM_ID, Directive, Inject, Optional, NgModule } from '@angular/core';
import * as i1 from 'primeng/api';
import * as i2 from 'primeng/dom';

/**
 * Ripple directive adds ripple effect to the host element.
 * @group Components
 */
class Ripple {
  document;
  platformId;
  renderer;
  el;
  zone;
  config;
  domHandler;
  constructor(document, platformId, renderer, el, zone, config, domHandler) {
    this.document = document;
    this.platformId = platformId;
    this.renderer = renderer;
    this.el = el;
    this.zone = zone;
    this.config = config;
    this.domHandler = domHandler;
  }
  animationListener;
  mouseDownListener;
  timeout;
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.config && this.config.ripple) {
        this.zone.runOutsideAngular(() => {
          this.create();
          this.mouseDownListener = this.renderer.listen(this.el.nativeElement, 'mousedown', this.onMouseDown.bind(this));
        });
      }
    }
  }
  onMouseDown(event) {
    let ink = this.getInk();
    if (!ink || this.document.defaultView?.getComputedStyle(ink, null).display === 'none') {
      return;
    }
    this.domHandler.removeClass(ink, 'p-ink-active');
    if (!this.domHandler.getHeight(ink) && !this.domHandler.getWidth(ink)) {
      let d = Math.max(this.domHandler.getOuterWidth(this.el.nativeElement), this.domHandler.getOuterHeight(this.el.nativeElement));
      ink.style.height = d + 'px';
      ink.style.width = d + 'px';
    }
    let offset = this.domHandler.getOffset(this.el.nativeElement);
    let x = this.domHandler.getPageX(event) - offset.left + this.document.body.scrollTop - this.domHandler.getWidth(ink) / 2;
    let y = event.pageY - offset.top + this.domHandler.getScrollLeft(this.document.body) - this.domHandler.getHeight(ink) / 2;
    this.renderer.setStyle(ink, 'top', y + 'px');
    this.renderer.setStyle(ink, 'inset-inline-start', x + 'px');
    this.domHandler.addClass(ink, 'p-ink-active');
    this.timeout = setTimeout(() => {
      let ink = this.getInk();
      if (ink) {
        this.domHandler.removeClass(ink, 'p-ink-active');
      }
    }, 401);
  }
  getInk() {
    const children = this.el.nativeElement.children;
    for (let i = 0; i < children.length; i++) {
      if (typeof children[i].className === 'string' && children[i].className.indexOf('p-ink') !== -1) {
        return children[i];
      }
    }
    return null;
  }
  resetInk() {
    let ink = this.getInk();
    if (ink) {
      this.domHandler.removeClass(ink, 'p-ink-active');
    }
  }
  onAnimationEnd(event) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.domHandler.removeClass(event.currentTarget, 'p-ink-active');
  }
  create() {
    let ink = this.renderer.createElement('span');
    this.renderer.addClass(ink, 'p-ink');
    this.renderer.appendChild(this.el.nativeElement, ink);
    this.renderer.setAttribute(ink, 'aria-hidden', 'true');
    this.renderer.setAttribute(ink, 'role', 'presentation');
    if (!this.animationListener) {
      this.animationListener = this.renderer.listen(ink, 'animationend', this.onAnimationEnd.bind(this));
    }
  }
  remove() {
    let ink = this.getInk();
    if (ink) {
      this.mouseDownListener && this.mouseDownListener();
      this.animationListener && this.animationListener();
      this.mouseDownListener = null;
      this.animationListener = null;
      this.domHandler.removeElement(ink);
    }
  }
  ngOnDestroy() {
    if (this.config && this.config.ripple) {
      this.remove();
    }
  }
  static ɵfac = function Ripple_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Ripple)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.PrimeNGConfig, 8), i0.ɵɵdirectiveInject(i2.DomHandler));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: Ripple,
    selectors: [["", "pRipple", ""]],
    hostAttrs: [1, "p-ripple", "p-element"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ripple, [{
    type: Directive,
    args: [{
      selector: '[pRipple]',
      standalone: true,
      host: {
        class: 'p-ripple p-element'
      }
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }, {
    type: i1.PrimeNGConfig,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i2.DomHandler
  }], null);
})();
class RippleModule {
  static ɵfac = function RippleModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RippleModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: RippleModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RippleModule, [{
    type: NgModule,
    args: [{
      imports: [Ripple],
      exports: [Ripple]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Ripple, RippleModule };
