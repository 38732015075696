import { CandidateProperty } from "../../../../apps/backend/src/app/candidates/candidate.model";
import { candidateLabelMapping, internalLabelMapping } from "./candidate.field-label.mapping";

export const NONAMETOKEN = "*NA*";
export const MAX_SELECTED_MULTI_EDIT_CANDIDATES = 100;

export function hasNoName(name: string): boolean {
  return name == null || name.trim().toLocaleUpperCase() === NONAMETOKEN;
}

export function handleNoNameCase(name: string): string {
  if (hasNoName(name)) {
    return "";
  } else {
    return name;
  }
}

export const AlwaysRequiredCandidateFields: string[] = [
  "os.profile.salutation",
  "os.profile.firstname",
  "os.profile.lastname",
];

export const RequiredCandidateFieldsExclusionList: string[] = [
  "os.profile.title",
  "os.profile.birthname",
  "os.profile.mobilePhone",
  "os.profile.immigrationCountryPhone",
];

interface FieldWithIsEmptyCheckbox {
  field: CandidateProperty;
  checkboxField: CandidateProperty;
}

export const CandidateFieldsWithIsEmptyCheckbox: FieldWithIsEmptyCheckbox[] = [
  {
    checkboxField: "os.profile.noFurtherEducationAvailable",
    field: "os.profile.furtherEducations",
  },
  {
    checkboxField: "os.profile.noExperienceAvailable",
    field: "os.profile.experiences",
  },
];

export const MedHealthCareCoreProfessionsOnlyFields: CandidateProperty[] = [
  "os.profile.preferredCareFacility",
  "os.profile.preferredCareFacilityFlexibility",
  "os.profile.preferredWorkingField",
  "os.profile.preferredWorkingFieldFlexibility",
];

export interface CandidateTabConfiguration {
  general?: boolean;
  qualifications?: boolean;
  furtherEducations?: boolean;
  experiences?: boolean;
  languageSkills?: boolean;
  preferences?: boolean;
  vaccinations?: boolean;
  insuranceAndTax?: boolean;
  bankDetails?: boolean;
  other?: boolean;
  qualificationEvaluation?: boolean;
  healthProfessionsRegister?: boolean;
  recognitionPath?: boolean;
  laborMarketAdmission?: boolean;
  qualificationMeasure?: boolean;
  complementaryMeasure?: boolean;
  vocationalSchool?: boolean;
  visa?: boolean;
  rwrCard?: boolean;
  residence?: boolean;
  housingAcquisition?: boolean;
  processStatus?: boolean;
  internal?: boolean;
  events?: boolean;
  notes?: boolean;
  family?: boolean;
  presentation?: boolean;
  attachments?: boolean;
  requiredDocument?: boolean;
  templates?: boolean;
  cardView?: boolean;
  governance?: boolean;
  emails?: boolean;
  customFields?: boolean;
  interviews?: boolean;
  employers?: boolean;
  collections?: boolean;
  education?: boolean;
  competencies?: boolean;
}

export function getFieldNameResourceKey(fieldName: string): string {
  if (fieldName.startsWith("os.profile.")) {
    return candidateLabelMapping[fieldName.replace("os.profile.", "")];
  } else if (fieldName.startsWith("internal.")) {
    return internalLabelMapping[fieldName.replace("internal.", "")];
  } else {
    throw new Error(`Unknown field name ${fieldName}`);
  }
}

export const CandidateProfileMaskSetId = "candidate";
