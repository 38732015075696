import { Injectable } from "@angular/core";
import { GetSettingsGQL } from "@ankaadia/graphql";
import { AuthService } from "@auth0/auth0-angular";
import { BehaviorSubject, Observable, filter, take } from "rxjs";
import { environment } from "../../environments/environment";
import { OfficeEnvService } from "./officeEnvService";

@Injectable({ providedIn: "root" })
export class SettingsService {
  isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  userDisplayName$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  organizationName$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isMasterUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  dialog;

  organizationId: string;

  constructor(
    private readonly settings: GetSettingsGQL,
    private readonly authService: AuthService,
    private readonly envService: OfficeEnvService
  ) {
    if (this.envService.getOfficeEnv() != Office.PlatformType.OfficeOnline) {
    } else {
      authService.isAuthenticated$
        .pipe(
          filter((auth) => !!auth),
          take(1)
        )
        .subscribe((auth) => {
          if (auth) {
            this.isAuthenticated$.next(true);
            this.getSettings().subscribe();
          }
        });
    }
  }

  loginWithPopup(): void {
    if (this.envService.getOfficeEnv() != Office.PlatformType.OfficeOnline) {
      this.showLoginPopup();
    } else {
      this.authService.loginWithPopup();
    }
  }

  logout(): void {
    if (this.envService.getOfficeEnv() != Office.PlatformType.OfficeOnline) {
      this.logoutWithDialog();
    } else {
      this.authService.logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  private showLoginPopup(): void {
    //the dialog is a separate runtime therefore the auth0-data is transferred via local storage
    localStorage.setItem("Auth0Subdomain", environment.auth0_domain);
    localStorage.setItem("Auth0ClientID", environment.auth0_clientId);
    localStorage.setItem("redirectUrl", environment.redirectUrl);

    Office.context.ui.displayDialogAsync(environment.loginUrl, { height: 45, width: 55 }, (result: any) => {
      const dialog = result.value;
      if (result.status != Office.AsyncResultStatus.Failed) {
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (m) => {
          const rslt = JSON.parse(m.message);
          this.envService.setBearerToken(rslt.auth0Token);
          this.isAuthenticated$.next(true);
          this.getSettings().subscribe();
          dialog.close();
        });
      }
    });
  }

  private getSettings(): Observable<void> {
    return new Observable((obs) => {
      this.settings.fetch({ firstLoadInSession: true }, { fetchPolicy: "network-only" }).subscribe({
        next: (val) => {
          const settings = val.data.settings;
          this.organizationId = settings.organizationId;
          this.organizationName$.next(settings.organizationName);
          this.userDisplayName$.next(settings.userDisplayName); // = of(settings.userDisplayName);
          this.isMasterUser$.next(settings.isMasterUser && settings.isMasterOrganization);
          obs.next();
          obs.complete();
        },
        error: () => {
          obs.error();
          obs.complete();
        },
      });
    });
  }

  logoutWithDialog(): void {
    localStorage.setItem("logoutUrl", environment.logoutUrl);
    Office.context.ui.displayDialogAsync(environment.logoutUrl, { height: 50, width: 50 }, (result) => {
      this.dialog = result.value;
      if (result.status != Office.AsyncResultStatus.Failed) {
        setTimeout(() => {
          this.envService.setBearerToken(null);
          this.isAuthenticated$.next(false);
          this.dialog.close();
          window.location.reload();
        }, 1000);
      }
    });
  }
}
