import { Inject, Injectable, Version } from "@angular/core";
import { Router } from "@angular/router";
import { BRANCHNAME, GITSHASHORT } from "@ankaadia/ankaadia-shared";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { IRequestContext } from "@microsoft/applicationinsights-common";
import {
  ApplicationInsights,
  DistributedTracingModes,
  ICustomProperties,
  IExceptionTelemetry,
} from "@microsoft/applicationinsights-web";
import { FRONTEND_ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from "../environment/environment.interface";
import { CustomErrorHandler } from "./custom-error.handler";
import { APP_VERSION } from "./tokens.model";

@Injectable({ providedIn: "root" })
export class ApplicationInsightsService {
  private static appInsights: ApplicationInsights;

  private readonly appInsights: ApplicationInsights;

  static bigTroubleNotifier(message: string, data: any): void {
    // eslint-disable-next-line no-console
    console.warn("BIG_TROUBLE", message, data);
    if (ApplicationInsightsService.appInsights) {
      ApplicationInsightsService.appInsights.trackEvent({ name: "BIG_TROUBLE" }, { message: message, data: data });
    }
  }

  constructor(
    private readonly router: Router,
    @Inject(APP_VERSION) private readonly appVersion: Version,
    @Inject(FRONTEND_ENVIRONMENT_INJECTION_TOKEN) environment: IEnvironment
  ) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appinsights_connectionstring,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: false,
        enableResponseHeaderTracking: false,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        addRequestContext: this.enrichDependency.bind(this),
        correlationHeaderExcludedDomains: ["auth0.com", "api-eu.cometchat.io", "apiclient-eu.cometchat.io"],
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router, errorServices: [new CustomErrorHandler()] },
        },
      },
    });

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    ApplicationInsightsService.appInsights = this.appInsights;
  }

  private enrichDependency(requestContext: IRequestContext): ICustomProperties {
    const properties: ICustomProperties = {};
    const responseText = requestContext.xhr?.responseText ?? "";
    // GraphQL operation response looks like this'{"data":{"operationName".
    // We do not want to do a full json parse here, so we just check if the response starts with '{"data":{"'.
    if (responseText.startsWith('{"data":{"')) {
      const idx = responseText.indexOf('"', 10);
      properties.gqOperationName = responseText.substring(10, idx);
    }
    return properties;
  }

  setUserId(userId: string): void {
    this.appInsights.setAuthenticatedUserContext(userId, userId, true);
  }

  startTrackEvent(name: string): void {
    this.appInsights.startTrackEvent(name);
  }

  stopTrackEvent(name: string, properties?: Record<string, any>, measurements?: Record<string, number>): void {
    this.appInsights.stopTrackEvent(name, properties, measurements);
  }

  clearUserId(): void {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name: name, uri: uri, isLoggedIn: true });
  }

  logException(error: Error, customProperties: ICustomProperties): void {
    const exception: IExceptionTelemetry = {
      exception: error,
    };
    this.appInsights.trackException(exception, customProperties);
  }

  logEvent(name: string, properties?: Record<string, any>): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: Record<string, any>): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logTrace(message: string, properties?: Record<string, any>): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  private loadCustomTelemetryProperties(): void {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties["ApplicationPlatform"] = "Web";
      item.properties["ApplicationName"] = "Ankaadia";
      item.properties["Version"] = this.appVersion.full;
      item.properties["BranchGHA"] = `${BRANCHNAME}/${GITSHASHORT}`;
    });
  }
}
