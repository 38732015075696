import { DocumentSetType } from "./file-type-settings";
import {
  DocumentFileModel,
  DocumentModel,
  DocumentModelWithAccessData,
  DocumentSetModel,
} from "./model/document.model";
import { IStaticDataModel } from "./model/staticData.model";
import { Union } from "./type-utils";
export type DocumentField = keyof Pick<DocumentModel, "completionState" | "comment">;

export type DocumentSetField = keyof Pick<
  DocumentSetModel,
  "validFrom" | "validUntil" | "issueDate" | "dateOfReceipt" | "resubmissionDate" | "physicalTypes"
>;

export const DocumentSetDateFields = [
  "validFrom",
  "validUntil",
  "issueDate",
  "dateOfReceipt",
  "resubmissionDate",
] as const;
export type DocumentSetDateField = keyof Pick<DocumentSetModel, Union<typeof DocumentSetDateFields>>;

export type DefaultSet = "Default";

export type SetContainer = Pick<DocumentModel, "documentSets">;
export type FreeFormatFilesContainer = Pick<DocumentModel, "freeFormatFiles">;
export type DocumentAccessData = Pick<DocumentModelWithAccessData, "isInternalDocument" | "isInternalByRequirement">;

export interface SetId {
  id: string;
}

export interface TypedSet {
  documentSetType: DocumentSetType;
}

export interface ReferencedSet {
  foreignKey: string;
}

export type SelectedSet = DefaultSet | SetId | TypedSet | ReferencedSet;

export interface SelectedSetField {
  field: DocumentSetField;
  selectedSet: SelectedSet;
}

export type SelectedDocumentField = DocumentField | SelectedSetField;

export function isSelectedSetField(field: SelectedDocumentField): field is SelectedSetField {
  return typeof (field as SelectedSetField)?.field === "string";
}

export function isDefaultSet(set: SelectedSet): set is DefaultSet {
  return set === "Default";
}

export function isSetId(set: SelectedSet): set is SetId {
  return typeof (set as SetId)?.id === "string";
}

export function isTypedSet(set: SelectedSet): set is TypedSet {
  return typeof (set as TypedSet)?.documentSetType === "string";
}

export function isReferencedSet(set: SelectedSet): set is ReferencedSet {
  return typeof (set as ReferencedSet)?.foreignKey === "string";
}

export function isSelectedDocumentField(field: SelectedDocumentField): field is DocumentField {
  return !isSelectedSetField(field);
}

export function getForeignKey(selectedField: SelectedDocumentField): string | null {
  return isSelectedSetField(selectedField) && isReferencedSet(selectedField.selectedSet)
    ? selectedField.selectedSet.foreignKey
    : null;
}

export function getSets<T extends SetContainer>(document: T): T["documentSets"] {
  return document?.documentSets ?? [];
}

export function getSetFiles<T extends DocumentSetModel>(set: T): T["files"] {
  return set?.files ?? [];
}

export function setHasDates<T extends DocumentSetModel>(set: T): boolean {
  return !!set?.issueDate || !!set?.dateOfReceipt || !!set?.validFrom || !!set?.validUntil;
}

export function getSetPhysicalTypes<T extends DocumentSetModel>(documentSet: T): string[] {
  return documentSet?.physicalTypes ?? [];
}

export function getDefaultSet<T extends SetContainer>(document: T): T["documentSets"][number] {
  return getSets(document).find((set) => set.isDefaultSet);
}

export function getSetById<T extends SetContainer>(document: T, setId: string): T["documentSets"][number] {
  return getSets(document).find((set) => set.id === setId);
}

export function getSetByType<T extends SetContainer>(
  document: T,
  documentSetType: DocumentSetType
): T["documentSets"][number] {
  return getSets(document).find((set) => set.type === documentSetType);
}

export function getReferencedSet<T extends SetContainer>(document: T, foreignKey: string): T["documentSets"][number] {
  return getSets(document).find((set) => set.foreignKey === foreignKey);
}

export function getSelectedSet<T extends SetContainer>(
  document: T,
  selectedSet?: SelectedSet
): T["documentSets"][number] {
  if (!selectedSet) {
    return null;
  }

  return isDefaultSet(selectedSet)
    ? getDefaultSet(document)
    : isSetId(selectedSet)
      ? getSetById(document, selectedSet.id)
      : isTypedSet(selectedSet)
        ? getSetByType(document, selectedSet.documentSetType)
        : getReferencedSet(document, selectedSet.foreignKey);
}

export function isSelectedSet<T extends DocumentSetModel>(set: T, selectedSet: SelectedSet): boolean {
  if (!selectedSet) {
    return false;
  }

  return isDefaultSet(selectedSet)
    ? set.isDefaultSet
    : isSetId(selectedSet)
      ? set.id === selectedSet.id
      : isTypedSet(selectedSet)
        ? selectedSet.documentSetType === set.type
        : selectedSet.foreignKey === set.foreignKey;
}

export function getSetLabel<T extends DocumentSetModel>(documentSet: T, translate?: (key: string) => string): string {
  if (!documentSet) {
    return null;
  }

  return documentSet.type
    ? translate
      ? translate(`documentSet.types.${documentSet.type}`)
      : documentSet.type
    : documentSet.name;
}

export function getSet<T extends DocumentFileModel, U extends SetContainer>(
  file: T,
  document: U
): U["documentSets"][number] {
  return getSets(document).find((set) => getSetFiles(set).includes(file));
}

export function hasFiles<T extends SetContainer>(document: T): boolean {
  return getFiles(document)?.length > 0;
}

export function getFiles<T extends SetContainer>(document: T): T["documentSets"][number]["files"] {
  return getSets(document).flatMap((set) => getSetFiles(set));
}

export function getFreeFormatFiles<T extends FreeFormatFilesContainer>(document: T): T["freeFormatFiles"] {
  return document?.freeFormatFiles ?? [];
}

export function getTags<T extends DocumentFileModel>(file: T): string[] {
  return file.tags ?? [];
}

export function isDocumentFile(file: DocumentFileModel | File): file is DocumentFileModel {
  return typeof (file as File).arrayBuffer !== "function";
}

export function isRegularFile(file: DocumentFileModel | File): file is File {
  return typeof (file as File).arrayBuffer === "function";
}

export function isInternalDocument<T extends DocumentAccessData>(document?: T): boolean {
  if (document === null || document === undefined) {
    return false;
  }

  return document.isInternalDocument === null || document.isInternalDocument === undefined
    ? document.isInternalByRequirement
    : document.isInternalDocument;
}

export function filterDocumentFormats<T extends IStaticDataModel>(options: T[], availableTags: string[]): T[] {
  return options?.filter((x) => !availableTags?.length || availableTags.includes(x.value));
}
