import { DOCUMENT } from "@angular/common";
import { inject } from "@angular/core";
import { isRtlLanguage } from "@ankaadia/ankaadia-shared";
import { TranslocoService } from "@jsverse/transloco";

export function dirInitializer(): void {
  const transloco = inject(TranslocoService);
  const document = inject(DOCUMENT);

  const language = transloco.getActiveLang();
  document.documentElement.lang = language;
  document.documentElement.dir = isRtlLanguage(language) ? "rtl" : "ltr";
}
