export interface ILanguageProgressTracking {
  id: string;
  module: string;
  presenceRatio: number;
  comment: string;
}

export interface ICustomFieldsModel {
  stFranziskus: {
    educationalAttribution: string;
  };
  talentCountry: {
    numberOfResidents: string;
  };
  defa: {
    expectationWorkliveInGermany: string;
    whyStopWorkingForExistingEmployer: string;
    basicCalculationScore: number;
    percentageCalculationScore: number;
    spatialThinkingScore: number;
    analysisScore: number;
    teamworkSkillScore: number;
    stressHandlungSkillScore: number;
    communicationSkillScore: number;
    assertivenessScore: number;
  };
  medwing: {
    languageProgressTracking: ILanguageProgressTracking[];
  };
}
