export const FRONTEND_ENVIRONMENT_INJECTION_TOKEN = "FRONTEND_ENVIRONMENT_INJECTION_TOKEN";

export interface IEnvironment {
  production: boolean;
  useServiceWorker: boolean;
  graphqlURI: string;
  wsURI: string;
  auth0_clientId: string;
  auth0_domain: string;
  auth0_issuer: string;
  auth0_audience: string;
  appinsights_connectionstring: string;
  emailEditorURI: string;
  facebookAppId: string;
  facebookPageId: string;
  applicationName: string;
  userFlowToken: string;
  recaptchaSiteKey: string;
  cometChatAppId: string;
  cometChatAuthKey: string;
}
