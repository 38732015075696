import { isNil, uniq } from "lodash";
import { Dictionary } from "ts-essentials";
import { firstOrDefault } from "./lodash-extra";
import { IDateWithTime } from "./model/candidate.model";

export const NOT_SENT_ID = "00000000-0000-0000-0000-000000000001";
export const NOT_RELEVANT_ID = "00000000-0000-0000-0000-000000000002";

export const INDIVIDUAL_CONTRACT_ID = "00000000-0000-0000-0000-000000000001";

/**
 * Information that is required to be entered by the user
 * Each enum value reflects a component field in the candidates profile
 */
export enum EnterMissingInformation {
  FederalState = "FederalState",
  FederalStateAt = "FederalStateAt",
  RecognitionAuthority = "RecognitionAuthority",
  RecognitionAuthorityAt = "RecognitionAuthorityAt",
  RecognitionType = "RecognitionType",
  EquivalenceTest = "EquivalenceTest",
  PathOfRecognition = "PathOfRecognition",
  PathOfRecognitionAt = "PathOfRecognitionAt",
  QualificationMeasure = "QualificationMeasure",
  QualificationType = "QualificationType",
  ResponsibleRoleRecognition = "ResponsibleRoleRecognition",
  ResponsibleRoleLaborMarket = "ResponsibleRoleLaborMarket",
  ResponsibleRoleQualification = "ResponsibleRoleQualification",
  ResponsibleRoleVisa = "ResponsibleRoleVisa",
  ResponsibleRoleResidence = "ResponsibleRoleResidence",
  StartOfFurtherEducation = "StartOfFurtherEducation",
  EndOfFurtherEducation = "EndOfFurtherEducation",
  FirstExamDate = "FirstExamDate",
  FirstFinalInterviewDate = "FirstFinalInterviewDate",
  FlightDate = "FlightDate",
  ArrivalDate = "ArrivalDate",
  ResidencePermit = "ResidencePermit",
  ImmigrationAuthority = "ImmigrationAuthority",
  RecognitionPaymentRole = "RecognitionPaymentRole",
  RecognitionPaymentRoleAccelerated = "RecognitionPaymentRoleAccelerated",
  FirstExamPassed = "FirstExamPassed",
  FirstFinalInterviewPassed = "FirstFinalInterviewPassed",
  ResponsibleRecognitionOrganization = "ResponsibleRecognitionOrganization",
  PsaRepresentative = "PsaRepresentative",
  PsaFirstDeputy = "PsaFirstDeputy",
  WorkEmployer = "WorkEmployer",
  WorkEmployerAt = "WorkEmployerAt",
  WorkConfirmationSignedDate = "WorkConfirmationSignedDate",
  WorkStartDate = "WorkStartDate",
  WorkContractSignDate = "WorkContractSignDate",
  WorkContractStartDate = "WorkContractStartDate",
  WorkContractTypeOfWorkLocation = "WorkContractTypeOfWorkLocation",
  WorkContractWorkLocation = "WorkContractWorkLocation",
  EstimatedArrivalDate = "EstimatedArrivalDate",
  EstimatedWorkStartDate = "EstimatedWorkStartDate",
  RecognitionFileNumber = "RecognitionFileNumber",
  RecognitionFileNumberAcceleratedProcedure = "RecognitionFileNumberAcceleratedProcedure",
  TargetRecognition = "TargetRecognition",
  LegalBasis = "LegalBasis",
  StartOfComplementaryMeasure = "StartOfComplementaryMeasure",
  EndOfComplementaryMeasure = "EndOfComplementaryMeasure",
  LocationOfComplementaryMeasure = "LocationOfComplementaryMeasure",
  PlannedEntryDate = "PlannedEntryDate",
  FingerPrintDate = "FingerPrintDate",
  PickUpDetails = "PickUpDetails",
  HealthProfessionRegisterAppointmentDate = "HealthProfessionRegisterAppointmentDate",
  HealthProfessionRegistrationDate = "HealthProfessionRegistrationDate",
  HealthProfessionRegisterSubmission = "HealthProfessionRegisterSubmission",
  RwrCardFileNumber = "RwrCardFileNumber",
  NoQualificationEvaluation = "NoQualificationEvaluation",
  ResponsibleRoleQualificationEvaluationPayment = "ResponsibleRoleQualificationEvaluationPayment",
  ResponsibleRoleQualificationEvaluation = "ResponsibleRoleQualificationEvaluation",
  EndOfProbationDate = "EndOfProbationDate",
  BankAppointmentDate = "BankAppointmentDate",
  CompanyDoctorAppointmentDate = "CompanyDoctorAppointmentDate",
  RegistrationOfficeDate = "RegistrationOfficeDate",
  PoliceRecordAppointmentDate = "PoliceRecordAppointmentDate",
  MedicalExaminationAppointmentDate = "MedicalExaminationAppointmentDate",
}

const _allResponsibleRoleTypes = [
  EnterMissingInformation.ResponsibleRoleRecognition,
  EnterMissingInformation.ResponsibleRoleLaborMarket,
  EnterMissingInformation.ResponsibleRoleQualification,
  EnterMissingInformation.ResponsibleRoleVisa,
  EnterMissingInformation.ResponsibleRoleResidence,
];
export type KnownResponsibleRoleTypes = (typeof _allResponsibleRoleTypes)[number];

export enum EnterMissingInformationAdditionalData {
  PsaRepresentativeFirstName = "PsaRepresentativeFirstName",
  PsaRepresentativeLastName = "PsaRepresentativeLastName",
  PsaFirstDeputyFirstName = "PsaFirstDeputyFirstName",
  PsaFirstDeputyLastName = "PsaFirstDeputyLastName",
  NoQualificationEvaluation = "NoQualificationEvaluation",
  ResponsibleQualificationEvaluationOrganizationId = "ResponsibleQualificationEvaluationOrganizationId",
  QualificationEvaluationRepresentativeId = "QualificationEvaluationRepresentativeId",
  QualificationEvaluationRepresentativeFirstName = "QualificationEvaluationRepresentativeFirstName",
  QualificationEvaluationRepresentativeLastName = "QualificationEvaluationRepresentativeLastName",
  QualificationEvaluationFirstDeputyId = "QualificationEvaluationFirstDeputyId",
  QualificationEvaluationFirstDeputyFirstName = "QualificationEvaluationFirstDeputyFirstName",
  QualificationEvaluationFirstDeputyLastName = "QualificationEvaluationFirstDeputyLastName",
  FederalState = "FederalState",
  RecognitionType = "RecognitionType",
}

export type RequiredInformationValue = string | number | boolean | Date | IDateWithTime | IQualificationExam[] | null;

export interface IQualificationExam {
  id: string;
  examType: string;
  firstExamDate?: Date;
  firstExamPassed?: string;
  secondExamDate?: Date;
  secondExamPassed?: string;
  examArea?: string;
  moduleName?: string;
}

export interface RequiredInformationItem {
  key: EnterMissingInformation;
  value: RequiredInformationValue;
  additionalData?: AdditionalData[];
}

export interface AdditionalData {
  key: EnterMissingInformationAdditionalData;
  value: RequiredInformationValue;
}

export interface RequiredInformation {
  items: RequiredInformationItem[];
}

/**
 * Enum of all possible (task) states
 * - Inactive: The task is not active (starting state)
 * - Active: The task is active and can be started (triggered by activation condition)
 * - Started: The task is started
 * - Cancelled: The task is cancelled
 * - Finished: The task is finished (i.e. successfully completed)
 * - Error: The task is in an error state
 */
export enum InstanceStateEnum {
  Active = "Active",
  Cancelled = "Cancelled",
  Error = "Error",
  Finished = "Finished",
  Inactive = "Inactive",
  Started = "Started",
}

/**
 * Enum for different reply types:
 * - ConfirmReception: The user can confirm that he has received the task
 * - ConfirmAgreement: The user can confirm that he agrees with the task
 * - ReplyWithText: The user can reply with a text
 * - ReplyWithYesOrNo: The user can reply with a yes or no
 */
export enum ReplyTypeEnum {
  ConfirmReception = "ConfirmReception",
  ConfirmAgreement = "ConfirmAgreement",
  ReplyWithText = "ReplyWithText",
  ReplyWithYesOrNo = "ReplyWithYesOrNo",
  ReplyWithYesOrNoAndForcedCommentAtNo = "ReplyWithYesOrNoAndForcedCommentAtNo",
  NoReply = "NoReply",
  ReplyWithDate = "ReplyWithDate",
}

/**
 * Enum for different process target types:
 * - FamiliyMember: The process is for a family member
 * - Candidate: The process is for a candidate
 * - Collection: The process is for a collection
 */
export enum ProcessTargetEnum {
  FamiliyMember = "FamiliyMember",
  Candidate = "Candidate",
  Collection = "Collection",
}

/**
 * Enum for different audit task modes
 * - GatherAuditInformation: The task is used to gather audit information
 * - PerformAudit: The task is used to perform an audit
 * - EnterAdditionalInformation: The task is used to enter additional information
 * - AuditAdditionalInformation: The task is used to audit additional information
 * - ViewQualityComittee: The task is used to view the quality comittee
 * - GatherFinalVotum: The task is used to gather the final votum
 * - ViewExaminee: The task is used to view the examinee
 */
export enum AuditTaskModeEnum {
  GatherAuditInformation = "GatherAuditInformation",
  PerformAudit = "PerformAudit",
  EnterAdditionalInformation = "EnterAdditionalInformation",
  AuditAdditionalInformation = "AuditAdditionalInformation",
  ViewCONFIDANTE = "ViewCONFIDANTE",
  ViewQualityComittee = "ViewQualityComittee",
  GatherFinalVotum = "GatherFinalVotum",
  AdditionalInformationAfterFinalVotum = "AdditionalInformationAfterFinalVotum",
  AdditionalInformationAuditAfterFinalVotum = "AdditionalInformationAuditAfterFinalVotum",
  ViewExaminee = "ViewExaminee",
  None = "None",
}

export enum TaskVisibilityEnum {
  WholeOrganization = "WholeOrganization",
  UserGroup = "UserGroup",
}

export enum ProcessInstanceStateEnum {
  Inactive = "Inactive",
  Active = "Active",
}

export enum ProcessModelCommandEnum {
  CreateTask = "CreateTask",
  UpdateTask = "UpdateTask",
  DeleteTask = "DeleteTask",
  MoveTask = "MoveTask",
  DuplicateTask = "DuplicateTask",
  CreatePhase = "CreatePhase",
  UpdatePhase = "UpdatePhase",
  DeletePhase = "DeletePhase",
  MovePhase = "MovePhase",
  DuplicatePhase = "DuplicatePhase",
  CreateArea = "CreateArea",
  UpdateArea = "UpdateArea",
  DeleteArea = "DeleteArea",
  MoveArea = "MoveArea",
  DuplicateArea = "DuplicateArea",
  BulkTranslate = "BulkTranslate",
  TranslateTask = "TranslateTask",
  TranslatePhase = "TranslatePhase",
  TranslateArea = "TranslateArea",
  TranslateProcess = "TranslateProcess",
}

export enum CandidateAssignmentExceptionType {
  Include = "Include",
  Exclude = "Exclude",
}

export enum CandidateAssignmentExceptionOperation {
  AddException = "AddException",
  RemoveException = "RemoveException",
}

export enum RuleExecutionErrorReason {
  RunConditionError = "RunConditionError",
  ExpressionError = "ExpressionError",
  ValidationError = "ValidationError",
  GeneralError = "GeneralError",
}

export interface IProcessString {
  language: string;
  value?: string;
}

export interface IProcessOptionalString {
  language: string;
  value?: string;
}

export interface IProcessOptionalQuillDelta {
  language: string;
  value?: any;
}

export interface IProcess {
  organizationId?: string;
  id?: string;
  collectionId?: string;
  languages: string[];
  roles: IProcessRole[];
}

export interface IProcessStageEntity {
  displayName?: IProcessOptionalString[];
  displayDescription?: IProcessOptionalString[];
  displayDescriptionQD?: IProcessOptionalQuillDelta[]; // Displaydescription stored as Quill Delta
}

export interface IProcessArea {
  phases: IProcessPhase[];
}

export interface IProcessPhase {
  tasks: IProcessTask[];
}

export interface IProcessRole {
  processRole: string;
  displayName: IProcessString[];
}

export interface ITaskReminderSettings {
  enabled: boolean;
  firstReminderInDays: number;
  followUpReminderInDays?: number;
  escalate?: boolean;
  escalationAfterAttemps?: number;
}

export interface IProcessQuillDelta {
  language: string;
  value?: any;
}

export interface IRuleExecutionError {
  reason: RuleExecutionErrorReason;
  ruleId: string;
  ruleName: string;
  errorMessage?: string;
  technicalDetails?: string;
}

export interface IProcessTask extends IProcessStageEntity {
  // TODO:
  // organizationId: string;
  // parentId: string;
  // rootId: string;
  // sortOrder: number;

  // Running process only
  instanceState?: InstanceStateEnum;
  processInstanceState?: ProcessInstanceStateEnum;
  displayDescriptionQD?: IProcessQuillDelta[];
  candidateId?: string;
  candidateDisplayId?: string;
  candidateDisplayName?: string;
  candidateOrganizationId?: string;
  manuallyCreated?: boolean;
  previousTaskId?: string;
  readonly?: boolean;
  ownerRoles?: string[];
  sortOrder?: number;

  // Common
  _etag?: string;
  atLeastOnePredecessorIds?: string[];
  cancelLabel?: IProcessString[];
  delayStartByDays?: number;
  displayDescription?: IProcessString[];
  displayName?: IProcessString[];
  fieldsToExcludeFromSync?: string[];
  finishLabel?: IProcessString[];
  hideGenericTaskComment?: boolean;
  id?: string;
  isDeleted?: boolean;
  isHidden?: boolean;
  localizedCancelLabel?: string;
  localizedDisplayDescription?: string;
  localizedDisplayName?: string;
  localizedFinishLabel?: string;
  localizedReactivatedCancelLabel?: string;
  localizedReactivatedFinishLabel?: string;
  localizedRuleExecutionError?: string;
  modelId: string;
  predecessorIds?: string[];
  reactivateTasksOnCancel?: string[];
  reactivateTasksOnFinish?: string[];
  reactivatedByTask?: string;
  reactivatedCancelLabel?: IProcessString[];
  reactivatedFinishLabel?: IProcessString[];
  reactivatedRequiresCancelReason?: boolean;
  ruleExecutionError?: IRuleExecutionError;
  reminderSettings?: ITaskReminderSettings;
  requiresCancelReason?: boolean;
  responsibleRoles: string[];
  selectFinishByDefault?: boolean;
  targetObject: ProcessTargetEnum;
  targetRoles: string[];
  visibleForRoles: string[];
}

export enum ProcessMagicString {
  MoveToFirstPosition = "MoveToFirstPosition",
}

export enum CollectionAutoTemplateVariableEnum {
  Source = "Source",
  EmployerBeforeRecognition = "EmployerBeforeRecognition",
  EmployerAfterRecognition = "EmployerAfterRecognition",
  LanguageSchool = "LanguageSchool",
  CountryOfOrigin = "CountryOfOrigin",
  ImmigrationCountry = "ImmigrationCountry",
  Function = "Function",
  EmployerApplication = "EmployerApplication",
  EmployerInterview = "EmployerInterview",
  Profession = "Profession",
  QualificationMeasure = "QualificationMeasure",
  CourseNumber = "CourseNumber",
}

export enum CollectionAutoTemplateVariableDataType {
  Organization = "Organization",
  Country = "Country",
  Function = "Function",
  Profession = "Profession",
  QualificationMeasure = "QualificationMeasure",
  Text = "Text",
}

const collectionAutoTemplateVariableDataTypeMap: Dictionary<CollectionAutoTemplateVariableDataType> = {
  Source: CollectionAutoTemplateVariableDataType.Organization,
  EmployerBeforeRecognition: CollectionAutoTemplateVariableDataType.Organization,
  EmployerAfterRecognition: CollectionAutoTemplateVariableDataType.Organization,
  LanguageSchool: CollectionAutoTemplateVariableDataType.Organization,
  CountryOfOrigin: CollectionAutoTemplateVariableDataType.Country,
  ImmigrationCountry: CollectionAutoTemplateVariableDataType.Country,
  Function: CollectionAutoTemplateVariableDataType.Function,
  EmployerApplication: CollectionAutoTemplateVariableDataType.Organization,
  EmployerInterview: CollectionAutoTemplateVariableDataType.Organization,
  Profession: CollectionAutoTemplateVariableDataType.Profession,
  QualificationMeasure: CollectionAutoTemplateVariableDataType.QualificationMeasure,
  CourseNumber: CollectionAutoTemplateVariableDataType.Text,
};

export function getCollectionAutoTemplateVariableDataType(
  variable: CollectionAutoTemplateVariableEnum
): CollectionAutoTemplateVariableDataType {
  const result = collectionAutoTemplateVariableDataTypeMap[variable];
  if (!result) {
    throw new Error(`Unknown CollectionAutoTemplateVariable: ${variable}`);
  }
  return result;
}

const collectionAutoTemplateVariableMap: Dictionary<CollectionAutoTemplateVariableEnum> = {
  "@Source": CollectionAutoTemplateVariableEnum.Source,
  "@LanguageSchool": CollectionAutoTemplateVariableEnum.LanguageSchool,
  "@CountryOfOrigin": CollectionAutoTemplateVariableEnum.CountryOfOrigin,
  "@ImmigrationCountry": CollectionAutoTemplateVariableEnum.ImmigrationCountry,
  "@EmployerBeforeRecognition": CollectionAutoTemplateVariableEnum.EmployerBeforeRecognition,
  "@EmployerAfterRecognition": CollectionAutoTemplateVariableEnum.EmployerAfterRecognition,
  "@Function": CollectionAutoTemplateVariableEnum.Function,
  "@EmployerApplication": CollectionAutoTemplateVariableEnum.EmployerApplication,
  "@EmployerInterview": CollectionAutoTemplateVariableEnum.EmployerInterview,
  "@Profession": CollectionAutoTemplateVariableEnum.Profession,
  "@QualificationMeasure": CollectionAutoTemplateVariableEnum.QualificationMeasure,
  "@CourseNumber": CollectionAutoTemplateVariableEnum.CourseNumber,
};

export function getCollectionAutoTemplateVariableNames(): string[] {
  return Object.keys(collectionAutoTemplateVariableMap);
}

const collectionAutoTemplateVariableMapReverse: Dictionary<string> = Object.entries(
  collectionAutoTemplateVariableMap
).reduce<Dictionary<string>>((acc, [key, value]) => ((acc[value] = key), acc), {});

export function getCollectionAutoTemplateVariableValue(variable: CollectionAutoTemplateVariableEnum): string {
  const result = collectionAutoTemplateVariableMapReverse[variable];
  if (!result) {
    throw new Error(`Unknown CollectionAutoTemplateVariable: ${variable}`);
  }
  return result;
}

export function fromCollectionAutoTemplateVariableValue(value: string): CollectionAutoTemplateVariableEnum {
  const result = collectionAutoTemplateVariableMap[value];
  if (!result) {
    throw new Error(`Unknown CollectionAutoTemplateVariable: ${value}`);
  }
  return result;
}

export function isCollectionAutoTemplateVariable(value: string): boolean {
  return !!collectionAutoTemplateVariableMap[value];
}

export function getCollectionAutoTemplateVariableTemplatePlaceholder(
  variable: CollectionAutoTemplateVariableEnum
): string {
  return `{${variable}}`;
}

export interface IQualificationEvaluationConfiguration {
  evaluatedQualification?: string;
  responsibleAuthorityForQualification?: string;
  responsibleAuthorityForQualificationInOrigin?: string;
  federalState?: string;
}

export interface IQualificationEvaluationRequest {
  evaluationRequested?: boolean;
  evaluationRequestedDate?: Date;
  fileNumber?: string;
}

export interface IQualificationEvaluationReceived {
  evaluationFinished?: boolean;
  evaluationFinishedDate?: Date;
}

export interface IDisplayNames {
  displayName?: string[];
  displayDescription?: string;
}

export function getProcessStageDisplayName(
  displayNameContainer: IProcessStageEntity,
  ...preferredLanguages: string[]
): IProcessOptionalString | null {
  const displayName = displayNameContainer.displayName ?? [];
  return getProcessString(displayName, ...preferredLanguages);
}

export function getProcessStageDisplayDescription(
  displayDescriptionContainer: IProcessStageEntity,
  ...preferredLanguages: string[]
): IProcessOptionalString | null {
  const displayDescription = displayDescriptionContainer.displayDescription ?? [];
  return getProcessString(displayDescription, ...preferredLanguages);
}

export function getProcessString<T extends Partial<IProcessString | IProcessOptionalString>>(
  strings: T[],
  ...preferredLanguages: string[]
): T | null {
  const fixedStrings = strings ?? [];
  const fixedLanguages = uniq(preferredLanguages ?? []);

  const foundLanguage = firstOrDefault(fixedLanguages, (language) => fixedStrings.some((x) => x.language === language));
  return !isNil(foundLanguage)
    ? firstOrDefault(fixedStrings, (x) => x.language === foundLanguage)
    : firstOrDefault(fixedStrings);
}
