export type TranslationKey = ""
    | "CandidateInterviewsFeedback"
  | "CandidateNote"
  | "EZBData.companyRegistrationAuthority"
  | "EZBData.create"
  | "EZBData.detailsOfSocialSecurityIssues.shortTitle"
  | "EZBData.detailsOfSocialSecurityIssues.title"
  | "EZBData.edit"
  | "EZBData.hasInsolvencyProceedingInLast5Years.shortTitle"
  | "EZBData.hasInsolvencyProceedingInLast5Years.title"
  | "EZBData.hasInsolvencyProceedingLackedAssets.shortTitle"
  | "EZBData.hasInsolvencyProceedingLackedAssets.title"
  | "EZBData.hasPendingSecurityPayments.shortTitle"
  | "EZBData.hasPendingSecurityPayments.title"
  | "EZBData.hasSocialSecurityIssuesInLast5Years.shortTitle"
  | "EZBData.hasSocialSecurityIssuesInLast5Years.title"
  | "EZBData.manage"
  | "EZBData.numberOfEmployers"
  | "EZBData.registerNo"
  | "EZBData.title"
  | "EmailTarget.placeholder"
  | "EmailTarget.required"
  | "EmailTarget.title"
  | "Process"
  | "ProcessArea"
  | "ProcessPhase"
  | "Unknown"
  | "accessDenied.noPermissions"
  | "accessDenied.notLoggedIn"
  | "accessDenied.title"
  | "accountHolder.placeholder"
  | "accountHolder.title"
  | "activationCondition.blocklyWorkspace"
  | "activationCondition.linkToBlocklyDocumentation"
  | "activationCondition.title"
  | "activeRecognitionPath.title"
  | "activityLog.criteria"
  | "activityLog.description.CandidateRequestDeletion"
  | "activityLog.description.autoProcessOperationTriggered"
  | "activityLog.description.candidateAdded"
  | "activityLog.description.candidateAddedToCollection"
  | "activityLog.description.candidateAddedToExam"
  | "activityLog.description.candidateBirthday"
  | "activityLog.description.candidateBirthdaySoon"
  | "activityLog.description.candidateDeleted"
  | "activityLog.description.candidateDeletionReminder"
  | "activityLog.description.candidateDroppedOut"
  | "activityLog.description.candidateLoggedIn"
  | "activityLog.description.candidateModified"
  | "activityLog.description.candidateNotFound"
  | "activityLog.description.candidateOptedIn"
  | "activityLog.description.candidateOptedOut"
  | "activityLog.description.candidateRemovedFromCollection"
  | "activityLog.description.candidateRemovedFromExam"
  | "activityLog.description.collectionAdded"
  | "activityLog.description.collectionDeleted"
  | "activityLog.description.collectionEmbeddedIntoCollection"
  | "activityLog.description.collectionNotFound"
  | "activityLog.description.collectionRemovedFromCollection"
  | "activityLog.description.collectionUpdated"
  | "activityLog.description.collectionUpdatedAndRenamed"
  | "activityLog.description.documentNotFound"
  | "activityLog.description.documentSetMetaDataModified"
  | "activityLog.description.educationExamCreated"
  | "activityLog.description.educationExamDeleted"
  | "activityLog.description.educationExamResultCreated"
  | "activityLog.description.educationExamResultDeleted"
  | "activityLog.description.educationExamUpdated"
  | "activityLog.description.emailNotDelivered"
  | "activityLog.description.feedbackProvided"
  | "activityLog.description.feedbackReset"
  | "activityLog.description.fieldAdded"
  | "activityLog.description.fieldDeleted"
  | "activityLog.description.fieldModified"
  | "activityLog.description.fileDeleted"
  | "activityLog.description.fileExpiresSoon"
  | "activityLog.description.fileModified"
  | "activityLog.description.fileUploaded"
  | "activityLog.description.freeFormatFileDeleted"
  | "activityLog.description.freeFormatFileUploaded"
  | "activityLog.description.invalidSharingTemplate"
  | "activityLog.description.invalidUserGroupRoleMapping"
  | "activityLog.description.organizationNotFound"
  | "activityLog.description.organizationNotLinked"
  | "activityLog.description.processCreated"
  | "activityLog.description.processDeleted"
  | "activityLog.description.processModelDeleted"
  | "activityLog.description.processModelPublished"
  | "activityLog.description.processModelReleaseCreated"
  | "activityLog.description.processModelUnpublished"
  | "activityLog.description.processNotFound"
  | "activityLog.description.sharingAdded"
  | "activityLog.description.sharingDeleted"
  | "activityLog.description.sharingDisabled"
  | "activityLog.description.sharingEnabled"
  | "activityLog.description.sharingTypeModified"
  | "activityLog.description.sharingUpdated"
  | "activityLog.description.taskCancelled"
  | "activityLog.description.taskChanged"
  | "activityLog.description.taskDeleted"
  | "activityLog.description.taskFailed"
  | "activityLog.description.taskFinished"
  | "activityLog.description.taskManuallyChanged"
  | "activityLog.description.taskManuallyCreated"
  | "activityLog.description.taskNotFound"
  | "activityLog.description.taskNoteAdded"
  | "activityLog.description.taskReminderSent"
  | "activityLog.description.taskStarted"
  | "activityLog.description.taskStateChanged"
  | "activityLog.description.taskStateManuallyChanged"
  | "activityLog.description.userLoggedIn"
  | "activityLog.description.userNotFound"
  | "activityLog.title"
  | "activityType.title"
  | "adHocExamResults.add"
  | "additionalInfo.title"
  | "additionalInformation.title"
  | "additionalInformation.titleShort"
  | "additionalInformationLabel.title"
  | "address.addressNoLineBreaks"
  | "address.first"
  | "address.fourth"
  | "address.houseNumber"
  | "address.place"
  | "address.placeholder"
  | "address.required"
  | "address.second"
  | "address.street"
  | "address.third"
  | "address.title"
  | "addressOfInstitution.title"
  | "addressType.invalidCombination"
  | "addressType.placeholder"
  | "addressType.required"
  | "addressType.title"
  | "addresses.addressesWithoutTypeExist"
  | "addresses.confirmDelete"
  | "addresses.idAddressIsMissing"
  | "addresses.immigrationCountryMismatch"
  | "addresses.isMissing"
  | "addresses.mustBeUnique"
  | "addresses.newAddress"
  | "addresses.title"
  | "advancedDescription.EmployerName"
  | "advancedDescription.LanguageSchoolName"
  | "advancedDescription.LocalPartnerName"
  | "advancedDescription.PSAName"
  | "advancedDescription.addressNoLineBreaks"
  | "advancedDescription.appCandidateDocumentLink"
  | "advancedDescription.appCandidateEditLink"
  | "advancedDescription.appCandidateProfileLink"
  | "advancedDescription.appCandidateViewLink"
  | "advancedDescription.appCollectionEditLink"
  | "advancedDescription.appEmployerSearchLink"
  | "advancedDescription.appMyOrganizationLink"
  | "advancedDescription.appProcessEditLink"
  | "advancedDescription.appProcessViewLink"
  | "advancedDescription.applicationSelectedLocations"
  | "advancedDescription.candidateAdditionalExperiences"
  | "advancedDescription.candidateAddress"
  | "advancedDescription.candidateAfterContract"
  | "advancedDescription.candidateAfterEmployer"
  | "advancedDescription.candidateAfterRecognitionEmployerAuthorizedRepresentative"
  | "advancedDescription.candidateAfterRecognitionEmployerAuthorizedRepresentativeFirstDeputy"
  | "advancedDescription.candidateAfterRecognitionEmployerRecognitionContactPerson"
  | "advancedDescription.candidateAfterRecognitionResponsibleOrganization"
  | "advancedDescription.candidateAfterRecognitionSelectedRepresentative"
  | "advancedDescription.candidateAfterRecognitionTypeOfWorkLocation"
  | "advancedDescription.candidateAfterSelectedRepresentative"
  | "advancedDescription.candidateAllLanguageSkills"
  | "advancedDescription.candidateCertifiedGermanLanguageLevel"
  | "advancedDescription.candidateChildren"
  | "advancedDescription.candidateContract"
  | "advancedDescription.candidateCountryOfOriginAddress"
  | "advancedDescription.candidateCurrentAddress"
  | "advancedDescription.candidateCurrentBankDetail"
  | "advancedDescription.candidateCurrentScholarship"
  | "advancedDescription.candidateCustomFields"
  | "advancedDescription.candidateDegree"
  | "advancedDescription.candidateDiplomDate"
  | "advancedDescription.candidateDisplayId"
  | "advancedDescription.candidateEducationVoucherRequested"
  | "advancedDescription.candidateEducations"
  | "advancedDescription.candidateEmployer"
  | "advancedDescription.candidateEmployerAuthorizedRepresentative"
  | "advancedDescription.candidateEmployerAuthorizedRepresentativeFirstDeputy"
  | "advancedDescription.candidateEmployerRecognitionContactPerson"
  | "advancedDescription.candidateEquivalenceTest"
  | "advancedDescription.candidateEstimatedWorkStartDate"
  | "advancedDescription.candidateEvaluatedForeignDegrees"
  | "advancedDescription.candidateEvaluationForeignDegreesFirstDeputy"
  | "advancedDescription.candidateEvaluationForeignDegreesPaymentRepresentative"
  | "advancedDescription.candidateEvaluationForeignDegreesRepresentative"
  | "advancedDescription.candidateExperiences"
  | "advancedDescription.candidateFamily"
  | "advancedDescription.candidateFunction"
  | "advancedDescription.candidateFurtherEducations"
  | "advancedDescription.candidateGender"
  | "advancedDescription.candidateGermanLanguageLevel"
  | "advancedDescription.candidateGermanLanguageLevelInProgress"
  | "advancedDescription.candidateHealthProfessionsRegisterAppointmentDate"
  | "advancedDescription.candidateIdAddress"
  | "advancedDescription.candidateImmigrationCountryAddress"
  | "advancedDescription.candidateInteviewsFeedback"
  | "advancedDescription.candidateLanguageSkills"
  | "advancedDescription.candidateLanguageTracking"
  | "advancedDescription.candidateMedicalExperiences"
  | "advancedDescription.candidateName"
  | "advancedDescription.candidateNoEvaluationForeignDegrees"
  | "advancedDescription.candidateOccupationalTitle"
  | "advancedDescription.candidateOther"
  | "advancedDescription.candidatePartner"
  | "advancedDescription.candidatePartnerWCustody"
  | "advancedDescription.candidatePostSchoolEducations"
  | "advancedDescription.candidatePreferredCareFacility"
  | "advancedDescription.candidateProfessionOriginal"
  | "advancedDescription.candidatePsa"
  | "advancedDescription.candidatePsaFirstDeputy"
  | "advancedDescription.candidatePsaRepresentative"
  | "advancedDescription.candidateRecognitionPaymentRole"
  | "advancedDescription.candidateRecognitionPaymentRoleAccelerated"
  | "advancedDescription.candidateRecognitionPaymentRoleAcceleratedOrganization"
  | "advancedDescription.candidateRecognitionPaymentRoleAcceleratedRepresentative"
  | "advancedDescription.candidateRecognitionPaymentRoleOrganization"
  | "advancedDescription.candidateRecognitionPaymentRoleRepresentative"
  | "advancedDescription.candidateResidencePermit"
  | "advancedDescription.candidateResponsibleAuthority"
  | "advancedDescription.candidateResponsibleEmployees"
  | "advancedDescription.candidateResponsibleOrganization"
  | "advancedDescription.candidateResponsibleOrganizationEvaluationForeignDegrees"
  | "advancedDescription.candidateResponsibleOrganizationEvaluationForeignDegreesPayment"
  | "advancedDescription.candidateResponsibleRecognitionFirstDeputy"
  | "advancedDescription.candidateResponsibleRecognitionRepresentative"
  | "advancedDescription.candidateResponsibleRoleComplementaryMeasure"
  | "advancedDescription.candidateResponsibleRoleQualification"
  | "advancedDescription.candidateResponsibleRoleRecognition"
  | "advancedDescription.candidateResponsibleRoleRwrCard"
  | "advancedDescription.candidateScholarships"
  | "advancedDescription.candidateSchoolEducations"
  | "advancedDescription.candidateSelectedRepresentative"
  | "advancedDescription.candidateSource"
  | "advancedDescription.candidateTotalExperience"
  | "advancedDescription.candidateVaccinations"
  | "advancedDescription.candidateVisa"
  | "advancedDescription.candidateVocationalSchool"
  | "advancedDescription.candidateWorkStartDate"
  | "advancedDescription.competences"
  | "advancedDescription.courses"
  | "advancedDescription.organizationCRMData"
  | "advancedDescription.processName"
  | "advancedDescription.recognitionAuthorityRespTitle"
  | "advancedDescription.today"
  | "advancedSettings.title"
  | "afterRecognition.shortTitle"
  | "afterRecognition.title"
  | "afterRecognitionWork.endDate"
  | "afterRecognitionWork.startDate"
  | "afterSSOLogin.description"
  | "afterSSOLogin.linkingAccount"
  | "afterSSOLogin.redirectingTo"
  | "agreed.title"
  | "ai.recommendation"
  | "ai.status"
  | "ai.title"
  | "allFilesSent.title"
  | "allowLinkingToManagedAndSharedOrganisation.title"
  | "allowLinkingToManagedAndSharedOrganisation.titleShort"
  | "alternative.title"
  | "alternativeRecognitionPath.title"
  | "alwaysRequiredFields.title"
  | "anabin.degree.class"
  | "anabin.degree.comment"
  | "anabin.degree.country"
  | "anabin.degree.degreeNameDE"
  | "anabin.degree.detailName"
  | "anabin.degree.durationMax"
  | "anabin.degree.durationMin"
  | "anabin.degree.femaleName"
  | "anabin.degree.fieldOfStudyDE"
  | "anabin.degree.institution.city"
  | "anabin.degree.institution.country"
  | "anabin.degree.institution.name"
  | "anabin.degree.institution.title"
  | "anabin.degree.institution.type"
  | "anabin.degree.name"
  | "anabin.degree.ratings.DEdegreeType"
  | "anabin.degree.ratings.equivalent"
  | "anabin.degree.ratings.title"
  | "anabin.degree.specialization"
  | "anabin.degree.type"
  | "anabin.institution.address"
  | "anabin.institution.altName"
  | "anabin.institution.city"
  | "anabin.institution.country"
  | "anabin.institution.degrees"
  | "anabin.institution.homepage"
  | "anabin.institution.name"
  | "anabin.institution.nameDE"
  | "anabin.institution.partnerCity"
  | "anabin.institution.partnerName"
  | "anabin.institution.phone"
  | "anabin.institution.state"
  | "anabin.institution.type"
  | "ankaadiaTools.exportStaticData"
  | "ankaadiaTools.rebuildSharingMatrix"
  | "ankaadiaTools.rebuildSharingMatrixDialog"
  | "ankaadiaTools.title"
  | "ankaadiaTools.usedVariables"
  | "anonymization.options"
  | "anonymization.placeholder"
  | "anonymizationTypeEnum.Address"
  | "anonymizationTypeEnum.BirthDate"
  | "anonymizationTypeEnum.Citizenship"
  | "anonymizationTypeEnum.CountryOfOrigin"
  | "anonymizationTypeEnum.EmailAddress"
  | "anonymizationTypeEnum.FamilyAndChildren"
  | "anonymizationTypeEnum.Gender"
  | "anonymizationTypeEnum.Name"
  | "anonymizationTypeEnum.PhoneNumber"
  | "anonymizationTypeEnum.Picture"
  | "application.coverLetter"
  | "application.hasEntries"
  | "application.selectedLocations"
  | "application.selectedPosition"
  | "application.title"
  | "applicationSafeCompetencies.title"
  | "applications.receive"
  | "appliedOn.title"
  | "appointmentHealthDepartmentDate.title"
  | "appointmentHealthDepartmentKept.title"
  | "area.title"
  | "areaOfDeployment.placeholder"
  | "areaOfDeployment.required"
  | "areaOfDeployment.title"
  | "arrivalAirport.title"
  | "arrivalDate.required"
  | "arrivalDate.title"
  | "arrivalPoint.required"
  | "arrivalPoint.title"
  | "arrivalTimeSlot.required"
  | "arrivalTimeSlot.title"
  | "arrivalTrainStation.title"
  | "asFreelancer.placeholder"
  | "asFreelancer.title"
  | "assignableMembers.title"
  | "atLeastOnePredecessorIds.placeholder"
  | "atLeastOnePredecessorIds.title"
  | "attachment.delete"
  | "attachment.download"
  | "attachment.edit"
  | "attachment.failed"
  | "attachment.failed2"
  | "attachment.generalError"
  | "attachment.missingAttachmentError"
  | "attachment.new"
  | "attachment.pending"
  | "attachment.view"
  | "attachmentForms.placeholder"
  | "attachmentForms.title"
  | "attachmentTemplate.placeholder"
  | "attachmentTemplate.required"
  | "attachmentTemplate.title"
  | "attachmentTemplate.unique"
  | "attachmentTemplates.emptyMessage"
  | "attachmentTemplates.placeholder"
  | "attachmentTemplates.title"
  | "attachments.required"
  | "attachments.title"
  | "attachments.view"
  | "attachments.zip"
  | "attendance.placeholder"
  | "attendance.title"
  | "attendance.updated"
  | "auditDefinitionItem.alerts.dragDropalert"
  | "auditDefinitionItem.alerts.title"
  | "auditDefinitionItem.confirmDelete"
  | "auditDefinitionItem.created.message"
  | "auditDefinitionItem.created.title"
  | "auditDefinitionItem.deleteNode.confirmDelete"
  | "auditDefinitionItem.deleted.cant"
  | "auditDefinitionItem.deleted.message"
  | "auditDefinitionItem.deleted.title"
  | "auditDefinitionItem.name"
  | "auditDefinitionItem.updated.message"
  | "auditDefinitionItem.updated.title"
  | "auditDefinitions.data.description"
  | "auditDefinitions.data.descriptionOwnRecruitment"
  | "auditDefinitions.data.helpText"
  | "auditDefinitions.data.helpTextProof"
  | "auditDefinitions.data.name"
  | "auditDefinitions.filesAreMandatory"
  | "auditDefinitions.forEmployer"
  | "auditDefinitions.forPSA"
  | "auditDefinitions.linked"
  | "auditDefinitions.manage"
  | "auditDefinitions.placeholder"
  | "auditDefinitions.reCertificationOnly"
  | "auditDefinitions.relevantFor"
  | "auditDefinitions.settings"
  | "auditDefinitions.title"
  | "autoCollection.autoFilter"
  | "autoCollection.confirmUpdateFilter"
  | "autoCollection.create"
  | "autoCollection.edit"
  | "autoCollection.editFilter"
  | "autoCollection.filterDetails.allCandidates"
  | "autoCollection.filterDetails.ownCandidates"
  | "autoCollection.filterNotSet.allCandidates"
  | "autoCollection.filterNotSet.ownCandidates"
  | "autoCollection.fromTemplate"
  | "autoCollection.includeSharedCandidates"
  | "autoCollection.new"
  | "autoCollectionTemplate.applyOnAllCandidates.confirm"
  | "autoCollectionTemplate.applyOnAllCandidates.success"
  | "autoCollectionTemplate.applyOnAllCandidates.title"
  | "autoCollectionTemplate.attach.autoLists"
  | "autoCollectionTemplate.attach.confirm"
  | "autoCollectionTemplate.attach.error"
  | "autoCollectionTemplate.attach.info"
  | "autoCollectionTemplate.attach.success"
  | "autoCollectionTemplate.attach.title"
  | "autoCollectionTemplate.autoTemplate"
  | "autoCollectionTemplate.collections.collection"
  | "autoCollectionTemplate.collections.creationDate"
  | "autoCollectionTemplate.collections.inSync"
  | "autoCollectionTemplate.collections.title"
  | "autoCollectionTemplate.collections.variables"
  | "autoCollectionTemplate.confirmDetach"
  | "autoCollectionTemplate.confirmSync"
  | "autoCollectionTemplate.confirmSyncAll"
  | "autoCollectionTemplate.confirmUpdateFilter"
  | "autoCollectionTemplate.deletePrompt.deleteBehavior"
  | "autoCollectionTemplate.deletePrompt.deleteBehaviorOptions.delete"
  | "autoCollectionTemplate.deletePrompt.deleteBehaviorOptions.detach"
  | "autoCollectionTemplate.deletePrompt.message"
  | "autoCollectionTemplate.deletePrompt.title"
  | "autoCollectionTemplate.detach"
  | "autoCollectionTemplate.detachedSuccessfully"
  | "autoCollectionTemplate.edit"
  | "autoCollectionTemplate.editFilter"
  | "autoCollectionTemplate.filterTemplate.title"
  | "autoCollectionTemplate.name.alreadyUsed"
  | "autoCollectionTemplate.name.placeholder"
  | "autoCollectionTemplate.name.required"
  | "autoCollectionTemplate.name.title"
  | "autoCollectionTemplate.new"
  | "autoCollectionTemplate.newTemplate"
  | "autoCollectionTemplate.requiredVariablesMismatch.message"
  | "autoCollectionTemplate.requiredVariablesMismatch.title"
  | "autoCollectionTemplate.saveBehavior.detachAll"
  | "autoCollectionTemplate.saveBehavior.message"
  | "autoCollectionTemplate.saveBehavior.messageVariableChanged"
  | "autoCollectionTemplate.saveBehavior.saveAsName.alreadyUsed"
  | "autoCollectionTemplate.saveBehavior.saveAsName.placeholder"
  | "autoCollectionTemplate.saveBehavior.saveAsName.required"
  | "autoCollectionTemplate.saveBehavior.saveAsName.title"
  | "autoCollectionTemplate.saveBehavior.saveAsNew"
  | "autoCollectionTemplate.saveBehavior.standard"
  | "autoCollectionTemplate.saveBehavior.title"
  | "autoCollectionTemplate.specialPurpose.hint"
  | "autoCollectionTemplate.specialPurpose.placeholder"
  | "autoCollectionTemplate.specialPurpose.title"
  | "autoCollectionTemplate.sync"
  | "autoCollectionTemplate.syncAll"
  | "autoCollectionTemplate.syncedAllSuccessfully"
  | "autoCollectionTemplate.syncedSuccessfully"
  | "autoCollectionTemplate.templateName.placeholder"
  | "autoCollectionTemplate.templateName.required"
  | "autoCollectionTemplate.templateName.title"
  | "autoCollectionTemplate.title"
  | "autoCollectionTemplate.variableDescriptions.CountryOfOrigin"
  | "autoCollectionTemplate.variableDescriptions.EmployerAfterRecognition"
  | "autoCollectionTemplate.variableDescriptions.EmployerApplication"
  | "autoCollectionTemplate.variableDescriptions.EmployerBeforeRecognition"
  | "autoCollectionTemplate.variableDescriptions.EmployerInterview"
  | "autoCollectionTemplate.variableDescriptions.Function"
  | "autoCollectionTemplate.variableDescriptions.ImmigrationCountry"
  | "autoCollectionTemplate.variableDescriptions.LanguageSchool"
  | "autoCollectionTemplate.variableDescriptions.Profession"
  | "autoCollectionTemplate.variableDescriptions.QualificationMeasure"
  | "autoCollectionTemplate.variableDescriptions.Source"
  | "autoCollectionTemplate.variableDescriptions.title"
  | "autoCollectionTemplate.variables"
  | "automaticRejectionEmailsEnabled.title"
  | "availableAfterCreation.title"
  | "availableForCandidates.title"
  | "availableFormats.title"
  | "availableLanguages.title"
  | "availableVersions.title"
  | "bank.title"
  | "bankAccountNumber.placeholder"
  | "bankAccountNumber.title"
  | "bankAppointmentDate.help"
  | "bankAppointmentDate.required"
  | "bankAppointmentDate.title"
  | "bankCode.placeholder"
  | "bankCode.title"
  | "bankCountry.placeholder"
  | "bankCountry.title"
  | "bankDetails.add"
  | "bankDetails.immigrationCountry"
  | "bankDetails.originalCountry"
  | "bankDetails.title"
  | "bankName.placeholder"
  | "bankName.title"
  | "bankSortNumber.placeholder"
  | "bankSortNumber.title"
  | "baseProcess.placeholder"
  | "baseProcess.required"
  | "baseProcess.title"
  | "beds.placeholder"
  | "beds.required"
  | "beds.title"
  | "beds.titleFull"
  | "beforeRecognition.shortTitle"
  | "beforeRecognition.title"
  | "beforeRecognitionWork.endDate"
  | "beforeRecognitionWork.startDate"
  | "behaviorType.placeholder"
  | "behaviorType.required"
  | "behaviorType.title"
  | "benefits.edit"
  | "benefits.placeholder"
  | "benefits.title"
  | "bic.placeholder"
  | "bic.title"
  | "bicOrSwift.title"
  | "birthDate.age"
  | "birthDate.deprecated"
  | "birthDate.invalid"
  | "birthDate.required"
  | "birthDate.title"
  | "birthDate.unavailable"
  | "birthDateInPassport.required"
  | "birthDateInPassport.title"
  | "birthName.required"
  | "birthName.title"
  | "blocked.title"
  | "blockly.block.activate"
  | "blockly.block.addDays"
  | "blockly.block.addHours"
  | "blockly.block.addMinutes"
  | "blockly.block.cancel"
  | "blockly.block.candidateCountryOfOrigin"
  | "blockly.block.candidateEmailEntered"
  | "blockly.block.candidateHasALanguageCertificateFor"
  | "blockly.block.candidateStatus"
  | "blockly.block.category"
  | "blockly.block.createDigitalDocumentRequirement"
  | "blockly.block.currentTask"
  | "blockly.block.days"
  | "blockly.block.donothing"
  | "blockly.block.else"
  | "blockly.block.field"
  | "blockly.block.getArrivalDate"
  | "blockly.block.getCandidateProperty"
  | "blockly.block.getWorkStartDate"
  | "blockly.block.if"
  | "blockly.block.isAfter"
  | "blockly.block.isAvailable"
  | "blockly.block.isCandidateOfProcessOrganization"
  | "blockly.block.isDocumentUploaded"
  | "blockly.block.isHidden"
  | "blockly.block.isSameOrAfter"
  | "blockly.block.isSelectedEquivalenceTestType"
  | "blockly.block.isSelectedFederalState"
  | "blockly.block.isSelectedQualificationMeasure"
  | "blockly.block.isSelectedRecognitionPath"
  | "blockly.block.isSelectedRecognitionType"
  | "blockly.block.isSelectedRoleForRecognition"
  | "blockly.block.isTaskFinished"
  | "blockly.block.isTodayOrWithinTheNext"
  | "blockly.block.noTasksAvailable"
  | "blockly.block.now"
  | "blockly.block.process"
  | "blockly.block.property"
  | "blockly.block.ruleRunCondition"
  | "blockly.block.ruleSetPropertyExpression"
  | "blockly.block.selectedTaskDoesNotSupportProperty"
  | "blockly.block.task"
  | "blockly.block.taskActivationCondition"
  | "blockly.block.taskExists"
  | "blockly.block.taskState"
  | "blockly.block.template"
  | "blockly.block.then"
  | "blockly.block.today"
  | "blockly.block.withLevel"
  | "blockly.category.candidate"
  | "blockly.category.date"
  | "blockly.category.document"
  | "blockly.category.lists"
  | "blockly.category.logic"
  | "blockly.category.math"
  | "blockly.category.process"
  | "blockly.category.search"
  | "blockly.category.text"
  | "blockly.category.valueRanges"
  | "blockly.category.variables"
  | "boolean.false"
  | "boolean.true"
  | "cancelReason.required"
  | "cancelReason.title"
  | "candidate.added"
  | "candidate.alternativeImage.confirmDelete"
  | "candidate.alternativeImage.title"
  | "candidate.assign"
  | "candidate.assignFailed"
  | "candidate.assignFailedToLists"
  | "candidate.assignFull"
  | "candidate.assigned"
  | "candidate.blocked.message"
  | "candidate.blocked.title"
  | "candidate.cardView"
  | "candidate.collectionWarning"
  | "candidate.confirmNumber"
  | "candidate.confirmRemove"
  | "candidate.deleted"
  | "candidate.deletionRequestWarning"
  | "candidate.droppedOutState"
  | "candidate.droppedOutWarning"
  | "candidate.duplicateEmailInWorksheet"
  | "candidate.educationWarning"
  | "candidate.file"
  | "candidate.filesWarning"
  | "candidate.formsWarning"
  | "candidate.fullName"
  | "candidate.functionKey"
  | "candidate.id"
  | "candidate.image.confirmDelete"
  | "candidate.image.title"
  | "candidate.immigrationCountryWarning"
  | "candidate.internal"
  | "candidate.interviewWarning"
  | "candidate.interviewsFeedback.asDate"
  | "candidate.interviewsFeedback.candidateFeedback"
  | "candidate.interviewsFeedback.comments"
  | "candidate.interviewsFeedback.confirmDelete"
  | "candidate.interviewsFeedback.created"
  | "candidate.interviewsFeedback.createdAt"
  | "candidate.interviewsFeedback.deleted"
  | "candidate.interviewsFeedback.employer"
  | "candidate.interviewsFeedback.employerId"
  | "candidate.interviewsFeedback.interviewDate"
  | "candidate.interviewsFeedback.interviewerFeedback"
  | "candidate.interviewsFeedback.participants"
  | "candidate.interviewsFeedback.required"
  | "candidate.interviewsFeedback.title"
  | "candidate.interviewsFeedback.updated"
  | "candidate.interviewsFeedback.vote"
  | "candidate.invite"
  | "candidate.invited.message"
  | "candidate.invited.title"
  | "candidate.invitedButNoEmail"
  | "candidate.lastStatusChangedDate"
  | "candidate.missingField"
  | "candidate.missingStates"
  | "candidate.noLastname"
  | "candidate.notUniqueEmail"
  | "candidate.notes"
  | "candidate.notesFull"
  | "candidate.notesRequired"
  | "candidate.notesTooLong"
  | "candidate.notesWarning"
  | "candidate.notificationsdisabled"
  | "candidate.onboarding"
  | "candidate.onboardingWarning"
  | "candidate.pausedWarning"
  | "candidate.placeholder"
  | "candidate.placeholderGeneral"
  | "candidate.presentation.downloadCV"
  | "candidate.presentation.errorCV"
  | "candidate.presentation.title"
  | "candidate.professionWarning"
  | "candidate.profile"
  | "candidate.profileUpdated.message"
  | "candidate.profileUpdated.title"
  | "candidate.profiles"
  | "candidate.readonlyProfile.message"
  | "candidate.readonlyProfile.title"
  | "candidate.recommendationNote"
  | "candidate.reinvite"
  | "candidate.rejectWarning"
  | "candidate.rejectWarningNoEmail"
  | "candidate.remove"
  | "candidate.removed.message"
  | "candidate.removed.title"
  | "candidate.responsibleEmployees.placeholder"
  | "candidate.responsibleEmployees.required"
  | "candidate.responsibleEmployees.shortTitle"
  | "candidate.responsibleEmployees.title"
  | "candidate.scholarship.amountOfMoney.placeholder"
  | "candidate.scholarship.amountOfMoney.title"
  | "candidate.scholarship.awardFrequency.placeholder"
  | "candidate.scholarship.awardFrequency.title"
  | "candidate.scholarship.endDate"
  | "candidate.scholarship.period"
  | "candidate.scholarship.startDate"
  | "candidate.scholarship.title"
  | "candidate.scholarships.add"
  | "candidate.scholarships.errors.timeframeOverlap"
  | "candidate.scholarships.title"
  | "candidate.stateKey"
  | "candidate.statusChangedToApprovedDate"
  | "candidate.statusChangedToArchivedDate"
  | "candidate.statusChangedToAssociatedDate"
  | "candidate.statusChangedToDroppedOutDate"
  | "candidate.statusChangedToForSelectionDate"
  | "candidate.statusChangedToInRecognitionDate"
  | "candidate.statusChangedToLabourMigrationFinishedDate"
  | "candidate.statusChangedToMatchedDate"
  | "candidate.statusChangedToNewDate"
  | "candidate.statusChangedToPausedDate"
  | "candidate.statusChangedToRejectedDate"
  | "candidate.statusChangedToTransferredDate"
  | "candidate.statusChangingReason"
  | "candidate.title"
  | "candidate.titleDrOnly"
  | "candidate.titleKey"
  | "candidate.unblocked.message"
  | "candidate.unblocked.title"
  | "candidate.unboard"
  | "candidate.unboarded.message"
  | "candidate.unboarded.title"
  | "candidate.updated"
  | "candidate.warning"
  | "candidate.warningCreateNewForeign"
  | "candidate.wrongExperienceField"
  | "candidate2.title"
  | "candidateAllowance.title"
  | "candidateDeletion.automaticDelete"
  | "candidateDeletion.candidateWillBeDeleted"
  | "candidateDeletion.confirmOne"
  | "candidateDeletion.confirmTwo"
  | "candidateDeletion.filter"
  | "candidateDeletion.label"
  | "candidateDeletion.succes"
  | "candidateDeletion.title"
  | "candidateEvent.category.title"
  | "candidateEvent.date.title"
  | "candidateEvent.timeframe.title"
  | "candidateEvents.notAvailableForCandidate"
  | "candidateId.title"
  | "candidateMultiEdit.allSelectedCandidates"
  | "candidateMultiEdit.invited"
  | "candidateMultiEdit.preview"
  | "candidateMultiEdit.title"
  | "candidateMultiEdit.toBeUpdated"
  | "candidateMultiEdit.uninvited"
  | "candidateMultiEdit.updated"
  | "candidateMultiEdit.withDifferentValue"
  | "candidateMultiEdit.withNoValue"
  | "candidateMultiEdit.withTheValue"
  | "candidateReply.title"
  | "candidateTags.placeholder"
  | "candidateTags.title"
  | "candidates.assign"
  | "candidates.assigned"
  | "candidates.assigned2"
  | "candidates.assignedFull"
  | "candidates.assignmentException.edit"
  | "candidates.assignmentExceptionOperation"
  | "candidates.assignmentExceptionsFull"
  | "candidates.available"
  | "candidates.downloadImportTemplate"
  | "candidates.downloadLegacyImportTemplate"
  | "candidates.downloadMigrationTemplate"
  | "candidates.edit"
  | "candidates.events"
  | "candidates.implicitOperation"
  | "candidates.import"
  | "candidates.importFailed"
  | "candidates.importOfCandidateFailed"
  | "candidates.importOfCandidateWasSkipped"
  | "candidates.imported"
  | "candidates.invalidDataInRows"
  | "candidates.invite"
  | "candidates.line"
  | "candidates.partiallyImported"
  | "candidates.title"
  | "candidates.uninvite"
  | "candidates.view"
  | "candidatesService.validation.invalidDate"
  | "candidatesService.validation.isNotEmpty"
  | "capacity.title"
  | "careFacilities.title"
  | "careFacility.placeholder"
  | "careFacility.required"
  | "careFacility.title"
  | "careSituations.title"
  | "category.placeholder"
  | "category.title"
  | "certificateOfConduct.placeholder"
  | "certificateOfConduct.title"
  | "certificateOfConductRequested.title"
  | "certifiedEducationExams.title"
  | "chamberOfCrafts.placeholder"
  | "chamberOfCrafts.required"
  | "chamberOfCrafts.title"
  | "changedAt.title"
  | "changedBy.title"
  | "checkTaskActivationPeriodically.title"
  | "children.invalid"
  | "children.required"
  | "children.title"
  | "citizenship.code"
  | "citizenship.internalCode"
  | "citizenship.placeholder"
  | "citizenship.required"
  | "citizenship.title"
  | "citizenship.unavailable"
  | "city.placeholder"
  | "city.required"
  | "city.title"
  | "cityOfBirth.deprecated"
  | "cityOfBirth.placeholder"
  | "cityOfBirth.required"
  | "cityOfBirth.title"
  | "class-validator.arrayNotEmpty"
  | "class-validator.isAlpha"
  | "class-validator.isAlphanumeric"
  | "class-validator.isArray"
  | "class-validator.isBoolean"
  | "class-validator.isDate"
  | "class-validator.isDefined"
  | "class-validator.isEmail"
  | "class-validator.isEnum"
  | "class-validator.isIn"
  | "class-validator.isInt"
  | "class-validator.isNegative"
  | "class-validator.isNotEmpty"
  | "class-validator.isNotIn"
  | "class-validator.isNumber"
  | "class-validator.isNumberString"
  | "class-validator.isObject"
  | "class-validator.isPositive"
  | "class-validator.isString"
  | "class-validator.isUrl"
  | "class-validator.isUuid"
  | "class-validator.matches"
  | "class-validator.max"
  | "class-validator.maxDate"
  | "class-validator.maxLength"
  | "class-validator.min"
  | "class-validator.minDate"
  | "class-validator.minLength"
  | "class-validator.moreItems"
  | "code.required"
  | "code.title"
  | "collection.assignFull"
  | "collection.assigned"
  | "collection.confirmDelete"
  | "collection.confirmTransfer"
  | "collection.confirmUnassign"
  | "collection.copyOf"
  | "collection.create"
  | "collection.created.message"
  | "collection.created.title"
  | "collection.deleteFailed.message"
  | "collection.deleteFailed.title"
  | "collection.deleted.message"
  | "collection.deleted.title"
  | "collection.edit"
  | "collection.editAssignments"
  | "collection.hasEntries"
  | "collection.new"
  | "collection.placeholder"
  | "collection.required"
  | "collection.share"
  | "collection.title"
  | "collection.transfer.errorMessage"
  | "collection.transfer.licenseMissing"
  | "collection.transfer.noEmbeddedCollections"
  | "collection.transfer.notPartOfProcess"
  | "collection.transfer.notSuccessfull"
  | "collection.transfer.notTransferedCandidates"
  | "collection.transfer.onlyOwnCandidates"
  | "collection.transfer.result"
  | "collection.transfer.sharingInvalid"
  | "collection.transfer.successfull"
  | "collection.transfer.title"
  | "collection.transfer.toBig"
  | "collection.transfer.toSmall"
  | "collection.transfer.transferedCandidates"
  | "collection.transfer.validationMessage"
  | "collection.transfer.wrongUser"
  | "collection.transferFull"
  | "collection.transferOptions.candidateCopy"
  | "collection.transferOptions.copyExplanation"
  | "collection.transferOptions.internalDocuments"
  | "collection.transferOptions.internalNotes"
  | "collection.transferOptions.interviewData"
  | "collection.transferWarning"
  | "collection.transferred.message"
  | "collection.transferred.title"
  | "collection.unassigned"
  | "collection.updated.message"
  | "collection.updated.title"
  | "collectionName.required"
  | "collectionName.title"
  | "collections.assignedFull"
  | "collections.available"
  | "collections.title"
  | "comment.added"
  | "comment.at"
  | "comment.by"
  | "comment.deleted"
  | "comment.title"
  | "comment.updated"
  | "common.add"
  | "common.and"
  | "common.andMore"
  | "common.attach"
  | "common.back"
  | "common.cancel"
  | "common.changedAt"
  | "common.clear"
  | "common.close"
  | "common.collapseAll"
  | "common.confirm"
  | "common.copy"
  | "common.create"
  | "common.createdAt"
  | "common.createdThrough"
  | "common.delete"
  | "common.detach"
  | "common.details"
  | "common.download"
  | "common.duration"
  | "common.edit"
  | "common.expandAll"
  | "common.hint"
  | "common.history"
  | "common.import"
  | "common.info"
  | "common.isInactive"
  | "common.missingLabel"
  | "common.month"
  | "common.more"
  | "common.n/a"
  | "common.name"
  | "common.new"
  | "common.next"
  | "common.noDataAvailable"
  | "common.notAvailable"
  | "common.ok"
  | "common.or"
  | "common.outOf"
  | "common.period"
  | "common.preview"
  | "common.publish"
  | "common.reUpload"
  | "common.refresh"
  | "common.refreshHard"
  | "common.release"
  | "common.remove"
  | "common.republish"
  | "common.required"
  | "common.save"
  | "common.saveAndClose"
  | "common.saveAndNext"
  | "common.search"
  | "common.select"
  | "common.settings"
  | "common.showLess"
  | "common.showMore"
  | "common.since"
  | "common.undo"
  | "common.unit.kb"
  | "common.unit.mb"
  | "common.unpublish"
  | "common.unsavedChanges"
  | "common.update"
  | "common.validate"
  | "common.validateOnTheFly"
  | "common.validation.max"
  | "common.validation.maxLength"
  | "common.validation.min"
  | "common.validation.minLength"
  | "common.validation.range"
  | "common.validation.required"
  | "common.validation.requiredShort"
  | "common.year"
  | "communication.title"
  | "companyDoctorAppointmentDate.required"
  | "companyDoctorAppointmentDate.title"
  | "companyName.required"
  | "companyName.title"
  | "competencies.title"
  | "complaint.description.title"
  | "complaint.title"
  | "complementaryMeasure.end"
  | "complementaryMeasure.institution"
  | "complementaryMeasure.location"
  | "complementaryMeasure.responsibleRole"
  | "complementaryMeasure.start"
  | "complementaryMeasure.title"
  | "completionState.complete"
  | "completionState.incomplete"
  | "completionState.placeholder"
  | "completionState.title"
  | "completionState.tooltip"
  | "condition.placeholder"
  | "condition.required"
  | "condition.title"
  | "configToken.required"
  | "configToken.title"
  | "configuration.title"
  | "confirmationSignedDate.title"
  | "connectWithFacebook.failure"
  | "connectWithFacebook.header"
  | "connectWithFacebook.message"
  | "connectWithFacebook.success"
  | "consent.accepted"
  | "consent.alreadyExists"
  | "consent.at"
  | "consent.configured"
  | "consent.confirmDelete"
  | "consent.create"
  | "consent.created.message"
  | "consent.created.title"
  | "consent.deleted.message"
  | "consent.deleted.title"
  | "consent.edit"
  | "consent.read"
  | "consent.requireCandidate"
  | "consent.requireUser"
  | "consent.targetGroup.placeholder"
  | "consent.targetGroup.required"
  | "consent.targetGroup.title"
  | "consent.title"
  | "consent.updated.message"
  | "consent.updated.title"
  | "consentDate.accepted"
  | "consentDate.notAccepted"
  | "contact.confirmDelete"
  | "contact.create"
  | "contact.created"
  | "contact.deleted"
  | "contact.edit"
  | "contact.placeholder"
  | "contact.required"
  | "contact.updated"
  | "contactAtArrivalLocation.info"
  | "contactAtArrivalLocation.title"
  | "contactSettings.confirmImport"
  | "contactSettings.downloadImportTemplate"
  | "contactSettings.hint.createOnly"
  | "contactSettings.hint.restrictions"
  | "contactSettings.import"
  | "contactSettings.importIssue.missingOrganization"
  | "contactSettings.importIssue.organizationContactsNotShared"
  | "contactSettings.importIssue.organizationNotShared"
  | "contactSettings.importIssue.overwritesExistingValue"
  | "contactSettings.importTo"
  | "contactUserGroups.placeholder"
  | "contactUserGroups.title"
  | "contacts.configured"
  | "contacts.confirmImport"
  | "contacts.downloadImportTemplate"
  | "contacts.help"
  | "contacts.import"
  | "contacts.importTo"
  | "contacts.manage"
  | "contacts.title"
  | "contents.required"
  | "contents.title"
  | "contract.fixedTerm"
  | "contract.permanent"
  | "contract.placeholder"
  | "contract.title"
  | "contract.type"
  | "contractConditions.detail"
  | "contractConditions.explanation"
  | "contractConditions.head"
  | "contractConditions.placeholder"
  | "contractConditions.saved"
  | "contractConditions.title"
  | "contractExtension.title"
  | "contractModel.placeholder"
  | "contractModel.title"
  | "contractSignDate.title"
  | "contractTemplate.code"
  | "contractTemplate.collectiveAgreement"
  | "contractTemplate.collectiveAgreementAT"
  | "contractTemplate.collectiveAgreementBoth"
  | "contractTemplate.confirmDelete"
  | "contractTemplate.contractType.placeholder"
  | "contractTemplate.contractType.title"
  | "contractTemplate.countervailingBenefitKeys"
  | "contractTemplate.countervailingBenefits"
  | "contractTemplate.create"
  | "contractTemplate.created.message"
  | "contractTemplate.created.title"
  | "contractTemplate.deleted.message"
  | "contractTemplate.deleted.title"
  | "contractTemplate.details"
  | "contractTemplate.edit"
  | "contractTemplate.employmentRelationshipType.placeholder"
  | "contractTemplate.employmentRelationshipType.title"
  | "contractTemplate.employmentRelationshipTypeKey"
  | "contractTemplate.endDate"
  | "contractTemplate.holidayDaysPerYear"
  | "contractTemplate.holidayEntitlement"
  | "contractTemplate.id"
  | "contractTemplate.inclShiftAllowance"
  | "contractTemplate.individual"
  | "contractTemplate.manage"
  | "contractTemplate.matching.label"
  | "contractTemplate.matching.placeholder"
  | "contractTemplate.monetaryEquivalentOfOtherBenefits"
  | "contractTemplate.name"
  | "contractTemplate.newForAT"
  | "contractTemplate.newForDE"
  | "contractTemplate.numberOfSalaries"
  | "contractTemplate.otherPaymentRegulation"
  | "contractTemplate.otherPaymentRegulationExplanation"
  | "contractTemplate.otherPecuniaryBenefits.code"
  | "contractTemplate.otherPecuniaryBenefits.placeholder"
  | "contractTemplate.otherPecuniaryBenefits.selectedItems"
  | "contractTemplate.otherPecuniaryBenefits.title"
  | "contractTemplate.overtimeCompensation"
  | "contractTemplate.overtimeHours"
  | "contractTemplate.overtimeRegulation"
  | "contractTemplate.overtimeWithinLimitsPermittedByLaw"
  | "contractTemplate.payRate"
  | "contractTemplate.paymentPerHour"
  | "contractTemplate.paymentPerMonth"
  | "contractTemplate.placeholder"
  | "contractTemplate.professionDescription"
  | "contractTemplate.professionTitle"
  | "contractTemplate.remuneration"
  | "contractTemplate.remunerationCalculationBasis.placeholder"
  | "contractTemplate.remunerationCalculationBasis.title"
  | "contractTemplate.remunerationCalculationBasisKey"
  | "contractTemplate.remunerationGroup"
  | "contractTemplate.remunerationGroupAU"
  | "contractTemplate.remunerationGroupBoth"
  | "contractTemplate.remunerationType.placeholder"
  | "contractTemplate.remunerationType.title"
  | "contractTemplate.remunerationTypeKey"
  | "contractTemplate.required"
  | "contractTemplate.shiftAllowance"
  | "contractTemplate.shiftAllowanceExplanation"
  | "contractTemplate.startDate"
  | "contractTemplate.title"
  | "contractTemplate.updated.message"
  | "contractTemplate.updated.title"
  | "contractTemplate.workingHoursPerWeek"
  | "contractTemplate.workingTimeType.placeholder"
  | "contractTemplate.workingTimeType.title"
  | "contractTemplate.workingTimeTypeKey"
  | "contractTemplates.confirmImport"
  | "contractTemplates.downloadImportTemplate"
  | "contractTemplates.import"
  | "contractTemplates.importIssue.duplicateName"
  | "contractTemplates.importIssue.missingOrganization"
  | "contractTemplates.importIssue.nameAlreadyExists"
  | "contractTemplates.importIssue.organizationContractsNotShared"
  | "contractTemplates.importIssue.organizationNotShared"
  | "contractTemplates.importToMyOrganizationOnly"
  | "contractTemplates.importToPartners"
  | "cooperationType.placeholder"
  | "cooperationType.title"
  | "costCenter.required"
  | "costCenter.title"
  | "costData.title"
  | "country.code"
  | "country.placeholder"
  | "country.required"
  | "country.title"
  | "countryOfBirth.key"
  | "countryOfBirth.placeholder"
  | "countryOfBirth.required"
  | "countryOfBirth.title"
  | "countryOfHabitualResidence.placeholder"
  | "countryOfHabitualResidence.required"
  | "countryOfHabitualResidence.title"
  | "countryOfOrigin.code"
  | "countryOfOrigin.placeholder"
  | "countryOfOrigin.required"
  | "countryOfOrigin.title"
  | "countryOfOrigin.unavailable"
  | "countryTypeOfBankDetail.title"
  | "courseNumber.title"
  | "courseStatus.placeholder"
  | "courseStatus.title"
  | "courseStatus.updated"
  | "coverLetter.title"
  | "crm.clientNumber.title"
  | "crm.crmData"
  | "crm.debitorNumber.title"
  | "crm.destinationOrganization"
  | "crm.import.confirmImport"
  | "crm.import.downloadImportTemplate"
  | "crm.import.duplicate"
  | "crm.import.importTo"
  | "crm.import.title"
  | "crm.notes"
  | "crm.notesHTML"
  | "crm.saved"
  | "crm.title"
  | "currency.placeholder"
  | "currency.title"
  | "current.title"
  | "currentRecognitionPath.title"
  | "custody.title"
  | "customDocumentTypes.added"
  | "customDocumentTypes.confirmDelete"
  | "customDocumentTypes.create"
  | "customDocumentTypes.deleted"
  | "customDocumentTypes.documentDescription"
  | "customDocumentTypes.documentFile"
  | "customDocumentTypes.documentName"
  | "customDocumentTypes.duplicated"
  | "customDocumentTypes.edit"
  | "customDocumentTypes.overwrittenDocumentType"
  | "customDocumentTypes.title"
  | "customDocumentTypes.updated"
  | "customFields.SFS-Daten"
  | "customFields.aboutMe.title"
  | "customFields.analysisScore.title"
  | "customFields.assertivenessScore.title"
  | "customFields.basicCalculationScore.title"
  | "customFields.candidateAllowance.title"
  | "customFields.communicationSkillScore.title"
  | "customFields.educationalAttribution.option1"
  | "customFields.educationalAttribution.option2"
  | "customFields.educationalAttribution.title"
  | "customFields.expectationWorkliveInGermany.title"
  | "customFields.languagePerformance"
  | "customFields.languageProgressTracking.comment"
  | "customFields.languageProgressTracking.module"
  | "customFields.languageProgressTracking.presenceRatio"
  | "customFields.percentageCalculationScore.title"
  | "customFields.spatialThinkingScore.title"
  | "customFields.stressHandlungSkillScore.title"
  | "customFields.talentCountrySpecific.numberOfResidents.title"
  | "customFields.talentCountrySpecific.title"
  | "customFields.teamworkSkillScore.title"
  | "customFields.title"
  | "customFields.whyStopWorkingForExistingEmployer.title"
  | "customKnowledges.title"
  | "customKnowledgesFilter.type"
  | "customKnowledgesFilter.value"
  | "customSkills.title"
  | "customSkillsFilter.type"
  | "customSkillsFilter.value"
  | "cv.download"
  | "cv.view"
  | "dashboard.ankaadiaUpdates"
  | "dashboard.candidateBirthdays"
  | "dashboard.candidatesCreated"
  | "dashboard.candidatesLoggedIn"
  | "dashboard.documentsExpiring"
  | "dashboard.documentsUploaded"
  | "dashboard.tasksFinished"
  | "dashboard.title"
  | "dataNotViewable"
  | "dataStrategy.filterHint"
  | "dataStrategy.filterHintBold"
  | "dataStrategy.noFilterSettings"
  | "dataStrategy.settings"
  | "dataStrategy.strategies.all.title"
  | "dataStrategy.strategies.candidateStateChanged.candidateState"
  | "dataStrategy.strategies.candidateStateChanged.placeholder"
  | "dataStrategy.strategies.candidateStateChanged.showStatusSelectionAtGeneration"
  | "dataStrategy.strategies.candidateStateChanged.title"
  | "dataStrategy.strategies.candidateStatus.candidateState"
  | "dataStrategy.strategies.candidateStatus.placeholder"
  | "dataStrategy.strategies.candidateStatus.showStatusSelectionAtGeneration"
  | "dataStrategy.strategies.candidateStatus.title"
  | "dataStrategy.strategies.examResults.showAtGeneration"
  | "dataStrategy.strategies.examResults.title"
  | "dataStrategy.strategies.feedbackReport.title"
  | "dataStrategy.strategies.performanceAndAttendance.showAtGeneration"
  | "dataStrategy.strategies.performanceAndAttendance.title"
  | "dataStrategy.title"
  | "date.add"
  | "date.noData"
  | "date.title"
  | "dateOfIssue.title"
  | "dateOfReceipt.asDate"
  | "dateOfReceipt.title"
  | "deficitNote.title"
  | "deficitNoteReceiveDate.title"
  | "deficitNoteReceived.title"
  | "definitionsOpt.create"
  | "definitionsOpt.edit"
  | "degree.title"
  | "delayStart.placeholder"
  | "delayStart.title"
  | "deliveryFormat.placeholder"
  | "deliveryFormat.placeholderOverview"
  | "deliveryFormat.required"
  | "deliveryFormat.title"
  | "demo.copy"
  | "demo.copyData"
  | "demo.copyDataType"
  | "demo.dataCopied"
  | "demo.dialogCaption"
  | "demo.failed"
  | "demo.makeDemoTenant"
  | "demo.success"
  | "demo.successful"
  | "demo.templatesToCopy"
  | "departureAirport.title"
  | "departurePoint.required"
  | "departurePoint.title"
  | "departureTrainStation.title"
  | "description.placeholder"
  | "description.required"
  | "description.title"
  | "detail.title"
  | "differences.added"
  | "differences.newValue"
  | "differences.oldValue"
  | "differences.perField"
  | "differences.removed"
  | "differences.title"
  | "differences.updated"
  | "diplomaIssuance.invalid"
  | "diplomaIssuance.title"
  | "diplomaIssuance.tooltip"
  | "diplomaticMission.city"
  | "diplomaticMission.placeholder"
  | "diplomaticMission.title"
  | "disagreed.title"
  | "disconnectFromFacebook.failure"
  | "disconnectFromFacebook.header"
  | "disconnectFromFacebook.message"
  | "disconnectFromFacebook.success"
  | "displayDataForMatching.title"
  | "displayDescription.title"
  | "displayId.title"
  | "displayName.required"
  | "displayName.title"
  | "displayNameTemplate.placeholder"
  | "displayNameTemplate.required"
  | "displayNameTemplate.title"
  | "doNotCreateOnSync.title"
  | "document.badTags"
  | "document.confirmCreate"
  | "document.confirmDelete"
  | "document.confirmDeleteOfLinkedDocument"
  | "document.convert"
  | "document.create"
  | "document.created.message"
  | "document.created.title"
  | "document.deleted.cant"
  | "document.deleted.message"
  | "document.deleted.title"
  | "document.download"
  | "document.edit"
  | "document.empty"
  | "document.error.accessDenied.contactProcessOwner"
  | "document.error.accessDenied.description"
  | "document.error.accessDenied.title"
  | "document.error.download"
  | "document.forcedUpload"
  | "document.invalid"
  | "document.locked"
  | "document.reload"
  | "document.replace"
  | "document.requiredFiles"
  | "document.title"
  | "document.titleFilter"
  | "document.toggleLock"
  | "document.tooltipFormat"
  | "document.unlocked"
  | "document.unspecified"
  | "document.updated.message"
  | "document.updated.title"
  | "document.uploadHint"
  | "document.valid"
  | "document.warning.convert"
  | "documentAlreadyUploaded.title"
  | "documentChangeDate.title"
  | "documentFormat.placeholder"
  | "documentFormat.required"
  | "documentFormat.title"
  | "documentGenerationLanguageHandling.title"
  | "documentLanguages.placeholder"
  | "documentLanguages.required"
  | "documentLanguages.title"
  | "documentRequirements.mappingCaption"
  | "documentRequirements.mappingFormat"
  | "documentRequirements.multipleFormatsError"
  | "documentRequirements.settings"
  | "documentRequirements.sourceFormat"
  | "documentRequirements.title"
  | "documentRequirements.updated.message"
  | "documentRequirements.updated.title"
  | "documentSent.notSent"
  | "documentSent.title"
  | "documentSet.add"
  | "documentSet.addDefault"
  | "documentSet.addLinked"
  | "documentSet.checkSpecificSets.options.no"
  | "documentSet.checkSpecificSets.options.yes"
  | "documentSet.checkSpecificSets.title"
  | "documentSet.default"
  | "documentSet.errors.corruptDefault"
  | "documentSet.errors.duplicateForeignKey"
  | "documentSet.errors.duplicateName"
  | "documentSet.errors.duplicateType"
  | "documentSet.errors.malformed"
  | "documentSet.errors.setTypeMismatch"
  | "documentSet.files.duplicateTags"
  | "documentSet.files.required"
  | "documentSet.files.title"
  | "documentSet.linkRequested"
  | "documentSet.makeDefault"
  | "documentSet.placeholder"
  | "documentSet.remove"
  | "documentSet.required"
  | "documentSet.title"
  | "documentSet.type.placeholder"
  | "documentSet.type.required"
  | "documentSet.type.title"
  | "documentSet.types.AlternativeRecognitionPath"
  | "documentSet.types.CurrentRecognitionPath"
  | "documentSet.types.SignedByAllParties"
  | "documentSet.types.SignedByOneParty"
  | "documentSet.types.Unsigned"
  | "documentSets.name"
  | "documentSets.type"
  | "documentTemplates.menuTooltip"
  | "documentTemplates.title"
  | "documentType.code"
  | "documentType.placeholder"
  | "documentType.required"
  | "documentType.title"
  | "documentType.warningSetCount"
  | "documentType.warningSetTypes"
  | "documentType.warningSingleSet"
  | "documentType.warningWriteMode"
  | "documents.allLocked"
  | "documents.allUnlocked"
  | "documents.available"
  | "documents.columnOptions.title"
  | "documents.confirmLock"
  | "documents.confirmUnlock"
  | "documents.copies"
  | "documents.copy"
  | "documents.documentHasAtLeastOneFile"
  | "documents.downloadAllAsPDF"
  | "documents.extractionError.ClassificationFailed"
  | "documents.extractionError.DownloadFailed"
  | "documents.extractionError.FileTooBig"
  | "documents.extractionError.NoText"
  | "documents.extractionError.TextExtractionFailed"
  | "documents.extractionError.TranslationFailed"
  | "documents.extractionError.noResult"
  | "documents.extractionError.noUrl"
  | "documents.extractionError.technicalError"
  | "documents.files"
  | "documents.formatKeys"
  | "documents.generation"
  | "documents.hasFiles"
  | "documents.infoHeader.message"
  | "documents.infoHeader.title"
  | "documents.limit"
  | "documents.lock"
  | "documents.menuTooltip"
  | "documents.noFiles.message"
  | "documents.noFiles.title"
  | "documents.original"
  | "documents.originals"
  | "documents.paperFormatKeys"
  | "documents.required"
  | "documents.staticDataSelector.confirmation.message"
  | "documents.staticDataSelector.confirmation.messageOverwrite"
  | "documents.staticDataSelector.confirmation.title"
  | "documents.staticDataSelector.overwrite.title"
  | "documents.staticDataSelector.overwrite.tooltip"
  | "documents.staticDataSelector.updateAll"
  | "documents.title"
  | "documents.unlock"
  | "documents.updated.message"
  | "documents.updated.title"
  | "documents.uploads.aboveMax"
  | "documents.uploads.belowMin"
  | "done.title"
  | "downloadService.message"
  | "drivingLicense.available"
  | "drivingLicense.keyArray"
  | "drivingLicense.placeholder"
  | "drivingLicense.plural"
  | "drivingLicense.required"
  | "drivingLicense.title"
  | "education.confirmCancel"
  | "education.title"
  | "educationCourse.confirmDelete"
  | "educationCourse.create"
  | "educationCourse.created.message"
  | "educationCourse.created.title"
  | "educationCourse.deleted.message"
  | "educationCourse.deleted.title"
  | "educationCourse.edit"
  | "educationCourse.placeholder"
  | "educationCourse.title"
  | "educationCourse.updated.message"
  | "educationCourse.updated.title"
  | "educationCourses.addToCourse.error"
  | "educationCourses.addToCourse.select"
  | "educationCourses.addToCourse.title"
  | "educationCourses.courseOrganizationMismatch"
  | "educationCourses.placeholder"
  | "educationCourses.required"
  | "educationCourses.title"
  | "educationExam.confirmDelete"
  | "educationExam.create"
  | "educationExam.created.message"
  | "educationExam.created.title"
  | "educationExam.deleted.message"
  | "educationExam.deleted.title"
  | "educationExam.edit"
  | "educationExam.placeholder"
  | "educationExam.title"
  | "educationExam.updated.message"
  | "educationExam.updated.title"
  | "educationExamParticipants.addToExam"
  | "educationExamParticipants.confirmDelete"
  | "educationExamParticipants.deleted.message"
  | "educationExamParticipants.deleted.title"
  | "educationExamParticipants.placeholder"
  | "educationExamParticipants.title"
  | "educationExamResult.confirmDelete.title"
  | "educationExamResult.create"
  | "educationExamResult.created.message"
  | "educationExamResult.created.title"
  | "educationExamResult.deleted.message"
  | "educationExamResult.deleted.title"
  | "educationExamResult.edit"
  | "educationExamResult.error.notPassedExamButModulesPassed"
  | "educationExamResult.error.partiallyPassedExamButAllModulesPassed"
  | "educationExamResult.error.passedExamButNotAllModulesPassed"
  | "educationExamResult.updated.message"
  | "educationExamResult.updated.title"
  | "educationExamResults.columns"
  | "educationExamResults.title"
  | "educationExamResults.titleForAdHocExam"
  | "educationExamResults.titleForNormalExam"
  | "educationExams.addExam"
  | "educationExams.addModuleExam"
  | "educationExams.addToExam.clearFilters"
  | "educationExams.addToExam.filter"
  | "educationExams.addToExam.select"
  | "educationExams.addToExam.title"
  | "educationExams.placeholder"
  | "educationExams.showParticipants"
  | "educationExams.showResults"
  | "educationExams.title"
  | "educationFilter.courseAttendance"
  | "educationFilter.courseName"
  | "educationFilter.coursePerformance"
  | "educationFilter.courseStatus"
  | "educationFilter.examLanguageLevel"
  | "educationFilter.examName"
  | "educationFilter.examResult"
  | "educationFilter.moduleName"
  | "educationFilter.passedExamModules"
  | "educationManagement.title"
  | "educationMode.placeholder"
  | "educationMode.required"
  | "educationMode.title"
  | "educationModule.code"
  | "educationModule.confirmDelete"
  | "educationModule.create"
  | "educationModule.created.message"
  | "educationModule.created.title"
  | "educationModule.deleted.message"
  | "educationModule.deleted.title"
  | "educationModule.edit"
  | "educationModule.placeholder"
  | "educationModule.required"
  | "educationModule.title"
  | "educationModule.updated.message"
  | "educationModule.updated.title"
  | "educationModules.title"
  | "educationPrecision.placeholder"
  | "educationPrecision.title"
  | "educationProvider.placeholder"
  | "educationProvider.required"
  | "educationProvider.title"
  | "educationUnits.required"
  | "educationUnits.title"
  | "educationVoucher.title"
  | "educationVoucherFileNumber.title"
  | "educationVoucherFirstDeputy.placeholder"
  | "educationVoucherFirstDeputy.required"
  | "educationVoucherFirstDeputy.title"
  | "educationVoucherReceiveDate.month"
  | "educationVoucherReceiveDate.title"
  | "educationVoucherReceiveDate.year"
  | "educationVoucherReceived.title"
  | "educationVoucherRepresentative.placeholder"
  | "educationVoucherRepresentative.required"
  | "educationVoucherRepresentative.title"
  | "educationVoucherRequestDate.month"
  | "educationVoucherRequestDate.title"
  | "educationVoucherRequestDate.year"
  | "educationVoucherRequested.title"
  | "email.clipboard"
  | "email.confirmSend"
  | "email.invalid"
  | "email.missing"
  | "email.nextRun"
  | "email.required"
  | "email.send"
  | "email.sent.message"
  | "email.sent.title"
  | "email.title"
  | "email.unequal"
  | "emailFooter.lowerText"
  | "emailFooter.message"
  | "emailFooter.unsubscribe"
  | "emailSendOutRule.edit"
  | "emailSendOutRule.isActive"
  | "emailSendOutRule.isActiveRequired"
  | "emailSendOutRule.new"
  | "emailSendOutRule.title"
  | "emailSendoutRules.filter"
  | "emailSendoutRules.filterExplanation"
  | "emailSendoutRules.title"
  | "emailTemplates.emptyLinks"
  | "emailTemplates.title"
  | "emailTemplates.unsupported"
  | "emails.confirmSend"
  | "emails.noSelection"
  | "emails.ofUser"
  | "emails.outdated"
  | "emails.refresh"
  | "emails.select"
  | "emails.send"
  | "emails.sent.message"
  | "emails.sent.title"
  | "emails.title"
  | "emergencyContact.info"
  | "emergencyContact.title"
  | "employer.id"
  | "employer.name"
  | "employer.nameAndPlace"
  | "employer.placeholder"
  | "employer.placeholderAfterRecognition"
  | "employer.region"
  | "employer.title"
  | "employer.titleAfterRecognition"
  | "employer.zipCode"
  | "employerData.title"
  | "employerFirstDeputy.placeholder"
  | "employerFirstDeputy.required"
  | "employerFirstDeputy.title"
  | "employerRepresentative.placeholder"
  | "employerRepresentative.required"
  | "employerRepresentative.shortTitle"
  | "employerRepresentative.title"
  | "employerSearch.applied.message"
  | "employerSearch.applied.title"
  | "employerSearch.apply"
  | "employerSearch.confirmApply"
  | "employerSearch.criteria"
  | "employerSearch.hint"
  | "employerSearch.noLocationsSelected"
  | "employerSearch.noResults"
  | "employerSearch.title"
  | "employmentApproval.title"
  | "employmentApprovalAuthority.placeholder"
  | "employmentApprovalAuthority.required"
  | "employmentApprovalAuthority.title"
  | "employmentApprovalReceiveDate.title"
  | "employmentApprovalReceived.title"
  | "employmentApprovalRequestDate.title"
  | "employmentApprovalRequested.title"
  | "employmentOffer.title"
  | "employmentRelationship.title"
  | "employmentRelationshipAT.title"
  | "employmentRelationshipDE.title"
  | "employmentTerms.after"
  | "employmentTerms.before"
  | "employmentTerms.differ"
  | "employmentTerms.title"
  | "employmentType.placeholder"
  | "employmentType.title"
  | "enabled.title"
  | "endDate.asDate"
  | "endDate.invalid"
  | "endDate.required"
  | "endDate.title"
  | "endOfComplementaryMeasure.title"
  | "endOfExperience.invalid"
  | "endOfExperience.title"
  | "endOfFurtherEducation.required"
  | "endOfFurtherEducation.title"
  | "endOfProbationDate.title"
  | "endOfTraining.invalid"
  | "endOfTraining.title"
  | "enum.ActivityType.AutoProcessOperationTriggered"
  | "enum.ActivityType.CandidateAdded"
  | "enum.ActivityType.CandidateAddedToCollection"
  | "enum.ActivityType.CandidateAddedToExam"
  | "enum.ActivityType.CandidateBirthday"
  | "enum.ActivityType.CandidateBirthdaySoon"
  | "enum.ActivityType.CandidateDeleted"
  | "enum.ActivityType.CandidateDeletionReminder"
  | "enum.ActivityType.CandidateDroppedOut"
  | "enum.ActivityType.CandidateLoggedIn"
  | "enum.ActivityType.CandidateNotFound"
  | "enum.ActivityType.CandidateOptedIn"
  | "enum.ActivityType.CandidateOptedOut"
  | "enum.ActivityType.CandidateRemovedFromCollection"
  | "enum.ActivityType.CandidateRemovedFromExam"
  | "enum.ActivityType.CandidateRequestDeletion"
  | "enum.ActivityType.CandidateUpdated"
  | "enum.ActivityType.CollectionAdded"
  | "enum.ActivityType.CollectionDeleted"
  | "enum.ActivityType.CollectionEmbeddedIntoCollection"
  | "enum.ActivityType.CollectionNotFound"
  | "enum.ActivityType.CollectionRemovedFromCollection"
  | "enum.ActivityType.CollectionUpdated"
  | "enum.ActivityType.DocumentNotFound"
  | "enum.ActivityType.DocumentSetMetaDataModified"
  | "enum.ActivityType.EducationExamCreated"
  | "enum.ActivityType.EducationExamDeleted"
  | "enum.ActivityType.EducationExamResultCreated"
  | "enum.ActivityType.EducationExamResultDeleted"
  | "enum.ActivityType.EducationExamUpdated"
  | "enum.ActivityType.EmailNotDelivered"
  | "enum.ActivityType.FeedbackProvided"
  | "enum.ActivityType.FileDeleted"
  | "enum.ActivityType.FileExpiresSoon"
  | "enum.ActivityType.FileModified"
  | "enum.ActivityType.FileUploaded"
  | "enum.ActivityType.FreeFormatFileDeleted"
  | "enum.ActivityType.FreeFormatFileUploaded"
  | "enum.ActivityType.InvalidSharingTemplate"
  | "enum.ActivityType.InvalidUserGroupRoleMapping"
  | "enum.ActivityType.OrganizationNotFound"
  | "enum.ActivityType.OrganizationNotLinked"
  | "enum.ActivityType.ProcessCreated"
  | "enum.ActivityType.ProcessDeleted"
  | "enum.ActivityType.ProcessModelDeleted"
  | "enum.ActivityType.ProcessModelPublished"
  | "enum.ActivityType.ProcessModelReleaseCreated"
  | "enum.ActivityType.ProcessModelUnpublished"
  | "enum.ActivityType.ProcessNotFound"
  | "enum.ActivityType.SharingAdded"
  | "enum.ActivityType.SharingDeleted"
  | "enum.ActivityType.SharingDisabled"
  | "enum.ActivityType.SharingEnabled"
  | "enum.ActivityType.SharingTypeModified"
  | "enum.ActivityType.SharingUpdated"
  | "enum.ActivityType.TaskCancelled"
  | "enum.ActivityType.TaskChanged"
  | "enum.ActivityType.TaskDeleted"
  | "enum.ActivityType.TaskFailed"
  | "enum.ActivityType.TaskFinished"
  | "enum.ActivityType.TaskManuallyChanged"
  | "enum.ActivityType.TaskManuallyCreated"
  | "enum.ActivityType.TaskNotFound"
  | "enum.ActivityType.TaskNoteAdded"
  | "enum.ActivityType.TaskReminderSent"
  | "enum.ActivityType.TaskStarted"
  | "enum.ActivityType.TaskStateChanged"
  | "enum.ActivityType.TaskStateManuallyChanged"
  | "enum.ActivityType.UserLoggedIn"
  | "enum.ActivityType.UserNotFound"
  | "enum.AddressType.CountryOfOriginAddress"
  | "enum.AddressType.CurrentAddress"
  | "enum.AddressType.IdAddress"
  | "enum.AddressType.ImmigrationCountryAddress"
  | "enum.AuditTaskModeEnum.AuditAdditionalInformation"
  | "enum.AuditTaskModeEnum.EnterAdditionalInformation"
  | "enum.AuditTaskModeEnum.GatherAuditInformation"
  | "enum.AuditTaskModeEnum.GatherFinalVotum"
  | "enum.AuditTaskModeEnum.PerformAudit"
  | "enum.AuditTaskModeEnum.ViewExaminee"
  | "enum.AuditTaskModeEnum.ViewQualityComittee"
  | "enum.AutoProcessToken.AddToAvailableCandidatesList"
  | "enum.AutoProcessToken.Application"
  | "enum.AutoProcessToken.ApplicationRejected"
  | "enum.AutoProcessToken.Approval"
  | "enum.AutoProcessToken.Approved"
  | "enum.AutoProcessToken.Creation"
  | "enum.AutoProcessToken.DroppedOut"
  | "enum.AutoProcessToken.EmployerChanged"
  | "enum.AutoProcessToken.InterviewCancel"
  | "enum.AutoProcessToken.InterviewRequest"
  | "enum.AutoProcessToken.LocalPartnerChanged"
  | "enum.AutoProcessToken.Matched"
  | "enum.AutoProcessToken.MoveToCourseList"
  | "enum.AutoProcessToken.NegativeFeedback"
  | "enum.AutoProcessToken.RemoveFromMatching"
  | "enum.AutoProcessToken.RemoveFromOnboarding"
  | "enum.BaseProcessTypeEnum.CreateProcessOperation"
  | "enum.BaseProcessTypeEnum.ExistingProcess"
  | "enum.CandidateAssignmentExceptionOperation.AddException"
  | "enum.CandidateAssignmentExceptionOperation.RemoveException"
  | "enum.CandidateAssignmentExceptionType.Exclude"
  | "enum.CandidateAssignmentExceptionType.Include"
  | "enum.CandidateRelation.Child"
  | "enum.CandidateRelation.Father"
  | "enum.CandidateRelation.Mother"
  | "enum.CandidateRelation.Other"
  | "enum.CandidateRelation.Partner"
  | "enum.CandidateStatus.Approved"
  | "enum.CandidateStatus.Archived"
  | "enum.CandidateStatus.Associated"
  | "enum.CandidateStatus.DroppedOut"
  | "enum.CandidateStatus.ForSelection"
  | "enum.CandidateStatus.InRecognition"
  | "enum.CandidateStatus.LabourMigrationFinished"
  | "enum.CandidateStatus.Matched"
  | "enum.CandidateStatus.New"
  | "enum.CandidateStatus.Paused"
  | "enum.CandidateStatus.Rejected"
  | "enum.CandidateStatus.Transferred"
  | "enum.CandidateStatus.Unknown"
  | "enum.CollectionAutoFilterTemplateSpecialPurpose.Default"
  | "enum.CollectionAutoFilterTemplateSpecialPurpose.ForApplication"
  | "enum.CollectionAutoFilterTemplateSpecialPurpose.ForInterview"
  | "enum.CollectionKindEnum.AutoOrGenerated"
  | "enum.CollectionKindEnum.AutoTemplate"
  | "enum.CollectionKindEnum.Standard"
  | "enum.CollectionType.Auto"
  | "enum.CollectionType.FromTemplate"
  | "enum.CollectionType.Standard"
  | "enum.CompletionState.Complete"
  | "enum.CompletionState.Incomplete"
  | "enum.ContactSharingMode.ShareAll"
  | "enum.ContactSharingMode.ShareDefault"
  | "enum.ContactSharingMode.ShareSelected"
  | "enum.ContactSharingMode.noAccess"
  | "enum.ContractTemplateSharingMode.ShareAll"
  | "enum.ContractTemplateSharingMode.ShareSelected"
  | "enum.ContractTemplateSharingMode.noAccess"
  | "enum.ContractType.AgreementByContract"
  | "enum.ContractType.CollectiveAgreement"
  | "enum.ContractType.CollectiveAgreementAT"
  | "enum.DocumentDeliveryFormat.Digital"
  | "enum.DocumentDeliveryFormat.Paper"
  | "enum.DocumentFinishingMode.Detail"
  | "enum.DocumentFinishingMode.Global"
  | "enum.DocumentGenerationLanguageHandling.BestFittingOnly"
  | "enum.DocumentGenerationLanguageHandling.ImmigrationCountryLanguagePlusAll"
  | "enum.DocumentGenerationLanguageHandling.ImmigrationCountryLanguagePlusNextBest"
  | "enum.DocumentGenerationMode.AllCandidates"
  | "enum.DocumentGenerationMode.OneCandidate"
  | "enum.DocumentPurpose.Recognition"
  | "enum.DocumentPurpose.Visum"
  | "enum.DocumentStatusEnum.Available"
  | "enum.DocumentStatusEnum.AvailableButExpired"
  | "enum.DocumentStatusEnum.Missing"
  | "enum.DocumentStatusEnum.MissingButStatusTakenOver"
  | "enum.DocumentStatusEnum.MissingNotRequired"
  | "enum.DocumentStatusEnum.NotShared"
  | "enum.DocumentStatusEnum.Partial"
  | "enum.DocumentTemplateType.Docx"
  | "enum.DocumentTemplateType.Json"
  | "enum.DocumentTemplateType.Pdf"
  | "enum.DocumentTemplateType.Xlsx"
  | "enum.EducationCourseAttendance.Absent"
  | "enum.EducationCourseAttendance.PartiallyPresent"
  | "enum.EducationCourseAttendance.Present"
  | "enum.EducationCourseCandidateStatus.InterventionRequired"
  | "enum.EducationCourseCandidateStatus.OnTrack"
  | "enum.EducationCourseCandidateStatus.Warning"
  | "enum.EducationCoursePerformanceStatus.Average"
  | "enum.EducationCoursePerformanceStatus.Bad"
  | "enum.EducationCoursePerformanceStatus.Good"
  | "enum.EducationMode.Blended"
  | "enum.EducationMode.FaceToFace"
  | "enum.EducationMode.Online"
  | "enum.EducationModuleStatus.Active"
  | "enum.EducationModuleStatus.Inactive"
  | "enum.EducationModuleType.LanguageLearning"
  | "enum.EmailEventType.Blocked"
  | "enum.EmailEventType.Bounced"
  | "enum.EmailEventType.Delivered"
  | "enum.EmailEventType.Dropped"
  | "enum.EmailEventType.Failed"
  | "enum.EmailEventType.NotSent"
  | "enum.EmailEventType.Opened"
  | "enum.EmailEventType.OptedOut"
  | "enum.EmailEventType.Resent"
  | "enum.EmailEventType.Sent"
  | "enum.EmailTemplateTarget.Candidate"
  | "enum.EmailTemplateTarget.NewUser"
  | "enum.EmailTemplateTarget.Participant"
  | "enum.EmailTemplateType.Automatic"
  | "enum.EmailTemplateType.Draft"
  | "enum.EmploymentRelationshipSharingMode.Share"
  | "enum.EmploymentRelationshipSharingMode.noAccess"
  | "enum.EmploymentRelationshipType.Internship"
  | "enum.EmploymentRelationshipType.JobShadowing"
  | "enum.EmploymentRelationshipType.Permanent"
  | "enum.EmploymentRelationshipType.SecondJob"
  | "enum.EmploymentRelationshipType.Temporary"
  | "enum.EnterMissingInformation.ArrivalDate"
  | "enum.EnterMissingInformation.BankAppointmentDate"
  | "enum.EnterMissingInformation.CompanyDoctorAppointmentDate"
  | "enum.EnterMissingInformation.EndOfComplementaryMeasure"
  | "enum.EnterMissingInformation.EndOfFurtherEducation"
  | "enum.EnterMissingInformation.EndOfProbationDate"
  | "enum.EnterMissingInformation.EquivalenceTest"
  | "enum.EnterMissingInformation.EstimatedArrivalDate"
  | "enum.EnterMissingInformation.EstimatedWorkStartDate"
  | "enum.EnterMissingInformation.FederalState"
  | "enum.EnterMissingInformation.FederalStateAt"
  | "enum.EnterMissingInformation.FingerPrintDate"
  | "enum.EnterMissingInformation.FirstExamDate"
  | "enum.EnterMissingInformation.FirstExamPassed"
  | "enum.EnterMissingInformation.FirstFinalInterviewDate"
  | "enum.EnterMissingInformation.FirstFinalInterviewPassed"
  | "enum.EnterMissingInformation.FlightDate"
  | "enum.EnterMissingInformation.HealthProfessionRegisterAppointmentDate"
  | "enum.EnterMissingInformation.HealthProfessionRegisterSubmission"
  | "enum.EnterMissingInformation.HealthProfessionRegistrationDate"
  | "enum.EnterMissingInformation.ImmigrationAuthority"
  | "enum.EnterMissingInformation.LegalBasis"
  | "enum.EnterMissingInformation.LocationOfComplementaryMeasure"
  | "enum.EnterMissingInformation.MedicalExaminationAppointmentDate"
  | "enum.EnterMissingInformation.NoQualificationEvaluation"
  | "enum.EnterMissingInformation.PathOfRecognition"
  | "enum.EnterMissingInformation.PathOfRecognitionAt"
  | "enum.EnterMissingInformation.PickUpDetails"
  | "enum.EnterMissingInformation.PlannedEntryDate"
  | "enum.EnterMissingInformation.PoliceRecordAppointmentDate"
  | "enum.EnterMissingInformation.PsaFirstDeputy"
  | "enum.EnterMissingInformation.PsaRepresentative"
  | "enum.EnterMissingInformation.QualificationMeasure"
  | "enum.EnterMissingInformation.QualificationType"
  | "enum.EnterMissingInformation.RecognitionAuthority"
  | "enum.EnterMissingInformation.RecognitionAuthorityAt"
  | "enum.EnterMissingInformation.RecognitionFileNumber"
  | "enum.EnterMissingInformation.RecognitionFileNumberAcceleratedProcedure"
  | "enum.EnterMissingInformation.RecognitionPaymentRole"
  | "enum.EnterMissingInformation.RecognitionPaymentRoleAccelerated"
  | "enum.EnterMissingInformation.RecognitionType"
  | "enum.EnterMissingInformation.RegistrationOfficeDate"
  | "enum.EnterMissingInformation.ResidencePermit"
  | "enum.EnterMissingInformation.ResponsibleRecognitionOrganization"
  | "enum.EnterMissingInformation.ResponsibleRoleLaborMarket"
  | "enum.EnterMissingInformation.ResponsibleRoleQualification"
  | "enum.EnterMissingInformation.ResponsibleRoleQualificationEvaluation"
  | "enum.EnterMissingInformation.ResponsibleRoleQualificationEvaluationPayment"
  | "enum.EnterMissingInformation.ResponsibleRoleRecognition"
  | "enum.EnterMissingInformation.ResponsibleRoleResidence"
  | "enum.EnterMissingInformation.ResponsibleRoleVisa"
  | "enum.EnterMissingInformation.RwrCardFileNumber"
  | "enum.EnterMissingInformation.StartOfComplementaryMeasure"
  | "enum.EnterMissingInformation.StartOfFurtherEducation"
  | "enum.EnterMissingInformation.TargetRecognition"
  | "enum.EnterMissingInformation.WorkConfirmationSignedDate"
  | "enum.EnterMissingInformation.WorkContractSignDate"
  | "enum.EnterMissingInformation.WorkContractStartDate"
  | "enum.EnterMissingInformation.WorkContractTypeOfWorkLocation"
  | "enum.EnterMissingInformation.WorkContractWorkLocation"
  | "enum.EnterMissingInformation.WorkEmployer"
  | "enum.EnterMissingInformation.WorkEmployerAt"
  | "enum.EnterMissingInformation.WorkStartDate"
  | "enum.ExamModuleType.Listening"
  | "enum.ExamModuleType.Oral"
  | "enum.ExamModuleType.Reading"
  | "enum.ExamModuleType.Speaking"
  | "enum.ExamModuleType.Writing"
  | "enum.ExamModuleType.Written"
  | "enum.ExamStatus.Active"
  | "enum.ExamStatus.Finished"
  | "enum.ExamType.ExternalCertifiedExam"
  | "enum.ExamType.InternalExam"
  | "enum.ExamType.PlacementTest"
  | "enum.ExperienceType.Normal"
  | "enum.ExperienceType.OtherActivities"
  | "enum.FeedbackStatusPhaseOne.Backlogged"
  | "enum.FeedbackStatusPhaseOne.InterviewRequested"
  | "enum.FeedbackStatusPhaseOne.Rejected"
  | "enum.FeedbackStatusPhaseTwo.Rejected"
  | "enum.FeedbackStatusPhaseTwo.Selected"
  | "enum.InstanceStateEnum.Active"
  | "enum.InstanceStateEnum.Cancelled"
  | "enum.InstanceStateEnum.Error"
  | "enum.InstanceStateEnum.Finished"
  | "enum.InstanceStateEnum.Inactive"
  | "enum.InstanceStateEnum.Started"
  | "enum.InstanceStateEnum.undefined"
  | "enum.KnowledgeProficiency.Basic"
  | "enum.KnowledgeProficiency.Expert"
  | "enum.KnowledgeProficiency.None"
  | "enum.KnownFormToken.CandidateAllowence"
  | "enum.KnownFormToken.InterviewAppointmentConfirmationForm"
  | "enum.KnownFormToken.InterviewAppointmentProposalForm"
  | "enum.KnownFormToken.InterviewAppointmentScheduleForm"
  | "enum.KnownFormToken.InterviewAppointmentScheduleSingleDateForm"
  | "enum.KnownFormToken.MatchingAgreementForm"
  | "enum.KnownFormToken.QualificationEvaluationConfiguration"
  | "enum.KnownFormToken.QualificationEvaluationReceived"
  | "enum.KnownFormToken.QualificationEvaluationRequest"
  | "enum.KnownFormToken.RecruitingConcept"
  | "enum.KnownFormToken.ShippingInformationForm"
  | "enum.KnownFormToken.SubmissionDate"
  | "enum.KnownFormToken.TravelBookingForm"
  | "enum.KnownJsonTemplateTokens.VisaApplicationJson"
  | "enum.KnownPdfFormTokens.AgreementAcceleratedRecognition"
  | "enum.KnownPdfFormTokens.AgreementGuetezeichen"
  | "enum.KnownPdfFormTokens.AgreementRecognitionPartnership"
  | "enum.KnownPdfFormTokens.AnnexSupportQualificationEmployee"
  | "enum.KnownPdfFormTokens.ApplicationAcceleratedRecognition"
  | "enum.KnownPdfFormTokens.ApplicationExtendResidencePermit"
  | "enum.KnownPdfFormTokens.ApplicationRegistrationOffice"
  | "enum.KnownPdfFormTokens.ApplicationResidencePermit"
  | "enum.KnownPdfFormTokens.ApplicationTechnikerInsurance"
  | "enum.KnownPdfFormTokens.ApplicationValuationDegrees"
  | "enum.KnownPdfFormTokens.ApplicationWageSubsidyEmployeeAbsenceTraining"
  | "enum.KnownPdfFormTokens.CandidateProfileTalentCountry"
  | "enum.KnownPdfFormTokens.CertificateEmployerSupport"
  | "enum.KnownPdfFormTokens.CertificateOfConduct"
  | "enum.KnownPdfFormTokens.CompensationConfirmation"
  | "enum.KnownPdfFormTokens.CompensatoryMeasure"
  | "enum.KnownPdfFormTokens.ConfirmationNoMatching"
  | "enum.KnownPdfFormTokens.ConstitutionInstruction"
  | "enum.KnownPdfFormTokens.CostAbsorption"
  | "enum.KnownPdfFormTokens.CostAbsorptionValuation"
  | "enum.KnownPdfFormTokens.DecisionAdaptionCourse"
  | "enum.KnownPdfFormTokens.DecisionQualificationMeasure"
  | "enum.KnownPdfFormTokens.DeclarationCancellationJobTitle"
  | "enum.KnownPdfFormTokens.DeclarationEmployeeWageSubsidyTraining"
  | "enum.KnownPdfFormTokens.DeclarationOfCorrespondence"
  | "enum.KnownPdfFormTokens.DeclarationOfEmployerAT"
  | "enum.KnownPdfFormTokens.DeclarationOfEmployment"
  | "enum.KnownPdfFormTokens.DeclarationOfEmploymentAfterRecognition"
  | "enum.KnownPdfFormTokens.DeclarationOfEmploymentBeforeRecognition"
  | "enum.KnownPdfFormTokens.DeclarationOfImpunity"
  | "enum.KnownPdfFormTokens.DeclarationRecognitionProcedure"
  | "enum.KnownPdfFormTokens.DeclarationVisaProcess"
  | "enum.KnownPdfFormTokens.DoctorCertificateStudyPurpose"
  | "enum.KnownPdfFormTokens.EducationVoucher"
  | "enum.KnownPdfFormTokens.EducationVoucherAG"
  | "enum.KnownPdfFormTokens.EducationVoucherOsnabrueck"
  | "enum.KnownPdfFormTokens.FamilyReunionChildren"
  | "enum.KnownPdfFormTokens.FamilyReunionPartner"
  | "enum.KnownPdfFormTokens.HealthProfessionsRegister"
  | "enum.KnownPdfFormTokens.KnowledgeExamApplication"
  | "enum.KnownPdfFormTokens.LanguageExamApplication"
  | "enum.KnownPdfFormTokens.MedicalCertificate"
  | "enum.KnownPdfFormTokens.MedicalCertificateDoctor"
  | "enum.KnownPdfFormTokens.NostrificationGuKG"
  | "enum.KnownPdfFormTokens.NostrificationGuKGTraining"
  | "enum.KnownPdfFormTokens.NostrificationMABG"
  | "enum.KnownPdfFormTokens.NostrificationMTDG"
  | "enum.KnownPdfFormTokens.NostrifizierungFH"
  | "enum.KnownPdfFormTokens.NostrifizierungFHPowerOfAttorney"
  | "enum.KnownPdfFormTokens.PowerOfAttorney"
  | "enum.KnownPdfFormTokens.PowerOfAttorneyDelivery"
  | "enum.KnownPdfFormTokens.PowerOfAttorneyRegular"
  | "enum.KnownPdfFormTokens.PowerOfAttorneyTechnikerInsurance"
  | "enum.KnownPdfFormTokens.PowerOfAttorneyValuation"
  | "enum.KnownPdfFormTokens.PowerOfRepresentation"
  | "enum.KnownPdfFormTokens.QuestionnaireScientology"
  | "enum.KnownPdfFormTokens.RWR"
  | "enum.KnownPdfFormTokens.RecognitionApplication"
  | "enum.KnownPdfFormTokens.RecognitionCV"
  | "enum.KnownPdfFormTokens.SubPowerOfAttorney"
  | "enum.KnownPdfFormTokens.SupplementarySheetA"
  | "enum.KnownPdfFormTokens.SupplementarySheetC"
  | "enum.KnownPdfFormTokens.VisaApplication"
  | "enum.KnownPdfFormTokens.VisaExtension"
  | "enum.KnownPdfFormTokens.VocationalTrainingQuestionnaire"
  | "enum.KnownPdfFormTokens.WorkGuarantee"
  | "enum.LanguageTrainingStatus.Canceled"
  | "enum.LanguageTrainingStatus.Finished"
  | "enum.LanguageTrainingStatus.Paused"
  | "enum.LanguageTrainingStatus.RegisterToLanguageCourse"
  | "enum.LanguageTrainingStatus.Registered"
  | "enum.LanguageTrainingStatus.Started"
  | "enum.MatchingStatus.Disabled"
  | "enum.MatchingStatus.Enabled"
  | "enum.MatchingStatus.Viewable"
  | "enum.MatchingStatus.title"
  | "enum.MessengerEventType.Failed"
  | "enum.MessengerEventType.Sent"
  | "enum.NotificationScope.Candidates"
  | "enum.NotificationScope.OwnedCandidates"
  | "enum.NotificationScope.Processes"
  | "enum.OccupationSkillType.Knowledge"
  | "enum.OccupationSkillType.SkillCompetence"
  | "enum.OrganizationAuditInterviewAssessmentEnum.ConfirmsTheInformation"
  | "enum.OrganizationAuditInterviewAssessmentEnum.PartiallyConfirmsTheInformation"
  | "enum.OrganizationAuditInterviewAssessmentEnum.ThereAreContradictionsToTheInformation"
  | "enum.OrganizationContactFunction.Contact"
  | "enum.OrganizationContactFunction.Representative"
  | "enum.OrganizationLinkOperationType.CreateDelegation"
  | "enum.OrganizationLinkOperationType.CreateProcess"
  | "enum.OrganizationLinkOperationType.CreateSharing"
  | "enum.OrganizationLinkOperationType.UpdateSharing"
  | "enum.PartnerSharingMode.ShareAll"
  | "enum.PartnerSharingMode.ShareSelected"
  | "enum.PartnerSharingMode.noAccess"
  | "enum.ProcessEventEnum.ApplicationDeclined"
  | "enum.ProcessEventEnum.ApplicationPutOnHold"
  | "enum.ProcessEventEnum.ApplicationReceived"
  | "enum.ProcessEventEnum.ApplicationRejected"
  | "enum.ProcessEventEnum.ApplicationRequested"
  | "enum.ProcessEventEnum.CandidateChanged"
  | "enum.ProcessEventEnum.CandidateCreated"
  | "enum.ProcessEventEnum.EmployerChanged"
  | "enum.ProcessEventEnum.InterviewCanceled"
  | "enum.ProcessEventEnum.InterviewRequested"
  | "enum.ProcessEventEnum.NegativeFeedback"
  | "enum.ProcessEventEnum.ProcessCompleted"
  | "enum.ProcessInstanceStateEnum.Active"
  | "enum.ProcessInstanceStateEnum.Inactive"
  | "enum.ProcessModelCommandTypeEnum.BulkTranslate"
  | "enum.ProcessModelCommandTypeEnum.CreateArea"
  | "enum.ProcessModelCommandTypeEnum.CreatePhase"
  | "enum.ProcessModelCommandTypeEnum.CreateTask"
  | "enum.ProcessModelCommandTypeEnum.DeleteArea"
  | "enum.ProcessModelCommandTypeEnum.DeletePhase"
  | "enum.ProcessModelCommandTypeEnum.DeleteTask"
  | "enum.ProcessModelCommandTypeEnum.DuplicateArea"
  | "enum.ProcessModelCommandTypeEnum.DuplicatePhase"
  | "enum.ProcessModelCommandTypeEnum.DuplicateTask"
  | "enum.ProcessModelCommandTypeEnum.MoveArea"
  | "enum.ProcessModelCommandTypeEnum.MovePhase"
  | "enum.ProcessModelCommandTypeEnum.MoveTask"
  | "enum.ProcessModelCommandTypeEnum.TranslateArea"
  | "enum.ProcessModelCommandTypeEnum.TranslatePhase"
  | "enum.ProcessModelCommandTypeEnum.TranslateProcess"
  | "enum.ProcessModelCommandTypeEnum.TranslateTask"
  | "enum.ProcessModelCommandTypeEnum.UpdateArea"
  | "enum.ProcessModelCommandTypeEnum.UpdatePhase"
  | "enum.ProcessModelCommandTypeEnum.UpdateTask"
  | "enum.ProcessOperationEnum.AddToList"
  | "enum.ProcessOperationEnum.CreateDelegation"
  | "enum.ProcessOperationEnum.CreateProcess"
  | "enum.ProcessOperationEnum.RemoveFromList"
  | "enum.ProcessRuleTargetType.Candidate"
  | "enum.ProcessRuleTargetType.State"
  | "enum.ProcessRuleTargetType.Task"
  | "enum.ProcessRuleTaskTargetType.All"
  | "enum.ProcessRuleTaskTargetType.AllOfAreas"
  | "enum.ProcessRuleTaskTargetType.AllOfPhases"
  | "enum.ProcessRuleTaskTargetType.Tasks"
  | "enum.ProcessRuleTriggerEvent.OnActive"
  | "enum.ProcessRuleTriggerEvent.OnCancelled"
  | "enum.ProcessRuleTriggerEvent.OnCreate"
  | "enum.ProcessRuleTriggerEvent.OnError"
  | "enum.ProcessRuleTriggerEvent.OnFinished"
  | "enum.ProcessRuleTriggerEvent.OnInactive"
  | "enum.ProcessRuleTriggerEvent.OnStarted"
  | "enum.ProcessRuleType.SetProperty"
  | "enum.ProcessTarget.Candidate"
  | "enum.ProcessTarget.Collection"
  | "enum.ProcessTargetEnum.Candidate"
  | "enum.ProcessTargetEnum.Collection"
  | "enum.ProcessTargetEnum.FamiliyMember"
  | "enum.ProcessTask.ProcessTaskAssignmentException"
  | "enum.ProcessTask.ProcessTaskAutoOperation"
  | "enum.ProcessTask.ProcessTaskDeactivateProcess"
  | "enum.ProcessTask.ProcessTaskDocumentCheck"
  | "enum.ProcessTask.ProcessTaskEmailSend"
  | "enum.ProcessTask.ProcessTaskEnterMissingInformation"
  | "enum.ProcessTask.ProcessTaskEnterMissingInformationV2"
  | "enum.ProcessTask.ProcessTaskInformationCollection"
  | "enum.ProcessTask.ProcessTaskInformationSendOut"
  | "enum.ProcessTask.ProcessTaskInviteToPlatform"
  | "enum.ProcessTask.ProcessTaskOrganizationAudit"
  | "enum.ProcessTask.ProcessTaskPdfFormGeneration"
  | "enum.ProcessTask.ProcessTaskReminder"
  | "enum.ProcessTask.ProcessTaskRoleSetup"
  | "enum.ProcessTask.ProcessTaskTriggerEvent"
  | "enum.ProcessTask.ProcessTaskUpdateCandidateProfile"
  | "enum.ProcessTask.ProcessTaskUpload"
  | "enum.ProcessTask.ProcessTaskWork"
  | "enum.ProfessionalFieldAdditionalInformationType.WhenExperienceAvailable"
  | "enum.ProfessionalFieldAdditionalInformationType.WhenExperienceNotAvailable"
  | "enum.ProfileDataEditStatus.Default"
  | "enum.ProfileDataEditStatus.Editable"
  | "enum.ProfileDataEditStatus.ReadOnly"
  | "enum.ProfileDataEditStatus.title"
  | "enum.RecipiantEnum.Candidate"
  | "enum.RecipiantEnum.CandidateManager"
  | "enum.RecipiantEnum.CountryManager"
  | "enum.RecipiantEnum.EducationProvider"
  | "enum.RecipiantEnum.Employer"
  | "enum.RecipiantEnum.HRDepartment"
  | "enum.RecipiantEnum.IntegrationProvider"
  | "enum.RecipiantEnum.LanguageSchool"
  | "enum.RecipiantEnum.LocalPartner"
  | "enum.RecipiantEnum.PSA"
  | "enum.RecipiantEnum.ProjectManager"
  | "enum.RecipiantEnum.RelocationProvider"
  | "enum.RecipiantEnum.StatusReader"
  | "enum.RecipiantEnum.Supervisor"
  | "enum.RecipiantEnum.responsibleRoles"
  | "enum.RecipiantEnum.targetRoles"
  | "enum.RecipiantEnum.visibleForRoles"
  | "enum.RecognitionProfessionalExperience.fullRecognition"
  | "enum.RecognitionProfessionalExperience.noRecognition"
  | "enum.RecognitionProfessionalExperience.partialRecognition"
  | "enum.RecognitionState.DeficitNotice"
  | "enum.RecognitionState.FullNotice"
  | "enum.RecognitionState.NoNoticeReceived"
  | "enum.RecognitionState.RecognitionNoticeReceived"
  | "enum.RecognitionState.RecognitionStarted"
  | "enum.RecognitionState.RejectNotice"
  | "enum.RemoveFromListBehaviorEnum.All"
  | "enum.RemoveFromListBehaviorEnum.AllWithSharing"
  | "enum.RemoveFromListBehaviorEnum.CollectionOrTemplate"
  | "enum.RemoveFromListBehaviorEnum.Current"
  | "enum.RemoveFromListBehaviorEnum.SharedToOtherEmployers"
  | "enum.RemunerationCalculationBasis.Other"
  | "enum.RemunerationCalculationBasis.PerHour"
  | "enum.RemunerationCalculationBasis.PerMonth"
  | "enum.RemunerationType.Salary"
  | "enum.RemunerationType.Wage"
  | "enum.ReplyTypeEnum.ConfirmAgreement"
  | "enum.ReplyTypeEnum.ConfirmReception"
  | "enum.ReplyTypeEnum.NoReply"
  | "enum.ReplyTypeEnum.ReplyWithDate"
  | "enum.ReplyTypeEnum.ReplyWithText"
  | "enum.ReplyTypeEnum.ReplyWithYesOrNo"
  | "enum.ReplyTypeEnum.ReplyWithYesOrNoAndForcedCommentAtNo"
  | "enum.SharingTypeEnum.Collaboration"
  | "enum.SharingTypeEnum.ReadOnlyCollaboration"
  | "enum.SharingTypeEnum.View"
  | "enum.SupportedImmigrationCountry.AT"
  | "enum.SupportedImmigrationCountry.DE"
  | "enum.SupportedImmigrationCountry.UNKNOWN"
  | "enum.TalentPoolParticipantType.Contributor"
  | "enum.TalentPoolParticipantType.User"
  | "enum.TalentPoolSharingCvKind.Standard"
  | "enum.TalentPoolSharingCvKind.TalentPool"
  | "enum.TalentPoolType.Standard"
  | "enum.TalentPoolType.Website"
  | "enum.TaskVisibilityEnum.UserGroup"
  | "enum.TaskVisibilityEnum.WholeOrganization"
  | "enum.TechnicalSourceEnum.API"
  | "enum.TechnicalSourceEnum.Copy"
  | "enum.TechnicalSourceEnum.Import"
  | "enum.TechnicalSourceEnum.Transfer"
  | "enum.TechnicalSourceEnum.UX"
  | "enum.TechnicalSourceEnum.undefined"
  | "enum.TemplateToDocumentTypeConversion.OriginalFileType"
  | "enum.TemplateToDocumentTypeConversion.PDF"
  | "enum.TerminationEmploymentActor.Employee"
  | "enum.TerminationEmploymentActor.Employer"
  | "enum.TranslationMode.All"
  | "enum.TranslationMode.MissingAndChanged"
  | "enum.UserRole.Administrator"
  | "enum.UserRole.Candidate"
  | "enum.UserRole.CandidateManager"
  | "enum.UserRole.ContentAdministrator"
  | "enum.UserRole.CourseAdministrator"
  | "enum.UserRole.CourseTeacher"
  | "enum.UserRole.CrmContributor"
  | "enum.UserRole.EmailReceiverOnly"
  | "enum.UserRole.PartnerAdministrator"
  | "enum.UserRole.ProcessAdministrator"
  | "enum.UserRole.ProcessObserver"
  | "enum.UserRole.User"
  | "enum.VoteType.Negative"
  | "enum.VoteType.Neutral"
  | "enum.VoteType.Positive"
  | "enum.WorkExperiencePrecissionEnum.DAY"
  | "enum.WorkExperiencePrecissionEnum.MONTH"
  | "enum.WorkingTimeTypeEnum.FullTime"
  | "enum.WorkingTimeTypeEnum.MarginalEmployment"
  | "enum.WorkingTimeTypeEnum.PartTime"
  | "enum.YearsOfSkillExperience.BetweenOneAndThree"
  | "enum.YearsOfSkillExperience.LessThanOne"
  | "enum.YearsOfSkillExperience.MoreThanThree"
  | "enum.YearsOfSkillExperience.NoExperience"
  | "enum.boolean.false"
  | "enum.boolean.true"
  | "enum.description.KnownFormToken.InterviewAppointmentConfirmationForm"
  | "enum.description.KnownFormToken.InterviewAppointmentProposalForm"
  | "enum.description.KnownFormToken.InterviewAppointmentScheduleForm"
  | "enum.description.KnownFormToken.MatchingAgreementForm"
  | "enum.description.KnownFormToken.ShippingInformationForm"
  | "enum.description.KnownFormToken.SubmissionDate"
  | "enum.description.KnownFormToken.TravelBookingForm"
  | "enum.language.ar-AE"
  | "enum.language.bg-BG"
  | "enum.language.bn-BD"
  | "enum.language.bs-BA"
  | "enum.language.da-DK"
  | "enum.language.de-DE"
  | "enum.language.el-GR"
  | "enum.language.en-GB"
  | "enum.language.es-ES"
  | "enum.language.et-EE"
  | "enum.language.fa-IR"
  | "enum.language.fi-FI"
  | "enum.language.fp-PH"
  | "enum.language.fr-FR"
  | "enum.language.ga-IE"
  | "enum.language.he-IL"
  | "enum.language.hi-IN"
  | "enum.language.hr-HR"
  | "enum.language.hu-HU"
  | "enum.language.id-ID"
  | "enum.language.it-IT"
  | "enum.language.ja-JP"
  | "enum.language.ka-GE"
  | "enum.language.ko-KR"
  | "enum.language.ku-TR"
  | "enum.language.me-ME"
  | "enum.language.mk-MK"
  | "enum.language.ml-IN"
  | "enum.language.nb-NO"
  | "enum.language.nl-NL"
  | "enum.language.pa-IN"
  | "enum.language.pl-PL"
  | "enum.language.pt-BR"
  | "enum.language.pt-PT"
  | "enum.language.ro-RO"
  | "enum.language.ru-RU"
  | "enum.language.sq-AL"
  | "enum.language.sr-SP"
  | "enum.language.sv-SE"
  | "enum.language.ta-IN"
  | "enum.language.th-TH"
  | "enum.language.tl-PH"
  | "enum.language.tr-TR"
  | "enum.language.uk-UA"
  | "enum.language.ur-PK"
  | "enum.language.vi-VN"
  | "enum.language.zh-CN"
  | "enum.organizationAuditActFilter.additionalClaimApproved"
  | "enum.organizationAuditActFilter.additionalClaimRejected"
  | "enum.organizationAuditActFilter.approved"
  | "enum.organizationAuditActFilter.approvedWithAdditionalClaim"
  | "enum.organizationAuditActFilter.audited"
  | "enum.organizationAuditActFilter.filled"
  | "enum.organizationAuditActFilter.rejected"
  | "enum.organizationAuditActFilter.unaudited"
  | "enum.organizationAuditActFilter.unfilled"
  | "enum.passedModules.Listening"
  | "enum.passedModules.Oral"
  | "enum.passedModules.Reading"
  | "enum.passedModules.Speaking"
  | "enum.passedModules.Writing"
  | "enum.passedModules.Written"
  | "enumNames.CandidateStatus"
  | "enumNames.EnterMissingInformation"
  | "enumNames.EnterMissingInformationAdditionalData"
  | "enumNames.InstanceStateEnum"
  | "enumNames.KnownPdfFormTokens"
  | "enumNames.ProcessInstanceStateEnum"
  | "enumNames.ProcessTargetEnum"
  | "enumNames.ReplyTypeEnum"
  | "enumNames.SupportedImmigrationCountry"
  | "enumNames.TaskVisibilityEnum"
  | "enumNames.WorkingTimeTypeEnum"
  | "equivalenceTest.placeholder"
  | "equivalenceTest.required"
  | "equivalenceTest.title"
  | "equivalentDocumentFormats.message"
  | "error.title"
  | "errorOccured.message"
  | "errorOccured.title"
  | "estimatedArrivalDate.month"
  | "estimatedArrivalDate.title"
  | "estimatedWorkStartDate.title"
  | "evaluatedQualification.placeholder"
  | "evaluatedQualification.required"
  | "evaluatedQualification.title"
  | "evaluationAuthority.homepage"
  | "evaluationAuthority.id"
  | "evaluationAuthority.title"
  | "evaluationRequest.title"
  | "evaluationResult.title"
  | "event.title"
  | "events.show"
  | "exam.title"
  | "examCertificateDate.invalid"
  | "examCertificateDate.required"
  | "examCertificateDate.title"
  | "examDate.confirmed"
  | "examDate.invalid"
  | "examDate.required"
  | "examDate.title"
  | "examDateAndTime.asDate"
  | "examDateAndTime.required"
  | "examDateAndTime.title"
  | "examGERA1.title"
  | "examGERA2.title"
  | "examGERB1.title"
  | "examGERB2.title"
  | "examGERC1.title"
  | "examGERC2.title"
  | "examInstitution.code"
  | "examInstitution.placeholder"
  | "examInstitution.required"
  | "examInstitution.title"
  | "examLocation.placeholder"
  | "examLocation.required"
  | "examLocation.title"
  | "examModule.title"
  | "examModuleInformation.title"
  | "examModules.code"
  | "examModules.group-2-part"
  | "examModules.group-4-part"
  | "examModules.placeholder"
  | "examModules.required"
  | "examModules.title"
  | "examResult.code"
  | "examResult.placeholder"
  | "examResult.title"
  | "examResultDate.title"
  | "examStatus.placeholder"
  | "examStatus.title"
  | "examStatus.tooltip"
  | "examType.code"
  | "examType.placeholder"
  | "examType.required"
  | "examType.title"
  | "experience.activity"
  | "experience.add"
  | "experience.addActivities"
  | "experience.asFreelancer"
  | "experience.birthPerMonth"
  | "experience.coreTasks"
  | "experience.coreTasksTranslated"
  | "experience.description"
  | "experience.inYears"
  | "experience.inYearsAndMonths"
  | "experience.month"
  | "experience.monthShort"
  | "experience.noExperienceAvailable"
  | "experience.noFurtherEducationAvailable"
  | "experience.notes"
  | "experience.opPerMonth"
  | "experience.otherActivity"
  | "experience.period"
  | "experience.professionalField"
  | "experience.title"
  | "experience.total"
  | "experience.totalExperienceInMonths"
  | "experience.unavailable"
  | "experience.years"
  | "experience.yearsShort"
  | "experienceField.title"
  | "experienceFields.keyArray"
  | "experienceFields.namesLine"
  | "experienceFields.notes"
  | "experienceFields.notesHTML"
  | "experienceFields.notesHTMLTranslated"
  | "experienceFields.notesTranslated"
  | "experienceFields.placeholder"
  | "experienceFields.required"
  | "experienceFields.title"
  | "experienceFields.title2"
  | "experienceNotes.title"
  | "experienceType.normal"
  | "experienceType.otherActivities"
  | "experienceType.placeholder"
  | "experienceType.title"
  | "experienceWorkArea.code"
  | "experienceWorkArea.placeholder"
  | "experienceWorkArea.title"
  | "experiences.title"
  | "expertMode.title"
  | "facebookMessenger.consent"
  | "facebookMessenger.consentheader"
  | "facebookMessenger.messageToLong"
  | "failed.title"
  | "familiarEquipment.title"
  | "familiarEquipment.translated"
  | "family.showMembers"
  | "family.title"
  | "familyMember.confirmDelete"
  | "familyMember.create"
  | "familyMember.deleted.message"
  | "familyMember.deleted.title"
  | "familyMember.edit"
  | "familyMember.placeholder"
  | "familyMember.title"
  | "favoriteCandidates.title"
  | "fax.placeholder"
  | "fax.title"
  | "federalState.placeholder"
  | "federalState.recognition"
  | "federalState.required"
  | "federalState.title"
  | "feedback.backlogged"
  | "feedback.confirmSubmit"
  | "feedback.confirmUnsubmit"
  | "feedback.interviewConducted"
  | "feedback.interviewRequested"
  | "feedback.placeholder"
  | "feedback.refreshed"
  | "feedback.rejected"
  | "feedback.report"
  | "feedback.reset"
  | "feedback.selected"
  | "feedback.statusPhaseOne"
  | "feedback.statusPhaseTwo"
  | "feedback.submit"
  | "feedback.submitted"
  | "feedback.title"
  | "feedback.unsubmit"
  | "feedback.unsubmitted"
  | "fictionalCertificate.title"
  | "fictionalCertificateAvailable.description"
  | "fictionalCertificateAvailable.title"
  | "fictionalCertificateDoesNotAuthorisesWorking.description"
  | "fictionalCertificateDoesNotAuthorisesWorking.title"
  | "fictionalCertificateNumber.title"
  | "fictionalCertificateValidUntilDate.title"
  | "field.placeholder"
  | "field.required"
  | "field.title"
  | "fieldNotShared"
  | "file.bad"
  | "file.candidateWarning"
  | "file.choose"
  | "file.confirmDelete"
  | "file.downloadFailed.message"
  | "file.downloadFailed.title"
  | "file.drop"
  | "file.formats"
  | "file.imageWarning"
  | "file.noImages"
  | "file.size"
  | "file.status"
  | "file.title"
  | "file.type"
  | "file.upload"
  | "file.uploadFailed"
  | "file.uploadImages"
  | "file.watermark"
  | "file.wizard"
  | "fileName.notValid"
  | "fileName.placeholder"
  | "fileName.required"
  | "fileName.title"
  | "fileName.unique"
  | "fileNumber.title"
  | "fileNumberAcceleratedProcedure.title"
  | "fileNumberVisa.entry"
  | "fileNumberVisa.title"
  | "fileType.placeholder"
  | "fileType.required"
  | "fileType.title"
  | "fileTypeToUpload.badFormats"
  | "fileTypeToUpload.placeholder"
  | "fileTypeToUpload.required"
  | "fileTypeToUpload.title"
  | "fileTypesToShare.placeholder"
  | "fileTypesToShare.title"
  | "filter.contains"
  | "filter.containsElement"
  | "filter.containsEqualString"
  | "filter.containsList"
  | "filter.containsString"
  | "filter.empty"
  | "filter.endsWith"
  | "filter.exists"
  | "filter.existsWithFiles"
  | "filter.hasFilesAndContainsElement"
  | "filter.hasNoFilesAndContainsElement"
  | "filter.lessThan"
  | "filter.lessThanOrEquals"
  | "filter.moreThan"
  | "filter.moreThanOrEquals"
  | "filter.no"
  | "filter.notContains"
  | "filter.notContainsElement"
  | "filter.notContainsEqualString"
  | "filter.notContainsList"
  | "filter.notContainsString"
  | "filter.notEmpty"
  | "filter.notExists"
  | "filter.notExistsWithFiles"
  | "filter.notSelected"
  | "filter.preset.category"
  | "filter.preset.create"
  | "filter.preset.name"
  | "filter.preset.order"
  | "filter.preset.organizationWide"
  | "filter.preset.personal"
  | "filter.preset.title"
  | "filter.selected"
  | "filter.startsWith"
  | "filter.title"
  | "filter.yes"
  | "fingerPrintDate.required"
  | "fingerPrintDate.title"
  | "firstContractExtension.title"
  | "firstDeputy.default"
  | "firstDeputy.placeholder"
  | "firstDeputy.required"
  | "firstDeputy.title"
  | "firstDeputy2.firstName"
  | "firstDeputy2.id"
  | "firstDeputy2.lastName"
  | "firstExamDate.month"
  | "firstExamDate.required"
  | "firstExamDate.title"
  | "firstExamDate.year"
  | "firstExamPassed.placeholder"
  | "firstExamPassed.required"
  | "firstExamPassed.title"
  | "firstFinalInterviewDate.required"
  | "firstFinalInterviewDate.title"
  | "firstFinalInterviewPassed.placeholder"
  | "firstFinalInterviewPassed.required"
  | "firstFinalInterviewPassed.title"
  | "firstLogin.connectFB"
  | "firstLogin.description"
  | "firstLogin.disconnectFB"
  | "firstLogin.email"
  | "firstLogin.facebook"
  | "firstLogin.signInWith"
  | "firstLogin.title"
  | "firstName.required"
  | "firstName.title"
  | "firstNameInPassport.required"
  | "firstNameInPassport.title"
  | "flightArrivalDate.title"
  | "flightDate.required"
  | "flightDate.title"
  | "flightNumber.title"
  | "formTemplate.pdfFormToken.title"
  | "formTemplates.menuTooltip"
  | "formTemplates.title"
  | "formatsAlreadyInDocumentSets.title"
  | "formatsAlreadyUploaded.title"
  | "freeFormatFiles.title"
  | "freeFormatFiles.tooltip"
  | "from.title"
  | "function.assistant"
  | "function.default"
  | "function.key"
  | "function.placeholder"
  | "function.required"
  | "function.specialist"
  | "function.title"
  | "furtherEducation.add"
  | "furtherEducation.description.placeholder"
  | "furtherEducation.description.title"
  | "furtherEducation.endDate.invalid"
  | "furtherEducation.endDate.required"
  | "furtherEducation.endDate.title"
  | "furtherEducation.fieldOfEducation.code"
  | "furtherEducation.fieldOfEducation.placeholder"
  | "furtherEducation.fieldOfEducation.title"
  | "furtherEducation.nameOfEducation.placeholder"
  | "furtherEducation.nameOfEducation.title"
  | "furtherEducation.notProfessionRelated.title"
  | "furtherEducation.period"
  | "furtherEducation.startDate.invalid"
  | "furtherEducation.startDate.required"
  | "furtherEducation.startDate.title"
  | "furtherEducation.title"
  | "furtherEducation.totalHours.title"
  | "furtherEducation.translated"
  | "furtherEducation.weeks"
  | "furtherEducations.title"
  | "gender.female"
  | "gender.male"
  | "gender.title"
  | "general.title"
  | "generalConditions.title"
  | "generalizedExperiences.title"
  | "generalizedExperiences.value"
  | "generationMode.placeholder"
  | "generationMode.required"
  | "generationMode.title"
  | "germanLanguageSkills.title"
  | "germanSkills.title"
  | "germanSkillsAtStart.title"
  | "governance.title"
  | "graphql.ACTIVATIONCONDITIONERROR"
  | "graphql.ATTACH_PROCESS_INVALID_ACTION_ID"
  | "graphql.ATTACH_PROCESS_INVALID_OPERATION"
  | "graphql.ATTACH_PROCESS_INVALID_OPERATION_ID"
  | "graphql.ATTACH_PROCESS_INVALID_PROCESS_ID"
  | "graphql.CANDIDATEALREADYINVITED"
  | "graphql.CANDIDATEEMAILNOTUNIQUE"
  | "graphql.CANDIDATEMISSINGFIELDS"
  | "graphql.CANDIDATENOAGENCY"
  | "graphql.CANDIDATENOBIRTHDATE"
  | "graphql.CANDIDATENOCOUNTRYOFORIGIN"
  | "graphql.CANDIDATEPROFESSIONNOTSUPPORTED"
  | "graphql.CANDIDATEPROFILECONFIGALREADYEXISTS"
  | "graphql.CANDIDATEREMINDEREMAILTEMPLATENOTFOUND"
  | "graphql.CANDIDATES_FROM_DIFFERENT_ORGANIZATIONS"
  | "graphql.CANDIDATE_NOT_FOUND"
  | "graphql.COLLECTIONALLREADYSHARED"
  | "graphql.COLLECTIONNAMENOTUNIQUE"
  | "graphql.COLLECTIONRUNNINGPROCESSES"
  | "graphql.COLLECTIONUSEDINAUTOPROCESSOPERATION"
  | "graphql.COLLECTION_COULD_NOT_BE_MAPPED"
  | "graphql.COLLECTION_NOT_FOUND"
  | "graphql.CONTENT_TEMPLATE_AT_LEAST_ONE_NODE"
  | "graphql.CONTENT_TEMPLATE_MISSING_MAIN_MARKER"
  | "graphql.DEMOALREADYLICENSED"
  | "graphql.DETACH_PROCESS_INVALID_ACTION_ID"
  | "graphql.DETACH_PROCESS_INVALID_OPERATION_ID"
  | "graphql.DETACH_PROCESS_INVALID_PROCESS_ID"
  | "graphql.DOCUMENTLOCKED"
  | "graphql.DOCUMENTNAMENOTUNIQUE"
  | "graphql.DOCUMENTSALREADYUPLOADED"
  | "graphql.DOCUMENTTEMPLATEISREQUIRED"
  | "graphql.DOCUMENTUNLOCKINGERROR"
  | "graphql.DOCUMENTUNLOCKINGERROR2"
  | "graphql.DOCUMENT_NOT_FOUND"
  | "graphql.DROPPEDOUTFORBIDDEN"
  | "graphql.EDUCATION_EXAM_RESULTS_EXIST"
  | "graphql.EMAILTEMPLATEHTMLNOTWELLFORMED"
  | "graphql.EMAILTEMPLATEISREQUIRED"
  | "graphql.EMAILTEMPLATENAMEALREADYEXISTS"
  | "graphql.EMAILTEMPLATESUBJECTNOTWELLFORMED"
  | "graphql.ESCALATIONEMAILTEMPLATENOTFOUND"
  | "graphql.FEATURENOTAVAILABLE"
  | "graphql.FILEDUPLICATEEMAIL"
  | "graphql.FILEEMPTY"
  | "graphql.FILEEXISTINGEMAIL"
  | "graphql.FILEINVALIDEMAIL"
  | "graphql.FILEINVALIDPROFESSION"
  | "graphql.FILEMALFORMED"
  | "graphql.FILEMISSINGADDRESS"
  | "graphql.FILEMISSINGBIRTHDATE"
  | "graphql.FILEMISSINGCITIZENSHIP"
  | "graphql.FILEMISSINGCITYOFBIRTH"
  | "graphql.FILEMISSINGCOUNTRYOFBIRTH"
  | "graphql.FILEMISSINGCOUNTRYOFORIGIN"
  | "graphql.FILEMISSINGEMAIL"
  | "graphql.FILEMISSINGFIELDS"
  | "graphql.FILEMISSINGFIRSTNAME"
  | "graphql.FILEMISSINGLASTNAME"
  | "graphql.FILEMISSINGMARTIALSTATUS"
  | "graphql.FILEMISSINGPHONE"
  | "graphql.FILEMISSINGQUALIFICATION"
  | "graphql.FILEMISSINGRECOGNOTICEFEDERALSTATE"
  | "graphql.FILEMISSINGREUNIFICATION"
  | "graphql.FILEMISSINGSALUTATION"
  | "graphql.GROUPNAMENOTUNIQUE"
  | "graphql.GROUPRUNNINGPROCESSES"
  | "graphql.LAYOUT_TEMPLATE_AT_LEAST_ONE_PLACEHOLDER"
  | "graphql.LAYOUT_TEMPLATE_MISSING_MAIN_MARKER"
  | "graphql.LAYOUT_TEMPLATE_MISSING_MARKERS"
  | "graphql.LAYOUT_TEMPLATE_UNIQUE_MARKERS"
  | "graphql.LOCALPARTNERPROFILECONFIGALREADYEXISTS"
  | "graphql.LOCALPARTNERPROFILECONFIGINUSE"
  | "graphql.MATCHINGENABLED"
  | "graphql.NOFITTINGCVSEMPLATEAVAILABLE"
  | "graphql.NOTARIZATIONSALREADYUPLOADED"
  | "graphql.NOTWELLFORMED"
  | "graphql.ORGALREADYLINKED"
  | "graphql.ORGANIZATIONDOCUMENTISREQUIRED"
  | "graphql.ORGANIZATION_NOT_FOUND"
  | "graphql.ORGHASCHILDREN"
  | "graphql.ORGLICENSED"
  | "graphql.ORGLINKED"
  | "graphql.ORGNOTCREATED"
  | "graphql.ORGNOTUPDATED"
  | "graphql.ORGRUNNINGPROCESSES"
  | "graphql.ORGSHAREDCOLLECTIONS"
  | "graphql.ORGUNLICENSED"
  | "graphql.ORGUSEDASSOURCEORLANGSCHOOL"
  | "graphql.PARTICIPATION_CONDITION_CANDIDATE_ADD"
  | "graphql.PARTICIPATION_CONDITION_CANDIDATE_UPDATE"
  | "graphql.PARTICIPATION_CONDITION_PROCESS_COLLECTION_CHANGE"
  | "graphql.PARTICIPATION_CONDITION_PROCESS_CREATE"
  | "graphql.PARTICIPATION_CONDITION_PROCESS_SYNC"
  | "graphql.PARTICIPATION_CONDITION_PROCESS_SYNC_ALL"
  | "graphql.PROCESSACTIVATIONOVERFLOW"
  | "graphql.PROCESSATLEASTONEVISIBILEFORROLE"
  | "graphql.PROCESSBADTEMPLATE"
  | "graphql.PROCESSDELEGATIONLOOP"
  | "graphql.PROCESSDELEGATIONOPERATIONONPARENTTASKINVALID"
  | "graphql.PROCESSDUPLICATEIDS"
  | "graphql.PROCESSDUPLICATEMODELID"
  | "graphql.PROCESSEMPTDISPLAYNAME"
  | "graphql.PROCESSEMPTYATTACHMENTDISPLAYNAME"
  | "graphql.PROCESSEMPTYPROCESSDOCUMENTDISPLAYNAME"
  | "graphql.PROCESSFINISHLABELISMISSING"
  | "graphql.PROCESSIDNOTWELLFORMED"
  | "graphql.PROCESSINVALIDAUTOSTATECHANGESETTINGS"
  | "graphql.PROCESSMISSINGANYROLES"
  | "graphql.PROCESSMISSINGATLEASTONEPREDECESSORS"
  | "graphql.PROCESSMISSINGATTACHMENTTASKS"
  | "graphql.PROCESSMISSINGDOCUMENT"
  | "graphql.PROCESSMISSINGDOCUMENTNAME"
  | "graphql.PROCESSMISSINGDOCUMENTSET"
  | "graphql.PROCESSMISSINGDOCUMENTTEMPLATE"
  | "graphql.PROCESSMISSINGEMAILTEMPLATE"
  | "graphql.PROCESSMISSINGEMAILTEMPLATELANGUAGE"
  | "graphql.PROCESSMISSINGMODELNAME"
  | "graphql.PROCESSMISSINGPREDECESSORS"
  | "graphql.PROCESSMISSINGROLENAME"
  | "graphql.PROCESSMISSINGROLES"
  | "graphql.PROCESSMODELCONTAINSERRORS"
  | "graphql.PROCESSMODELEXCLUDEIDNOTFOUND"
  | "graphql.PROCESSMODELIMPORTMODELMISSING"
  | "graphql.PROCESSMODELINSERTAREAIDEXISTS"
  | "graphql.PROCESSMODELINSERTPHASEIDEXISTS"
  | "graphql.PROCESSMODELINSERTTASKIDEXISTS"
  | "graphql.PROCESSMODELINUSE"
  | "graphql.PROCESSMODELISEMPTY"
  | "graphql.PROCESSMODELISNOTVALID"
  | "graphql.PROCESSMODELNOTAVAILABLE"
  | "graphql.PROCESSMODELNOTFOUND"
  | "graphql.PROCESSMODELOFWRONGTYPE"
  | "graphql.PROCESSMODELREPLACEAREAIDNOTFOUND"
  | "graphql.PROCESSMODELREPLACEPHASEIDNOTFOUND"
  | "graphql.PROCESSMODELREPLACETASKIDNOTFOUND"
  | "graphql.PROCESSNAMENOTUNIQUE"
  | "graphql.PROCESSROLENAMENOTWELLFORMED"
  | "graphql.PROCESSTEMPLATESNOTSPECIFIED"
  | "graphql.PROCESSUNSUPPORTEDFILETYPES"
  | "graphql.PROCESSWRONGCONVERSIONTYPE"
  | "graphql.PROCESS_NOT_FOUND"
  | "graphql.PROCESS_OPERATION_PROCESS_MODEL_MISMATCH"
  | "graphql.RELEASEDPROCESSMODELINUSE"
  | "graphql.RELEASEDPROCESSMODELISPUBLISHED"
  | "graphql.REMINDEREMAILTEMPLATENOTFOUND"
  | "graphql.REQUIREDDOCUMENTSREQUIRED"
  | "graphql.REQUIREDOCUMENTNAMEALREADYEXISTS"
  | "graphql.RULEEVENTNOTSUPPORTED"
  | "graphql.RULEINVALIDTARGETID"
  | "graphql.RULEINVALIDTASKID"
  | "graphql.RULEMISSINGPROPERTYNAME"
  | "graphql.RULEMISSINGTARGET"
  | "graphql.RULEMISSINGTARGETAREAIDS"
  | "graphql.RULEMISSINGTARGETPHASEIDS"
  | "graphql.RULEMISSINGTARGETTASKIDS"
  | "graphql.RULEMISSINGTARGETTASKS"
  | "graphql.RULERUNCONDITIONERROR"
  | "graphql.RULETARGETAREIDSNOTSUPPORTED"
  | "graphql.RULETARGETIDSNOTSUPPORTED"
  | "graphql.RULETARGETPHASEIDSNOTSUPPORTED"
  | "graphql.RULETARGETTASKIDSNOTSUPPORTED"
  | "graphql.SHARINGCONFIGURATIONCHANGED"
  | "graphql.SHARINGISNOTCOLLABORATION"
  | "graphql.SHARINGNNOTSUPPORTEDITECANDIDATE"
  | "graphql.SHARINGNOTVALID"
  | "graphql.SHARINGRUNNINGPROCESSES"
  | "graphql.SHARING_PRESET_NAME_NOT_UNIQUE"
  | "graphql.SYNC_ALL_WITH_TEMPLATE_FAILED"
  | "graphql.TALENT_POOL_NAME_NOT_UNIQUE"
  | "graphql.TALENT_POOL_PARTICIPANTS_NOT_UNIQUE"
  | "graphql.TALENT_POOL_SHARING_NOT_UNIQUE"
  | "graphql.TASKDELETED"
  | "graphql.TASK_NOT_FOUND"
  | "graphql.TEMPLATE_AND_COLLECTION_MISMATCH"
  | "graphql.TEMPLATE_AND_COLLECTION_VARIABLE_MISMATCH"
  | "graphql.TEMPLATE_NAME_NOT_UNIQUE"
  | "graphql.TEMPLATE_OR_COLLECTION_NOT_FOUND"
  | "graphql.TEMPLATE_USED_IN_ACTIONS"
  | "graphql.TEMPLATE_USED_IN_PROCESSES"
  | "graphql.TEMPLATE_VARIABLES_CHANGED"
  | "graphql.TRANSLATIONSALREADYUPLOADED"
  | "graphql.TRANSLATION_FAILED"
  | "graphql.UNDEFINEDCANDIDATEPROPERTY"
  | "graphql.UNEXPECTEDCANDIDATEROLE"
  | "graphql.USEREMAILNOTUNIQUE"
  | "graphql.USEREXISTASCANDIDATE"
  | "graphql.USER_NOT_FOUND"
  | "graphql.atomic.ACTIVATIONCONDITIONERROR"
  | "graphql.atomic.AREAMODELISEMPTY"
  | "graphql.atomic.NOREPLY_REQUIRES_ADDITIONAL_OPTIONS"
  | "graphql.atomic.PHASEMODELISEMPTY"
  | "graphql.atomic.PROCESSDUPLICATEIDS"
  | "graphql.atomic.PROCESSDUPLICATEMODELID"
  | "graphql.atomic.PROCESSEMPTDISPLAYNAME"
  | "graphql.atomic.PROCESSFINISHLABELISMISSING"
  | "graphql.atomic.PROCESSIDNOTWELLFORMED"
  | "graphql.atomic.PROCESSINVALIDAUTOSTATECHANGESETTINGS"
  | "graphql.atomic.PROCESSMISSINGANYROLES"
  | "graphql.atomic.PROCESSMISSINGATLEASTONEPREDECESSORS"
  | "graphql.atomic.PROCESSMISSINGATTACHMENTTASKS"
  | "graphql.atomic.PROCESSMISSINGDOCUMENTNAME"
  | "graphql.atomic.PROCESSMISSINGDOCUMENTTEMPLATE"
  | "graphql.atomic.PROCESSMISSINGEMAILTEMPLATE"
  | "graphql.atomic.PROCESSMISSINGPREDECESSORS"
  | "graphql.atomic.PROCESSMISSINGROLENAME"
  | "graphql.atomic.PROCESSMISSINGROLES"
  | "graphql.atomic.PROCESSMODELISEMPTY"
  | "graphql.atomic.PROCESSMODELISNOTVALID"
  | "graphql.atomic.PROCESSROLENAMENOTWELLFORMED"
  | "graphql.atomic.RULERUNCONDITIONERROR"
  | "graphql.atomic.UNDEFINEDCANDIDATEPROPERTY"
  | "hasDifferentEmployerRelationships.title"
  | "hasDifferentRelationships.title"
  | "hasLiabilityInsurance.title"
  | "hasReceivedHealthInsuranceCard.title"
  | "hasSharedCustody.title"
  | "hasTime.title"
  | "healthInsuranceCompanyName.placeholder"
  | "healthInsuranceCompanyName.title"
  | "healthInsuranceNumber.placeholder"
  | "healthInsuranceNumber.title"
  | "healthProfessionsRegister.placeholder"
  | "healthProfessionsRegister.title"
  | "healthProfessionsRegisterAppointmentDate.required"
  | "healthProfessionsRegisterAppointmentDate.title"
  | "healthProfessionsRegisterDate.required"
  | "healthProfessionsRegisterDate.title"
  | "healthProfessionsRegisterSubmission.required"
  | "healthProfessionsRegisterSubmission.submission"
  | "healthProfessionsRegisterSubmission.title"
  | "heatmap.title"
  | "help.title"
  | "hideCandidateNameInFileName.title"
  | "highestDegree.code"
  | "highestDegree.degreeCity"
  | "highestDegree.degreeCountry"
  | "highestDegree.degreeEndDate"
  | "highestDegree.degreeExperienceInYears"
  | "highestDegree.degreeInstitution"
  | "highestDegree.degreeStartDate"
  | "highestDegree.title"
  | "hobbies.title"
  | "hobbies.translated"
  | "home.title"
  | "hoursPerWeek.title"
  | "housing.add.title"
  | "housing.additionalCosts.title"
  | "housing.cancellationOfContractSignDate.month"
  | "housing.cancellationOfContractSignDate.title"
  | "housing.cancellationOfContractSignDate.year"
  | "housing.coldRent.title"
  | "housing.comments.title"
  | "housing.contractData.title"
  | "housing.contractSignDate.month"
  | "housing.contractSignDate.title"
  | "housing.contractSignDate.year"
  | "housing.housingEntry.title"
  | "housing.housingManagement.title"
  | "housing.keyHandoverDateTime.hasTime"
  | "housing.keyHandoverDateTime.title"
  | "housing.movingInDate.month"
  | "housing.movingInDate.title"
  | "housing.movingInDate.year"
  | "housing.movingOutDate.month"
  | "housing.movingOutDate.title"
  | "housing.movingOutDate.year"
  | "housing.name"
  | "housing.nameOfContactPerson.title"
  | "housing.operatingCosts.title"
  | "housing.phoneOfContactPerson.title"
  | "housing.readinessForOccupancyDate.month"
  | "housing.readinessForOccupancyDate.title"
  | "housing.readinessForOccupancyDate.year"
  | "housing.title"
  | "housingAcquisition.title"
  | "housingContract.title"
  | "hr.contactPartner.placeholder"
  | "hr.contactPartner.required"
  | "hr.contactPartner.title"
  | "hr.personnelArea.placeholder"
  | "hr.personnelArea.required"
  | "hr.personnelArea.title"
  | "hr.personnelDepartment.placeholder"
  | "hr.personnelDepartment.required"
  | "hr.personnelDepartment.title"
  | "hr.personnelNumber.placeholder"
  | "hr.personnelNumber.required"
  | "hr.personnelNumber.title"
  | "hr.supervisor.placeholder"
  | "hr.supervisor.required"
  | "hr.supervisor.title"
  | "hr.terminationEmploymentActor.placeholder"
  | "hr.terminationEmploymentActor.required"
  | "hr.terminationEmploymentActor.title"
  | "hr.terminationEmploymentDate.date"
  | "hr.terminationEmploymentDate.placeholder"
  | "hr.terminationEmploymentDate.required"
  | "hr.terminationEmploymentDate.title"
  | "hr.title"
  | "iban.placeholder"
  | "iban.title"
  | "id.title"
  | "idCardNumber.required"
  | "idCardNumber.title"
  | "immigration.application.arrivalDate"
  | "immigration.application.arrived"
  | "immigration.application.filter"
  | "immigrationApplication.arrived"
  | "immigrationApplication.arrivedMonth"
  | "immigrationApplication.arrivedYear"
  | "immigrationApplication.issueDate"
  | "immigrationApplication.issueDateMonth"
  | "immigrationApplication.issueDateYear"
  | "immigrationApplication.receipt"
  | "immigrationApplicationAlternative.dateOfReceipt"
  | "immigrationApplicationAlternative.dateOfReceiptYear"
  | "immigrationApplicationAlternative.issueDate"
  | "immigrationApplicationAlternative.issueDateYear"
  | "immigrationApplicationAlternative.month"
  | "immigrationApplicationAlternative.startDate"
  | "immigrationApplicationAlternative.startYear"
  | "immigrationApplicationAlternative.year"
  | "immigrationAuthority.placeholder"
  | "immigrationAuthority.required"
  | "immigrationAuthority.title"
  | "immigrationCountry.placeholder"
  | "immigrationCountry.title"
  | "immigrationCountryPhone.required"
  | "immigrationCountryPhone.title"
  | "immigrationOrOriginCountryBankDetail.title"
  | "implicitFavorite.title"
  | "import-result-dialog.caption"
  | "import-result-dialog.column.language"
  | "import-result-dialog.column.lineNumber"
  | "import-result-dialog.column.message"
  | "import-result-dialog.column.name"
  | "import-result-dialog.column.property"
  | "import-result-dialog.column.severity"
  | "import-result-dialog.hint"
  | "import-result-dialog.import-failed"
  | "import-result-dialog.import-success"
  | "import-result-dialog.solve-issues"
  | "import-result-dialog.type.AugmentedCandidate"
  | "import-result-dialog.type.CandidateEntity"
  | "import-result-dialog.type.ContractTemplateEntity"
  | "import-result-dialog.type.Legacy-Import"
  | "import-result-dialog.type.OrganizationContactEntity"
  | "import-result-dialog.type.OrganizationContactSettingsEntity"
  | "import-result-dialog.type.OrganizationEntity"
  | "importModel.placeholder"
  | "importModel.required"
  | "importModel.title"
  | "inbox.title"
  | "initializing.authentication"
  | "initializing.authorization"
  | "initializing.title"
  | "instanceState.placeholder"
  | "instanceState.required"
  | "instanceState.title"
  | "institutionName.title"
  | "insuranceAndTax.description"
  | "insuranceAndTax.title"
  | "internal.hr.title"
  | "internal.title"
  | "internationalExperience.required"
  | "internationalExperience.title"
  | "interview.title"
  | "interviewDate.invalid"
  | "interviewDate.latestInterviewDateWithCurrentEmployer"
  | "interviewDate.latestInterviewDateWithEmployerOfSharing"
  | "interviewDate.required"
  | "interviewDate.title"
  | "interviewLanguageLevel.placeholder"
  | "interviewLanguageLevel.required"
  | "interviewLanguageLevel.title"
  | "invite.at"
  | "invite.block"
  | "invite.blocked"
  | "invite.facebookId"
  | "invite.messernOpt-In"
  | "invite.notLinked"
  | "invite.title"
  | "invite.unblock"
  | "invited.missing"
  | "invited.show"
  | "invited.title"
  | "invoiceAddress.required"
  | "invoiceAddress.title"
  | "isCurrentAccount.title"
  | "isDeleted.title"
  | "isHidden.title"
  | "isImmigrationCountryBank.title"
  | "isInternalDocument.managedByRequirements"
  | "isInternalDocument.title"
  | "isInternalDocument.tooltip"
  | "isNotCurrentAccount.title"
  | "isProcessExtension.title"
  | "isReplacementForCandidate.title"
  | "isSystemProcess.title"
  | "isTemporaryEmployee.after"
  | "isTemporaryEmployee.before"
  | "isTemporaryEmployee.title"
  | "issueDate.asDate"
  | "issueDate.title"
  | "itSkills.title"
  | "itSkills.translated"
  | "jobPreferences.title"
  | "knowledge.title"
  | "knowledgeTestInstitution.title"
  | "knowledges.description"
  | "knowledges.title"
  | "knowledgesFilter.type"
  | "knowledgesFilter.value"
  | "knownVariables.title"
  | "laborMarketAdmission.title"
  | "language.auto"
  | "language.code"
  | "language.placeholder"
  | "language.required"
  | "language.title"
  | "languageCertificate.A1.title"
  | "languageCertificate.A2.title"
  | "languageCertificate.B1.title"
  | "languageCertificate.B2.title"
  | "languageCertificate.C1.title"
  | "languageCertificate.C2.title"
  | "languageCertificate.title"
  | "languageSkills.add"
  | "languageSkills.certificateDate"
  | "languageSkills.certified"
  | "languageSkills.certifiedGermanLanguageLevel"
  | "languageSkills.certifiedGermanLanguageLevelResultDate"
  | "languageSkills.existing"
  | "languageSkills.fullyCertified"
  | "languageSkills.fullyCertifiedAt"
  | "languageSkills.germanLanguageLevel"
  | "languageSkills.germanLanguageLevelInProgress"
  | "languageSkills.germanLanguageLevelInProgressResultDate"
  | "languageSkills.germanLanguageLevelResultDate"
  | "languageSkills.highestNotPassedGermanLanguageCertifiedExam"
  | "languageSkills.highestNotPassedGermanLanguageNotCertifiedExam"
  | "languageSkills.remove"
  | "languageSkills.testInstitution"
  | "languageSkills.title"
  | "languageSkills.unavailable"
  | "languageTrainingStatus.title"
  | "languageTrainingTracking.add"
  | "languageTrainingTracking.error.double"
  | "languageTrainingTracking.error.notFitting"
  | "languageTrainingTracking.languageLevelCV.title"
  | "languageTrainingTracking.languageLevelTestDate.title"
  | "languageTrainingTracking.languageLevelTestDate.tooltip"
  | "languageTrainingTracking.languageLevelTestResult.title"
  | "languageTrainingTracking.languageLevelTestResult.tooltip"
  | "languageTrainingTracking.languageProgressTracking.assessmentTestDate.asDate"
  | "languageTrainingTracking.languageProgressTracking.assessmentTestDate.title"
  | "languageTrainingTracking.languageProgressTracking.assessmentTestResult.title"
  | "languageTrainingTracking.languageProgressTracking.assessmentTestSecondTryDate.asDate"
  | "languageTrainingTracking.languageProgressTracking.assessmentTestSecondTryDate.title"
  | "languageTrainingTracking.languageProgressTracking.assessmentTestSecondTryResult.title"
  | "languageTrainingTracking.languageProgressTracking.level.title"
  | "languageTrainingTracking.languageProgressTracking.module.title"
  | "languageTrainingTracking.languageProgressTracking.presenceRatio.asValue"
  | "languageTrainingTracking.languageProgressTracking.presenceRatio.title"
  | "languageTrainingTracking.languageProgressTracking.statusLanguageCertificate.title"
  | "languageTrainingTracking.languageProgressTracking.statusLanguageCertificateDate.title"
  | "languageTrainingTracking.languageTracking.title"
  | "languageTrainingTracking.title"
  | "languages.placeholder"
  | "languages.required"
  | "languages.title"
  | "lastExamDate.asDate"
  | "lastExamDate.title"
  | "lastInstanceStateChangedAt.title"
  | "lastLogin.title"
  | "lastModified.title"
  | "lastModifiedBy.title"
  | "lastName.required"
  | "lastName.title"
  | "latestRelease.title"
  | "latestReleaseAt.title"
  | "layoutTemplate.sendSeparateUserEmailsForEachCandidate"
  | "layoutTemplate.title"
  | "layoutTemplate.use"
  | "legalBasis.placeholder"
  | "legalBasis.required"
  | "legalBasis.title"
  | "liabilityInsurance.title"
  | "liabilityInsuranceCompanyName.title"
  | "liabilityInsuranceNumber.title"
  | "license.confirm"
  | "license.remove"
  | "license.removed"
  | "links.appCandidateDocumentLink"
  | "links.appCandidateEditLink"
  | "links.appCandidateProfileLink"
  | "links.appCandidateViewLink"
  | "links.appCollectionEditLink"
  | "links.appCollectionViewLink"
  | "links.appEmployerSearchLink"
  | "links.appMyOrganizationLink"
  | "links.appNewCandidatesLink"
  | "links.appProcessEditLink"
  | "links.appProcessViewLink"
  | "livesInGermany.title"
  | "loading"
  | "localPartnerProfileAccess.confirmDelete.message"
  | "localPartnerProfileAccess.confirmDelete.title"
  | "localPartnerProfileAccess.create"
  | "localPartnerProfileAccess.deleted.message"
  | "localPartnerProfileAccess.deleted.title"
  | "localPartnerProfileAccess.edit"
  | "localPartnerProfileAccess.manage"
  | "localPartnerProfileAccess.setName"
  | "localPartnerProfileAccess.setNameRequired"
  | "localPartnerProfileAccess.title"
  | "localizedString.languages.required"
  | "localizedString.missingTranslations"
  | "location.placeholder"
  | "location.title"
  | "locationOfComplementaryMeasure.required"
  | "locationOfComplementaryMeasure.title"
  | "locations.title"
  | "login.title"
  | "loginDenied.message"
  | "loginDenied.message2"
  | "loginDenied.title"
  | "logo.title"
  | "logout.title"
  | "manageable.title"
  | "mandatoryInternship.placeholder"
  | "mandatoryInternship.title"
  | "mandatoryOvertime.no"
  | "mandatoryOvertime.placeholder"
  | "mandatoryOvertime.title"
  | "mandatoryOvertime.yes"
  | "manuallyCreated.title"
  | "maritalStatus.code"
  | "maritalStatus.placeholder"
  | "maritalStatus.required"
  | "maritalStatus.title"
  | "maritalStatusSince.title"
  | "masterData.title"
  | "matchedRolesOnly.title"
  | "matching.disable"
  | "matching.enable"
  | "matching.placeholder"
  | "matching.title"
  | "matchingAgreement.text"
  | "maxParticipants.required"
  | "maxParticipants.title"
  | "medicalCheckAttended.title"
  | "medicalCheckAttendedDate.month"
  | "medicalCheckAttendedDate.title"
  | "medicalCheckAttendedDate.year"
  | "medicalCheckPassed.title"
  | "medicalCheckPassedDate.month"
  | "medicalCheckPassedDate.title"
  | "medicalCheckPassedDate.year"
  | "medicalExamination.title"
  | "medicalExaminationAppointmentDate.required"
  | "medicalExaminationAppointmentDate.title"
  | "meetingDate.placeholder"
  | "meetingDate.title"
  | "meetingLink.placeholder"
  | "meetingLink.title"
  | "member.add"
  | "members.add"
  | "members.parentId"
  | "members.title"
  | "messagesReception.candidateLastUpdate"
  | "messagesReception.confirmationNo"
  | "messagesReception.confirmationYes"
  | "messagesReception.filter"
  | "messagesReception.resetSuccess"
  | "messagesReception.title"
  | "messagesReception.userLastUpdate"
  | "messenger.contact"
  | "messenger.noReply"
  | "messenger.show"
  | "messenger.standardReply"
  | "messenger.welcomeMessageAfterOptIn"
  | "migration.at.employmentRelationship.afterRecognition.contractTemplate.title"
  | "migration.at.employmentRelationship.afterRecognition.title"
  | "migration.at.employmentRelationship.beforeRecognition.contractSignedByAllParties.title"
  | "migration.at.employmentRelationship.beforeRecognition.contractSignedByOneParty.title"
  | "migration.at.employmentRelationship.beforeRecognition.contractTemplate.title"
  | "migration.at.employmentRelationship.beforeRecognition.employmentOffer.title"
  | "migration.at.employmentRelationship.beforeRecognition.title"
  | "migration.at.employmentRelationship.title"
  | "migration.at.healthProfessionsRegister.title"
  | "migration.at.laborMarketAdmission.title"
  | "migration.at.qualificationEvaluation.title"
  | "migration.at.qualificationMeasure.title"
  | "migration.at.recognitionPath.recognitionApplication.title"
  | "migration.at.recognitionPath.title"
  | "migration.at.residence.title"
  | "migration.at.visa.title"
  | "migration.at.vocationalSchool.title"
  | "migration.at.vocationalSchool.trainingContract.title"
  | "migration.complementaryMeasure.title"
  | "migration.de.employmentRelationship.afterRecognition.contractTemplate.title"
  | "migration.de.employmentRelationship.afterRecognition.title"
  | "migration.de.employmentRelationship.beforeRecognition.contractSignedByAllParties.title"
  | "migration.de.employmentRelationship.beforeRecognition.contractSignedByOneParty.title"
  | "migration.de.employmentRelationship.beforeRecognition.contractTemplate.title"
  | "migration.de.employmentRelationship.beforeRecognition.employmentOffer.title"
  | "migration.de.employmentRelationship.beforeRecognition.title"
  | "migration.de.employmentRelationship.title"
  | "migration.de.laborMarketAdmission.employmentApproval.title"
  | "migration.de.laborMarketAdmission.title"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.qualificationEvaluationByCentralDepartment.title"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.qualificationEvaluationByFederalStateAuthority.title"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.qualificationEvaluationNoticeKind.title"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.qualificationEvaluationRejection.title"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.qualificationEvaluationRequest.title"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.title"
  | "migration.de.qualificationEvaluation.title"
  | "migration.de.qualificationMeasure.educationVoucher.title"
  | "migration.de.qualificationMeasure.qualificationParticipation.title"
  | "migration.de.qualificationMeasure.title"
  | "migration.de.recognitionPath.current.certificate.title"
  | "migration.de.recognitionPath.current.deficitNote.title"
  | "migration.de.recognitionPath.current.immigrationApplication.title"
  | "migration.de.recognitionPath.current.recognition.title"
  | "migration.de.recognitionPath.current.recognitionApplication.title"
  | "migration.de.recognitionPath.current.recognitionRejection.title"
  | "migration.de.recognitionPath.current.title"
  | "migration.de.residence.appointmentHealthDepartmentDate.title"
  | "migration.de.residence.bankAppointmentDate.title"
  | "migration.de.residence.companyDoctorAppointmentDate.title"
  | "migration.de.residence.medicalExaminationAppointmentDate.title"
  | "migration.de.residence.personalOnboardingDateTime.title"
  | "migration.de.residence.policeRecordAppointmentDate.title"
  | "migration.de.residence.registrationOfficeDate.title"
  | "migration.de.residence.title"
  | "migration.de.visa.title"
  | "migration.de.visa.visaDocument.title"
  | "migration.de.vocationalSchool.title"
  | "migration.de.vocationalSchool.trainingContract.title"
  | "migration.employmentRelationship.afterRecognition.title"
  | "migration.employmentRelationship.beforeRecognition.title"
  | "migration.employmentRelationship.title"
  | "migration.housingAcquisition.housings.contract.title"
  | "migration.housingAcquisition.housings.title"
  | "migration.housingAcquisition.title"
  | "migration.laborMarketAdmission.title"
  | "migration.qualificationMeasure.title"
  | "migration.recognitionPath.current.title"
  | "migration.residence.title"
  | "migration.title"
  | "migration.visa.title"
  | "missing.documents"
  | "missing.templates"
  | "missing.users"
  | "mobile.required"
  | "mobile.title"
  | "modelId.pattern"
  | "modelId.placeholder"
  | "modelId.required"
  | "modelId.title"
  | "modelId.unique"
  | "myApplications.title"
  | "myTasks.title"
  | "name.forbidden"
  | "name.notValid"
  | "name.placeholder"
  | "name.required"
  | "name.searchBy"
  | "name.title"
  | "name.unique"
  | "nameInPassport.required"
  | "nameInPassport.title"
  | "nameOfInstitution.nameAndPlace"
  | "nameOfInstitution.title"
  | "nameOfInstitution2.title"
  | "neutralRecognition.title"
  | "noDetailedEquivalence.confirm"
  | "noDetailedEquivalence.help"
  | "noDetailedEquivalence.title"
  | "noQualificationEvaluation.title"
  | "nonProfessionalTrainings.title"
  | "notDone.title"
  | "notFound.message"
  | "notFound.title"
  | "notRelevant.title"
  | "notShared.title"
  | "note.confirmCancel"
  | "note.confirmDelete"
  | "note.created"
  | "note.deleted"
  | "note.html"
  | "note.title"
  | "note.updated"
  | "notifications.clearAll"
  | "notifications.openActivityLog"
  | "notifications.since"
  | "notifications.title"
  | "number.title"
  | "occupation.code"
  | "occupation.original"
  | "occupation.placeholder"
  | "occupation.required"
  | "occupation.title"
  | "occupation.unavailable"
  | "occupationalTitle.title"
  | "occupationalTitle.tooltip"
  | "officeAddin.allCandidates"
  | "officeAddin.analysis"
  | "officeAddin.candidateList"
  | "officeAddin.changeLanguageWarning"
  | "officeAddin.changesInTimeFrame"
  | "officeAddin.clipBoardExplanation"
  | "officeAddin.dataImport"
  | "officeAddin.example"
  | "officeAddin.explanation"
  | "officeAddin.flatMode"
  | "officeAddin.importCandidateData"
  | "officeAddin.importChanges"
  | "officeAddin.knownVariables"
  | "officeAddin.languageWarning"
  | "officeAddin.licensedOrganizations"
  | "officeAddin.organizations"
  | "officeAddin.relationalMode"
  | "officeAddin.salesData"
  | "officeAddin.syntax"
  | "officeAddin.tableWriteIssue"
  | "officeAddin.tableWriteSuccess"
  | "officeAddin.timeFrame"
  | "officeAddin.unlicensedOrganizations"
  | "operatingNumber.title"
  | "optional.title"
  | "optional.titleLong"
  | "options.title"
  | "organization.allowManagement"
  | "organization.allowManagementByCreator"
  | "organization.confirmUnlink.message"
  | "organization.confirmUnlink.title"
  | "organization.create"
  | "organization.created.message"
  | "organization.created.title"
  | "organization.creationDate"
  | "organization.creator.title"
  | "organization.deleted.message"
  | "organization.deleted.title"
  | "organization.edit"
  | "organization.editLink"
  | "organization.editUserGroups"
  | "organization.editUsers"
  | "organization.filter"
  | "organization.id"
  | "organization.import"
  | "organization.link"
  | "organization.linkByMe"
  | "organization.linkFull"
  | "organization.linkToMe"
  | "organization.linkUpdated.message"
  | "organization.linkUpdated.title"
  | "organization.linked.message"
  | "organization.linked.title"
  | "organization.manage"
  | "organization.my"
  | "organization.parent.placeholder"
  | "organization.parent.title"
  | "organization.placeholder"
  | "organization.providedBy"
  | "organization.recognitionProfessionalExperience"
  | "organization.required"
  | "organization.sharedWith"
  | "organization.switch"
  | "organization.title"
  | "organization.unique"
  | "organization.unlink"
  | "organization.unlinked.message"
  | "organization.unlinked.title"
  | "organization.updated.message"
  | "organization.updated.title"
  | "organizationAuditAct.applicant"
  | "organizationAuditAct.auditor"
  | "organizationAuditAct.auditorNotSelected"
  | "organizationAuditAct.emptyTree"
  | "organizationAuditAct.filterby"
  | "organizationAuditAct.inputs.additionalDemand.fulfilled"
  | "organizationAuditAct.inputs.additionalDemand.notfulfilled"
  | "organizationAuditAct.inputs.additionalDemand.title"
  | "organizationAuditAct.inputs.additionalDemandDescription"
  | "organizationAuditAct.inputs.additionalDocuments"
  | "organizationAuditAct.inputs.comment"
  | "organizationAuditAct.inputs.description"
  | "organizationAuditAct.inputs.feedbackAdditionalDemand"
  | "organizationAuditAct.inputs.files"
  | "organizationAuditAct.inputs.testResult.fulfilled"
  | "organizationAuditAct.inputs.testResult.fulfilledOnRecomMade"
  | "organizationAuditAct.inputs.testResult.notfulfilled"
  | "organizationAuditAct.inputs.testResult.title"
  | "organizationAuditAct.recruitingConcept.countries"
  | "organizationAuditAct.recruitingConcept.languageTraining"
  | "organizationAuditAct.recruitingConcept.matching"
  | "organizationAuditAct.recruitingConcept.other"
  | "organizationAuditAct.recruitingConcept.sourcing"
  | "organizationId.title"
  | "organizationProfile.location.vacancies.add"
  | "organizationProfile.location.vacancies.title"
  | "organizationProfile.location.validation.duplicateStaffEntries.title"
  | "organizationProfile.location.validation.duplicateStaffEntries.tooltip"
  | "organizationProfile.manage"
  | "organizationProfile.title"
  | "organizationProfile.updated.message"
  | "organizationProfile.updated.title"
  | "organizationType.placeholder"
  | "organizationType.required"
  | "organizationType.title"
  | "organizations.confirmCode"
  | "organizations.confirmImport"
  | "organizations.downloadImportTemplate"
  | "organizations.import"
  | "organizations.importFailed"
  | "organizations.importTo"
  | "organizations.invalidDataInRows"
  | "organizations.involved"
  | "organizations.manage"
  | "organizations.required"
  | "organizations.title"
  | "organizationsService.validation.duplicate.code"
  | "organizationsService.validation.duplicate.name"
  | "organizationsService.validation.exists.code"
  | "organizationsService.validation.exists.name"
  | "organizationsService.validation.required.code"
  | "organizationsService.validation.required.name"
  | "os.profile.languageSkills.exam.title"
  | "os.profile.languageSkills.examDateAsDate"
  | "os.profile.languageSkills.examGERA1.title"
  | "os.profile.languageSkills.examGERA2.title"
  | "os.profile.languageSkills.examGERB1.title"
  | "os.profile.languageSkills.examGERB2.title"
  | "os.profile.languageSkills.examGERC1.title"
  | "os.profile.languageSkills.examGERC2.title"
  | "os.profile.languageSkills.examResultKey"
  | "os.profile.languageSkills.isSkillFromExam"
  | "os.profile.languageSkills.title"
  | "os.profile.languageTrainingTracking.title"
  | "os.profile.other.title"
  | "os.profile.passport.title"
  | "os.profile.processStatus.title"
  | "os.profile.title"
  | "os.profile.vaccinations.title"
  | "os.profile.vaccinations.vaccinationDocument.title"
  | "os.title"
  | "other.title"
  | "other.title2"
  | "otherActivities.placeholder"
  | "otherActivities.title"
  | "otherCustodian.placeholder"
  | "otherCustodian.title"
  | "otherLanguageSkills.title"
  | "outbox.title"
  | "overview.title"
  | "owner.title"
  | "ownerRoles.title"
  | "paperForm.placeholder"
  | "paperForm.takeover.title"
  | "paperForm.takeover.tooltip"
  | "paperForm.title"
  | "participantCollection.placeholder"
  | "participantCollection.required"
  | "participantCollection.title"
  | "partner.category"
  | "partner.categoryPlaceHolder"
  | "partner.categoryRequired"
  | "partner.languageSchool"
  | "partner.languageSchoolRequired"
  | "partner.localPartner"
  | "partner.psa"
  | "partner.source"
  | "partner.sourcePlaceholder"
  | "partner.sourceRequired"
  | "partner.title"
  | "passAuthority.required"
  | "passAuthority.title"
  | "passNumber.dateOfIssue"
  | "passNumber.required"
  | "passNumber.title"
  | "passNumber.validUnit"
  | "passed.title"
  | "passedExamModules.placeholder"
  | "passedExamModules.title"
  | "passedModules.code"
  | "passedModules.placeholder"
  | "passedModules.title"
  | "passport.title"
  | "password.confirmReset"
  | "password.reset"
  | "password.resetSuccess"
  | "pathOfRecognition.placeholder"
  | "pathOfRecognition.required"
  | "pathOfRecognition.title"
  | "performance.placeholder"
  | "performance.title"
  | "performance.updated"
  | "personal.title"
  | "personalData.title"
  | "personalOnboardingDateTime.title"
  | "phase.title"
  | "phone.clipboard"
  | "phone.placeholder"
  | "phone.required"
  | "phone.title"
  | "phoneNumber.contact"
  | "phoneNumber.german"
  | "phoneNumber.title"
  | "pickUpDetails.required"
  | "pickUpDetails.title"
  | "pickUpVehicle.title"
  | "pixo.square"
  | "placeholder.title"
  | "plannedEntryDate.required"
  | "plannedEntryDate.title"
  | "plannedExamDateAndTime.placeholder"
  | "plannedExamDateAndTime.required"
  | "plannedExamDateAndTime.title"
  | "plannedExamDateAndTimeConfirmed.required"
  | "plannedExamDateAndTimeConfirmed.title"
  | "plannedExamResultDate.placeholder"
  | "plannedExamResultDate.required"
  | "plannedExamResultDate.title"
  | "policeRecordAppointmentDate.help"
  | "policeRecordAppointmentDate.required"
  | "policeRecordAppointmentDate.title"
  | "position.placeholder"
  | "position.required"
  | "position.title"
  | "practiceExamDate.title"
  | "practiceHours.title"
  | "practiceInstitution.title"
  | "preConfiguredDocumentSetName.title"
  | "predecessors.placeholder"
  | "predecessors.title"
  | "preferences.candidates.title"
  | "preferences.careFacility.code"
  | "preferences.careFacility.flexibility.title"
  | "preferences.careFacility.placeholder"
  | "preferences.careFacility.title"
  | "preferences.city.flexibility.title"
  | "preferences.city.placeholder"
  | "preferences.city.title"
  | "preferences.employmentType.placeholder"
  | "preferences.employmentType.title"
  | "preferences.employmentType.titleShort"
  | "preferences.field.flexibility.title"
  | "preferences.field.placeholder"
  | "preferences.field.title"
  | "preferences.flexible"
  | "preferences.languageLevelForInterview.placeholder"
  | "preferences.languageLevelForInterview.required"
  | "preferences.languageLevelForInterview.title"
  | "preferences.languageLevelForInterview.titleShort"
  | "preferences.languageLevelForTransfer.placeholder"
  | "preferences.languageLevelForTransfer.required"
  | "preferences.languageLevelForTransfer.title"
  | "preferences.languageLevelForTransfer.titleShort"
  | "preferences.locationState.flexibility.title"
  | "preferences.pathOfRecognition.placeholder"
  | "preferences.pathOfRecognition.title"
  | "preferences.preferredFamilyReunion.placeholder"
  | "preferences.preferredFamilyReunion.title"
  | "preferences.preferredStartDate.invalid"
  | "preferences.preferredStartDate.title"
  | "preferences.preferredStartDate.titleShort"
  | "preferences.preferredTypeOfImmigration.placeholder"
  | "preferences.preferredTypeOfImmigration.title"
  | "preferences.state.flexibility.title"
  | "preferences.state.placeholder"
  | "preferences.state.title"
  | "preferences.temporalScope.placeholder"
  | "preferences.temporalScope.title"
  | "preferences.temporalScope.titleShort"
  | "preferences.title"
  | "preferences.unavailable"
  | "preferredCandidates.titleShort"
  | "preferredCareFacility.titleShort"
  | "preferredCity.title"
  | "preferredCity.titleShort"
  | "preferredLocationState.title"
  | "preferredLocationState.titleShort"
  | "preferredPathOfRecognition.title"
  | "preferredPathOfRecognition.titleShort"
  | "preferredWorkingField.titleShort"
  | "prefix.required"
  | "prefix.title"
  | "presentation.recommendationNote.title"
  | "presentation.recommendationNote.translated"
  | "preset.displayDescription.title"
  | "preset.displayName.required"
  | "preset.displayName.title"
  | "preset.message"
  | "preview.unavailable.title"
  | "primeng.accept"
  | "primeng.addRule"
  | "primeng.after"
  | "primeng.apply"
  | "primeng.before"
  | "primeng.cancel"
  | "primeng.choose"
  | "primeng.clear"
  | "primeng.contains"
  | "primeng.dateAfter"
  | "primeng.dateBefore"
  | "primeng.dateFormat"
  | "primeng.dateIs"
  | "primeng.dateIsNot"
  | "primeng.emptyFilterMessage"
  | "primeng.emptyMessage"
  | "primeng.endsWith"
  | "primeng.equals"
  | "primeng.gt"
  | "primeng.gte"
  | "primeng.invalidFileLimitMessageDetail"
  | "primeng.invalidFileLimitMessageSummary"
  | "primeng.invalidFileSizeMessageDetail"
  | "primeng.invalidFileSizeMessageSummary"
  | "primeng.invalidFileTypeMessageDetail"
  | "primeng.invalidFileTypeMessageSummary"
  | "primeng.is"
  | "primeng.isNot"
  | "primeng.lt"
  | "primeng.lte"
  | "primeng.matchAll"
  | "primeng.matchAny"
  | "primeng.medium"
  | "primeng.noFilter"
  | "primeng.notContains"
  | "primeng.notEquals"
  | "primeng.passwordPrompt"
  | "primeng.reject"
  | "primeng.removeRule"
  | "primeng.selectedItemsLabel"
  | "primeng.startsWith"
  | "primeng.strong"
  | "primeng.today"
  | "primeng.upload"
  | "primeng.weak"
  | "primeng.weekHeader"
  | "process.allSynced.message"
  | "process.allSynced.title"
  | "process.atLeastOneDelegationMustExist"
  | "process.autoStateChanges.conditions.daysStateUnchanged.title"
  | "process.autoStateChanges.conditions.title"
  | "process.autoStateChanges.mutations.title"
  | "process.autoStateChanges.title"
  | "process.collectionName"
  | "process.confirmActOnParent"
  | "process.confirmChangeRoles"
  | "process.confirmConfigureOnParent"
  | "process.confirmDeactivate"
  | "process.confirmDelete"
  | "process.confirmName"
  | "process.confirmReactivate"
  | "process.confirmSync"
  | "process.confirmSyncAll"
  | "process.create"
  | "process.created.message"
  | "process.created.title"
  | "process.createdFromAction"
  | "process.deactivate"
  | "process.deactivated.message"
  | "process.deactivated.title"
  | "process.delegate"
  | "process.delegation"
  | "process.delegationShort"
  | "process.deleted.message"
  | "process.deleted.title"
  | "process.edit"
  | "process.emailHandling"
  | "process.emailHandlingPlaceHolder"
  | "process.emailSendOut"
  | "process.isDelegation"
  | "process.isUserNotAssignToProcess"
  | "process.modelDefinitionOutdated"
  | "process.modelNotFound"
  | "process.name"
  | "process.placeholder"
  | "process.processConfig"
  | "process.reactivate"
  | "process.reactivated.message"
  | "process.reactivated.title"
  | "process.refreshed.message"
  | "process.refreshed.title"
  | "process.report"
  | "process.reports"
  | "process.required"
  | "process.review.confirmOk.title"
  | "process.review.feedbackText.title"
  | "process.review.fileFormats.title"
  | "process.review.isOutDated.title"
  | "process.review.reviewFinished.title"
  | "process.review.votum.title"
  | "process.showOutbox"
  | "process.showSendItems"
  | "process.singleSetConfig.title"
  | "process.statusReport"
  | "process.statusReportShort"
  | "process.sync"
  | "process.syncAll"
  | "process.synced.message"
  | "process.synced.title"
  | "process.system"
  | "process.taskListReport"
  | "process.taskListReportWithHistory"
  | "process.title"
  | "process.updated.message"
  | "process.updated.title"
  | "process.warnConfigureOnParent"
  | "processAction.create"
  | "processAction.delete.confirm"
  | "processAction.deleted.message"
  | "processAction.deleted.title"
  | "processAction.edit"
  | "processAction.linkage.attach.confirm"
  | "processAction.linkage.attach.error"
  | "processAction.linkage.attach.success"
  | "processAction.linkage.detach.confirm"
  | "processAction.linkage.detach.error"
  | "processAction.linkage.detach.success"
  | "processAction.linkage.dialog.missingProcesses"
  | "processAction.linkage.dialog.processes"
  | "processAction.linkage.dialog.title"
  | "processAction.linkage.title"
  | "processAction.manage"
  | "processAction.operation.create.title"
  | "processAction.operation.edit.title"
  | "processAction.title"
  | "processActions.addToList.infoDetail"
  | "processActions.createDelegation.infoDetail"
  | "processActions.createProcess.infoDetail"
  | "processActions.removeFromList.behaviorType.All"
  | "processActions.removeFromList.behaviorType.AllWithSharing"
  | "processActions.removeFromList.behaviorType.CollectionOrTemplate"
  | "processActions.removeFromList.behaviorType.Current"
  | "processActions.removeFromList.behaviorType.SharedToOtherEmployers"
  | "processActions.removeFromList.behaviorType.title"
  | "processActions.removeFromList.infoDetail"
  | "processEvents.placeholder"
  | "processEvents.required"
  | "processEvents.title"
  | "processInstanceState.title"
  | "processModel.activation"
  | "processModel.additional.title"
  | "processModel.advanced.description"
  | "processModel.advanced.title"
  | "processModel.allowedValues.emptyMessage"
  | "processModel.allowedValues.placeholder"
  | "processModel.allowedValues.required"
  | "processModel.allowedValues.title"
  | "processModel.attachDownloadableTaskFilesToEmails.title"
  | "processModel.attachmentsAllowedOnTopLevel.title"
  | "processModel.autoStateChanges.condition.formatted"
  | "processModel.autoStateChanges.condition.title"
  | "processModel.autoStateChanges.conditions.daysStateUnchanged.required"
  | "processModel.autoStateChanges.conditions.daysStateUnchanged.suffix"
  | "processModel.autoStateChanges.conditions.daysStateUnchanged.title"
  | "processModel.autoStateChanges.conditions.title"
  | "processModel.autoStateChanges.create.title"
  | "processModel.autoStateChanges.edit.title"
  | "processModel.autoStateChanges.emptyMessage"
  | "processModel.autoStateChanges.mutations.title"
  | "processModel.autoStateChanges.title"
  | "processModel.autoUpdate.title"
  | "processModel.baseModel"
  | "processModel.cancelLabel.placeholder"
  | "processModel.cancelLabel.title"
  | "processModel.checkRequiredDocuments.atLeastOneDocumentRequired"
  | "processModel.checkRequiredDocuments.title"
  | "processModel.commandHistory.BulkTranslate"
  | "processModel.commandHistory.CreateArea"
  | "processModel.commandHistory.CreatePhase"
  | "processModel.commandHistory.CreateTask"
  | "processModel.commandHistory.DeleteArea"
  | "processModel.commandHistory.DeletePhase"
  | "processModel.commandHistory.DeleteTask"
  | "processModel.commandHistory.DuplicateArea"
  | "processModel.commandHistory.DuplicatePhase"
  | "processModel.commandHistory.DuplicateTask"
  | "processModel.commandHistory.MoveArea"
  | "processModel.commandHistory.MovePhase"
  | "processModel.commandHistory.MoveTask"
  | "processModel.commandHistory.TranslateArea"
  | "processModel.commandHistory.TranslatePhase"
  | "processModel.commandHistory.TranslateProcess"
  | "processModel.commandHistory.TranslateTask"
  | "processModel.commandHistory.UpdateArea"
  | "processModel.commandHistory.UpdatePhase"
  | "processModel.commandHistory.UpdateTask"
  | "processModel.confirmCreateRelease"
  | "processModel.confirmCreateReleaseAndPublish"
  | "processModel.confirmDelete"
  | "processModel.confirmDeleteRelease"
  | "processModel.confirmPublish"
  | "processModel.confirmUnpublish"
  | "processModel.confirmUpdateRelease"
  | "processModel.create"
  | "processModel.createRelease.failed"
  | "processModel.createRelease.success"
  | "processModel.createRelease.title"
  | "processModel.createReleaseAndPublish.success"
  | "processModel.createReleaseAndPublish.title"
  | "processModel.created.message"
  | "processModel.created.title"
  | "processModel.deleteRelease.failed"
  | "processModel.deleteRelease.success"
  | "processModel.deleteRelease.title"
  | "processModel.deleted.message"
  | "processModel.deleted.title"
  | "processModel.derivedFrom"
  | "processModel.displayTexts.title"
  | "processModel.doNotAutoFinishTaskInCaseAllRequirementsFullfilled.title"
  | "processModel.doNotStartNewTasksWhoseSuccessorsAreAlreadyFinished"
  | "processModel.documents.add"
  | "processModel.documents.edit"
  | "processModel.documents.title"
  | "processModel.dontSendEmails.title"
  | "processModel.draftMode"
  | "processModel.edit"
  | "processModel.emptyTree"
  | "processModel.fieldsToExcludeFromSync.title"
  | "processModel.finishAllTasksWhoseSuccessorsAreAlreadyFinished"
  | "processModel.finishLabel.placeholder"
  | "processModel.finishLabel.title"
  | "processModel.finishingMode.placeholder"
  | "processModel.finishingMode.title"
  | "processModel.general.description"
  | "processModel.general.title"
  | "processModel.hideGenericTaskComment.title"
  | "processModel.history.changes"
  | "processModel.history.command"
  | "processModel.history.date"
  | "processModel.history.details"
  | "processModel.history.stageName"
  | "processModel.history.title"
  | "processModel.includeDocumentsMarkedAsNotRelevantInRequiredCount.title"
  | "processModel.isModelShared"
  | "processModel.isSelectableInProcessArea"
  | "processModel.maxUploadedDocumentsRequired.title"
  | "processModel.minUploadedDocumentsRequired.title"
  | "processModel.misc"
  | "processModel.newDerived"
  | "processModel.newEmpty"
  | "processModel.numberOfAllowedAttachments.title"
  | "processModel.organizationToken.title"
  | "processModel.other.title"
  | "processModel.participationConditions.create"
  | "processModel.participationConditions.edit"
  | "processModel.participationConditions.emptyMessage"
  | "processModel.participationConditions.placeholder"
  | "processModel.participationConditions.title"
  | "processModel.path.placeholder"
  | "processModel.path.required"
  | "processModel.path.title"
  | "processModel.placeholder"
  | "processModel.preset.activate"
  | "processModel.preset.roleMapping.create"
  | "processModel.preset.roleMapping.edit"
  | "processModel.preset.roleMapping.emptyMessage"
  | "processModel.preset.roleMapping.required"
  | "processModel.preset.roleMapping.title"
  | "processModel.preset.title"
  | "processModel.publishProcessModelAfterRelease.title"
  | "processModel.published.message"
  | "processModel.published.title"
  | "processModel.publishedVersion.title"
  | "processModel.reactivateTasksOnCancel.placeholder"
  | "processModel.reactivateTasksOnCancel.title"
  | "processModel.reactivateTasksOnFinish.placeholder"
  | "processModel.reactivateTasksOnFinish.title"
  | "processModel.reactivatedCancelLabel.placeholder"
  | "processModel.reactivatedCancelLabel.title"
  | "processModel.reactivatedFinishLabel.placeholder"
  | "processModel.reactivatedFinishLabel.title"
  | "processModel.reactivatedRequiresCancelReason.title"
  | "processModel.recalculateTaskState"
  | "processModel.required"
  | "processModel.requiredTranslations.title"
  | "processModel.requiresCancelReason.title"
  | "processModel.role.create.title"
  | "processModel.role.custom.title"
  | "processModel.role.edit.title"
  | "processModel.rootLanguage.title"
  | "processModel.rules.action"
  | "processModel.rules.create.title"
  | "processModel.rules.description"
  | "processModel.rules.edit.title"
  | "processModel.rules.emptyMessage"
  | "processModel.rules.event.placeholder"
  | "processModel.rules.event.required"
  | "processModel.rules.event.title"
  | "processModel.rules.expression.placeholder"
  | "processModel.rules.expression.required"
  | "processModel.rules.expression.title"
  | "processModel.rules.propertyName.placeholder"
  | "processModel.rules.propertyName.required"
  | "processModel.rules.propertyName.title"
  | "processModel.rules.shortDescription.placeholder"
  | "processModel.rules.shortDescription.required"
  | "processModel.rules.shortDescription.title"
  | "processModel.rules.target"
  | "processModel.rules.targetTasks.areaIds.placeholder"
  | "processModel.rules.targetTasks.areaIds.required"
  | "processModel.rules.targetTasks.areaIds.title"
  | "processModel.rules.targetTasks.excludeTaskIds.placeholder"
  | "processModel.rules.targetTasks.excludeTaskIds.title"
  | "processModel.rules.targetTasks.phaseIds.placeholder"
  | "processModel.rules.targetTasks.phaseIds.required"
  | "processModel.rules.targetTasks.phaseIds.title"
  | "processModel.rules.targetTasks.taskIds.placeholder"
  | "processModel.rules.targetTasks.taskIds.required"
  | "processModel.rules.targetTasks.taskIds.title"
  | "processModel.rules.targetTasks.type.placeholder"
  | "processModel.rules.targetTasks.type.required"
  | "processModel.rules.targetTasks.type.title"
  | "processModel.rules.targetType.placeholder"
  | "processModel.rules.targetType.required"
  | "processModel.rules.targetType.title"
  | "processModel.rules.title"
  | "processModel.rules.trigger"
  | "processModel.selectFinishByDefault.title"
  | "processModel.selectTaskType.title"
  | "processModel.settings.title"
  | "processModel.syncOptions"
  | "processModel.task.assignmentException.assignmentExceptionOperation.placeholder"
  | "processModel.task.assignmentException.assignmentExceptionOperation.required"
  | "processModel.task.assignmentException.assignmentExceptionOperation.title"
  | "processModel.task.assignmentException.assignmentExceptionType.placeholder"
  | "processModel.task.assignmentException.assignmentExceptionType.required"
  | "processModel.task.assignmentException.assignmentExceptionType.title"
  | "processModel.task.assignmentException.description"
  | "processModel.task.autoOperation.description"
  | "processModel.task.autoOperation.token.required"
  | "processModel.task.autoOperation.token.title"
  | "processModel.task.deactivateProcess.description"
  | "processModel.task.documentCheck.description"
  | "processModel.task.documentCheck.documentCheckType.placeholder"
  | "processModel.task.documentCheck.documentCheckType.required"
  | "processModel.task.documentCheck.documentCheckType.title"
  | "processModel.task.documentCheck.lockDocumentsAfterReviewForIds.placeholder"
  | "processModel.task.documentCheck.lockDocumentsAfterReviewForIds.required"
  | "processModel.task.documentCheck.lockDocumentsAfterReviewForIds.title"
  | "processModel.task.documentCheck.numberOfReviewers.required"
  | "processModel.task.documentCheck.numberOfReviewers.suffix"
  | "processModel.task.documentCheck.numberOfReviewers.title"
  | "processModel.task.documentCheck.referenceTaskUploadModelIds.placeholder"
  | "processModel.task.documentCheck.referenceTaskUploadModelIds.required"
  | "processModel.task.documentCheck.referenceTaskUploadModelIds.title"
  | "processModel.task.emailSend.description"
  | "processModel.task.enterMissingInformation.availableFields.title"
  | "processModel.task.enterMissingInformation.canAdd.title"
  | "processModel.task.enterMissingInformation.canRemove.title"
  | "processModel.task.enterMissingInformation.description"
  | "processModel.task.enterMissingInformation.requestedInformation.required"
  | "processModel.task.enterMissingInformation.requiredInformation.canAdd.title"
  | "processModel.task.enterMissingInformation.requiredInformation.canRemove.title"
  | "processModel.task.enterMissingInformation.requiredInformation.children.title"
  | "processModel.task.enterMissingInformation.requiredInformation.isHidden.title"
  | "processModel.task.enterMissingInformation.requiredInformation.isOptional.title"
  | "processModel.task.enterMissingInformation.requiredInformation.isRequired.title"
  | "processModel.task.enterMissingInformation.requiredInformation.placeholder"
  | "processModel.task.enterMissingInformation.requiredInformation.propertyPath.title"
  | "processModel.task.enterMissingInformation.requiredInformation.required"
  | "processModel.task.enterMissingInformation.requiredInformation.title"
  | "processModel.task.enterMissingInformation.selectedFields.title"
  | "processModel.task.enterMissingInformation.skipOnAlreadyEntered.title"
  | "processModel.task.informationCollection.confirmationMode.confirmation"
  | "processModel.task.informationCollection.confirmationMode.edit"
  | "processModel.task.informationCollection.confirmationMode.placeholder"
  | "processModel.task.informationCollection.confirmationMode.title"
  | "processModel.task.informationCollection.description"
  | "processModel.task.informationCollection.prefillTaskId.placeholder"
  | "processModel.task.informationCollection.prefillTaskId.title"
  | "processModel.task.informationCollection.token.placeholder"
  | "processModel.task.informationCollection.token.required"
  | "processModel.task.informationCollection.token.title"
  | "processModel.task.inviteToPlatform.description"
  | "processModel.task.organizationAudit.acceptFileTypes.hint"
  | "processModel.task.organizationAudit.acceptFileTypes.title"
  | "processModel.task.organizationAudit.auditDefinitionName.required"
  | "processModel.task.organizationAudit.auditDefinitionName.title"
  | "processModel.task.organizationAudit.auditDefinitionName23.required"
  | "processModel.task.organizationAudit.auditDefinitionName23.title"
  | "processModel.task.organizationAudit.auditDefinitionName24.required"
  | "processModel.task.organizationAudit.auditDefinitionName24.title"
  | "processModel.task.organizationAudit.auditMode.placeholder"
  | "processModel.task.organizationAudit.auditMode.required"
  | "processModel.task.organizationAudit.auditMode.title"
  | "processModel.task.organizationAudit.description"
  | "processModel.task.organizationAudit.maxFileSize.suffix"
  | "processModel.task.organizationAudit.maxFileSize.title"
  | "processModel.task.organizationAudit.processAuditGroupName.required"
  | "processModel.task.organizationAudit.processAuditGroupName.title"
  | "processModel.task.organizationAudit.processDeadlineInDays.suffix"
  | "processModel.task.organizationAudit.processDeadlineInDays.title"
  | "processModel.task.organizationAudit.recruitingConceptTaskId.placeholder"
  | "processModel.task.organizationAudit.recruitingConceptTaskId.required"
  | "processModel.task.organizationAudit.recruitingConceptTaskId.title"
  | "processModel.task.pdfFormGeneration.description"
  | "processModel.task.pdfFormGeneration.tokens.placeholder"
  | "processModel.task.pdfFormGeneration.tokens.required"
  | "processModel.task.pdfFormGeneration.tokens.title"
  | "processModel.task.reminder.description"
  | "processModel.task.reminder.reminderDate.minDate"
  | "processModel.task.reminder.reminderDate.title"
  | "processModel.task.roleSetup.description"
  | "processModel.task.roleSetup.setupRoles.placeholder"
  | "processModel.task.roleSetup.setupRoles.required"
  | "processModel.task.roleSetup.setupRoles.title"
  | "processModel.task.sendOutInformation.attachmentTasks.placeholder"
  | "processModel.task.sendOutInformation.attachmentTasks.title"
  | "processModel.task.sendOutInformation.candidateDocuments.emptyMessage"
  | "processModel.task.sendOutInformation.candidateDocuments.requiredSets"
  | "processModel.task.sendOutInformation.candidateDocuments.title"
  | "processModel.task.sendOutInformation.createDownloadableArchiveWithLink.title"
  | "processModel.task.sendOutInformation.description"
  | "processModel.task.sendOutInformation.noReplyEmailConfigurationWarning"
  | "processModel.task.sendOutInformation.noReplyRequiresAdditionalOptions"
  | "processModel.task.sendOutInformation.organizationDocuments.emptyMessage"
  | "processModel.task.sendOutInformation.organizationDocuments.organizationRole"
  | "processModel.task.sendOutInformation.organizationDocuments.title"
  | "processModel.task.triggerEvent.description"
  | "processModel.task.triggerEvent.processEvent.placeholder"
  | "processModel.task.triggerEvent.processEvent.required"
  | "processModel.task.triggerEvent.processEvent.title"
  | "processModel.task.updateCandidateProfile.create.title"
  | "processModel.task.updateCandidateProfile.description"
  | "processModel.task.updateCandidateProfile.edit.title"
  | "processModel.task.updateCandidateProfile.expression.placeholder"
  | "processModel.task.updateCandidateProfile.expression.required"
  | "processModel.task.updateCandidateProfile.expression.title"
  | "processModel.task.updateCandidateProfile.properties.emptyMessage"
  | "processModel.task.updateCandidateProfile.properties.required"
  | "processModel.task.updateCandidateProfile.properties.title"
  | "processModel.task.upload.attachmentsDisplayText.title"
  | "processModel.task.upload.description"
  | "processModel.task.upload.documentId.placeholder"
  | "processModel.task.upload.documentId.required"
  | "processModel.task.upload.documentId.title"
  | "processModel.task.upload.documentPurpose.placeholder"
  | "processModel.task.upload.documentPurpose.title"
  | "processModel.task.upload.documentSet.placeholder"
  | "processModel.task.upload.documentSet.title"
  | "processModel.task.upload.documentTypesToUpload.emptyMessage"
  | "processModel.task.upload.documentTypesToUpload.title"
  | "processModel.task.upload.downloads.title"
  | "processModel.task.upload.minfileFormatsRequired.placeholder"
  | "processModel.task.upload.minfileFormatsRequired.title"
  | "processModel.task.upload.preConfiguredAllowedDocumentFormats.placeholder"
  | "processModel.task.upload.preConfiguredAllowedDocumentFormats.title"
  | "processModel.task.upload.preConfiguredDocumentFormatOverwrite.placeholder"
  | "processModel.task.upload.preConfiguredDocumentFormatOverwrite.title"
  | "processModel.task.upload.preConfiguredFilterForCandidate.title"
  | "processModel.task.upload.processDocumentsToUpload.emptyMessage"
  | "processModel.task.upload.processDocumentsToUpload.title"
  | "processModel.task.upload.requireNewUpload.title"
  | "processModel.task.upload.uploads.title"
  | "processModel.task.upload.usePreConfiguredDocumentTypes.title"
  | "processModel.task.work.description"
  | "processModel.taskActivation.title"
  | "processModel.taskCompletion.title"
  | "processModel.taskEmails.create.title"
  | "processModel.taskEmails.description"
  | "processModel.taskEmails.edit.title"
  | "processModel.taskEmails.emptyMessage"
  | "processModel.taskEmails.matchedRolesOnly.title"
  | "processModel.taskEmails.recipientRoles.placeholder"
  | "processModel.taskEmails.recipientRoles.required"
  | "processModel.taskEmails.recipientRoles.title"
  | "processModel.taskEmails.state.placeholder"
  | "processModel.taskEmails.state.required"
  | "processModel.taskEmails.state.title"
  | "processModel.taskEmails.template.placeholder"
  | "processModel.taskEmails.template.required"
  | "processModel.taskEmails.template.title"
  | "processModel.taskEmails.title"
  | "processModel.taskReactivation.title"
  | "processModel.taskResponsibilities.title"
  | "processModel.title"
  | "processModel.tree.refresh.success"
  | "processModel.treeNode.copyNode.confirm"
  | "processModel.treeNode.copyNode.failed"
  | "processModel.treeNode.copyNode.success"
  | "processModel.treeNode.delete.confirm"
  | "processModel.treeNode.delete.failed"
  | "processModel.treeNode.delete.success"
  | "processModel.treeNode.move.failed"
  | "processModel.treeNode.move.success"
  | "processModel.treeNode.new.area"
  | "processModel.treeNode.new.phase"
  | "processModel.treeNode.new.task"
  | "processModel.treeNode.placeholder"
  | "processModel.treeNode.reset.confirm"
  | "processModel.treeNode.undo.confirm"
  | "processModel.treeNode.undo.failed"
  | "processModel.treeNode.undo.success"
  | "processModel.unpublished.message"
  | "processModel.unpublished.title"
  | "processModel.updateRelease.failed"
  | "processModel.updateRelease.success"
  | "processModel.updateRelease.title"
  | "processModel.updated.message"
  | "processModel.updated.title"
  | "processModel.validate.failed"
  | "processModel.validate.success"
  | "processModel.validationErrors"
  | "processModel.version"
  | "processModelId.placeholder"
  | "processModelId.required"
  | "processModelId.title"
  | "processModelItemEditor.command.failed"
  | "processModelItemEditor.command.success"
  | "processModelService.applyCommand.failed"
  | "processModelService.undoCommand.failed"
  | "processModels.manage"
  | "processModels.title"
  | "processOperations.confirmDelete"
  | "processOperations.confirmLeave"
  | "processOperations.placeholder"
  | "processOperations.required"
  | "processOperations.title"
  | "processStatus.title"
  | "processType.placeholder"
  | "processes.edit"
  | "processes.export"
  | "processes.title"
  | "processes.view"
  | "profession.code"
  | "profession.default"
  | "profession.highestProfession"
  | "profession.original"
  | "profession.placeholder"
  | "profession.required"
  | "profession.title"
  | "professionArea.placeholder"
  | "professionArea.title"
  | "professionSubArea.placeholder"
  | "professionSubArea.title"
  | "professionalCertificate.title"
  | "professionalCertificateReceiveDate.title"
  | "professionalCertificateReceived.title"
  | "professionalCertificateRequestDate.title"
  | "professionalCertificateRequested.title"
  | "professionalDriver.title"
  | "professionalExperience.confirmDelete"
  | "professionalExperience.create"
  | "professionalExperience.created.message"
  | "professionalExperience.created.title"
  | "professionalExperience.deleted.message"
  | "professionalExperience.deleted.title"
  | "professionalExperience.edit"
  | "professionalExperience.updated.message"
  | "professionalExperience.updated.title"
  | "professionalExperiences.forKnowledgeField"
  | "professionalExperiences.forSkillCompetenceField"
  | "professionalExperiences.title"
  | "professionalField.confirmDelete"
  | "professionalField.create"
  | "professionalField.created.message"
  | "professionalField.created.title"
  | "professionalField.deleted.message"
  | "professionalField.deleted.title"
  | "professionalField.edit"
  | "professionalField.updated.message"
  | "professionalField.updated.title"
  | "professionalFieldSettings.standardCompetencesProfessionIdBlackList.title"
  | "professionalFieldSettings.standardKnowledgeProfessionIdBlackList.title"
  | "professionalFieldSettings.updated.message"
  | "professionalFieldSettings.updated.title"
  | "professionalFields.categories"
  | "professionalFields.title"
  | "professionalTitle.title"
  | "professionalTitle.tooltip"
  | "professionalTrainings.title"
  | "professions.my"
  | "professions.saved"
  | "proficiency.placeholder"
  | "proficiency.title"
  | "profileAccess.SelectStatus"
  | "profileAccess.confirmDelete.message"
  | "profileAccess.confirmDelete.title"
  | "profileAccess.create"
  | "profileAccess.created.message"
  | "profileAccess.created.title"
  | "profileAccess.deleted.message"
  | "profileAccess.deleted.title"
  | "profileAccess.documentUpload.title"
  | "profileAccess.edit"
  | "profileAccess.enabled"
  | "profileAccess.fieldName"
  | "profileAccess.mandatory"
  | "profileAccess.statusRequired"
  | "profileAccess.title"
  | "profileAccess.titlePartner"
  | "profileAccess.updated.message"
  | "profileAccess.updated.title"
  | "profileAccess.visible"
  | "profileDataEditStatus.placeholder"
  | "profileDataEditStatus.title"
  | "profileEditor.deleted.confirmation"
  | "profileEditor.deleted.message"
  | "profileEditor.deleted.title"
  | "profileEditor.profileCreatedMessage"
  | "profileEditor.updated.confirmationUpdate"
  | "profileEditor.updated.message"
  | "profileEditor.updated.title"
  | "progress.candidate"
  | "progress.overall"
  | "progress.status"
  | "property.title"
  | "psaFirstDeputy.placeholder"
  | "psaFirstDeputy.required"
  | "psaFirstDeputy.title"
  | "psaFirstDeputy2.firstName"
  | "psaFirstDeputy2.id"
  | "psaFirstDeputy2.lastName"
  | "psaRepresentative.placeholder"
  | "psaRepresentative.required"
  | "psaRepresentative.title"
  | "psaRepresentative2.firstName"
  | "psaRepresentative2.id"
  | "psaRepresentative2.lastName"
  | "public.title"
  | "publishedAt.title"
  | "publishedBy.title"
  | "publishedRevision.title"
  | "qualification.add"
  | "qualification.code"
  | "qualification.degree"
  | "qualification.description"
  | "qualification.endMonth"
  | "qualification.endYear"
  | "qualification.period"
  | "qualification.placeholder"
  | "qualification.remove"
  | "qualification.startMonth"
  | "qualification.startYear"
  | "qualification.title"
  | "qualification.unavailable"
  | "qualificationEvaluation.at"
  | "qualificationEvaluation.confirm"
  | "qualificationEvaluation.de"
  | "qualificationEvaluation.title"
  | "qualificationEvaluationAdd.title"
  | "qualificationEvaluationByCentralDepartment.title"
  | "qualificationEvaluationByCentralDepartmentReceiveDate.title"
  | "qualificationEvaluationByCentralDepartmentReceived.title"
  | "qualificationEvaluationByFederalStateAuthority.title"
  | "qualificationEvaluationByFederalStateAuthorityReceiveDate.title"
  | "qualificationEvaluationByFederalStateAuthorityReceived.title"
  | "qualificationEvaluationFileNumber.placeholder"
  | "qualificationEvaluationFileNumber.title"
  | "qualificationEvaluationFinished.title"
  | "qualificationEvaluationFinishedDate.title"
  | "qualificationEvaluationFirstDeputy.placeholder"
  | "qualificationEvaluationFirstDeputy.required"
  | "qualificationEvaluationFirstDeputy.title"
  | "qualificationEvaluationNoticeKind.placeholder"
  | "qualificationEvaluationNoticeKind.title"
  | "qualificationEvaluationRejection.title"
  | "qualificationEvaluationRejectionReceiveDate.title"
  | "qualificationEvaluationRejectionReceived.title"
  | "qualificationEvaluationRepresentative.placeholder"
  | "qualificationEvaluationRepresentative.required"
  | "qualificationEvaluationRepresentative.title"
  | "qualificationEvaluationRequest.title"
  | "qualificationEvaluationRequested.title"
  | "qualificationEvaluationRequestedDate.title"
  | "qualificationEvaluationResponsible.title"
  | "qualificationEvaluations.title"
  | "qualificationEvaluationsDE.title"
  | "qualificationIsDone.title"
  | "qualificationIsDoneDate.title"
  | "qualificationIsSend.title"
  | "qualificationIsSendDate.title"
  | "qualificationMeasure.at"
  | "qualificationMeasure.code"
  | "qualificationMeasure.de"
  | "qualificationMeasure.placeholder"
  | "qualificationMeasure.required"
  | "qualificationMeasure.title"
  | "qualificationMeasureFileNumber.title"
  | "qualificationModule.add"
  | "qualificationModule.placeholder"
  | "qualificationModule.practiceHours.required"
  | "qualificationModule.practiceHours.shortTitle"
  | "qualificationModule.practiceHours.title"
  | "qualificationModule.required"
  | "qualificationModule.theoryHours.required"
  | "qualificationModule.theoryHours.shortTitle"
  | "qualificationModule.theoryHours.title"
  | "qualificationModule.title"
  | "qualificationModule.totalHours.title"
  | "qualificationModules.caption"
  | "qualificationModules.title"
  | "qualificationParticipation.title"
  | "qualificationType.code"
  | "qualificationType.placeholder"
  | "qualificationType.title"
  | "qualifications.title"
  | "readonly.title"
  | "received.title"
  | "recipient.title"
  | "recipientRoles.title"
  | "recogNoticeAuthority.placeholder"
  | "recogNoticeAuthority.required"
  | "recogNoticeAuthority.title"
  | "recognition.application.arrivalDate"
  | "recognition.application.arrived"
  | "recognition.application.filter"
  | "recognition.examCases"
  | "recognition.examCasesFull"
  | "recognition.practiceHours"
  | "recognition.practiceHoursFull"
  | "recognition.received"
  | "recognition.started"
  | "recognition.submissionDate"
  | "recognition.theoryHours"
  | "recognition.theoryHoursFull"
  | "recognition.title"
  | "recognition.titleShort"
  | "recognitionApplication.arrived"
  | "recognitionApplication.dateOfReceipt"
  | "recognitionApplication.issueDate"
  | "recognitionApplication.issueMonth"
  | "recognitionApplication.issueYear"
  | "recognitionApplication.monthOfReceipt"
  | "recognitionApplication.year"
  | "recognitionApplication.yearOfReceipt"
  | "recognitionApplicationAlternative.dateOfReceipt"
  | "recognitionApplicationAlternative.dateOfReceiptYear"
  | "recognitionApplicationAlternative.federalState"
  | "recognitionApplicationAlternative.fileNumber"
  | "recognitionApplicationAlternative.issueDate"
  | "recognitionApplicationAlternative.issueDateYear"
  | "recognitionApplicationAlternative.month"
  | "recognitionApplicationAlternative.noticeReceived"
  | "recognitionApplicationAlternative.recognitionAuthority"
  | "recognitionApplicationAlternative.recognitionNoticeKindAlternative"
  | "recognitionApplicationAlternative.startDate"
  | "recognitionApplicationAlternative.startYear"
  | "recognitionApplicationAlternative.year"
  | "recognitionAuthority.id"
  | "recognitionAuthority.locationId"
  | "recognitionAuthority.region"
  | "recognitionAuthority.respId"
  | "recognitionAuthority.respState"
  | "recognitionAuthority.state"
  | "recognitionAuthority.stateAbbr"
  | "recognitionAuthority.stateReglementation"
  | "recognitionAuthority.title"
  | "recognitionAuthority.unused"
  | "recognitionCenter.placeholder"
  | "recognitionCenter.required"
  | "recognitionCenter.title"
  | "recognitionContactPerson.default"
  | "recognitionDocument.title"
  | "recognitionMedicalCertificate.title"
  | "recognitionNoticeKind.candidatePresentation.deficit"
  | "recognitionNoticeKind.candidatePresentation.full"
  | "recognitionNoticeKind.candidatePresentation.notAvailable"
  | "recognitionNoticeKind.candidatePresentation.rejection"
  | "recognitionNoticeKind.code"
  | "recognitionNoticeKind.deprecated"
  | "recognitionNoticeKind.info"
  | "recognitionNoticeKind.placeholder"
  | "recognitionNoticeKind.title"
  | "recognitionOrDeficitNoteReceiveDate.title"
  | "recognitionOrDeficitNoteReceived.title"
  | "recognitionPartnership.placeholder"
  | "recognitionPartnership.required"
  | "recognitionPartnership.title"
  | "recognitionPath.at"
  | "recognitionPath.de"
  | "recognitionPath.title"
  | "recognitionPaymentRole.placeholder"
  | "recognitionPaymentRole.required"
  | "recognitionPaymentRole.title"
  | "recognitionPaymentRoleAccelerated.placeholder"
  | "recognitionPaymentRoleAccelerated.required"
  | "recognitionPaymentRoleAccelerated.title"
  | "recognitionReceiveDate.title"
  | "recognitionReceived.title"
  | "recognitionReimbursement.title"
  | "recognitionRejection.title"
  | "recognitionRejectionReceiveDate.title"
  | "recognitionRejectionReceived.shortTitle"
  | "recognitionRejectionReceived.title"
  | "recognitionStartDate.title"
  | "recognitionStarted.title"
  | "recognitionType.key"
  | "recognitionType.placeholder"
  | "recognitionType.required"
  | "recognitionType.title"
  | "region.placeholder"
  | "region.title"
  | "registrationAuthority.title"
  | "registrationAuthorityAppointmentNumber.title"
  | "registrationOfficeDate.required"
  | "registrationOfficeDate.title"
  | "regulatoryReimbursement.title"
  | "relation.code"
  | "relation.placeholder"
  | "relation.required"
  | "relation.title"
  | "releaseHistory.noHistory"
  | "releaseHistory.title"
  | "releasedAt.title"
  | "releasedBy.title"
  | "relocationRequirements.placeholder"
  | "relocationRequirements.title"
  | "reminderDate.required"
  | "reminderDate.title"
  | "reminderSettings.title"
  | "replacedByCandidate.title"
  | "replacementForCandidate.placeholder"
  | "replacementForCandidate.title"
  | "reply.title"
  | "replyType.placeholder"
  | "replyType.required"
  | "replyType.title"
  | "representative.addFromUsers"
  | "representative.default"
  | "representative.placeholder"
  | "representative.required"
  | "representative.title"
  | "representative2.firstName"
  | "representative2.id"
  | "representative2.lastName"
  | "required"
  | "requiredCandidateFields.placeholder"
  | "requiredCandidateFields.title"
  | "requiredDocument.add"
  | "requiredDocument.atLeastOne"
  | "requiredDocument.confirmDelete"
  | "requiredDocument.created.at"
  | "requiredDocument.created.message"
  | "requiredDocument.created.title"
  | "requiredDocument.deleted.cant"
  | "requiredDocument.deleted.message"
  | "requiredDocument.deleted.title"
  | "requiredDocument.description"
  | "requiredDocument.digitalOnly"
  | "requiredDocument.duplicate"
  | "requiredDocument.edit"
  | "requiredDocument.menuTitle"
  | "requiredDocument.name"
  | "requiredDocument.notNecessary"
  | "requiredDocument.numberOfCopies"
  | "requiredDocument.onlyAll"
  | "requiredDocument.onlyOne"
  | "requiredDocument.overview.title"
  | "requiredDocument.overview.tooManyCandidates"
  | "requiredDocument.placeholder"
  | "requiredDocument.removeRowMessage"
  | "requiredDocument.requiredFormat"
  | "requiredDocument.selectFormat"
  | "requiredDocument.title"
  | "requiredDocument.titleAT"
  | "requiredDocument.titleDE"
  | "requiredDocument.updated.message"
  | "requiredDocument.updated.title"
  | "requiredStaff.placeholder"
  | "requiredStaff.required"
  | "requiredStaff.title"
  | "requiredVaccines.confirmDelete.title"
  | "requiredVaccines.deleted.message"
  | "requiredVaccines.deleted.title"
  | "requiredVaccines.errors.duplicate"
  | "requiredVaccines.errors.maxLengthProfession"
  | "requiredVaccines.errors.onlyAll"
  | "requiredVaccines.mandatoryVaccines.placeholder"
  | "requiredVaccines.mandatoryVaccines.required"
  | "requiredVaccines.mandatoryVaccines.title"
  | "requiredVaccines.notNecessary.title"
  | "requiredVaccines.overview.title"
  | "requiredVaccines.overview.tooManyCandidates"
  | "requiredVaccines.overview.tooltips.available"
  | "requiredVaccines.overview.tooltips.missing"
  | "requiredVaccines.overview.tooltips.missingNotRequired"
  | "requiredVaccines.overview.tooltips.notShared"
  | "requiredVaccines.overview.tooltips.partial"
  | "requiredVaccines.title"
  | "requiredVaccines.updated.message"
  | "requiredVaccines.updated.title"
  | "requiredVaccines.vaccineFunction.placeholder"
  | "requiredVaccines.vaccineFunction.required"
  | "requiredVaccines.vaccineFunction.title"
  | "residence.at"
  | "residence.de"
  | "residence.title"
  | "residenceAndEntry.title"
  | "residenceBank.title"
  | "residenceBankContactPartner.title"
  | "residenceEntry.title"
  | "residenceFirstDeputy.placeholder"
  | "residenceFirstDeputy.prefix"
  | "residenceFirstDeputy.required"
  | "residenceFirstDeputy.title"
  | "residencePermit.errors.outdated"
  | "residencePermit.period"
  | "residencePermit.placeholder"
  | "residencePermit.required"
  | "residencePermit.title"
  | "residencePermitAdd.title"
  | "residencePermitDocument.title"
  | "residencePermitIssueDate.month"
  | "residencePermitIssueDate.title"
  | "residencePermitIssueDate.year"
  | "residencePermitIssued.title"
  | "residencePermitReceived.title"
  | "residencePermitValidFromDate.month"
  | "residencePermitValidFromDate.title"
  | "residencePermitValidFromDate.year"
  | "residencePermitValidUntilDate.month"
  | "residencePermitValidUntilDate.title"
  | "residencePermitValidUntilDate.year"
  | "residencePermits.fictionalCertificate.title"
  | "residencePermits.residencePermitDocument.title"
  | "residencePermits.title"
  | "residenceRepresentative.placeholder"
  | "residenceRepresentative.prefix"
  | "residenceRepresentative.required"
  | "residenceRepresentative.title"
  | "residenceRequestedByPostDate.title"
  | "residenceRequestedElectronicallyDate.title"
  | "residentSince.placeholder"
  | "residentSince.title"
  | "responsibilities.title"
  | "responsibleAuthorityForQualification.placeholder"
  | "responsibleAuthorityForQualification.required"
  | "responsibleAuthorityForQualification.title"
  | "responsibleAuthorityForQualificationInOrigin.placeholder"
  | "responsibleAuthorityForQualificationInOrigin.required"
  | "responsibleAuthorityForQualificationInOrigin.title"
  | "responsibleEducationVoucherOrganization.placeholder"
  | "responsibleEducationVoucherOrganization.required"
  | "responsibleEducationVoucherOrganization.title"
  | "responsibleForTransfer.info"
  | "responsibleForTransfer.title"
  | "responsibleOrganization.placeholder"
  | "responsibleOrganization.required"
  | "responsibleOrganization.title"
  | "responsibleQualificationEvaluationOrganization.placeholder"
  | "responsibleQualificationEvaluationOrganization.required"
  | "responsibleQualificationEvaluationOrganization.title"
  | "responsibleRecognitionOrganization.placeholder"
  | "responsibleRecognitionOrganization.required"
  | "responsibleRecognitionOrganization.title"
  | "responsibleRecognitionOrganization.warningContactsNotShared"
  | "responsibleResidenceOrganization.placeholder"
  | "responsibleResidenceOrganization.required"
  | "responsibleResidenceOrganization.title"
  | "responsibleRoleComplementaryMeasure.placeholder"
  | "responsibleRoleComplementaryMeasure.required"
  | "responsibleRoleComplementaryMeasure.title"
  | "responsibleRoleEducationVoucher.otherRole"
  | "responsibleRoleEducationVoucher.placeholder"
  | "responsibleRoleEducationVoucher.required"
  | "responsibleRoleEducationVoucher.title"
  | "responsibleRoleHousingAcquisition.otherRole"
  | "responsibleRoleHousingAcquisition.placeholder"
  | "responsibleRoleHousingAcquisition.required"
  | "responsibleRoleHousingAcquisition.title"
  | "responsibleRoleLaborMarket.placeholder"
  | "responsibleRoleLaborMarket.required"
  | "responsibleRoleLaborMarket.title"
  | "responsibleRolePayment.placeholder"
  | "responsibleRolePayment.required"
  | "responsibleRolePayment.title"
  | "responsibleRoleQualification.placeholder"
  | "responsibleRoleQualification.required"
  | "responsibleRoleQualification.title"
  | "responsibleRoleQualificationEvaluation.placeholder"
  | "responsibleRoleQualificationEvaluation.required"
  | "responsibleRoleQualificationEvaluation.title"
  | "responsibleRoleQualificationEvaluationPayment.placeholder"
  | "responsibleRoleQualificationEvaluationPayment.required"
  | "responsibleRoleQualificationEvaluationPayment.title"
  | "responsibleRoleRWRCard.placeholder"
  | "responsibleRoleRWRCard.required"
  | "responsibleRoleRWRCard.title"
  | "responsibleRoleRecognition.placeholder"
  | "responsibleRoleRecognition.required"
  | "responsibleRoleRecognition.title"
  | "responsibleRoleResidence.placeholder"
  | "responsibleRoleResidence.required"
  | "responsibleRoleResidence.title"
  | "responsibleRoleScholarship.otherRole"
  | "responsibleRoleScholarship.placeholder"
  | "responsibleRoleScholarship.required"
  | "responsibleRoleScholarship.title"
  | "responsibleRoleVisa.placeholder"
  | "responsibleRoleVisa.required"
  | "responsibleRoleVisa.title"
  | "responsibleRoles.currentUserMustBeInOneOfTheRoles"
  | "responsibleRoles.placeholder"
  | "responsibleRoles.required"
  | "responsibleRoles.title"
  | "resubmissionDate.asDate"
  | "resubmissionDate.title"
  | "resubmissionReason.title"
  | "result.title"
  | "reunification.message"
  | "reunification.shortTitle"
  | "reunification.title"
  | "revisionNumber.title"
  | "revisionNumber.toBePublished.required"
  | "revisionNumber.toBePublished.title"
  | "role.placeholder"
  | "role.required"
  | "role.title"
  | "roleMapping.title"
  | "roles.isOptional"
  | "roles.placeholder"
  | "roles.processRole.required"
  | "roles.processRole.title"
  | "roles.processRole.unique"
  | "roles.required"
  | "roles.setCreatorOrgAsDefault"
  | "roles.title"
  | "roles.visibleStates"
  | "runningNumberToken.placeholder"
  | "runningNumberToken.title"
  | "rwr.fileNumber"
  | "rwr.received"
  | "rwr.receivedCardDate"
  | "rwr.receivedDate"
  | "rwr.requestDate"
  | "rwr.requested"
  | "rwr.resonsible"
  | "rwr.validFrom"
  | "rwr.validUnitl"
  | "rwrCard.title"
  | "rwrCardFileNumber.title"
  | "rwrCardReceived.title"
  | "rwrCardReceivedDate.title"
  | "rwrCardValidFromDate.title"
  | "rwrCardValidUntilDate.title"
  | "rwrRequested.title"
  | "rwrRequestedDate.title"
  | "salary.title"
  | "salesTableTypes.AllCandidatesTable"
  | "salesTableTypes.CandidateCountPerStateOverTime"
  | "salesTableTypes.CandidateStatusMovementOverTime"
  | "salesTableTypes.CandidateStatusMovementOverTimeNew"
  | "salesTableTypes.CandidateTable"
  | "salesTableTypes.CollectionTable"
  | "salesTableTypes.DocumentTable"
  | "salesTableTypes.GueteSiegelTable"
  | "salesTableTypes.LoginTable"
  | "salesTableTypes.OrganizationMappingTable"
  | "salesTableTypes.OrganizationTable"
  | "salesTableTypes.UserTable"
  | "salutation.code"
  | "salutation.mr"
  | "salutation.mrs"
  | "salutation.mx"
  | "salutation.placeholder"
  | "salutation.required"
  | "salutation.title"
  | "salutationInPassport.placeholder"
  | "salutationInPassport.title"
  | "sameAddressAsCandidate.title"
  | "scope.placeholder"
  | "scope.title"
  | "secondContractExtension.title"
  | "secondExamDate.month"
  | "secondExamDate.title"
  | "secondExamDate.year"
  | "secondExamPassed.placeholder"
  | "secondExamPassed.title"
  | "secondFinalInterview.title"
  | "secondFinalInterviewPassed.placeholder"
  | "secondFinalInterviewPassed.title"
  | "selectedPosition.placeholder"
  | "selectedPosition.required"
  | "selectedPosition.title"
  | "selectionCriteria.configure"
  | "selectionCriteria.placeholder"
  | "sendMail.recipientRoles.title"
  | "sendMail.templateLanguage.title"
  | "sendMail.templateName.title"
  | "sepa.title"
  | "sepaOrSwift.title"
  | "setsAvailableForCandidates.title"
  | "settings.title"
  | "severity.error"
  | "severity.info"
  | "severity.success"
  | "severity.warn"
  | "shareContacts.title"
  | "shareContactsOfMyPartners.title"
  | "shareContactsOfMyPartners.titleShort"
  | "shareContractTemplates.title"
  | "shareContractTemplatesOfMyPartners.title"
  | "shareContractTemplatesOfMyPartners.titleShort"
  | "shareData.title"
  | "shareEmploymentRelationship.title"
  | "shareEmploymentRelationshipOfMyPartners.title"
  | "shareEmploymentRelationshipOfMyPartners.titleShort"
  | "sharePartners.title"
  | "sharePartnersOfMyPartners.title"
  | "sharePartnersOfMyPartners.titleShort"
  | "sharing.autoCVName"
  | "sharing.autoCVRequest"
  | "sharing.autoCvUnavailable"
  | "sharing.autoCvUnavailableDetail"
  | "sharing.confirmDelete"
  | "sharing.confirmInternalDataSharing"
  | "sharing.confirmTabsSharing"
  | "sharing.create"
  | "sharing.created.message"
  | "sharing.created.title"
  | "sharing.cvOrganizationName"
  | "sharing.deleted.message"
  | "sharing.deleted.title"
  | "sharing.disableSubmitFeedback"
  | "sharing.edit"
  | "sharing.enableNotificationEmails"
  | "sharing.enabled"
  | "sharing.expiryDate"
  | "sharing.isAnonymous"
  | "sharing.isCandidateEditable"
  | "sharing.notificationEmailUserGroups"
  | "sharing.profileAccess"
  | "sharing.profileAccessPlaceholder"
  | "sharing.profileAccessToolTip"
  | "sharing.shareInternalDocuments"
  | "sharing.showCompletionStateAtDocuments"
  | "sharing.updated.message"
  | "sharing.updated.title"
  | "sharing.userGroupMustBeSelected"
  | "sharing.variableIsNotAllowed"
  | "sharingPreset.confirmDelete"
  | "sharingPreset.create"
  | "sharingPreset.created.message"
  | "sharingPreset.created.title"
  | "sharingPreset.deleted.message"
  | "sharingPreset.deleted.title"
  | "sharingPreset.edit"
  | "sharingPreset.placeholder"
  | "sharingPreset.title"
  | "sharingPreset.updated.message"
  | "sharingPreset.updated.title"
  | "sharingPresets.title"
  | "sharingType.placeholder"
  | "sharingType.required"
  | "sharingType.title"
  | "sharings.title"
  | "shiftSystem.title"
  | "shippingDate.title"
  | "showCompletionStatus.title"
  | "showDocumentLanguageSelection.title"
  | "showTaskVisibilityConfiguration.title"
  | "showTime.title"
  | "signatureAddition.placeholder"
  | "signatureAddition.title"
  | "size.title"
  | "skill.title"
  | "skillLevel.code"
  | "skillLevel.placeholder"
  | "skillLevel.required"
  | "skillLevel.shortTitle"
  | "skillLevel.title"
  | "skills.description"
  | "skills.title"
  | "skillsFilter.type"
  | "skillsFilter.value"
  | "socSecNumber.required"
  | "socSecNumber.title"
  | "socSecNumber.tooltip"
  | "socialSkills.title"
  | "socialSkills.translated"
  | "startDate.asDate"
  | "startDate.invalid"
  | "startDate.required"
  | "startDate.title"
  | "startOfComplementaryMeasure.required"
  | "startOfComplementaryMeasure.title"
  | "startOfExperience.invalid"
  | "startOfExperience.required"
  | "startOfExperience.title"
  | "startOfFurtherEducation.required"
  | "startOfFurtherEducation.title"
  | "startOfTraining.invalid"
  | "startOfTraining.title"
  | "staticDataTypeNames.AwardFrequency"
  | "staticDataTypeNames.Beds"
  | "staticDataTypeNames.Benefits"
  | "staticDataTypeNames.CareFacilities"
  | "staticDataTypeNames.Countries"
  | "staticDataTypeNames.Currencies"
  | "staticDataTypeNames.DocumentFormats"
  | "staticDataTypeNames.DrivingLicenses"
  | "staticDataTypeNames.Education"
  | "staticDataTypeNames.EquivalenceTest"
  | "staticDataTypeNames.ExamInstitutions"
  | "staticDataTypeNames.ExamResultState"
  | "staticDataTypeNames.ExamType"
  | "staticDataTypeNames.FederalStates"
  | "staticDataTypeNames.Functions"
  | "staticDataTypeNames.Gender"
  | "staticDataTypeNames.KnownCities"
  | "staticDataTypeNames.LanguageLevels"
  | "staticDataTypeNames.LanguageModules"
  | "staticDataTypeNames.Languages"
  | "staticDataTypeNames.MartialStatus"
  | "staticDataTypeNames.OtherActivities"
  | "staticDataTypeNames.PathOfRecognition"
  | "staticDataTypeNames.Positions"
  | "staticDataTypeNames.PreferredFamilyReunion"
  | "staticDataTypeNames.PreferredTypeOfImmigration"
  | "staticDataTypeNames.ProcessRole"
  | "staticDataTypeNames.Profession"
  | "staticDataTypeNames.ProfessionArea"
  | "staticDataTypeNames.ProfessionalFields"
  | "staticDataTypeNames.QualificationMeasures"
  | "staticDataTypeNames.QualificationType"
  | "staticDataTypeNames.RecognitionNoticeKind"
  | "staticDataTypeNames.RecognitionState"
  | "staticDataTypeNames.RecognitionType"
  | "staticDataTypeNames.RelocationRequirements"
  | "staticDataTypeNames.ResidencePermits"
  | "staticDataTypeNames.Salutation"
  | "staticDataTypeNames.SignatureAddition"
  | "staticDataTypeNames.SupportedImmigrationCountries"
  | "staticDataTypeNames.TargetRecognition"
  | "staticDataTypeNames.Title"
  | "staticDataTypeNames.TravelVehicles"
  | "staticDataTypeNames.Vaccination"
  | "staticDataTypeNames.VaccinationStatus"
  | "staticDataTypeNames.WorkArea"
  | "staticDataTypeNames.WorkLocations"
  | "staticDataTypeNames.WorkingTimeType"
  | "status.placeholder"
  | "status.title"
  | "subject.required"
  | "subject.title"
  | "submissionDate.invalid"
  | "submissionDate.required"
  | "submissionDate.title"
  | "suitabilityForWork.code"
  | "suitabilityForWork.placeholder"
  | "suitabilityForWork.title"
  | "support"
  | "supportedPathOfRecognition.placeholder"
  | "supportedPathOfRecognition.title"
  | "swift.title"
  | "systemEntry.title"
  | "systemRequiredFields.title"
  | "systemSettings.apiAccessKey"
  | "systemSettings.copyClipboard"
  | "systemSettings.days"
  | "systemSettings.deletionSettings"
  | "systemSettings.fileNameWithCandidateId"
  | "systemSettings.fileNameWithFileFormat"
  | "systemSettings.fileSettings"
  | "systemSettings.isPubliclyVisible"
  | "systemSettings.isSystemLogoutEnabled"
  | "systemSettings.linktoApiDocumentation"
  | "systemSettings.logoutPeriodMinutes"
  | "systemSettings.notifiedUsersForCandidateDeletion"
  | "systemSettings.regenerateApiKey"
  | "systemSettings.regenerateApiKeyConfirm"
  | "systemSettings.regenerateApiKeyConfirmHeader"
  | "systemSettings.removeCandidatesFromAllListsIfDropout"
  | "systemSettings.saved"
  | "systemSettings.standardImmigrationCountry"
  | "systemSettings.supportedImmigrationCountries"
  | "systemSettings.title"
  | "tabsToShare.internalRequired"
  | "tabsToShare.placeholder"
  | "tabsToShare.required"
  | "tabsToShare.title"
  | "talentPool.availableFrom"
  | "talentPool.confirmDelete"
  | "talentPool.copyLink"
  | "talentPool.countryOfOrigin"
  | "talentPool.create"
  | "talentPool.created.message"
  | "talentPool.created.title"
  | "talentPool.deleted.message"
  | "talentPool.deleted.title"
  | "talentPool.edit"
  | "talentPool.germanLevel"
  | "talentPool.immigrationCountry"
  | "talentPool.isSpecial"
  | "talentPool.learningGermanLevel"
  | "talentPool.placeholder"
  | "talentPool.required"
  | "talentPool.title"
  | "talentPool.updated.message"
  | "talentPool.updated.title"
  | "talentPool.yearsOfProfessionalExperience"
  | "talentPoolFilter.advancedSearch.title"
  | "talentPoolFilter.availableFrom.placeholder"
  | "talentPoolFilter.availableFrom.title"
  | "talentPoolFilter.candidatePreferences.title"
  | "talentPoolFilter.certifiedLanguageLevels.title"
  | "talentPoolFilter.countryOfOrigin.placeholder"
  | "talentPoolFilter.countryOfOrigin.title"
  | "talentPoolFilter.drivingLicense.placeholder"
  | "talentPoolFilter.drivingLicense.title"
  | "talentPoolFilter.function.placeholder"
  | "talentPoolFilter.function.title"
  | "talentPoolFilter.immigrationCountry.placeholder"
  | "talentPoolFilter.immigrationCountry.title"
  | "talentPoolFilter.knowledge.placeholder"
  | "talentPoolFilter.knowledge.title"
  | "talentPoolFilter.languageSkills.placeholder"
  | "talentPoolFilter.languageSkills.title"
  | "talentPoolFilter.notCertifiedLanguageLevels.title"
  | "talentPoolFilter.preferredCareFacility.placeholder"
  | "talentPoolFilter.preferredCareFacility.title"
  | "talentPoolFilter.preferredEmploymentType.placeholder"
  | "talentPoolFilter.preferredEmploymentType.title"
  | "talentPoolFilter.preferredFamilyReunion.placeholder"
  | "talentPoolFilter.preferredFamilyReunion.title"
  | "talentPoolFilter.preferredLocationState.placeholder"
  | "talentPoolFilter.preferredLocationState.title"
  | "talentPoolFilter.preferredTemporalScope.placeholder"
  | "talentPoolFilter.preferredTemporalScope.title"
  | "talentPoolFilter.preferredTypeOfImmigration.placeholder"
  | "talentPoolFilter.preferredTypeOfImmigration.title"
  | "talentPoolFilter.preferredWorkingField.placeholder"
  | "talentPoolFilter.preferredWorkingField.title"
  | "talentPoolFilter.profession.associatedProfessions"
  | "talentPoolFilter.profession.placeholder"
  | "talentPoolFilter.profession.title"
  | "talentPoolFilter.professionArea.placeholder"
  | "talentPoolFilter.professionArea.title"
  | "talentPoolFilter.professionSubArea.placeholder"
  | "talentPoolFilter.professionSubArea.title"
  | "talentPoolFilter.recognitionReceived.title"
  | "talentPoolFilter.resetFilter.title"
  | "talentPoolFilter.skills.placeholder"
  | "talentPoolFilter.skills.title"
  | "talentPoolFilter.title"
  | "talentPoolFilter.yearsOfExperience.max"
  | "talentPoolFilter.yearsOfExperience.min"
  | "talentPoolFilter.yearsOfExperience.title"
  | "talentPoolMarkedTalents.confirmRequest"
  | "talentPoolMarkedTalents.confirmUnmark"
  | "talentPoolMarkedTalents.mark"
  | "talentPoolMarkedTalents.marked.message"
  | "talentPoolMarkedTalents.marked.title"
  | "talentPoolMarkedTalents.request"
  | "talentPoolMarkedTalents.requestAll"
  | "talentPoolMarkedTalents.title"
  | "talentPoolMarkedTalents.unmark"
  | "talentPoolMarkedTalents.unmarked.message"
  | "talentPoolMarkedTalents.unmarked.title"
  | "talentPoolParticipant.confirmDelete"
  | "talentPoolParticipant.create"
  | "talentPoolParticipant.created.message"
  | "talentPoolParticipant.created.title"
  | "talentPoolParticipant.deleted.message"
  | "talentPoolParticipant.deleted.title"
  | "talentPoolParticipant.edit"
  | "talentPoolParticipant.title"
  | "talentPoolParticipant.updated.message"
  | "talentPoolParticipant.updated.title"
  | "talentPoolParticipants.manage"
  | "talentPoolParticipants.placeholder"
  | "talentPoolParticipants.required"
  | "talentPoolParticipants.title"
  | "talentPoolSharing.confirmDelete"
  | "talentPoolSharing.create"
  | "talentPoolSharing.created.message"
  | "talentPoolSharing.created.title"
  | "talentPoolSharing.deleted.message"
  | "talentPoolSharing.deleted.title"
  | "talentPoolSharing.edit"
  | "talentPoolSharing.enabled"
  | "talentPoolSharing.updated.message"
  | "talentPoolSharing.updated.title"
  | "talentPoolSharing.useCv"
  | "talentPoolSharings.title"
  | "talentPoolTalentRequest.contactForm.companyName.required"
  | "talentPoolTalentRequest.contactForm.companyName.title"
  | "talentPoolTalentRequest.contactForm.email.invalid"
  | "talentPoolTalentRequest.contactForm.emailOrPhone.required"
  | "talentPoolTalentRequest.contactForm.sendRequest"
  | "talentPoolTalentRequest.contactForm.title"
  | "talentPoolTalentRequest.request"
  | "talentPoolTalentRequest.success.title"
  | "talentPoolTalents.title"
  | "talentPoolTypeFilter.placeholder"
  | "talentPools.edit"
  | "talentPools.manage"
  | "talentPools.view"
  | "targetObject.placeholder"
  | "targetObject.required"
  | "targetObject.title"
  | "targetRecognition.placeholder"
  | "targetRecognition.required"
  | "targetRecognition.title"
  | "targetRecognition.tooltip"
  | "targetRoles.placeholder"
  | "targetRoles.required"
  | "targetRoles.title"
  | "targetSize.title"
  | "task.act"
  | "task.acted"
  | "task.configure"
  | "task.confirmCancel"
  | "task.confirmDelete.activationConditionHint"
  | "task.confirmDelete.messageParent"
  | "task.confirmDelete.messageSingle"
  | "task.confirmDelete.predecessorsHint"
  | "task.confirmDelete.rulesHint"
  | "task.confirmDelete.sustainableHint"
  | "task.confirmDelete.taskFunctionalityHint"
  | "task.confirmDelete.titleParent"
  | "task.confirmDelete.titleSingle"
  | "task.confirmMissingTranslations"
  | "task.confirmationMode"
  | "task.create"
  | "task.created"
  | "task.deleted"
  | "task.error"
  | "task.formToken"
  | "task.linkCopied"
  | "task.linkCopy"
  | "task.noAudit"
  | "task.prefillTaskId"
  | "task.reactivatedByTask"
  | "task.reminder"
  | "task.selection.atLeastOneCandidateRequired"
  | "task.selection.title"
  | "task.showActivities"
  | "task.showAudit"
  | "task.started"
  | "task.title"
  | "task.updated"
  | "taskEmails.title"
  | "taskReminder.description"
  | "taskReminder.header"
  | "taskReminder.remindAction"
  | "taskReminder.settings.escalation.prefix"
  | "taskReminder.settings.escalation.required"
  | "taskReminder.settings.escalation.suffix"
  | "taskReminder.settings.escalation.switch"
  | "taskReminder.settings.first.prefix"
  | "taskReminder.settings.first.required"
  | "taskReminder.settings.first.suffix"
  | "taskReminder.settings.first.title"
  | "taskReminder.settings.following.prefix"
  | "taskReminder.settings.following.required"
  | "taskReminder.settings.following.suffix"
  | "taskReminder.settings.following.title"
  | "taskReminder.settings.max.prefix"
  | "taskReminder.settings.max.suffix"
  | "taskReminder.status.escalated"
  | "taskReminder.status.lasttaskReminder"
  | "taskReminder.status.maxReached"
  | "taskReminder.status.nexttaskReminder"
  | "taskReminder.status.taskReminderCount"
  | "taskReminder.success"
  | "taskReminder.switch"
  | "taskState.agreed"
  | "taskState.canceled"
  | "taskState.commentedAt"
  | "taskState.commentedByName"
  | "taskState.documentReviewResults"
  | "taskState.downloadableFiles"
  | "taskState.dueDate"
  | "taskState.files"
  | "taskState.finished"
  | "taskState.formTokens"
  | "taskState.forms"
  | "taskState.isCommentByCandidate"
  | "taskState.isNotRelevant"
  | "taskState.prefillTaskId"
  | "taskState.received"
  | "taskState.requiredInformation"
  | "taskState.reviewDoneByReviewers"
  | "taskState.reviewFinished"
  | "taskState.sentDate"
  | "taskState.sentTo"
  | "taskState.showInternalFlagAtDocuments"
  | "taskState.showMarkedAsCompletedFlagAtDocuments"
  | "taskState.snoozed"
  | "taskState.templateId"
  | "taskState.templateLanguage"
  | "taskState.uploadedFileTypes"
  | "taskState.uploadedProcessDocuments"
  | "taskVisibility.placeholder"
  | "taskVisibility.required"
  | "taskVisibility.title"
  | "tasks.title"
  | "taxNumber.required"
  | "taxNumber.title"
  | "taxNumber.tooltip"
  | "teachers.placeholder"
  | "teachers.required"
  | "teachers.title"
  | "template.badTags"
  | "template.confirmDelete"
  | "template.confirmDiscard"
  | "template.confirmGenerate"
  | "template.confirmGenerateAll"
  | "template.create"
  | "template.created.message"
  | "template.created.title"
  | "template.created.titleWarning"
  | "template.deleted.cant"
  | "template.deleted.message"
  | "template.deleted.title"
  | "template.docxTemplate"
  | "template.download"
  | "template.downloadAll"
  | "template.downloadAllInAllFormats"
  | "template.downloadDocx"
  | "template.downloadPdf"
  | "template.duplicated.message"
  | "template.duplicated.title"
  | "template.edit"
  | "template.errors.POADeprecatedNRW"
  | "template.errors.emailMalformed"
  | "template.errors.formNotSupported"
  | "template.errors.generic"
  | "template.errors.missingField"
  | "template.errors.missingVariables"
  | "template.errors.noCertificateAuthority"
  | "template.errors.noFormAvailable"
  | "template.errors.noImmigrationAuthority"
  | "template.errors.noRecognitionAuthority"
  | "template.errors.noReportTemplateAvailable"
  | "template.errors.noResidenceAct"
  | "template.errors.noSupportAustria"
  | "template.errors.noTemplateAvailable"
  | "template.errors.notAvailable"
  | "template.errors.residenceAct16dRequired"
  | "template.generate"
  | "template.generateAll"
  | "template.generated.message"
  | "template.generated.title"
  | "template.generatedAll.title"
  | "template.generatedAt"
  | "template.generatedBy"
  | "template.generationWarning"
  | "template.newDocx"
  | "template.newXlsx"
  | "template.newXlsxReport"
  | "template.noForm"
  | "template.placeholder"
  | "template.required"
  | "template.saved.message"
  | "template.saved.title"
  | "template.templateFormat"
  | "template.title"
  | "template.updated.message"
  | "template.updated.title"
  | "template.updated.titleWarning"
  | "template.validation.title"
  | "template.xlsxReportTemplate"
  | "template.xlsxTemplate"
  | "templateOutdated.title"
  | "templateTreeNodeNames.Psa"
  | "templateTreeNodeNames.acceleratedProcedurePaymentRoleOrganization"
  | "templateTreeNodeNames.acceleratedProcedurePaymentRoleRepresentative"
  | "templateTreeNodeNames.afterContract"
  | "templateTreeNodeNames.afterEmployer"
  | "templateTreeNodeNames.afterEmployerAuthorizedRepresentative"
  | "templateTreeNodeNames.afterEmployerAuthorizedRepresentativeFirstDeputy"
  | "templateTreeNodeNames.afterRecognitionEmployerAuthorizedRepresentative"
  | "templateTreeNodeNames.afterRecognitionEmployerAuthorizedRepresentativeFirstDeputy"
  | "templateTreeNodeNames.afterRecognitionEmployerRecognitionContactPerson"
  | "templateTreeNodeNames.afterRecognitionOrganization"
  | "templateTreeNodeNames.afterRecognitionResponsibleOrganization"
  | "templateTreeNodeNames.afterRecognitionSelectedRepresentative"
  | "templateTreeNodeNames.allLanguageSkills"
  | "templateTreeNodeNames.application"
  | "templateTreeNodeNames.candidateCurrentAddress"
  | "templateTreeNodeNames.candidateRole"
  | "templateTreeNodeNames.children"
  | "templateTreeNodeNames.childrenAll"
  | "templateTreeNodeNames.childrenWithAndWithoutReunification"
  | "templateTreeNodeNames.contactPartner"
  | "templateTreeNodeNames.countryOfOriginAddress"
  | "templateTreeNodeNames.courses"
  | "templateTreeNodeNames.currentBankDetail"
  | "templateTreeNodeNames.currentRentalAgreement"
  | "templateTreeNodeNames.currentScholarship"
  | "templateTreeNodeNames.currentUser"
  | "templateTreeNodeNames.customFields"
  | "templateTreeNodeNames.documents"
  | "templateTreeNodeNames.educations"
  | "templateTreeNodeNames.employer"
  | "templateTreeNodeNames.employerAuthorizedRepresentative"
  | "templateTreeNodeNames.employerAuthorizedRepresentativeFirstDeputy"
  | "templateTreeNodeNames.employerRecognitionContactPerson"
  | "templateTreeNodeNames.evaluatedForeignDegrees"
  | "templateTreeNodeNames.evaluationForeignDegreesFirstDeputy"
  | "templateTreeNodeNames.evaluationForeignDegreesPaymentRepresentative"
  | "templateTreeNodeNames.evaluationForeignDegreesRepresentative"
  | "templateTreeNodeNames.exams"
  | "templateTreeNodeNames.experiences"
  | "templateTreeNodeNames.familyWithAndWithoutReunification"
  | "templateTreeNodeNames.father"
  | "templateTreeNodeNames.furtherEducations"
  | "templateTreeNodeNames.germanAddress"
  | "templateTreeNodeNames.housingAcquisitionFirstDeputy"
  | "templateTreeNodeNames.housingAcquisitionHousings"
  | "templateTreeNodeNames.housingAcquisitionRepresentative"
  | "templateTreeNodeNames.idAddress"
  | "templateTreeNodeNames.immigrationCountryAddress"
  | "templateTreeNodeNames.interviewsFeedback"
  | "templateTreeNodeNames.languageSchool"
  | "templateTreeNodeNames.languageSchoolRole"
  | "templateTreeNodeNames.languageSkills"
  | "templateTreeNodeNames.languageTracking"
  | "templateTreeNodeNames.localPartner"
  | "templateTreeNodeNames.medicalExperiences"
  | "templateTreeNodeNames.mother"
  | "templateTreeNodeNames.newestRentalAgreement"
  | "templateTreeNodeNames.other"
  | "templateTreeNodeNames.partner"
  | "templateTreeNodeNames.partnerWCustody"
  | "templateTreeNodeNames.passedLanguageExamModules"
  | "templateTreeNodeNames.postSchoolEducations"
  | "templateTreeNodeNames.process"
  | "templateTreeNodeNames.psa"
  | "templateTreeNodeNames.psaFirstDeputy"
  | "templateTreeNodeNames.psaRepresentative"
  | "templateTreeNodeNames.qualificationModules"
  | "templateTreeNodeNames.recognitionAuthority"
  | "templateTreeNodeNames.recognitionPaymentRoleOrganization"
  | "templateTreeNodeNames.recognitionPaymentRoleRepresentative"
  | "templateTreeNodeNames.recognitionState"
  | "templateTreeNodeNames.replacedByCandidates"
  | "templateTreeNodeNames.replacementForCandidate"
  | "templateTreeNodeNames.residenceFirstDeputy"
  | "templateTreeNodeNames.residencePermit"
  | "templateTreeNodeNames.residenceRepresentative"
  | "templateTreeNodeNames.respContacts"
  | "templateTreeNodeNames.responsibleAfterRecognitionOrganization"
  | "templateTreeNodeNames.responsibleEmployees"
  | "templateTreeNodeNames.responsibleOrganization"
  | "templateTreeNodeNames.responsibleOrganizationEvaluationForeignDegrees"
  | "templateTreeNodeNames.responsibleOrganizationEvaluationForeignDegreesPayment"
  | "templateTreeNodeNames.responsibleOrganizationHousingAcquisition"
  | "templateTreeNodeNames.responsibleOrganizationResidence"
  | "templateTreeNodeNames.responsibleRecognitionFirstDeputy"
  | "templateTreeNodeNames.responsibleRecognitionRepresentative"
  | "templateTreeNodeNames.scholarships"
  | "templateTreeNodeNames.schoolEducations"
  | "templateTreeNodeNames.selectedRepresentative"
  | "templateTreeNodeNames.sourceOrganizationData"
  | "templateTreeNodeNames.supervisor"
  | "templateTreeNodeNames.tasks"
  | "templateTreeNodeNames.vaccinations"
  | "templateTreeNodeNames.vocationalSchool"
  | "templates.menuTitle"
  | "templates.placeholder"
  | "templates.reports"
  | "templates.required"
  | "templates.templatesOf"
  | "templates.title"
  | "temporalScope.placeholder"
  | "temporalScope.title"
  | "theoryExamDate.title"
  | "theoryHours.title"
  | "theoryInstitution.title"
  | "throwErrorOnNoSelection.help"
  | "throwErrorOnNoSelection.title"
  | "time.title"
  | "title.placeholder"
  | "title.required"
  | "title.title"
  | "title.titleDrOnly"
  | "today.long"
  | "today.title"
  | "totalHours.title"
  | "trackingNumber.placeholder"
  | "trackingNumber.title"
  | "trainArrivalDate.title"
  | "trainDepartureDate.title"
  | "trainNumber.title"
  | "transferLanguageLevel.placeholder"
  | "transferLanguageLevel.required"
  | "transferLanguageLevel.title"
  | "translate.title"
  | "translation.download"
  | "translation.existing"
  | "translation.export.title"
  | "translation.hint"
  | "translation.hintManual"
  | "translation.import.confirm"
  | "translation.import.failed"
  | "translation.import.success"
  | "translation.import.title"
  | "translation.import.warning"
  | "translation.languages"
  | "translation.message.invalidExcelFile"
  | "translation.message.invalidFile"
  | "translation.message.invalidModel"
  | "translation.message.invalidWorkbook"
  | "translation.message.itemDoesNotExist"
  | "translation.message.missingInfoSheet"
  | "translation.message.missingTranslationSheet"
  | "translation.message.noChange"
  | "translation.message.nothingToTranslate"
  | "translation.message.nothingToTranslateInLanguage"
  | "translation.message.propertyDoesNotExist"
  | "translation.message.rootLanguageValueChanged"
  | "translation.noTranslation"
  | "translation.single"
  | "translation.title"
  | "translation.translate"
  | "translationMode.placeholder"
  | "translationMode.required"
  | "translationMode.title"
  | "translationProposal.activated"
  | "translationProposal.gatheringComponent.existingTranslation"
  | "translationProposal.gatheringComponent.header"
  | "translationProposal.gatheringComponent.proposedTranslation"
  | "translationProposal.gatheringComponent.reasonForProposal"
  | "translationProposal.gatheringComponent.translationHint"
  | "translationProposal.success"
  | "translationProposal.tooltip"
  | "translationProposal.turnOff"
  | "translationSheet.column.header.englishTranslation"
  | "translationSheet.column.header.existingTranslation"
  | "translationSheet.column.header.key"
  | "translationSheet.column.header.lastUpdatedBy"
  | "translationSheet.column.header.lastUpdatedOn"
  | "translationSheet.column.header.machineTranslation"
  | "translationSheet.column.header.rootLanguageHash"
  | "translationSheet.column.header.rootLanguageValue"
  | "translationSheet.column.header.translatedBy"
  | "translationSheet.column.header.translation"
  | "translationSheet.column.header.translationStatus"
  | "translationSheet.error.invalidHexValue"
  | "translationSheet.error.invalidStringValue"
  | "translationSheet.error.metadataCorrupt"
  | "translationSheet.error.metadataInvalid"
  | "translationSheet.error.metadataMissing"
  | "translationSheet.error.missingInfoSheet"
  | "translationSheet.error.missingTranslation"
  | "translationSheet.error.missingTranslationSheet"
  | "translationSheet.error.missingValueInCell"
  | "translationSheet.error.parseWorkbook"
  | "travelReimbursement.title"
  | "travelVehicle.placeholder"
  | "travelVehicle.title"
  | "travelVehicleOther.required"
  | "travelVehicleOther.title"
  | "type.forbidden"
  | "type.placeholder"
  | "type.required"
  | "type.title"
  | "typeOfBankTransfer.isBic"
  | "typeOfBankTransfer.isSepa"
  | "typeOfBankTransfer.title"
  | "typeOfWorkLocation.after"
  | "typeOfWorkLocation.before"
  | "typeOfWorkLocation.placeholder"
  | "typeOfWorkLocation.title"
  | "unavailable.title"
  | "unknown.countryOfOrigin"
  | "unknown.employer"
  | "unknown.localPartner"
  | "unknown.title"
  | "unsubscribe.info"
  | "unsubscribe.question"
  | "unsubscribe.success"
  | "unsubscribe.title"
  | "until.title"
  | "uploadService.createEntityFailed"
  | "uploadService.invalidData"
  | "uploadService.technicalError"
  | "uploadService.undoEntityFailed"
  | "url.invalid"
  | "user.badInput.title"
  | "user.blockUnblock"
  | "user.blocked.message"
  | "user.blocked.title"
  | "user.concurrency.message"
  | "user.concurrency.title"
  | "user.confirmBlock.message"
  | "user.confirmBlock.title"
  | "user.confirmDelete.message"
  | "user.confirmDelete.title"
  | "user.confirmReinvite.message"
  | "user.confirmReinvite.title"
  | "user.confirmUnblock.message"
  | "user.confirmUnblock.title"
  | "user.create"
  | "user.created.message"
  | "user.created.title"
  | "user.deleted.message"
  | "user.deleted.title"
  | "user.edit"
  | "user.placeholder"
  | "user.reinvite"
  | "user.reinvited.message"
  | "user.reinvited.title"
  | "user.removed"
  | "user.required"
  | "user.title"
  | "user.unblocked.message"
  | "user.unblocked.title"
  | "user.updated.message"
  | "user.updated.title"
  | "user.version.message"
  | "user.version.newVersionAvailable"
  | "user.version.newVersionAvailableHint"
  | "user.version.title"
  | "userFeedback.create"
  | "userFeedback.created"
  | "userFeedback.freeTextTitle"
  | "userFeedback.functionalityRating"
  | "userFeedback.overallRating"
  | "userFeedback.performanceRating"
  | "userFeedback.text"
  | "userFeedback.usabilityRating"
  | "userGroup.add"
  | "userGroup.confirmDelete.message"
  | "userGroup.confirmDelete.title"
  | "userGroup.created.message"
  | "userGroup.created.title"
  | "userGroup.deleted.message"
  | "userGroup.deleted.title"
  | "userGroup.edit"
  | "userGroup.manage"
  | "userGroup.manageMembersOf"
  | "userGroup.members.placeholder"
  | "userGroup.members.required"
  | "userGroup.members.title"
  | "userGroup.placeholder"
  | "userGroup.removed"
  | "userGroup.required"
  | "userGroup.title"
  | "userGroup.updated.message"
  | "userGroup.updated.title"
  | "userGroups.linked"
  | "userGroups.manage"
  | "userGroups.placeholder"
  | "userGroups.title"
  | "userLanguage.changedSuccess"
  | "userLanguage.placeholder"
  | "userLanguage.required"
  | "userLanguage.title"
  | "userLanguage.titleShort"
  | "userRole.placeholder"
  | "userRole.required"
  | "userRole.title"
  | "userRoles.title"
  | "userflow.title"
  | "users.added"
  | "users.manage"
  | "users.title"
  | "vacationDays.title"
  | "vaccinationConfig.title"
  | "vaccinationDocument.title"
  | "vaccinations.add"
  | "vaccinations.comments.placeholder"
  | "vaccinations.comments.title"
  | "vaccinations.isCompleted"
  | "vaccinations.key"
  | "vaccinations.lastShot.asDate"
  | "vaccinations.lastShot.invalid"
  | "vaccinations.lastShot.title"
  | "vaccinations.missing.title"
  | "vaccinations.name.placeholder"
  | "vaccinations.name.title"
  | "vaccinations.overviewSource.title"
  | "vaccinations.plannedFinalShot.invalid"
  | "vaccinations.plannedFinalShot.title"
  | "vaccinations.plannedNextShot.invalid"
  | "vaccinations.plannedNextShot.title"
  | "vaccinations.title"
  | "vaccinations.vaccinationStatus.placeholder"
  | "vaccinations.vaccinationStatus.required"
  | "vaccinations.vaccinationStatus.title"
  | "vaccinations.vaccine.placeholder"
  | "vaccinations.vaccine.title"
  | "validFrom.asDate"
  | "validFrom.title"
  | "validUntil.asDate"
  | "validUntil.title"
  | "value.placeholder"
  | "value.required"
  | "value.search"
  | "value.title"
  | "values.title"
  | "visa.at"
  | "visa.de"
  | "visa.entry.plane"
  | "visa.entry.title"
  | "visa.entry.train"
  | "visa.title"
  | "visaEnquired.title"
  | "visaEnquiredDate.title"
  | "visaReceiveDate.title"
  | "visaReceived.title"
  | "visaRelocationRequirements.placeholder"
  | "visaRelocationRequirements.title"
  | "visaRequestDate.title"
  | "visaRequested.title"
  | "visaTransfer.title"
  | "visaValidFromDate.title"
  | "visaValidUntilDate.title"
  | "visibleForRoles.placeholder"
  | "visibleForRoles.required"
  | "visibleForRoles.title"
  | "vocationalSchool.at"
  | "vocationalSchool.de"
  | "vocationalSchool.description"
  | "vocationalSchool.title"
  | "vocationalSchoolAddress.houseNumber"
  | "vocationalSchoolAddress.street"
  | "vocationalSchoolAddress.title"
  | "vocationalSchoolCity.title"
  | "vocationalSchoolComment.title"
  | "vocationalSchoolCountry.title"
  | "vocationalSchoolCurrentTrainingYear.placeholder"
  | "vocationalSchoolCurrentTrainingYear.title"
  | "vocationalSchoolEndDate.title"
  | "vocationalSchoolExpectedEndDate.title"
  | "vocationalSchoolFederalState.title"
  | "vocationalSchoolMedicalCheck.title"
  | "vocationalSchoolSchool.title"
  | "vocationalSchoolSchoolCourse.title"
  | "vocationalSchoolSchoolName.title"
  | "vocationalSchoolStartDate.title"
  | "vocationalSchoolTrainingTime.title"
  | "vocationalSchoolTrainingTimeInPartTime.title"
  | "vocationalSchoolTrainingTimeInYearsTotal.placeholder"
  | "vocationalSchoolTrainingTimeInYearsTotal.title"
  | "vocationalSchoolTrainingTimeShortened.title"
  | "vocationalSchoolZipCode.title"
  | "warning.title"
  | "wayToUs.placeholder"
  | "wayToUs.title"
  | "website.title"
  | "website.title2"
  | "week.columns"
  | "week.index"
  | "week.prefill"
  | "week.prefillWarning"
  | "week.prefilled"
  | "week.title"
  | "withoutCandidates.title"
  | "workContractSignedByAllParties.title"
  | "workContractSignedByOneParty.title"
  | "workExperiences.title"
  | "workExperiences.total"
  | "workHours.code"
  | "workHours.placeholder"
  | "workHours.title"
  | "workLocation.after"
  | "workLocation.before"
  | "workLocation.title"
  | "workStartDate.required"
  | "workStartDate.title"
  | "workingCity.title"
  | "workingExperiencePrecision.placeholder"
  | "workingExperiencePrecision.title"
  | "workingHoursPerWeek.title"
  | "workplace.title"
  | "xlsxImport.sheet.error.constraint.between"
  | "xlsxImport.sheet.error.constraint.greaterThan"
  | "xlsxImport.sheet.error.constraint.greaterThanOrEqual"
  | "xlsxImport.sheet.error.constraint.lessThan"
  | "xlsxImport.sheet.error.constraint.lessThanOrEqual"
  | "xlsxImport.sheet.error.invalidString"
  | "xlsxImport.sheet.error.noDate"
  | "xlsxImport.sheet.error.noInt"
  | "xlsxImport.sheet.error.noNumber"
  | "xlsxImport.sheet.error.notInList"
  | "xlsxImport.sheet.error.textConstraint.between"
  | "xlsxImport.sheet.error.textConstraint.exact"
  | "xlsxImport.sheet.error.textConstraint.greaterThan"
  | "xlsxImport.sheet.error.textConstraint.greaterThanOrEqual"
  | "xlsxImport.sheet.error.textConstraint.lessThan"
  | "xlsxImport.sheet.error.textConstraint.lessThanOrEqual"
  | "xlsxImport.sheet.error.title"
  | "xlsxImport.validation.invalidField"
  | "xlsxImport.validation.invalidType"
  | "xlsxImport.validation.invalidVersion"
  | "xlsxImport.validation.missingSheet"
  | "xlsxImport.validation.noData"
  | "xlsxImport.validation.obsoleteOption"
  | "xlsxImport.validation.unallowedOption"
  | "yearOfEstablishment.placeholder"
  | "yearOfEstablishment.title"
  | "yearsOfExperience.placeholder"
  | "yearsOfExperience.title"
  | "zipCode.invalid"
  | "zipCode.placeholder"
  | "zipCode.required"
  | "zipCode.title";
  