import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TableMode } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { MessageService } from "primeng-v17/api";
import { Button } from "primeng-v17/button";
import { DropdownModule } from "primeng-v17/dropdown";
import { RadioButtonModule } from "primeng-v17/radiobutton";
import { catchError, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { SettingsService } from "../../shared/settings.service";
import { WriteTablesService } from "../../shared/write-tables.service";
import { ExcelBaseComponent } from "../excel-base/excel-base.component";
import { CandidateDataService } from "./candidate-data.service";

@Component({
  selector: "app-candidate-data",
  templateUrl: "./candidate-data.component.html",
  styleUrl: "./candidate-data.component.scss",
  imports: [RadioButtonModule, FormsModule, DropdownModule, Button, NgIf],
})
export class CandidateDataComponent extends ExcelBaseComponent implements OnInit {
  myDate: Date[] | undefined;
  tableMode: TableMode = TableMode.Relational;
  lists: any[];
  prodMode = environment.production;

  selectedList: any;

  TableMode: typeof TableMode = TableMode;

  constructor(
    private readonly candidateDataService: CandidateDataService,
    private readonly writeTables: WriteTablesService,
    private readonly settings: SettingsService,
    override readonly messageService: MessageService,
    override readonly transloco: TranslocoService
  ) {
    super(transloco, messageService);
  }

  ngOnInit(): void {
    this.candidateDataService.getCollectionData().subscribe(
      (data) =>
        (this.lists = data.collection?.map((c) => {
          return { name: c.name, id: c.id, organizationId: c.organizationId };
        }))
    );
  }

  getData(): void {
    this.setBusy(true);
    this.candidateDataService
      .getCandidateData(
        this.settings.organizationId,
        this.tableMode,
        this.language,
        this.selectedList?.id,
        this.selectedList?.organizationId
      )
      .pipe(
        catchError((error) => {
          this.sendFailureMessage(error);
          this.setBusy(false);
          return of(null);
        })
      )
      .subscribe(async (d) => {
        if (d) {
          const result = await this.writeTables.writeDataToManyTables(d);
          this.sendTableUpdateMessage(result);
          this.setBusy(false);
        }
      });
  }

  clearWorkbook(): void {
    this.candidateDataService.deleteAllButFirstTable();
  }
}
