import { HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize, Observable, tap } from "rxjs";
import { ApplicationInsightsService } from "../services/application-insights.service";

interface SpinnerTimer {
  timerHandle: number;
  spinnerStarted: boolean;
}

const excludeFromSpinner = [
  "getImageToken",
  "getVisibleTasks",
  "keepAlive",
  "getEmailsForProcess",
  "getOutboxForProcess",
  "getNextOutboxRun",
  "getDashboardMetrics",
  "getAllCollections",
  "getOrganizationList",
  "getCascadedLinkedOrganizations",
  "getNotificationActivityCount",
  "getMessagesReception",
  "getUserInboxCount",
  "getUserOrganizations",
  "getLastLoginDate",
  "getActivityFilterOrganizations",
  "getActivityFilterUsers",
  "getActivityFilterCollections",
  "getActivityFilterCandidates",
  "getActivityFilterProcesses",
  "getCandidateProcessRoleList",
  "getSwitchableOrganizations",
  "getMinimalAnabinDegrees",
  "getMissingDocumentTemplates",
];

let count = 0;

function spinnerStart(spinner: NgxSpinnerService): void {
  count++;
  void spinner.show("Main");
}

function spinnerStop(spinner: NgxSpinnerService, force: boolean): void {
  count--;
  if (count <= 0 || force) {
    void spinner.hide("Main");
  }
}

function timerStart(spinner: NgxSpinnerService, appInsights: ApplicationInsightsService, state: SpinnerTimer): void {
  if (state.timerHandle < 0) {
    state.timerHandle = window.setTimeout(() => {
      state.spinnerStarted = true;
      spinnerStart(spinner);
      appInsights.startTrackEvent("Spinner");
    }, 950);
  }
}

function timerStop(spinner: NgxSpinnerService, appInsights: ApplicationInsightsService, state: SpinnerTimer): void {
  if (state?.timerHandle != null && state.timerHandle >= 0) {
    clearTimeout(state.timerHandle);
    if (state.spinnerStarted) {
      spinnerStop(spinner, false);
      state.spinnerStarted = false;
      appInsights.stopTrackEvent("Spinner", { timerHandle: state.timerHandle });
    }
  }
}

export function uiBlockInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const spinner = inject(NgxSpinnerService);
  const appInsights = inject(ApplicationInsightsService);

  if (!req.url.endsWith("api/graphql") || excludeFromSpinner.includes(req?.body?.operationName)) {
    return next(req);
  } else {
    const state: SpinnerTimer = { spinnerStarted: false, timerHandle: -1 };
    return next(req).pipe(
      tap((x) => {
        if (x.type == HttpEventType.Sent) {
          timerStart(spinner, appInsights, state);
        }
        if (x.type == HttpEventType.Response) {
          timerStop(spinner, appInsights, state);
        }
      }),
      finalize(() => {
        timerStop(spinner, appInsights, state);
      })
    );
  }
}
