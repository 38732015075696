import {
  ArrayIterator,
  Many,
  ary,
  first,
  flatMapDeep,
  includes,
  last,
  map,
  omit,
  partial,
  partialRight,
  pick,
  some,
  stubTrue,
  xor,
} from "lodash";
import { Predicate } from "./type-utils";

/**
 * Returns items that appear only once in the array.
 *
 * Example:
 * ```ts
 * const result = uniques(["a", "b", "c", "b", "e", "c", "b"]);
 * expect(result).toEqual(["a", "e"]);
 * ```
 */
export function uniques<T>(items: T[]): T[] {
  return xor(...items.map((a) => [a]));
}

/**
 * Returns items that appear more than once in the array.
 *
 * Example:
 * ```ts
 * const result = duplicates(["a", "b", "c", "b", "e", "c", "b"]);
 * expect(result).toEqual(["b", "c"]);
 * ```
 */
export function duplicates<T>(items: T[]): T[] {
  return xor(items, uniques(items));
}

/**
 * {@link _.flatMapDeep} that works properly.
 *
 * `flatMapDeep` is basically an alias for `map`+`flattenDeep`. If you were looking for a method that would
 * recursively apply the `map` part and then flatten the result, then this would be it.
 */
export function flatMapSuperDeep<T>(items: T[], iteratee: (_: T) => T[]): T[] {
  const flatten = (x: T): [T, T[]] => [x, flatMapDeep(iteratee(x), flatten)];
  return flatMapDeep(items, flatten);
}

/**
 * Returns the first element in the array.
 *
 * Example 1:
 * ```ts
 * const result = first(["a", "b", "c"]);
 * expect(result).toEqual("a");
 * ```
 *
 * Example 2:
 * ```ts
 * const result = first([]);
 * expect(result).toBeNull();
 * ```
 *
 * Example 3:
 * ```ts
 * const result = first(["a", "b", "b", "c"], (element) => element === "b");
 * expect(result).toEqual("b");
 * ```
 *
 * Example 4:
 * ```ts
 * const result = first(["a", "b", "c"], (element) => element === "d");
 * expect(result).toBeNull();
 * ```
 *
 * @throws `Error` if the array is `undefined` or `null`.
 * @param items the array to get the first element from.
 * @returns the first element that was found.
 */
export function firstOrDefault<T>(items: T[], predicate?: Predicate<T>): T | null {
  if (items === undefined) {
    throw new Error("Expected an array, but got undefined.");
  }

  if (items === null) {
    throw new Error("Expected an array, but got null.");
  }

  const filtered = items.filter(predicate ?? stubTrue);
  if (filtered.length === 0) {
    return null;
  }

  return first(filtered);
}

/**
 * Returns the last element in the array.
 *
 * Example 1:
 * ```ts
 * const result = last(["a", "b", "c"]);
 * expect(result).toEqual("c");
 * ```
 *
 * Example 2:
 * ```ts
 * const result = last([]);
 * expect(result).toBeNull();
 * ```
 *
 * Example 3:
 * ```ts
 * const result = last(["a", "b", "b", "c"], (element) => element === "b");
 * expect(result).toEqual("b");
 * ```
 *
 * Example 4:
 * ```ts
 * const result = last(["a", "b", "c"], (element) => element === "d");
 * expect(result).toBeNull();
 * ```
 *
 * @throws `Error` if the array is `undefined` or `null`.
 * @param items the array to get the last element from.
 * @returns the last element that was found.
 */
export function lastOrDefault<T>(items: T[], predicate?: Predicate<T>): T | null {
  if (items === undefined) {
    throw new Error("Expected an array, but got undefined.");
  }

  if (items === null) {
    throw new Error("Expected an array, but got null.");
  }

  const filtered = items.filter(predicate ?? stubTrue);
  if (filtered.length === 0) {
    return null;
  }

  return last(filtered);
}

/**
 * Returns the only element in the array.
 *
 * Example 1:
 * ```ts
 * const result = single(["a"]);
 * expect(result).toEqual("a");
 * ```
 *
 * Example 2:
 * ```ts
 * const result = single(["a", "b", "c"], (element) => element === "a");
 * expect(result).toEqual("a");
 * ```
 *
 * Example 3:
 * ```ts
 * const func = (): string => single(["a", "b"]);
 * expect(func).toThrow("Expected exactly one element. but got 2 elements.");
 * ```
 *
 * @throws `Error` if the array is `undefined`, `null`, or when more than one element is found.
 * @param items the array to get the only element from.
 * @param predicate an optional predicate to filter the array.
 * @returns the single element that was found.
 */
export function single<T>(items: T[], predicate?: Predicate<T>): T {
  if (items === undefined) {
    throw new Error("Expected exactly one element, but got an undefined array.");
  }

  if (items === null) {
    throw new Error("Expected exactly one element, but got a null array.");
  }

  const filtered = items.filter(predicate ?? stubTrue);
  if (filtered.length === 1) {
    return first(filtered);
  }

  throw new Error(`Expected exactly one element. but got ${filtered.length} elements.`);
}

/**
 * Returns the only element in the array.
 *
 * Example 1:
 * ```ts
 * const result = single(["a"]);
 * expect(result).toEqual("a");
 * ```
 *
 * Example 2:
 * ```ts
 * const result = single(["a", "b", "c"], (element) => element === "a");
 * expect(result).toEqual("a");
 * ```
 *
 * Example 3:
 * ```ts
 * const result = singleOrDefault(["b", "c", "d"], (element) => element === "a");
 * expect(result).toBeNull();
 * ```
 *
 * Example 4:
 * ```ts
 * const func = (): string => single(["a", "a", "b"], (element) => element === "a";
 * expect(func).toThrow("Expected exactly one element. but got 2 elements.");
 * ```
 *
 * @throws `Error` if the array is `undefined`, `null`, or when more than one element is found.
 * @param items the array to get the only element from.
 * @param predicate an optional predicate to filter the array.
 * @returns the single element that was found, or `null` if no element was found
 */
export function singleOrDefault<T>(items: T[], predicate?: Predicate<T>): T | null {
  if (items === undefined) {
    throw new Error("Expected exactly one element, but got an undefined array.");
  }

  if (items === null) {
    throw new Error("Expected exactly one element, but got a null array.");
  }

  const filtered = items.filter(predicate ?? stubTrue);
  if (filtered.length === 0) {
    return null;
  }

  if (filtered.length === 1) {
    return first(filtered);
  }

  throw new Error(`Expected exactly one element. but got ${filtered.length} elements.`);
}

/**
 * Returns whether the array has exactly one element.
 *
 * Example 1:
 * ```ts
 * const result = hasOne(["a"]);
 * expect(result).toBeTrue();
 * ```
 *
 * Example 2:
 * ```ts
 * const result = hasOne(["a", "b", "c"], (element) => element === "a");
 * expect(result).toBeTrue();
 * ```
 *
 * Example 3:
 * ```ts
 * const result = hasOne(["a", "b", "c"], (element) => element === "d");
 * expect(result).toBeFalse();
 * ```
 *
 * Example 4:
 * ```ts
 * const result = hasOne(["a", "b", "c"]);
 * expect(result).toBeFalse();
 * ```
 *
 * Example 5:
 * ```ts
 * const result = hasOne([]);
 * expect(result).toBeFalse();
 * ```
 *
 * Example 6:
 * ```ts
 * const result = hasOne(null);
 * expect(result).toBeFalse();
 * ```
 *
 * @param items the array to check.
 * @param predicate an optional predicate to filter the array.
 * @returns `true` if the array has exactly one element, `false` otherwise.
 **/
export function hasOne<T>(items: T[], predicate?: Predicate<T>): boolean {
  return (items ?? []).filter(predicate ?? stubTrue).length === 1;
}

/**
 * Picks properties from each item of array.
 *
 * Equivalent to `items.map(x => pick(x, props))`.
 *
 * Example:
 * ```ts
 * const result = pickEach([{ n: 1 }, { n: 2, o: 3 }], "n");
 * expect(result).toEqual([{ n: 1 }, { n: 2 }]);
 * ```
 *
 * @see {@link omitEach}
 */
export function pickEach<T, K extends keyof T>(items: T[], ...props: Many<K>[]): Pick<T, K>[] {
  return map(items, <ArrayIterator<T, Pick<T, K>>>partialRight(pick, ...props));
}

/**
 * Omits properties from each item of array.
 *
 * Equivalent to `items.map(x => omit(x, props))`.
 *
 * Example:
 * ```ts
 * const result = omitEach([{ n: 1, o: 6 }, { n: 2 }], "o");
 * expect(result).toEqual([{ n: 1 }, { n: 2 }]);
 * ```
 *
 * @see {@link pickEach}
 */
export function omitEach<T, K extends keyof T>(items: T[], ...props: Many<K>[]): Omit<T, K>[] {
  return map(items, <ArrayIterator<T, Omit<T, K>>>partialRight(omit, ...props));
}

/**
 * Checks if arrays intersect.
 *
 * Equivalent to `intersection(left, right).length > 0`.
 *
 * Example:
 * ```ts
 * const result = intersects([2, 3], [3]);
 * expect(result).toBeTrue();
 * ```
 */
export function intersects<T>(left: T[], right: T[]): boolean {
  return some(left, ary(partial(includes, right), 1));
}
