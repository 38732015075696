import { IExcelAddInEnvironment } from "./environment.interface";

// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment: IExcelAddInEnvironment = {
  production: false,
  graphqlURI: "https://backend-dev.ankaadia.com/api/graphql",
  wsURI: "ws://localhost:7071/api/subscriptions",

  auth0_clientId: "OrQySMsAq2gDReyQPXbIvrwnXPcs9krP",
  auth0_domain: "dev-goodthing.eu.auth0.com",
  auth0_issuer: "dev-goodthing.eu.auth0.com",
  auth0_audience: "https://goodthing.reininger.de",
  appinsights_connectionstring:
    "InstrumentationKey=1d13da41-e94b-476b-b6a5-a0c4f29a0965;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/",
  redirectUrl: "https://excel-dev.ankaadia.com/assets/popupRedirect.html",
  loginUrl: "https://excel-dev.ankaadia.com/assets/loginPage.html",
  logoutUrl: "https://excel-dev.ankaadia.com/assets/logoutPage.html",
  applicationName: "ExcelAdd-In",
  emailEditorURI: null,
  facebookAppId: null,
  facebookPageId: null,
  recaptchaSiteKey: null,
  useServiceWorker: false,
  cometChatAppId: undefined,
  cometChatAuthKey: undefined,
};
