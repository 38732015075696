import { isEmpty, isNil, orderBy } from "lodash";

interface DocumentRequirementAlternativeContainer {
  documentType?: string;
  documentSetType?: string;
}

interface DocumentRequirementContainer {
  alternatives?: DocumentRequirementAlternativeContainer[];
}

export function createRequirementGroupKey<T extends DocumentRequirementContainer>(group: T): string {
  const alternatives = group.alternatives ?? [];
  const documentTypes = alternatives
    .map(({ documentType }) => documentType)
    .filter((documentType) => !isNil(documentType));

  return orderBy(documentTypes, (type) => type, "asc").join(",");
}

export function createRequirementAlternativeKey<T extends DocumentRequirementAlternativeContainer>(
  alternative: T
): string {
  return !isNil(alternative.documentType)
    ? !isNil(alternative.documentSetType)
      ? `${alternative.documentType} (${alternative.documentSetType})`
      : alternative.documentType
    : null;
}

export function isValidRequirementGroup<T extends DocumentRequirementContainer>(group: T): boolean {
  const alternatives = group.alternatives ?? [];
  return !isEmpty(alternatives) && alternatives.every(({ documentType }) => !isNil(documentType));
}
