import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { enableProdMode, Version } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { ANKAADIA_VERSION } from "@ankaadia/ankaadia-shared";
import {
  apolloFactory,
  APP_VERSION,
  FRONTEND_ENVIRONMENT_INJECTION_TOKEN,
  uiBlockInterceptor,
  uiLanguageInterceptor,
  versionInterceptor,
} from "@ankaadia/ankaadia-shared-frontend";
import { provideAuth0 } from "@auth0/auth0-angular";
import { provideApollo } from "apollo-angular";
import "json.date-extensions";
import { ConfirmationService, MessageService } from "primeng-v17/api";
import { AppComponent } from "./app/app.component";
import { authHttpInterceptorExcel } from "./app/shared/interceptors/auth-interceptor";
import { provideTransloco } from "./app/shared/transloco/transloco.provider";
import { environment } from "./environments/environment";

(<any>JSON).useDateParser();

if (environment.production) {
  enableProdMode();
}

void Office.onReady(() => {
  bootstrapApplication(AppComponent, {
    providers: [
      provideAnimationsAsync(),

      provideHttpClient(
        withInterceptors([
          uiBlockInterceptor, // Important to be the first
          authHttpInterceptorExcel, // Auth Interceptor emits two requests, but apparently cancelles one directly
          versionInterceptor,
          uiLanguageInterceptor,
        ])
      ),

      provideAuth0({
        clientId: environment.auth0_clientId,
        domain: environment.auth0_domain,
        errorPath: "/error",
        skipRedirectCallback: false,
        authorizationParams: {
          audience: environment.auth0_audience,
          redirect_uri: window.location.origin,
        },
        httpInterceptor: {
          allowedList: [environment.graphqlURI],
        },
      }),

      provideTransloco(),

      provideApollo(apolloFactory(environment, null)),

      ConfirmationService,
      MessageService,

      { provide: FRONTEND_ENVIRONMENT_INJECTION_TOKEN, useValue: environment },
      { provide: APP_VERSION, useValue: new Version(ANKAADIA_VERSION) },
    ],
  })
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
});
