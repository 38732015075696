import * as i3 from '@angular/common';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { PLATFORM_ID, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, ContentChildren, ViewChild, NgModule } from '@angular/core';
import * as i1 from 'primeng/api';
import { PrimeTemplate } from 'primeng/api';
import { ZIndexUtils } from 'primeng/utils';
import * as i2 from 'primeng/dom';

/**
 * BlockUI can either block other components or the whole page.
 * @group Components
 */
const _c0 = ["mask"];
const _c1 = ["*"];
const _c2 = a0 => ({
  "p-blockui-document": a0,
  "p-blockui p-component-overlay p-component-overlay-enter": true
});
const _c3 = () => ({
  display: "none"
});
function BlockUI_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
class BlockUI {
  document;
  el;
  cd;
  config;
  renderer;
  platformId;
  domHandler;
  /**
   * Name of the local ng-template variable referring to another component.
   * @group Props
   */
  target;
  /**
   * Whether to automatically manage layering.
   * @group Props
   */
  autoZIndex = true;
  /**
   * Base zIndex value to use in layering.
   * @group Props
   */
  baseZIndex = 0;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Current blocked state as a boolean.
   * @group Props
   */
  get blocked() {
    return this._blocked;
  }
  set blocked(val) {
    if (this.mask && this.mask.nativeElement) {
      if (val) this.block();else this.unblock();
    } else {
      this._blocked = val;
    }
  }
  templates;
  mask;
  _blocked = false;
  animationEndListener;
  contentTemplate;
  constructor(document, el, cd, config, renderer, platformId, domHandler) {
    this.document = document;
    this.el = el;
    this.cd = cd;
    this.config = config;
    this.renderer = renderer;
    this.platformId = platformId;
    this.domHandler = domHandler;
  }
  ngAfterViewInit() {
    if (this._blocked) this.block();
    if (this.target && !this.target.getBlockableElement) {
      throw 'Target of BlockUI must implement BlockableUI interface';
    }
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }
  block() {
    if (isPlatformBrowser(this.platformId)) {
      this._blocked = true;
      this.mask.nativeElement.style.display = 'flex';
      if (this.target) {
        this.target.getBlockableElement().appendChild(this.mask.nativeElement);
        this.target.getBlockableElement().style.position = 'relative';
      } else {
        this.renderer.appendChild(this.document.body, this.mask.nativeElement);
        this.domHandler.blockBodyScroll();
      }
      if (this.autoZIndex) {
        ZIndexUtils.set('modal', this.mask.nativeElement, this.baseZIndex + this.config.zIndex.modal);
      }
    }
  }
  unblock() {
    if (isPlatformBrowser(this.platformId) && this.mask && !this.animationEndListener) {
      this.animationEndListener = this.renderer.listen(this.mask.nativeElement, 'animationend', this.destroyModal.bind(this));
      this.domHandler.addClass(this.mask.nativeElement, 'p-component-overlay-leave');
    }
  }
  destroyModal() {
    this._blocked = false;
    if (this.mask && isPlatformBrowser(this.platformId)) {
      ZIndexUtils.clear(this.mask.nativeElement);
      this.domHandler.removeClass(this.mask.nativeElement, 'p-component-overlay-leave');
      this.renderer.removeChild(this.el.nativeElement, this.mask.nativeElement);
      this.domHandler.unblockBodyScroll();
    }
    this.unbindAnimationEndListener();
    this.cd.markForCheck();
  }
  unbindAnimationEndListener() {
    if (this.animationEndListener && this.mask) {
      this.animationEndListener();
      this.animationEndListener = null;
    }
  }
  ngOnDestroy() {
    this.unblock();
    this.destroyModal();
  }
  static ɵfac = function BlockUI_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BlockUI)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.PrimeNGConfig), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i2.DomHandler));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: BlockUI,
    selectors: [["p-blockUI"]],
    contentQueries: function BlockUI_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function BlockUI_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.mask = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      target: "target",
      autoZIndex: [2, "autoZIndex", "autoZIndex", booleanAttribute],
      baseZIndex: [2, "baseZIndex", "baseZIndex", numberAttribute],
      styleClass: "styleClass",
      blocked: "blocked"
    },
    standalone: false,
    features: [i0.ɵɵInputTransformsFeature],
    ngContentSelectors: _c1,
    decls: 4,
    vars: 11,
    consts: [["mask", ""], [3, "ngClass", "ngStyle"], [4, "ngTemplateOutlet"]],
    template: function BlockUI_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 1, 0);
        i0.ɵɵprojection(2);
        i0.ɵɵtemplate(3, BlockUI_ng_container_3_Template, 1, 0, "ng-container", 2);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c2, !ctx.target))("ngStyle", i0.ɵɵpureFunction0(10, _c3));
        i0.ɵɵattribute("aria-busy", ctx.blocked)("data-pc-name", "blockui")("data-pc-section", "root");
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngTemplateOutlet", ctx.contentTemplate);
      }
    },
    dependencies: [i3.NgClass, i3.NgTemplateOutlet, i3.NgStyle],
    styles: ["@layer primeng{.p-blockui{position:absolute;top:0;inset-inline-start:0;width:100%;height:100%;background-color:transparent;transition-property:background-color;display:flex;align-items:center;justify-content:center}.p-blockui.p-component-overlay{position:absolute}.p-blockui-document.p-component-overlay{position:fixed}.p-blockui-leave.p-component-overlay{background-color:transparent}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUI, [{
    type: Component,
    args: [{
      selector: 'p-blockUI',
      template: `
        <div
            #mask
            [class]="styleClass"
            [attr.aria-busy]="blocked"
            [ngClass]="{ 'p-blockui-document': !target, 'p-blockui p-component-overlay p-component-overlay-enter': true }"
            [ngStyle]="{ display: 'none' }"
            [attr.data-pc-name]="'blockui'"
            [attr.data-pc-section]="'root'"
        >
            <ng-content></ng-content>
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-blockui{position:absolute;top:0;inset-inline-start:0;width:100%;height:100%;background-color:transparent;transition-property:background-color;display:flex;align-items:center;justify-content:center}.p-blockui.p-component-overlay{position:absolute}.p-blockui-document.p-component-overlay{position:fixed}.p-blockui-leave.p-component-overlay{background-color:transparent}}\n"]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i1.PrimeNGConfig
  }, {
    type: i0.Renderer2
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i2.DomHandler
  }], {
    target: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    baseZIndex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    styleClass: [{
      type: Input
    }],
    blocked: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    mask: [{
      type: ViewChild,
      args: ['mask']
    }]
  });
})();
class BlockUIModule {
  static ɵfac = function BlockUIModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BlockUIModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: BlockUIModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [BlockUI],
      declarations: [BlockUI]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BlockUI, BlockUIModule };
