import {
  cloneDeep,
  isArray,
  isDate,
  isEmpty,
  isEqual,
  isFunction,
  isObject as isLodashObject,
  isNil,
  isString,
  keyBy,
  keys,
  set,
  transform,
  unset,
  values,
} from "lodash";

/**
 * Result element of deep diff
 * @description
 * - path: path to the changed element
 * - oldValue: old value of the changed element
 * - newValue: new value of the changed element
 * - modificationType: type of modification
 * @see ModificationType for details of the allowed modification types
 */
export interface Change {
  path: string;
  jsonPath: string;
  oldValue: any;
  newValue: any;
  modificationType: ModificationType.Added | ModificationType.Removed | ModificationType.Updated;
}

/**
 * Type of modification
 * @description
 * - Added: the element was added
 * - Removed: the element was removed
 * - Updated: the element was updated
 */
export enum ModificationType {
  Added = "Added",
  Removed = "Removed",
  Updated = "Updated",
}

export enum ComparisonLevelType {
  Field = "Field",
  Object = "Object",
}

/**
 * Options for deep diff
 * @description
 * - path: path to the root element
 * - arrayComparison: array comparison function
 * - ignoreKeys: keys to ignore
 * @see ArrayComparerArgs for details of the allowed array comparison functions
 */
export class DiffOptions {
  path?: string;
  arrayComparison?: "strict" | "sloppy" | string[] | ((lhs: any[], rhs: any[], args: ArrayComparerArgs) => Change[]);
  ignoreKeys?: string[];
  level?: ComparisonLevelType;
}

/**
 * Arguments for array comparison function
 * @description
 * - path: path to the array
 * - compareArrayElement: function to call, when the pair of items was found. Compares two array elements.
 */
export class ArrayComparerArgs {
  path: string;
  jsonPath: string;
  compareArrayElement: (
    lhs: any,
    rhs: any,
    index: number,
    attribute: {
      key: string;
      value: string | number;
    }
  ) => Change[];
}

interface JsonPathPart {
  key?: string;
  index?: number;
  attribute?: {
    key: string;
    value: string | number;
  };
}

/**
 * Deep diff between two objects including nested fields and arrays. Its explicitly allowed to bring your own array comparison function.
 * @description
 * The implementation is based on lodash.
 * In case you have a nested array of objects and you want to pick the elements based on any identifier, you can use your own array comparison function.
 * However you can also use the predefined array comparison functions:
 * - "strict": element-wise comparison (default)
 * - "sloppy": using the fields "id", "modelId", "language" to identify the corresponding elements
 * - string[]: using the given fields to identify the corresponding elements
 * @param lhs object or array to compare (e.g. old)
 * @param rhs object or array to compare with (e.g. new)
 * @param options optional options
 * @returns array of changes
 * @see DiffOptions for details of the allowed options
 * @see Change for details of the returned changes
 * @example
 * diffDeep(lhs, rhs, { arrayComparison: "strict" }); // where arrayComparison is element-wise comparison (default), i.e. same as diffDeep(lhs, rhs)
 * or
 * diffDeep(lhs, rhs, { arrayComparison: "strict", ignoreKeys: ["_ts", "id", "organizationId"] }); // where the fields "_ts", "id", "organizationId" are ignored
 * or
 * diffDeep(lhs, rhs, { arrayComparison: "sloppy" }); // where arrayComparison is using the fields "id", "modelId", "language" to identify the corresponding elements
 * or
 * diffDeep(lhs, rhs, { arrayComparison: ["id"], ignoreKeys: ["id", "modelId", "language"], path: "root" });
 * or
 * diffDeep(lhs, rhs, { arrayComparison: myArrayCompareFunc }); // where myArrayCompareFunc is a function that defines the array comparison
 * // e.g. like this:
 * function myArrayCompareFunc(lhs: any[], rhs: any[], args: ArrayComparerArgs): Change[] {
 * if (lhs === rhs) { return []; }
 * if (lhs.length !== rhs.length) { return [{ path: args.path, oldValue: lhs, newValue: rhs, modificationType: ModificationType.Updated }]; }
 * return lhs.map((x, i) => args.compareArrayElement(lhs[i], rhs[i], i)).flat();
 *};
 */
export function diffDeep(lhs: any, rhs: any, options: DiffOptions = {}): Change[] {
  const comparison = options.arrayComparison ?? "strict";
  const arrayComparer =
    comparison === "strict"
      ? strictArrayComparer
      : comparison === "sloppy"
        ? sloppyArrayComparer
        : isArray(comparison) && comparison.length > 0 && isString(comparison[0])
          ? (lhs: any[], rhs: any[], args: ArrayComparerArgs): Change[] =>
              objectArrayComparer(lhs, rhs, comparison, args)
          : isFunction(comparison)
            ? comparison
            : undefined;
  if (!arrayComparer) {
    throw new Error(`Invalid array comparison: ${comparison}`);
  }
  const args: DiffArgs = {
    path: options.path ?? "",
    jsonPath: options.path ?? "$",
    arrayComparer,
    ignoreKeys: options.ignoreKeys ?? [],
    level: options.level ?? ComparisonLevelType.Field,
  };
  return diffRecursively(lhs, rhs, args);
}

class DiffArgs {
  path: string;
  jsonPath: string;
  arrayComparer: (lhs: any[], rhs: any[], args: ArrayComparerArgs) => Change[];
  ignoreKeys: string[];
  level: ComparisonLevelType;
}

function diffRecursively(lhs: any, rhs: any, args: DiffArgs): Change[] {
  if (lhs === rhs) {
    return [];
  }

  if (args.level === ComparisonLevelType.Object) {
    const change = compareOnObjectLevel(lhs, rhs, args);
    if (change) {
      return [change];
    }
  }

  if (isArray(lhs) || isArray(rhs)) {
    return compareArray(lhs, rhs, args);
  }

  if (isObject(lhs) || isObject(rhs)) {
    return objectCompare(lhs, rhs, args);
  }

  return comparePrimitive(lhs, rhs, args);
}

function compareArray(lhs: any[], rhs: any[], args: DiffArgs): Change[] {
  if (lhs === rhs || (isNil(lhs) && isNil(rhs)) || (isEmpty(lhs) && isEmpty(rhs))) {
    return [];
  }

  if (!isObject(lhs?.[0]) && !isObject(rhs?.[0])) {
    return comparePrimitive(lhs, rhs, args);
  }

  const arrayComparerArgs: ArrayComparerArgs = {
    path: args.path,
    jsonPath: args.jsonPath,
    compareArrayElement: (lhs, rhs, index, attribute) =>
      diffRecursively(lhs, rhs, updatePath(args, { index, attribute })),
  };
  return args.arrayComparer(lhs, rhs, arrayComparerArgs);
}

function comparePrimitive(lhs: any, rhs: any, args: DiffArgs): Change[] {
  if (isEqual(lhs, rhs)) {
    return [];
  }

  if (isNil(lhs)) {
    return [
      {
        path: args.path,
        jsonPath: args.jsonPath,
        oldValue: undefined,
        newValue: rhs,
        modificationType: ModificationType.Added,
      },
    ];
  }

  if (isNil(rhs)) {
    return [
      {
        path: args.path,
        jsonPath: args.jsonPath,
        oldValue: lhs,
        newValue: undefined,
        modificationType: ModificationType.Removed,
      },
    ];
  }

  return [
    {
      path: args.path,
      jsonPath: args.jsonPath,
      oldValue: lhs,
      newValue: rhs,
      modificationType: ModificationType.Updated,
    },
  ];
}

function strictArrayComparer(lhs: any[], rhs: any[], args: ArrayComparerArgs): Change[] {
  const changes: Change[] = [];
  for (let i = 0; i < Math.max(lhs?.length ?? 0, rhs?.length ?? 0); i++) {
    changes.push(...args.compareArrayElement(lhs?.[i], rhs?.[i], i, null));
  }
  return changes;
}

function sloppyArrayComparer(lhs: any[], rhs: any[], args: ArrayComparerArgs): Change[] {
  if (lhs[0] && rhs[0] && isObject(lhs[0]) && isObject(rhs[0])) {
    const keys = ["id", "modelId", "language"];
    return objectArrayComparer(lhs, rhs, keys, args);
  }

  return strictArrayComparer(lhs, rhs, args);
}

function objectArrayComparer(lhs: any[], rhs: any[], keys: string[], args: ArrayComparerArgs): Change[] {
  const firstNonNull = lhs?.find((x) => !isNil(x));
  const key = firstNonNull && isObject(firstNonNull) ? probeKeys(firstNonNull, keys) : null;
  if (!key) {
    return strictArrayComparer(lhs, rhs, args);
  }

  const rhsLkp = keyBy(rhs, key);
  const changes: Change[] = [];
  const removedIndices: number[] = [];
  lhs.forEach((lhsItem, index) => {
    const keyValue = lhsItem[key];
    const rhsItem = rhsLkp[keyValue];
    delete rhsLkp[keyValue];
    if (rhsItem) {
      changes.push(...args.compareArrayElement(lhsItem, rhsItem, index, { key, value: keyValue }));
    } else {
      removedIndices.push(index);
      changes.push({
        path: combinePath(args.path, index.toString()),
        jsonPath: combineJsonPath(args.jsonPath, { attribute: { key, value: keyValue } }),
        oldValue: lhsItem,
        newValue: undefined,
        modificationType: ModificationType.Removed,
      });
    }
  });
  removedIndices.reverse();
  values(rhsLkp).forEach((rhsItem, index) => {
    const removedIndex = removedIndices.pop();
    changes.push({
      path: combinePath(args.path, (removedIndex ?? lhs.length + index).toString()),
      jsonPath: combineJsonPath(
        args.jsonPath,
        removedIndex
          ? {
              attribute: { key, value: lhs[removedIndex][key] },
            }
          : //: { index: lhs.length + index }
            { attribute: { key, value: rhsItem[key] } }
      ),
      oldValue: undefined,
      newValue: rhsItem,
      modificationType: ModificationType.Added,
    });
  });
  return changes;

  function probeKeys(lhs: any, keys: string[]): string {
    if (isNil(lhs)) return null;
    for (const key of keys) {
      if (lhs[key]) {
        return key;
      }
    }
    return null;
  }
}

function objectCompare(lhs: object, rhs: object, args: DiffArgs): Change[] {
  const changes: Change[] = [];

  function addChange(change: Change): void {
    if (isObject(change.newValue) && isNil(change.oldValue)) {
      changes.push(
        ...(args.level === ComparisonLevelType.Object
          ? [change]
          : diffRecursively(createEmptyObject(change.newValue), change.newValue, setPath(args, change)))
      );
    } else if (isObject(change.oldValue) && isNil(change.newValue)) {
      changes.push(
        ...(args.level === ComparisonLevelType.Object
          ? [change]
          : diffRecursively(change.oldValue, createEmptyObject(change.oldValue), setPath(args, change)))
      );
    } else {
      changes.push(change);
    }
  }

  const lhsKeys = keys(lhs).filter((x) => !args.ignoreKeys?.includes(x));
  for (const lhsKey of lhsKeys) {
    const incomingChanges = diffRecursively(lhs?.[lhsKey], rhs?.[lhsKey], updatePath(args, { key: lhsKey }));
    for (const change of incomingChanges) {
      addChange(change);
    }
  }

  const rhsKeys = keys(rhs).filter((x) => !lhsKeys.includes(x) && !args.ignoreKeys?.includes(x));
  for (const rhsKey of rhsKeys) {
    const incomingChanges = diffRecursively(lhs?.[rhsKey], rhs?.[rhsKey], updatePath(args, { key: rhsKey }));
    for (const change of incomingChanges) {
      addChange(change);
    }
  }

  return changes;
}

function combinePath(path: string, key: string): string {
  return (path ? path + "." : "") + key;
}

function updatePath(args: DiffArgs, value: JsonPathPart): DiffArgs {
  const path = combinePath(args.path, value.key ?? value.index.toString());
  const jsonPath = combineJsonPath(args.jsonPath, value);
  return { ...args, path, jsonPath };
}

function setPath(args: DiffArgs, change: Change): DiffArgs {
  if (!change) {
    throw new Error("Can not set path - Invalid change");
  }
  return { ...args, path: change.path, jsonPath: change.jsonPath };
}

function combineJsonPath(jsonPath: string, value: JsonPathPart): string {
  if (value.key) {
    return `${jsonPath}.${value.key}`;
  }
  if (value.attribute) {
    const attributeValue = isString(value.attribute.value)
      ? (value.attribute.value = `'${value.attribute.value}'`)
      : value.attribute.value;
    return `${jsonPath}[?(@.${value.attribute.key}==${attributeValue})]`;
  }
  if (!isNil(value.index)) {
    return `${jsonPath}[${value.index}]`;
  }
  throw new Error("Can not construct jsonPath - Invalid value");
}

/**
 * Since `isObject` provided by Lodash reports true also for arrays and dates, we're wrapping it like this.
 */
function isObject(value: unknown): boolean {
  return isLodashObject(value) && !isArray(value) && !isDate(value);
}

/**
 * Produces an object with the same structure as the input object but all fields set to `undefined`.
 *
 * Example:
 *
 * ```
 * const input = { path: "something", newValue: { id: 2323, language: "PA", skillLevel: "A1" }, modificationType: "Added" };
 * const output = createNullObject(input);
 * expect(output).toEqual({ path: undefined, newValue: { id: undefined, language: undefined, skillLevel: undefined }, modificationType: undefined });
 * ```
 */
function createEmptyObject(obj: any): any {
  return transform(
    obj,
    (result, value, key) => {
      if (isArray(value) && isObject(value[0])) {
        result[key] = value.map((x) => createEmptyObject(x));
      } else if (isObject(value)) {
        result[key] = createEmptyObject(value);
      } else {
        result[key] = undefined;
      }
    },
    {}
  );
}

/**
 * Applies the given changes to a copy of the given target object.
 * @param target object to apply the changes to
 * @param changes changes to apply
 * @returns new instance of the target object with the changes applied
 */
export function applyChanges(target: any, changes: Change[]): any {
  const result = cloneDeep(target);
  changes.forEach((change) => applyChange(result, change));
  return result;

  function applyChange(target: any, change: Change): any {
    switch (change.modificationType) {
      case ModificationType.Added:
      case ModificationType.Updated:
        set(target, change.path, change.newValue);
        break;
      case ModificationType.Removed:
        unset(target, change.path);
        break;
    }
  }
}

/**
 * Returns the delta object of the given changes.
 * @param changes changes to apply
 * @param valueForRemoved value to use for removed fields (default: undefined)
 * @returns delta object (remark removed fields are returned as undefined)
 */
export function getDelta(changes: Change[], valueForRemoved: any = undefined): any {
  const result = {};
  changes.forEach((change) => applyChange(result, change));
  return result;

  function applyChange(target: any, change: Change): any {
    switch (change.modificationType) {
      case ModificationType.Added:
      case ModificationType.Updated:
        set(target, change.path, change.newValue);
        break;
      case ModificationType.Removed:
        set(target, change.path, valueForRemoved);
        break;
    }
  }
}
function compareOnObjectLevel(lhs: any, rhs: any, args: DiffArgs): Change | null {
  if (isNil(lhs) && !isNil(rhs)) {
    return {
      path: args.path,
      jsonPath: args.jsonPath,
      oldValue: lhs,
      newValue: rhs,
      modificationType: ModificationType.Added,
    };
  }
  if (!isNil(lhs) && isNil(rhs)) {
    return {
      path: args.path,
      jsonPath: args.jsonPath,
      oldValue: lhs,
      newValue: rhs,
      modificationType: ModificationType.Removed,
    };
  }
  return null;
}
