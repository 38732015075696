import { Union } from "../type-utils";

export enum ActivityType {
  CandidateAdded = "CandidateAdded",
  CandidateDeleted = "CandidateDeleted",
  CandidateUpdated = "CandidateUpdated",
  CandidateBirthday = "CandidateBirthday",
  CandidateBirthdaySoon = "CandidateBirthdaySoon",
  FileUploaded = "FileUploaded",
  FileModified = "FileModified",
  DocumentSetMetaDataModified = "DocumentSetMetaDataModified",
  FileDeleted = "FileDeleted",
  FreeFormatFileUploaded = "FreeFormatFileUploaded",
  FreeFormatFileDeleted = "FreeFormatFileDeleted",
  FileExpiresSoon = "FileExpiresSoon",
  CollectionAdded = "CollectionAdded",
  CollectionDeleted = "CollectionDeleted",
  CollectionUpdated = "CollectionUpdated",
  CandidateAddedToCollection = "CandidateAddedToCollection",
  CandidateRemovedFromCollection = "CandidateRemovedFromCollection",
  CollectionEmbeddedIntoCollection = "CollectionEmbeddedIntoCollection",
  CollectionRemovedFromCollection = "CollectionRemovedFromCollection",
  CandidateDeletionReminder = "CandidateDeletionReminder",
  SharingAdded = "SharingAdded",
  SharingDeleted = "SharingDeleted",
  SharingUpdated = "SharingUpdated",
  SharingEnabled = "SharingEnabled",
  SharingDisabled = "SharingDisabled",
  SharingTypeModified = "SharingTypeModified",
  ProfileMaskUpdated = "ProfileMaskUpdated",
  TaskStarted = "TaskStarted",
  TaskManuallyChanged = "TaskManuallyChanged",
  TaskChanged = "TaskChanged",
  TaskFinished = "TaskFinished",
  TaskCancelled = "TaskCancelled",
  TaskFailed = "TaskFailed",
  TaskReminderSent = "TaskReminderSent",
  TaskDeleted = "TaskDeleted",
  TaskManuallyCreated = "TaskManuallyCreated",
  TaskStateChanged = "TaskStateChanged",
  TaskStateManuallyChanged = "TaskStateManuallyChanged",
  EmailNotDelivered = "EmailNotDelivered",
  CandidateLoggedIn = "CandidateLoggedIn",
  FeedbackProvided = "FeedbackProvided",
  CandidateDroppedOut = "CandidateDroppedOut",
  AutoProcessOperationTriggered = "AutoProcessOperationTriggered",
  TaskNoteAdded = "TaskNoteAdded",
  CandidateRequestDeletion = "CandidateRequestDeletion",
  CandidateOptedOut = "CandidateOptedOut",
  CandidateOptedIn = "CandidateOptedIn",
  UserLoggedIn = "UserLoggedIn",
  ProcessDeleted = "ProcessDeleted",
  ProcessCreated = "ProcessCreated",
  ProcessModelPublished = "ProcessModelPublished",
  ProcessModelUnpublished = "ProcessModelUnpublished",
  ProcessModelReleaseCreated = "ProcessModelReleaseCreated",
  ProcessModelDeleted = "ProcessModelDeleted",
  EducationExamDeleted = "EducationExamDeleted",
  EducationExamUpdated = "EducationExamUpdated",
  EducationExamCreated = "EducationExamCreated",
  EducationExamResultCreated = "EducationExamResultCreated",
  EducationExamResultDeleted = "EducationExamResultDeleted",
  CandidateAddedToExam = "CandidateAddedToExam",
  CandidateRemovedFromExam = "CandidateRemovedFromExam",
  CandidateNotFound = "CandidateNotFound",
  UserNotFound = "UserNotFound",
  ProcessNotFound = "ProcessNotFound",
  DocumentNotFound = "DocumentNotFound",
  OrganizationNotFound = "OrganizationNotFound",
  CollectionNotFound = "CollectionNotFound",
  TaskNotFound = "TaskNotFound",
  InvalidUserGroupRoleMapping = "InvalidUserGroupRoleMapping",
  InvalidSharingTemplate = "InvalidSharingTemplate",
  OrganizationNotLinked = "OrganizationNotLinked",
  ContractCreated = "ContractCreated",
  ContractCancelled = "ContractCancelled",
  ContractCancellationWithdrawn = "ContractCancellationWithdrawn",
  ContractAnnulled = "ContractAnnulled",
  ContractPlanChanged = "ContractPlanChanged",
  OnlineApplicationRun = "OnlineApplicationRun",
}

export const DocumentActivityTypes = [
  ActivityType.FileUploaded,
  ActivityType.FileModified,
  ActivityType.FileDeleted,
  ActivityType.FreeFormatFileUploaded,
  ActivityType.FreeFormatFileDeleted,
  ActivityType.FileExpiresSoon,
  ActivityType.DocumentSetMetaDataModified,
] as const;

export type DocumentActivityType = Union<typeof DocumentActivityTypes>;
export const DocumentFileModifiedActivityTypes = [ActivityType.FileModified] as const;
export const FreeFormatFileActivityTypes = [ActivityType.FreeFormatFileUploaded] as const;
export const DocumentFileActivityTypes = [ActivityType.FileUploaded, ActivityType.FileExpiresSoon] as const;

export const PreviewableDocumentFileActivityTypes = [
  ...DocumentFileActivityTypes,
  ...DocumentFileModifiedActivityTypes,
  ...FreeFormatFileActivityTypes,
] as const;

export type PreviewableDocumentFileActivityType = Union<typeof PreviewableDocumentFileActivityTypes>;

export enum AutomationOperationType {
  "CreateProcess" = "CreateProcess",
  "CreateDelegation" = "CreateDelegation",
  "CreateSharing" = "CreateSharing",
  "UpdateSharing" = "UpdateSharing",
}
