import { isRtlLanguage } from "@ankaadia/ankaadia-shared";
import { TranslocoService } from "@jsverse/transloco";

export function dirInitializer(transloco: TranslocoService, document: Document): () => void {
  return () => {
    const language = transloco.getActiveLang();
    document.documentElement.lang = language;
    document.documentElement.dir = isRtlLanguage(language) ? "rtl" : "ltr";
  };
}
