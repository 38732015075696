import { noop } from "lodash";

export function EnableFeature(enabled: boolean): ClassDecorator {
  return (target) => {
    if (!enabled) {
      Object.getOwnPropertyNames(target.prototype).forEach((k) => (target.prototype[k] = noop));
    }
    return target;
  };
}

export const ENABLE_FACEBOOK = true;
export const ENABLE_TALENT_POOL = false;
export const ENABLE_BILLING = false;
export const ENABLE_UNIFIED_INBOX = false;
export const ENABLE_CHAT = false;
