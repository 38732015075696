import { Injectable } from "@angular/core";
import { chunk } from "lodash";
import { ExcelAddInDto, ExcelTable } from "../_generated/graphql";

export interface IresultInfo {
  finishState: string;
  issues: string;
  timeTaken: string;
}

@Injectable({ providedIn: "root" })
export class WriteTablesService {
  async writeDataToManyTables(tableData: ExcelAddInDto, addIndex = true): Promise<IresultInfo> {
    let ctr = 0;
    const timestamp = new Date().getTime();

    const results = await Promise.all(
      tableData.dataTables.map(async (table) => await this.writeDataToTable(table, addIndex ? ctr++ : null))
    );
    const issues = results.filter((result) => result != "success").join(", ");

    return {
      finishState: issues ? "warn" : "success",
      issues: issues,
      timeTaken: ((new Date().getTime() - timestamp) / 1000).toString(),
    };
  }

  async writeDataToTable(tableData: ExcelTable, index: number): Promise<string> {
    try {
      await Excel.run(async (context) => {
        const sheets = context.workbook.worksheets;
        const app = context.workbook.application;

        let tableName = tableData.label.substring(0, 27);
        if (index) tableName = tableName + "_" + index;
        let currentWorksheet: Excel.Worksheet;

        sheets.load("items/name");
        await context.sync();

        if (!sheets.items.some((e) => e.name === tableName)) {
          currentWorksheet = sheets.add(tableName);
          await context.sync();
          // sheet.load("name, position");
        } else {
          currentWorksheet = context.workbook.worksheets.getItem(tableName);
        }

        currentWorksheet.load("name, position");
        await context.sync();

        currentWorksheet.getRange().clear();
        await context.sync();

        const candidateTable = currentWorksheet.tables.add(
          `A1:${this.number2Digit(tableData.columns.length)}1`,
          true /*hasHeaders*/
        );
        await context.sync();
        candidateTable.name = index
          ? tableName.replace(/[\s-]/g, "").substring(0, 30) + index
          : tableName.replace(/[\s-]/g, "").substring(0, 32);
        candidateTable.getHeaderRowRange().values = [tableData.columns.map((item) => item.label)];
        await context.sync();

        app.suspendApiCalculationUntilNextSync();
        app.suspendScreenUpdatingUntilNextSync();
        if (tableData.dataCells?.length > 0) {
          const chunked = chunk(tableData.dataCells, 5000);
          for (const chunk of chunked) {
            candidateTable.rows.add(null, chunk);
            // https://docs.microsoft.com/office/dev/add-ins/concepts/resource-limits-and-performance-optimization#excel-add-ins
            await context.sync();
          }
        }
        currentWorksheet.getUsedRange().format.autofitColumns();
        currentWorksheet.calculate(true); // We calculate and activate the sheet in order to make sure that they appear in the workbook
        currentWorksheet.activate();
        await context.sync();
      });
      return "success";
    } catch (e) {
      return tableData.label;
    }
  }

  private number2Digit(num: number): string {
    if (num <= 0) return undefined;
    if (num <= 26) {
      return (num + 9).toString(36).toUpperCase();
    } else {
      if (num % 26 === 0) {
        return this.number2Digit(Math.floor(num / 26 - 1)) + "Z";
      } else {
        return this.number2Digit(Math.floor(num / 26)) + ((num % 26) + 9).toString(36).toUpperCase();
      }
    }
  }
}
