import { Component } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { MessageService } from "primeng/api";
import { catchError, of } from "rxjs";
import { WriteTablesService } from "../../shared/write-tables.service";
import { ExcelBaseComponent } from "../excel-base/excel-base.component";
import { ChangeStatisticsService } from "./change-statistics.service";

@Component({
  selector: "app-change-statistics",
  templateUrl: "./change-statistics.component.html",
  styleUrl: "./change-statistics.component.scss",
})
export class ChangeStatisticsComponent extends ExcelBaseComponent {
  myDate: Date[] | undefined;

  dateSelectorState = "";

  constructor(
    private readonly changeStatistics: ChangeStatisticsService,
    override readonly transloco: TranslocoService,
    override readonly messageService: MessageService,
    private readonly writeTables: WriteTablesService
  ) {
    super(transloco, messageService);
  }

  resetInvalidDateState(): void {
    this.dateSelectorState = "";
  }

  getData(): void {
    if (!this.myDate) {
      this.dateSelectorState = "invalid-entry";
      return null;
    }

    this.setBusy(true);
    this.changeStatistics
      .getChangeStatisticsData(this.language, this.myDate?.[0], this.myDate?.[1] ?? this.myDate[0])
      .pipe(
        catchError((error) => {
          this.sendFailureMessage(error);
          this.setBusy(false);
          return of(null);
        })
      )
      .subscribe(async (d) => {
        if (d) {
          const result = await this.writeTables.writeDataToManyTables(d);
          this.sendTableUpdateMessage(result);
          this.setBusy(false);
        }
      });
  }
}
