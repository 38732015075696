import { Component, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { PrimeNGConfig } from "primeng/api";

import { TranslocoService, translate } from "@jsverse/transloco";
import { filter } from "rxjs";
import { environment } from "../environments/environment";
import { OfficeEnvService } from "./shared/officeEnvService";
import { SettingsService } from "./shared/settings.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  clientId: string = environment.auth0_clientId;
  authID: string = environment.auth0_domain;
  graphQL: string = environment.graphqlURI;

  isBusy = false;

  authenticated = false;
  isMasterUser = false;

  isExcel = false;

  readonly language = this.transloco.getActiveLang();

  constructor(
    protected readonly auth0: AuthService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly settings: SettingsService,
    private readonly officeEnvService: OfficeEnvService,
    protected readonly transloco: TranslocoService
  ) {}

  myTranslation(key: string): string {
    return translate(key);
  }

  setBusyState(busyState: boolean): void {
    this.isBusy = busyState;
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.settings.isAuthenticated$.pipe(filter((x) => x)).subscribe((auth) => (this.authenticated = auth));
    this.settings.isMasterUser$.pipe(filter((x) => x)).subscribe((isMaster) => (this.isMasterUser = isMaster));
    this.isExcel = this.officeEnvService.getHostApplication() === Office.HostType.Excel;
  }
}
