export interface Language {
  code: string;
  label: string;
  flag: string;
}

const languageMap: Record<string, Language> = {
  // structure: language - country
  //   language: (lowercase) 2-letter ISO 639-1 code or 3-letter ISO 639-3 code
  //   country: (uppercase) 2-letter ISO 3166 code or BCP 47 language tag
  // always update the language resource, i.e. de-DE: "enum.language..."
  // generate date formats: `nx tool date-formats`
  AL: { code: "sq-AL", label: "Shqip", flag: "al" }, // AL is wrong, should be SQ, but we can't change it now because it's used in the database. Adapted country-spoken-languages.json
  AR: { code: "ar-AE", label: "العربية", flag: "ae" },
  TR: { code: "tr-TR", label: "Türkçe", flag: "tr" },
  BG: { code: "bg-BG", label: "Български", flag: "bg" },
  BS: { code: "bs-BA", label: "Bosanski", flag: "ba" },
  DA: { code: "da-DK", label: "Dansk", flag: "dk" },
  DE: { code: "de-DE", label: "Deutsch", flag: "de" },
  EN: { code: "en-GB", label: "English", flag: "uk" },
  ES: { code: "es-ES", label: "Español", flag: "es" },
  FA: { code: "fa-IR", label: "فارسی", flag: "ir" },
  FI: { code: "fi-FI", label: "Suomi", flag: "fi" },
  FR: { code: "fr-FR", label: "Français", flag: "fr" },
  GA: { code: "ga-IE", label: "Gaeilge", flag: "ie" },
  HE: { code: "he-IL", label: "עברית", flag: "il" },
  HI: { code: "hi-IN", label: "हिन्दी", flag: "in" },
  HR: { code: "hr-HR", label: "Hrvatski", flag: "hr" },
  IT: { code: "it-IT", label: "Italiano", flag: "it" },
  JA: { code: "ja-JP", label: "日本語", flag: "jp" },
  ME: { code: "me-ME", label: "Crnogorski", flag: "me" },
  MK: { code: "mk-MK", label: "Mакедонски", flag: "mk" },
  ML: { code: "ml-IN", label: "മലയാളം", flag: "in" },
  NL: { code: "nl-NL", label: "Nederlands", flag: "nl" },
  NO: { code: "nb-NO", label: "Norsk", flag: "no" },
  TL: { code: "tl-PH", label: "Tagalog", flag: "ph" },
  PT: { code: "pt-PT", label: "Português", flag: "pt" },
  BR: { code: "pt-BR", label: "Português (Brasil)", flag: "br" },
  RO: { code: "ro-RO", label: "Română", flag: "ro" },
  RU: { code: "ru-RU", label: "Русский", flag: "ru" },
  SR: { code: "sr-SP", label: "Srpski", flag: "rs" },
  SV: { code: "sv-SE", label: "Svenska", flag: "se" },
  UK: { code: "uk-UA", label: "Українська", flag: "ua" },
  VI: { code: "vi-VN", label: "Tiếng Việt", flag: "vn" },
  ZH: { code: "zh-CN", label: "中文", flag: "cn" },
  ET: { code: "et-EE", label: "Eesti", flag: "ee" },
  FP: { code: "fp-PH", label: "Filipino", flag: "ph" },
  ID: { code: "id-ID", label: "Indonesian", flag: "id" },
  KU: { code: "ku-TR", label: "Kurdish", flag: "tr" },
  UR: { code: "ur-PK", label: "Urdu", flag: "pk" },
  PL: { code: "pl-PL", label: "Polski", flag: "pl" },
  SY: { code: "sy-SY", label: "Syrisch", flag: "sy" },
  TA: { code: "ta-IN", label: "Tamil", flag: "in" },
  BN: { code: "bn-BD", label: "Bengali", flag: "bd" },
  HU: { code: "hu-HU", label: "Hungarian", flag: "hu" },
  EL: { code: "el-GR", label: "Greek", flag: "gr" },
  CM: { code: "cm-CN", label: "Mandarin", flag: "cn" },
  PA: { code: "pa-IN", label: "Punjabi", flag: "in" },
  KO: { code: "ko-KR", label: "Korean", flag: "kr" },
  TH: { code: "th-TH", label: "Thai", flag: "th" },
  KA: { code: "ka-GE", label: "Georgian", flag: "ge" },
  CS: { code: "cs-CZ", label: "Czech", flag: "cs" },
  HY: { code: "hy-AM", label: "Armenian", flag: "am" },
  LT: { code: "lt-LT", label: "Lithuanian", flag: "lt" },
  // read the comment up there
};

export interface IFieldSortOrder {
  de_DE: number;
  en_GB: number;
  es_ES: number;
  fr_FR: number;
  pt_PT: number;
  sr_SP: number;
  tr_TR: number;
  ru_RU: number;
  ml_IN: number;
  vi_VN: number;
  it_IT: number;
}

export type DatabaseLanguageCode = keyof IFieldSortOrder;

export function supportedLanguages2LetterISO(): string[] {
  return Object.keys(languageMap);
}

export function supportedLanguages4LetterISO(): string[] {
  return Object.values(languageMap).map((x) => x.code);
}

export function supportedLanguageFlags2LetterISO(): Record<string, string> {
  return Object.entries(languageMap).reduce((acc, [code, { flag }]) => ((acc[code] = flag), acc), {});
}

export function supportedLanguageFlags4LetterISO(): Record<string, string> {
  return Object.values(languageMap).reduce((acc, { code, flag }) => ((acc[code] = flag), acc), {});
}

/**
 * Some parts of UI can be displayed in all these languages.
 *
 * Use this for validation in a non-strict mode.
 *
 * Don't use this for displaying available languages. Instead, choose one of these based on the user role:
 * - {@link supportedUserInterfaceLanguages4LetterISOForCandidate}
 * - {@link supportedUserInterfaceLanguages4LetterISOForUser}
 */
export function supportedUserInterfaceLanguages4LetterISO(): string[] {
  return [
    "de-DE",
    "en-GB",
    "es-ES",
    "fr-FR",
    "pt-PT",
    "pt-BR",
    "sr-SP",
    "tr-TR",
    "ru-RU",
    "ml-IN",
    "vi-VN",
    "it-IT",
    "ar-AE",
    "fa-IR",
  ];
}

/**
 * Candidate UI can be displayed in all these languages.
 *
 * Use this for displaying available languages to the candidates.
 */
export function supportedUserInterfaceLanguages4LetterISOForCandidate(): string[] {
  return supportedUserInterfaceLanguages4LetterISO();
}

/**
 * User UI can be displayed in all these languages.
 *
 * Use this for displaying available languages to the users.
 */
export function supportedUserInterfaceLanguages4LetterISOForUser(): string[] {
  return supportedUserInterfaceLanguages4LetterISO().filter((x) => !isRtlLanguage(x));
}

export function supportedReportLanguages4LetterISO(): string[] {
  return ["de-DE", "en-GB"];
}

export function supportedDatabaseLanguages4LetterIso(): string[] {
  return ["de-DE", "en-GB", "es-ES", "fr-FR", "pt-PT", "sr-SP", "tr-TR", "ru-RU", "ml-IN", "vi-VN", "it-IT"];
}

export function isRtlLanguage(language: string): boolean {
  const rtlLanguages = ["ar-AE", "fa-IR"];
  return rtlLanguages.includes(ensure4LetterIsoLanguage(language));
}

export function mapIso4ToDatabaseLanguage(fourLetter: string): DatabaseLanguageCode {
  switch (fourLetter) {
    case "de-DE":
      return "de_DE";
    case "en-GB":
      return "en_GB";
    case "es-ES":
      return "es_ES";
    case "fr-FR":
      return "fr_FR";
    case "pt-PT":
      return "pt_PT";
    case "sr-SP":
      return "sr_SP";
    case "tr-TR":
      return "tr_TR";
    case "ml-IN":
      return "ml_IN";
    case "vi-VN":
      return "vi_VN";
    case "it-IT":
      return "it_IT";
    default:
      return "en_GB";
  }
}

export function getLanguageConfiguration(languages: string[]): Language[] {
  return languages.map((x) => languageMap[mapIso4To2IsoLanguage(x)]);
}

export function supportedLanguages(): Language[] {
  const nonTranslatableLanguages = ["cm-CN", "sy-SY", "me-ME"];
  const supportedLanguages = Object.values(languageMap).sort((a, b) => a.label.localeCompare(b.label));
  const supportedAndTranslatable = supportedLanguages.filter((x) => !nonTranslatableLanguages.includes(x.code));
  return supportedAndTranslatable;
}

export function ensure4LetterIsoLanguage<T extends string | string[]>(anyLetter: T): T {
  if (!Array.isArray(anyLetter)) {
    if (anyLetter != null && anyLetter.length == 2) {
      return mapIso2To4IsoLanguage(anyLetter.toUpperCase()) as T;
    } else if (anyLetter != null && anyLetter.length == 5 && anyLetter[3] === anyLetter[3].toLowerCase()) {
      return (anyLetter.substring(0, 3) + anyLetter[3].toUpperCase() + anyLetter[4].toUpperCase()) as T;
    } else {
      return anyLetter;
    }
  } else {
    return anyLetter.map((x) => ensure4LetterIsoLanguage(x)) as T;
  }
}

export function ensure2LetterIsoLanguage<T extends string | string[]>(anyLetter: T): T {
  if (!Array.isArray(anyLetter)) {
    if (anyLetter != null && anyLetter.length == 5) {
      return mapIso4To2IsoLanguage(anyLetter) as T;
    } else {
      return anyLetter;
    }
  } else {
    return anyLetter.map((x) => ensure2LetterIsoLanguage(x)) as T;
  }
}

export function equalsSupportedLanguage(lang1: string, lang2: string): boolean {
  return ensure4LetterIsoLanguage(lang1).toUpperCase() === ensure4LetterIsoLanguage(lang2).toUpperCase();
}

export function mapLanguageToIso2CodeLanguage(language: Language): Language {
  return { ...language, code: ensure2LetterIsoLanguage(language.code) };
}

// Languages are saved as Iso2 --> replace code property of ISO4 by a ISO2
export function mapIso2CodeToIso2CodeLanguage(iso2Code: string): Language {
  return { ...languageMap[iso2Code], code: iso2Code };
}

function mapIso4To2IsoLanguage(fourLetter: string): string {
  return Object.entries(languageMap).find(([, x]) => x.code === fourLetter)?.[0];
}

function mapIso2To4IsoLanguage(twoLetter: string): string {
  return languageMap[twoLetter]?.code;
}
