import { isEmpty, isNil, uniqBy } from "lodash";
import { validateAllowances, validateBenefits, validateNumbers } from "./labor-contract-validation";
import { ILaborAgreementModel } from "./model/contract-template.model";
import { TranslatableValidationIssue, TranslatedValidationIssue } from "./model/translatable-validation-issue";

export function validateLaborAgreement(
  laborAgreement: ILaborAgreementModel,
  translateError: (error: string) => string
): void {
  const issues = getTranslatableAgreementValidationIssues(laborAgreement);
  if (isEmpty(issues)) {
    return;
  }

  throw new Error(
    issues
      .map(({ propertyName, message }) => new TranslatedValidationIssue(propertyName, translateError(message)))
      .map(({ propertyName, message }) => `${propertyName}: ${message}`)
      .join("\n")
  );
}

export function getTranslatableAgreementValidationIssues(
  laborAgreement: ILaborAgreementModel
): TranslatableValidationIssue[] {
  return [
    ...validateBasics(laborAgreement),
    ...validateMinMaxDates(laborAgreement),
    ...validatePayGrades(laborAgreement),
    ...validateAllowances(laborAgreement.allowances ?? []),
    ...validateBenefits(laborAgreement.benefits ?? []),
  ];
}

function validateBasics(laborAgreement: ILaborAgreementModel): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  if (isNil(laborAgreement.country)) {
    issues.push(new TranslatableValidationIssue("country", "required"));
  }

  const numberValidationIssues = validateNumbers(laborAgreement, ["probationPeriod", "noticePeriod"]);
  return [...issues, ...numberValidationIssues];
}

function validateMinMaxDates(laborAgreement: Partial<ILaborAgreementModel>): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  const { validFrom, validUntil } = laborAgreement;
  if (validFrom && validUntil && validFrom > validUntil) {
    issues.push(new TranslatableValidationIssue("validFrom", "validFrom.errors.isSmallerThanValidUntil"));
  }

  return issues;
}

function validatePayGrades(laborAgreement: Partial<ILaborAgreementModel>): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  const payGrades = laborAgreement.payGrades ?? [];
  if (isEmpty(payGrades)) {
    issues.push(new TranslatableValidationIssue("payGrades", "required"));
  }

  const uniquePayGrades = uniqBy(payGrades, ({ salaryGroup, salaryStep }) =>
    JSON.stringify({ salaryGroup, salaryStep })
  );

  if (uniquePayGrades.length !== payGrades.length) {
    issues.push(new TranslatableValidationIssue("payGrades", "laborAgreement.errors.duplicatePayGrade"));
  }

  for (const payGrade of payGrades) {
    issues.push(...validateNumbers(payGrade, ["compensationRate", "workingHoursPerWeek"]));
  }

  return issues;
}
